import React from "react"
import { Button } from "reactstrap"

export const ButtonBar = ({
  className,
  cancelText,
  onCancelClick,
  buttonText,
  onButtonClick,
  disabled,
}) => (
  <div
    className={`d-flex justify-content-end align-items-center ${
      className || ""
    }`}>
    <span className="text-primary px-4 cursor-pointer" onClick={onCancelClick}>
      {cancelText || "Cancel"}
    </span>
    <Button
      color="primary"
      onClick={onButtonClick}
      disabled={disabled}
      type="button">
      {buttonText || "Submit"}
    </Button>
  </div>
)
