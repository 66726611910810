import React, { Component } from "react"
import { Input } from "reactstrap"

export class GradeSystemListItem extends Component {
  onChange = e => {
    const { onChange, index } = this.props
    onChange(index, e.target)
  }

  deleteGrade = () => {
    const { deleteGrade, index } = this.props
    deleteGrade(index)
  }

  increaseOrder = () => {
    const { changeOrder, index } = this.props
    changeOrder(index, "asc")
  }

  decreaseOrder = () => {
    const { changeOrder, index } = this.props
    changeOrder(index, "desc")
  }

  edit = () => {
    const { index, onClick } = this.props
    onClick(index)
  }

  render() {
    const { index, length, label, code, active, toggleActive } = this.props

    return (
      <div className="student-upload-list__item row border-bottom py-3">
        <div className="col-3 d-flex align-items-center">
          <div className="student-upload-list__item__order mr-2">{index}</div>
          {index < length - 1 ? (
            <i
              className="student-upload-list__item__icon fa fa-arrow-down mr-2"
              onClick={this.decreaseOrder}
            />
          ) : null}
          {index > 0 ? (
            <i
              className="student-upload-list__item__icon fa fa-arrow-up"
              onClick={this.increaseOrder}
            />
          ) : null}
        </div>
        <div className="student-upload-list__item__label col-2">
          {active ? (
            <Input
              type="text"
              name="code"
              value={code}
              onChange={this.onChange}
            />
          ) : (
            code
          )}
        </div>
        <div className="student-upload-list__item__label col-6">
          {active ? (
            <Input
              type="text"
              name="label"
              value={label}
              onChange={this.onChange}
              autoFocus
            />
          ) : (
            <React.Fragment>
              {label}
              <i
                className="student-upload-list__item__label__pencil fa fa-pencil ml-2"
                onClick={this.edit}
              />
            </React.Fragment>
          )}
        </div>

        <div className="col-1 d-flex align-items-center">
          {active ? (
            <i
              className="fa fa-check-square-o student-upload-list__item__icon--check-square"
              onClick={toggleActive}
            />
          ) : (
            <i
              className="fa fa-times student-upload-list__item__icon student-upload-list__item__icon--times"
              onClick={this.deleteGrade}
            />
          )}
        </div>
      </div>
    )
  }
}
