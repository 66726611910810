import React, { useState } from "react"
import { CautionModal, SuccessModal } from "components"

export const DangerousMutation = ({
  dangerousMutation,
  mutationLoading,
  cautionHeading,
  cautionText,
  cautionButtonText,
  successMessage,
  errorMessage,
  errorHandler,
  toggleComponent,
  showSuccess = true,
}) => {
  const [showCautionModal, setShowCautionModal] = useState(true)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessageToUse, setErrorMessageToUse] = useState("")

  const toggleCautionModal = () => setShowCautionModal(!showCautionModal)
  const toggleSuccessModal = () => setShowSuccessModal(!showSuccessModal)

  const executeDangerousMutation = () => {
    dangerousMutation()
      .then(() => {
        toggleCautionModal()
        showSuccess ? toggleSuccessModal() : toggleComponent()
      })
      .catch(error => {
        if (errorMessage) {
          setErrorMessageToUse(errorMessage)
        } else {
          const updatedErrorMessage = errorHandler(error)
          setErrorMessageToUse(updatedErrorMessage)
        }
        setHasError(true)
      })
  }

  return (
    <>
      {showCautionModal && (
        <CautionModal
          isOpen
          loading={mutationLoading}
          toggle={toggleComponent}
          heading={cautionHeading}
          text={cautionText}
          buttonText={cautionButtonText}
          onButtonClick={executeDangerousMutation}
          error={hasError ? errorMessageToUse : ""}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          isOpen
          toggle={toggleComponent}
          heading="Success"
          text={successMessage}
        />
      )}
    </>
  )
}
