import React from "react"

import Logo from "images/logo.png"
import {
  TERMS_OF_USE_LINK,
  PRIVACY_POLICY_LINK,
} from "domains/app/constants/documentLinks"

export const Footer = ({ className = "" }) => (
  <div className={`${className} c-footer container color-footer-text`}>
    <div className="row py-3">
      <div className="col-md-8">
        <img className="c-footer__logo" src={Logo} alt="Logo" />
        <span className="ml-3 color-footer-text">
          &copy; Class Solver. All rights reserved.
        </span>
        <a
          href={TERMS_OF_USE_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className="color-footer-text ml-3">
          Terms of Use
        </a>
        <a
          href={PRIVACY_POLICY_LINK}
          target="_blank"
          rel="noopener noreferrer"
          className="color-footer-text ml-3">
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
)
