import { cloneDeep } from "lodash"
import { isNonEmptyArray } from "util/array"

import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { sortSolverStudents } from "util/sortUtil"

const getDifference = (
  oldMetricsData,
  newMetricsData,
  metricName,
  percentage = false
) => {
  // field to get from metrics object
  const countField = `${metricName}.count`
  const oldMetricCount = getPropertyIfDefined(oldMetricsData, countField)
  const newMetricCount = getPropertyIfDefined(newMetricsData, countField)

  if (isDefinedNotNull(oldMetricCount) && isDefinedNotNull(newMetricCount)) {
    if (percentage) {
      // get total fields to be able to calculate percentage differences
      const totalField = `${metricName}.total`
      const oldMetricTotal = getPropertyIfDefined(oldMetricsData, totalField)
      const newMetricTotal = getPropertyIfDefined(newMetricsData, totalField)

      return (
        Math.floor((newMetricCount / newMetricTotal) * 100) -
        Math.floor((oldMetricCount / oldMetricTotal) * 100)
      )
    } else {
      return newMetricCount - oldMetricCount
    }
  }
  return undefined
}

export const getMetricDifferences = (
  oldData,
  newData,
  friendshipPreferences = true,
  differentTeacherSetting = true,
  characteristicId = ""
) => {
  const metricDifferences = {
    atLeastOneFriend: friendshipPreferences
      ? getDifference(oldData, newData, "atLeastOneFriend")
      : "-",
    mandatoryRequests: getDifference(oldData, newData, "mandatoryRequests"),
    importantRequests: getDifference(oldData, newData, "importantRequests"),
    genderBalance: getDifference(oldData, newData, "genderBalance"),
    differentTeacher: differentTeacherSetting
      ? getDifference(oldData, newData, "differentTeacher")
      : "-",
    characteristicScores: getDifference(
      oldData,
      newData,
      "characteristicScores.aggregate",
      true
    ),
  }
  if (characteristicId !== "" || characteristicId !== "all") {
    return {
      ...metricDifferences,
      activeCharacteristic: getCharacteristicMetricDifference(
        oldData,
        newData,
        characteristicId
      ),
    }
  }
  return metricDifferences
}

export const getBoostedMetricDifferences = (
  data,
  friendshipPreferences = true,
  differentTeacherSetting = true
) => {
  const newSolution = getPropertyIfDefined(data, "new")
  // If there is no result from boosy
  if (!isDefinedNotNull(newSolution)) {
    return undefined
  }
  const newSolutionMetrics = getPropertyIfDefined(data, "new.solutionMetrics")
  const oldSolutionMetrics = getPropertyIfDefined(data, "old.solutionMetrics")

  return getMetricDifferences(
    oldSolutionMetrics,
    newSolutionMetrics,
    friendshipPreferences,
    differentTeacherSetting
  )
}

const getCharacteristicMetricDifference = (
  oldMetrics,
  newMetrics,
  characteristicId
) => {
  // find the correct characteristic
  const newSolutionCharacteristic =
    newMetrics.characteristicScores.characteristicMetrics.find(
      metric =>
        metric.characteristic.id.toString() === characteristicId.toString()
    )
  const oldSolutionCharacteristic =
    oldMetrics.characteristicScores.characteristicMetrics.find(
      metric =>
        metric.characteristic.id.toString() === characteristicId.toString()
    )

  if (
    isDefinedNotNull(newSolutionCharacteristic) &&
    isDefinedNotNull(oldSolutionCharacteristic) &&
    oldSolutionCharacteristic.metric &&
    newSolutionCharacteristic.metric
  ) {
    return (
      Math.floor(
        (newSolutionCharacteristic.metric.count /
          newSolutionCharacteristic.metric.total) *
          100
      ) -
      Math.floor(
        (oldSolutionCharacteristic.metric.count /
          oldSolutionCharacteristic.metric.total) *
          100
      )
    )
  }
  return undefined
}

export const getBoostedCharacteristicMetricDifference = (
  data,
  characteristicId
) => {
  const newSolution = getPropertyIfDefined(data, "new")
  // If there is no result from boost
  if (!isDefinedNotNull(newSolution)) {
    return undefined
  }
  const newSolutionMetrics = getPropertyIfDefined(data, "new.solutionMetrics")
  const oldSolutionMetrics = getPropertyIfDefined(data, "old.solutionMetrics")

  return getCharacteristicMetricDifference(
    oldSolutionMetrics,
    newSolutionMetrics,
    characteristicId
  )
}

export const getStudentMetricDifferences = (
  data,
  studentId,
  previousStudentMetrics,
  friendshipPreferences = true
) => {
  const newSolution = getPropertyIfDefined(data, "new")
  // If there is no result from boost
  if (!newSolution || !previousStudentMetrics) {
    return undefined
  }

  const newStudentMetric = data.solution.studentMetrics.find(metrics => {
    return metrics.student.id === studentId
  })

  const oldStudentMetric = previousStudentMetrics

  // check if different teacher has changed
  let differentTeacher = 0
  if (
    oldStudentMetric.metrics.differentTeacher !==
    newStudentMetric.metrics.differentTeacher
  ) {
    differentTeacher = newStudentMetric.metrics.differentTeacher ? 1 : -1
  }

  return {
    friendshipPreferences: friendshipPreferences
      ? getDifference(
          oldStudentMetric.metrics,
          newStudentMetric.metrics,
          "friendshipPreferences"
        )
      : "-",
    mandatoryRequests: getDifference(
      oldStudentMetric.metrics,
      newStudentMetric.metrics,
      "mandatoryRequests"
    ),
    importantRequests: getDifference(
      oldStudentMetric.metrics,
      newStudentMetric.metrics,
      "importantRequests"
    ),
    differentTeacher,
  }
}

/**
 * Moves an item from one list to another list.
 */
export const move = (source, destination, studentIndexes) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const studentIndexesInReverseOrder = studentIndexes.toReversed()

  for (const studentIndex of studentIndexesInReverseOrder) {
    const [removed] = sourceClone.splice(studentIndex, 1)
    // push removed student onto class
    destClone.push(removed)
  }

  const result = {}
  result["sourceClone"] = sourceClone
  result["destClone"] = destClone

  return result
}

export const getBoostedStudentId = boostedStudent => {
  return getPropertyIfDefined(boostedStudent, "studentMetric.student.id")
}

export const cloneAndSortData = data => {
  const newData = cloneDeep(data)
  const newGradeId = newData.activeGrade.id.toString()
  newData.classes.sort((a, b) =>
    a.classDetails.label.localeCompare(b.classDetails.label)
  )
  // Sort the students in each class
  const newClasses = newData.classes.map(c => {
    sortSolverStudents(newGradeId, c.students)
    return c
  })
  // assign data the sorted classes
  return { ...newData, classes: newClasses }
}

export const sortClassesForActiveGrade = (classes, activeGradeId) => {
  const clonedClasses = cloneDeep(classes)
  const newGradeId = activeGradeId.toString()
  clonedClasses.sort((a, b) =>
    a.classDetails.label.localeCompare(b.classDetails.label)
  )
  // Sort the students in each class
  const newClasses = clonedClasses.map(c => {
    sortSolverStudents(newGradeId, c.students)
    return c
  })
  // return the sorted classes
  return newClasses
}

export const hasAsyncSolveInProgress = data => {
  return data.__typename === "SolutionStatus"
}

export const hasClassEntryCriteria = classes => {
  return classes.some(({ classDetails }) => {
    return (
      isNonEmptyArray(classDetails.entryCriteriaStudentAttribute) ||
      isNonEmptyArray(classDetails.entryCriteriaCharacteristicResponse) ||
      isNonEmptyArray(classDetails.entryCriteriaNullResponse)
    )
  })
}
