import React from "react"

import {
  CharacteristicResponseCounts,
  CharacteristicResponseTooltip,
} from "domains/solver/components"

import { isCurrentClassCharacteristic } from "util/characteristics"

const numStudentsWithResponse = (characteristicResponse, students) => {
  return students.filter(student => {
    return student.characteristicResponses.some(
      response => response.id === characteristicResponse.id
    )
  }).length
}

export const SolverClassHeader = ({
  students,
  classData,
  isPrint = false,
  editable = true,
  toggleEditClassModal,
  hasGenderX,
  lockState,
  lockClassStudents,
  readMode,
  activeGradeId,
  hasMax,
  activeCharacteristic,
  currentClassResponses,
}) => {
  const {
    classDetails: {
      id: classId,
      label,
      schoolGrades,
      maxClassSize,
      maxSizePerGrade,
    },
    counts,
  } = classData

  let characteristicResponseCounts = []

  if (activeCharacteristic) {
    const characteristicResponses = isCurrentClassCharacteristic(
      activeCharacteristic
    )
      ? currentClassResponses
      : activeCharacteristic.characteristicResponses

    characteristicResponseCounts = characteristicResponses.map(
      characteristicResponse => {
        const studentCount = numStudentsWithResponse(
          characteristicResponse,
          students
        )
        return {
          characteristicResponse,
          studentCount,
        }
      }
    )
  } else {
    characteristicResponseCounts = []
  }

  const isCompositeClass = schoolGrades.length > 1

  const outerClassName = isPrint
    ? "table-header-class-header"
    : "c-solver-class__header"
  const innerClassNames = isPrint
    ? "table-header-class-header-count"
    : "c-solver-class__header__count d-print-none"

  const handleClick = editable ? toggleEditClassModal : () => {}

  return (
    <div
      className={`${outerClassName} d-flex align-items-center justify-content-between`}>
      <div className="d-flex flex-column justify-content-between h-100">
        <span className="d-flex flex-grow-1 align-items-center">
          {readMode || (
            <i
              className={
                "d-print-none d-flex align-items-center " +
                (lockState
                  ? "fa fa-lock c-solver-class__header_locked"
                  : "fa fa-unlock c-solver-class__header_unlocked")
              }
              onClick={lockClassStudents}
            />
          )}
          <span
            className={`${editable && "cursor-pointer"} ${readMode && "ml-2"}`}
            onClick={handleClick}>
            {label}
          </span>
        </span>
        <div
          className="cursor-pointer"
          data-tooltip-id={`characteristic-response-counts-${classId}`}>
          <CharacteristicResponseCounts
            characteristicResponseCounts={characteristicResponseCounts}
          />
        </div>
        <CharacteristicResponseTooltip
          id={`characteristic-response-counts-${classId}`}
          activeCharacteristic={activeCharacteristic}
          characteristicResponseCounts={characteristicResponseCounts}
          className="c-characteristic-tooltip p-0"
          place="bottom"
          variant="light"
          delayShow={500}
        />
      </div>
      <div className={innerClassNames}>
        {isCompositeClass ? (
          <CompositeClassCountSummary
            counts={counts}
            hasGenderX={hasGenderX}
            maxClassSize={maxClassSize}
            maxSizePerGrade={maxSizePerGrade}
            activeGradeId={activeGradeId}
            hasMax={hasMax}
          />
        ) : (
          <SingleGradeCountSummary
            counts={counts}
            hasGenderX={hasGenderX}
            maxClassSize={maxClassSize}
            hasMax={hasMax}
          />
        )}
      </div>
    </div>
  )
}

const exceededClass = exceeded => (exceeded ? "text-danger" : "")

const Max = ({ max }) => {
  return max ? <span>({max} max)</span> : <span>&nbsp;</span>
}

const SingleGradeCountSummary = ({
  counts: {
    genders: { activeFemaleCount, activeMaleCount, activeNonBinaryCount },
    total,
  },
  hasGenderX,
  maxClassSize,
  hasMax,
}) => {
  const exceedsClassMax = maxClassSize ? total > maxClassSize : false
  return (
    <>
      <span>{activeFemaleCount} F</span>
      <span>{activeMaleCount} M</span>
      {hasGenderX && <span>{activeNonBinaryCount} X</span>}
      <span className={exceededClass(exceedsClassMax)}>{total} T</span>
      {hasMax && (
        <div className={`u-text-10 ${exceededClass(exceedsClassMax)}`}>
          <Max max={maxClassSize} />
        </div>
      )}
    </>
  )
}

const CompositeClassCountSummary = ({
  counts: {
    genders: {
      activeFemaleCount,
      activeMaleCount,
      activeNonBinaryCount,
      totalFemaleCount,
      totalMaleCount,
      totalNonBinaryCount,
    },
    total: activeTotalCount,
  },
  hasGenderX,
  maxClassSize,
  maxSizePerGrade,
  activeGradeId,
  hasMax,
}) => {
  const totalCount = totalFemaleCount + totalMaleCount + totalNonBinaryCount

  const maxSizeForGrade = maxSizePerGrade.find(
    ({ grade: { id } }) => id === activeGradeId
  )

  const maxGradeSize = maxSizeForGrade ? maxSizeForGrade.max : null

  const exceedsClassMax = maxClassSize ? totalCount > maxClassSize : false
  const exceedsGradeMax = maxGradeSize ? activeTotalCount > maxGradeSize : false

  return (
    <>
      <div
        className="text-right"
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          whiteSpace: "pre",
        }}>
        <div className="px-1">{activeFemaleCount} |</div>
        <div>{totalFemaleCount} F</div>
        <div className="px-1">{activeMaleCount} |</div>
        <div>{totalMaleCount} M</div>
        {hasGenderX && (
          <>
            <div className="px-1">{activeNonBinaryCount} |</div>
            <div>{totalNonBinaryCount} X</div>
          </>
        )}
        <div className="px-1">
          <span className={exceededClass(exceedsGradeMax)}>
            {activeTotalCount}
          </span>{" "}
          |
        </div>
        <div>
          <span className={exceededClass(exceedsClassMax)}>{totalCount} T</span>
        </div>
        {hasMax && (
          <>
            <div className={`px-1 u-text-10 ${exceededClass(exceedsGradeMax)}`}>
              <Max max={maxGradeSize} />
              &nbsp;
            </div>
            <div className={`u-text-10 ${exceededClass(exceedsClassMax)}`}>
              <Max max={maxClassSize} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
