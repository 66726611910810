import { cloneDeep } from "lodash"

import { emailQuery } from "domains/auth/graphql"
import { solverClientQuery } from "domains/solver/graphql"
import { studentFlagsClientQuery } from "domains/students/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"
import { getStudentImportMatchers } from "domains/upload/graphql"
import * as resolverTypes from "constants/resolverTypes"

export const Resolvers = {
  Mutation: {
    updateEmail: (_, { email }, { cache }) => {
      const query = emailQuery
      // Get Previous State
      const previous = cache.readQuery({ query })

      const data = {
        email: {
          ...previous.email,
          email: email,
        },
      }

      cache.writeQuery({ query, data })
      return null
    },
    updateSolverActiveGrade: (_, { activeGrade }, { cache }) => {
      const query = solverClientQuery

      // Get Previous State
      const previous = cache.readQuery({ query })

      const data = {
        solver: {
          ...previous.solver,
          activeGrade,
        },
      }

      cache.writeQuery({ query, data })
      return null
    },
    updateLastAssignment: (_, { lastAssignmentCached }, { cache }) => {
      const query = solverClientQuery

      // Get Previous State
      const previous = cache.readQuery({ query })

      const data = {
        solver: {
          ...previous.solver,
          lastAssignmentCached: lastAssignmentCached,
        },
      }

      cache.writeQuery({ query, data })
      return null
    },

    updateSolverUndoRedo: (_, { type, assignmentObject }, { cache }) => {
      const query = solverClientQuery
      // Get Previous State
      const previous = cloneDeep(cache.readQuery({ query }))

      let data = {}

      switch (type) {
        case resolverTypes.PUSH: {
          // add assignment object to undoMoves
          data = {
            solver: {
              ...previous.solver,
              undoMoves: [...previous.solver.undoMoves, assignmentObject],
              redoMoves: [],
            },
          }

          cache.writeQuery({ query, data })
          return null
        }
        case resolverTypes.CLEAR: {
          // clear the undo redo lists
          data = {
            solver: {
              ...previous.solver,
              undoMoves: [],
              redoMoves: [],
            },
          }

          cache.writeQuery({ query, data })
          return null
        }
        case resolverTypes.UNDO: {
          // pop from undoMoves and push onto redoMoves
          const object = previous.solver.undoMoves.pop()

          data = {
            solver: {
              ...previous.solver,
              undoMoves: previous.solver.undoMoves,
              redoMoves: [...previous.solver.redoMoves, object],
            },
          }

          cache.writeQuery({ query, data })

          const assignmentObjectUpdated = {
            toAssignment: object.fromAssignment,
            fromAssignment: object.toAssignment,
          }

          return {
            assignmentObject: assignmentObjectUpdated,
            type: resolverTypes.UNDO,
          }
        }
        case resolverTypes.REDO: {
          // pop from redoMoves and push onto undoMoves
          const object = previous.solver.redoMoves.pop()

          data = {
            solver: {
              ...previous.solver,
              redoMoves: previous.solver.redoMoves,
              undoMoves: [...previous.solver.undoMoves, object],
            },
          }

          cache.writeQuery({ query, data })

          return {
            assignmentObject: object,
            type: resolverTypes.REDO,
          }
        }
        default: {
          return null
        }
      }
    },
    updateStudentEditFlag: (_, { type, value }, { cache }) => {
      const query = studentFlagsClientQuery
      // Get Previous State
      const previous = cache.readQuery({ query })
      let data = {}

      switch (type) {
        case resolverTypes.DELETED_STUDENT: {
          // also set student updated to true
          data = {
            studentFlags: {
              ...previous.studentFlags,
              deletedStudent: value,
              studentUpdated: true,
            },
          }
          break
        }
        case resolverTypes.STUDENT_UPDATED: {
          // also set student updated to true
          data = {
            studentFlags: {
              ...previous.studentFlags,
              studentUpdated: value,
            },
          }
          break
        }
        default:
          break
      }

      cache.writeQuery({ query, data })
      return null
    },
    updateSettings: (_, { type, value }, { cache }) => {
      const query = settingsClientQuery
      // Get Previous State
      const previous = cache.readQuery({ query })
      let data = {}

      switch (type) {
        case resolverTypes.ADMIN_ONLY: {
          // also set student updated to true
          data = {
            settings: {
              ...previous.settings,
              adminOnlyRequests: value,
            },
          }
          break
        }
        default:
          break
      }

      cache.writeQuery({ query, data })
      return null
    },
    updateStudentImportMatchers: (_, { newMatchers }, { cache }) => {
      const query = getStudentImportMatchers
      const previous = cache.readQuery({ query })

      const data = {
        ...previous,
        studentsImport: {
          ...previous.studentsImport,
          ...newMatchers,
        },
        __typename: "studentsImport",
      }

      cache.writeQuery({ query, data })
      return data
    },
  },
}
