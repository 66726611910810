import React, { Component } from "react"
import { GithubPicker } from "react-color"
import { getOpacity } from "util/colours"

import { CHARACTERISTIC_COLOUR_PALETTE } from "domains/characteristics/constants"

export class ColourPicker extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.displayColourPicker &&
      prevProps.colour !== this.props.colour
    ) {
      this.toggleColourPicker()
    }
  }
  handleColourChange = colour => {
    this.onChange(
      colour.hex +
        Math.round(colour.rgb.a * 255)
          .toString(16)
          .padStart(2, "0")
    )
  }

  onChange = colour => {
    if (this.props.onChange) {
      this.props.onChange(colour)
    }
  }

  toggleColourPicker = () => {
    if (this.props.onToggleColourPicker) {
      this.props.onToggleColourPicker()
    }
  }

  render() {
    const { displayColourPicker, colour } = this.props

    // e.g. "#ffffff00"
    const opacity = getOpacity(colour)
    const hasColour = colour && opacity !== 0

    const buttonStyle = {
      backgroundColor: colour,
      border: hasColour ? "none" : "#bfbfbf solid 1px",
    }

    const iconStyle = {
      color: hasColour ? "inherit" : "#bfbfbf",
    }

    return (
      <div>
        <div
          className="c-chars__dropdown m-2"
          style={buttonStyle}
          onClick={this.toggleColourPicker}>
          <i className="fa fa-chevron-down" style={iconStyle} />
        </div>
        {displayColourPicker && (
          <div className="c-colour-palette">
            <GithubPicker
              onChange={this.handleColourChange}
              triangle="hide"
              colors={CHARACTERISTIC_COLOUR_PALETTE}
              width="215px"
            />
          </div>
        )}
      </div>
    )
  }
}
