import { REGION } from "constants/storageTokens"
import ReactMarkdown from "react-markdown"

export const DEFAULT_REGION = "us"

export const getApiRegion = () => localStorage.getItem(REGION)

export const setApiRegion = region => {
  if (
    region === null ||
    region === "null" ||
    region === "undefined" ||
    region === undefined
  ) {
    localStorage.removeItem(REGION)
  } else {
    localStorage.setItem(REGION, region)
  }
}

export const getApiBaseUri = () => {
  const apiUris =
    regionApiUris[process.env.REACT_APP_ENV || "development"][getApiRegion()]

  return apiUris.substring(0, apiUris.lastIndexOf("/"))
}

export const getRegionFromCountryCode = countryCode => {
  countryCode = countryCode.toLowerCase()

  const countryMappings = {
    us: "us",
    ca: "ca",
    cn: "us",
    au: "au",
    nz: "au",
    za: "au",
  }

  return countryMappings[countryCode] || DEFAULT_REGION
}

export const regionApiUris = {
  production: {
    us: "https://us.api.classsolver.com/api",
    au: "https://au.api.classsolver.com/api",
    ca: "https://ca.api.classsolver.com/api",
  },
  staging: {
    us: "https://classsolverapi-staging-australiasoutheast-as.azurewebsites.net/api",
    au: "https://classsolverapi-staging-australiasoutheast-as.azurewebsites.net/api",
    ca: "https://classsolverapi-staging-australiasoutheast-as.azurewebsites.net/api",
  },
  auth0: {
    us: "https://classsolverapi-auth0-australiasoutheast-as.azurewebsites.net/api",
    au: "https://classsolverapi-auth0-australiasoutheast-as.azurewebsites.net/api",
    ca: "https://classsolverapi-auth0-australiasoutheast-as.azurewebsites.net/api",
  },
  development: {
    us: "http://localhost:4000/api",
    au: "http://localhost:4000/api",
    ca: "http://localhost:5000/api",
  },
}

export const regionAuth0Config = {
  production: {
    us: {
      domain: "us.login.classsolver.com",
      clientId: "2jg8lLm0RuMrtxc9oCU1kbY16qPoqmXD",
      audience: "https://us.api.classsolver.com",
    },
    au: {
      domain: "au.login.classsolver.com",
      clientId: "MAEoLqwXFq2kueZsmUiOYEKhdJ7y5M4m",
      audience: "https://au.api.classsolver.com",
    },
    ca: {
      domain: "ca.login.classsolver.com",
      clientId: "QJ3kECCDtoP8GJ9RlB6z2wGX0Z4PD0u5",
      audience: "https://ca.api.classsolver.com",
    },
  },
  staging: {
    us: {
      domain: "staging-au.login.classsolver.com",
      clientId: "Lu8540jeKpb8y3LYvGU7PEn7G3zX4a0X",
      audience:
        "https://classsolverapi-staging-australiasoutheast-as.azurewebsites.net",
    },
    au: {
      domain: "staging-au.login.classsolver.com",
      clientId: "Lu8540jeKpb8y3LYvGU7PEn7G3zX4a0X",
      audience:
        "https://classsolverapi-staging-australiasoutheast-as.azurewebsites.net",
    },
    ca: {
      domain: "staging-au.login.classsolver.com",
      clientId: "Lu8540jeKpb8y3LYvGU7PEn7G3zX4a0X",
      audience:
        "https://classsolverapi-staging-australiasoutheast-as.azurewebsites.net",
    },
  },
  development: {
    us: {
      domain: "dev-au.login.classsolver.com",
      clientId: "CcWmVvUL3XH7XEXvXWdhzyvy0UZmi4Rz",
      audience: "http://localhost:4000",
    },
    au: {
      domain: "dev-au.login.classsolver.com",
      clientId: "CcWmVvUL3XH7XEXvXWdhzyvy0UZmi4Rz",
      audience: "http://localhost:4000",
    },
    ca: {
      domain: "dev-au.login.classsolver.com",
      clientId: "CcWmVvUL3XH7XEXvXWdhzyvy0UZmi4Rz",
      audience: "http://localhost:4000",
    },
  },
}

export const getField = (introspectedData, fieldName) => {
  return introspectedData.__schema.mutationType.fields.find(
    field => field.name === fieldName
  )
}

export const getFormattedDescription = (introspectedData, fieldName) => {
  const description = getField(introspectedData, fieldName).description

  return <ReactMarkdown>{description}</ReactMarkdown>
}
