import React from "react"
import { GradeMappingSelection } from "./GradeMappingSelection"
import { pluralize } from "util/nameUtil"

export const GradeMappingRow = props => {
  // Destructure props
  const {
    grades,
    gradeLabel,
    handleGradeMappingSelected,
    handleNextGradeSelect,
    handleGradeIgnore,
    allowNewGradeMapping,
  } = props

  // Handle when a grade is mapped
  const handleGradeSelect = React.useCallback(
    code => {
      const selectedGrade = grades.find(grade => grade.code === code)
      return handleGradeMappingSelected(gradeLabel, selectedGrade)
    },
    [grades, gradeLabel, handleGradeMappingSelected]
  )

  // Handle when a next grade is mapped
  const handleNextSelect = React.useCallback(
    code => {
      const selectedGrade = grades.find(grade => grade.code === code)
      return handleNextGradeSelect(gradeLabel, selectedGrade)
    },
    [grades, gradeLabel, handleNextGradeSelect]
  )

  // Handle when the Ignore checkbox is toggled
  const handleToggleIgnore = React.useCallback(
    event => {
      handleGradeIgnore(gradeLabel, event.target.checked)
    },
    [gradeLabel, handleGradeIgnore]
  )

  // Determine if this row is being ignored or not
  const isIgnored = React.useMemo(() => gradeLabel.ignored, [gradeLabel])

  return (
    <div key={gradeLabel.name} className="row mt-3">
      <div className="col-lg pt-1">
        <div className="text-center c-grade-label-mapping__gradeLabel">
          {gradeLabel.name}
        </div>

        <div className="text-center align-center font-italic color-grey-default c-grade-label-mapping__records">
          {pluralize(gradeLabel.studentCount, "student", "students")}
        </div>
      </div>

      <div className="col-md">
        <GradeMappingSelection
          grades={grades}
          mappedGrade={gradeLabel}
          handleGradeSelect={handleGradeSelect}
          isDisabled={isIgnored}
        />
      </div>

      {allowNewGradeMapping && (
        <>
          <div className="col-1 pt-1">
            <em>goes to</em>
          </div>

          <div className="col-md">
            <GradeMappingSelection
              grades={grades}
              mappedGrade={gradeLabel}
              handleNextGradeSelect={handleNextSelect}
              isDisabled={isIgnored}
              isNextGrade
            />
          </div>
        </>
      )}

      <div className="col-sm text-center">
        <input
          type="checkbox"
          onChange={handleToggleIgnore}
          checked={gradeLabel.ignored === true}
        />
      </div>
    </div>
  )
}
