import React, { useState } from "react"
import { Tooltip } from "react-tooltip"
import { Loader } from "components"
import { EditSchoolModal } from "domains/accountSettings/components"
import {
  schoolWithSisQuery,
  schoolWithSisSuperQuery,
} from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"
import { useQuery } from "@apollo/client"
import { fullName } from "util/nameUtil"

export const SchoolStatusTooltip = () => {
  return (
    <Tooltip
      id="school-status"
      className="c-account-details-status-tooltip p-0"
      variant="light"
      opacity={1}>
      <div className="c-account-details-status-tooltip__body">
        <p>
          If “Blind Ding Lock” = True Then “<b>Bling Ding Locked</b>”
        </p>

        <p>
          else If “Solver Lock” = True Then “<b>Solver Locked</b>”
        </p>

        <p>else</p>

        <ol>
          <li className="ml-2">
            <p>
              <b>Not Activated</b> - none of the administrators have verified
              their emails
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Inactive</b> -{" "}
              {
                "if there’s no active administrator (e.g. all administrators were deactivated, there’s no administrator, or all administrators were inactive for >18month)"
              }
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Done</b> -{" "}
              {
                "there’s an active solution for all “new grades” (that’s not LEAVING) that have active students (If too tricky, use <10 unallocated students)"
              }
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Step 3</b> -{" "}
              {
                "there’s an active solution (at least one grade has class list run)"
              }
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Step 2</b> -{" "}
              {
                "there’s more than 1 surveys (either sent, expired or completed) or there is more than 1 requests entered or there is more than 1 friends"
              }
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Step 1</b> - {"there’s a more than 1 active students"}
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Not started</b> -{" "}
              {
                "at least one administrator has verified their emails and 0 active students"
              }
            </p>
          </li>

          <li className="ml-2">
            <p>
              <b>Error</b> - {"technically shouldn’t happen."}
            </p>
          </li>
        </ol>

        <p>{"End if"}</p>
      </div>
    </Tooltip>
  )
}

export const AccountDetails = ({ isSuper }) => {
  const { data, loading } = useQuery(
    isSuper ? schoolWithSisSuperQuery : schoolWithSisQuery,
    {
      variables: { schoolId: getSchoolId() },
      errorPolicy: "all",
    }
  )

  const [editSchoolModal, setEditSchoolModal] = useState(false)

  const toggleEditSchoolModal = () =>
    setEditSchoolModal(prevState => !prevState)

  const school = data && data.school

  return (
    <div className="c-account-details pb-5 u-content-border-bottom">
      <h2 className="mb-5 u-blue-primary">Account Details</h2>

      {loading ? (
        <div className="position-relative">
          <Loader />
        </div>
      ) : (
        <div>
          <div className=" my-1 ml-3">
            <h3 className="mb-3 u-bold u-white-space-pre-wrap text-uppercase">
              {school.name}
            </h3>
            <div>
              <div className="mb-2">{school.contactName}</div>
              <div className="mb-2">
                {`${school.address || ""}  `}
                {`${school.suburb || ""} `}
                {`  ${school.postcode || ""} `}
                {`  ${school.stateCode || ""}`},
                {`
              ${school.countryCode}
              `}
              </div>
              <div className="mb-2">{school.contactNumber}</div>
              <div className="mb-2">{school.email}</div>
              <div className="mb-2">{`${school.numberOfStudents} Students`}</div>
              {isSuper && (
                <>
                  <div className="mb-2">
                    CRM Account Name: {school.crmAccountName}
                  </div>
                  <div className="mb-2">
                    Crm Status:{" "}
                    {school.crmStatus !== null && `${school.crmStatus}`}
                  </div>
                  <div className="mb-2">
                    Account Type:{" "}
                    {school.accountType !== null &&
                      `${school.accountType.toLowerCase()}`}
                  </div>
                  <div className="mb-2">
                    Account Name:{" "}
                    {school.accountName !== null && `${school.accountName}`}
                  </div>
                  <div>
                    {/* <i
                      className="fa fa-info-circle ml-3 icon"
                      data-tooltip-id="school-status"
                    /> */}
                    <div className="mb-2">
                      School Status: <b>{school.schoolStatus}</b>
                      <i
                        className="fa fa-info-circle ml-2 icon"
                        data-tooltip-id="school-status"
                      />
                    </div>

                    <SchoolStatusTooltip />
                  </div>

                  <div className="mb-2">
                    Managers: {school.managers.map(fullName).join(", ")}
                  </div>
                </>
              )}
            </div>
            <a
              className="c-account-details__edit text-primary"
              onClick={toggleEditSchoolModal}>
              Edit
            </a>
            {editSchoolModal && (
              <EditSchoolModal
                toggle={toggleEditSchoolModal}
                isSuper={isSuper}
                refetchQueries={[
                  isSuper
                    ? {
                        query: schoolWithSisSuperQuery,
                        variables: { schoolId: getSchoolId() },
                      }
                    : {
                        query: schoolWithSisQuery,
                        variables: { schoolId: getSchoolId() },
                      },
                ]}
                data={school}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
