import * as React from "react"
import { Button, Input } from "reactstrap"
import { useNavigate, useLocation } from "react-router-dom"

import { HelpModal } from "domains/upload/helpModal"
import { ButtonBar } from "components/buttonBar"

import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"
import { UploadErrorModal } from "domains/upload"

import { sortCurrentClassesByLabel } from "util/sortUtil"

const defaultWarning = {
  body: error => {
    return (
      <div>
        <p>
          Sorry, there was an error importing your data. Please try again and if
          the error persists, please contact us by clicking "Need help
          importing?" with the following error details:
        </p>
        <pre>{error.message}</pre>
      </div>
    )
  },
}
const knownWarnings = {
  "duplicate-column-value": {
    body: ({ details }) => {
      const firstColumnName = details.column_key
      const otherColumnName = Object.keys(details.rows[0]).find(
        columnName => columnName !== firstColumnName
      )
      const errorDetails = details.rows
        .map(row => `${row[firstColumnName]},${row[otherColumnName]}`)
        .join("\n")

      return (
        <div>
          <p>
            The following rows were duplicated in your file. Please double check
            and remove them from the CSV file.
          </p>

          <div>
            <pre className="mb-0">{errorDetails}</pre>
          </div>
        </div>
      )
    },
  },
  "file-has-no-data": {
    body: () => (
      <div>
        <p>
          There is no data to import. Please double check your class label
          matchings and try again.
        </p>
      </div>
    ),
  },
}

export const MapClassLabels = () => {
  // Utility for manipulating the router
  const navigate = useNavigate()

  // Bring in the router location so we can grab the jobId from state
  const { state: routeState } = useLocation()

  // Keep a piece of state which determines if the help modal should be
  // displayed
  const [showHelpModal, setShowHelpModal] = React.useState(false)

  // Keep a piece of state which holds any upload errors
  const [uploadError, setUploadError] = React.useState()

  // Load up the upload job
  const {
    fetch: [uploadJob],
    cancel: [cancelUploadJob],
    update: [updateBulkUploadJob, { loading: isUpdating }],
    import: [importBulkUploadJob, { loading: isImportingJob }],
  } = useBulkUploadJob(routeState.jobId)

  // Memoize a list of possible class labels to select
  const possibleClasses = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      return sortCurrentClassesByLabel(
        uploadJob.fileSummary.currentClassesInSchool
      )
    } else {
      return []
    }
  }, [uploadJob])

  // Memoize a list of classes that are in the CSV file. These are the
  // classes that need to be matched to a possible classes above in `possibleClasses`
  const sourceClasses = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      return sortCurrentClassesByLabel(
        // Ignore blank classes
        uploadJob.fileSummary.sourceClassLabels.filter(
          ({ label }) => label !== ""
        )
      )
    } else {
      return []
    }
  }, [uploadJob])

  const [classesToIgnore, setClassesToIgnore] = React.useState(() => {
    const classLabels = sourceClasses.reduce((acc, c) => {
      const cloned_acc = { ...acc }
      cloned_acc[c.label] = false

      return cloned_acc
    }, {})

    return classLabels
  })

  const maybeSameLabel = (label1, label2) => {
    // Normalize the labels
    const l1 = label1.toLowerCase().replaceAll(/[-_\s]/gi, "")
    const l2 = label2.toLowerCase().replaceAll(/[-_\s]/gi, "")

    return l1 === l2
  }

  // Prefill the mapped classes if they already match a possible class
  const defaultMappedClasses = Object.fromEntries(
    sourceClasses.flatMap(({ label: sourceClassLabel }) => {
      const preMappedClass = possibleClasses.find(
        ({ label: possibleClassLabel }) => {
          return maybeSameLabel(possibleClassLabel, sourceClassLabel)
        }
      )

      return preMappedClass ? [[sourceClassLabel, preMappedClass.label]] : []
    })
  )

  const [mappedClasses, setMappedClasses] = React.useState(defaultMappedClasses)

  // Memoize whether the Import button should be disabled because
  // the user is yet to map some fields
  const isMappingComplete = React.useMemo(() => {
    return sourceClasses.every(
      ({ label }) =>
        classesToIgnore[label] ||
        (mappedClasses[label] && mappedClasses[label] !== "empty")
    )
  }, [mappedClasses, sourceClasses, classesToIgnore])

  // Helper to toggle the help modal
  const toggleHelpModal = React.useCallback(() => {
    setShowHelpModal(showModal => !showModal)
  }, [])

  React.useEffect(() => {
    let disablebutton = true
    for (const val of Object.values(sourceClasses)) {
      if (
        mappedClasses[val.label] === undefined ||
        mappedClasses[val.label] === "empty"
      ) {
        disablebutton = disablebutton && false
      }
    }
    // setDisableAssignAllUnmatchedAsNew(disablebutton)
  }, [mappedClasses, sourceClasses])

  // Handle when the user wants to go back to the mapping columns screen
  const handleBack = React.useCallback(() => {
    return navigate("/Teachers/MapColumns", {
      state: {
        jobId: routeState.jobId,
      },
    })
  }, [navigate, routeState])

  // Handle when the 'Import Now' button is clicked
  const handleContinue = React.useCallback(() => {
    // Compute the new meta we want to assign to the upload job from this step
    const meta = JSON.stringify({
      class_map: mappedClasses,
      classes_to_ignore: classesToIgnore,
    })

    // Put params together nicely
    const params = { meta: meta, overwriteMeta: false }

    // Update the job and show a dialog on success
    return updateBulkUploadJob(params).then(result => {
      if (result.data && result.data.updateBulkUploadJob) {
        importBulkUploadJob()
          .then(() => {
            navigate("/Teachers")
          })
          .catch(error => {
            setUploadError(error.graphQLErrors[0])
          })
      } else {
        // TODO: Show an error
      }
    })
  }, [
    mappedClasses,
    updateBulkUploadJob,
    navigate,
    importBulkUploadJob,
    classesToIgnore,
  ])

  // Handle when the user wishes to cancel upload job
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Teachers")
    })
  }, [cancelUploadJob, navigate])

  // Handle when a classes label is mapped to a possible classes id
  const handleClassMapped = React.useCallback((sourceLabel, classId) => {
    setMappedClasses(mappedClasses => {
      return {
        ...mappedClasses,
        [sourceLabel]: classId,
      }
    })
  }, [])

  // Don't bother rendering this page if there are no classes to map
  if (sourceClasses.length === 0) {
    handleContinue()
  }

  return (
    <div className="container mt-5 pt-5 w-lg-75">
      <div className="mt-5 d-flex flex-row align-items-center">
        <h1 className="mb-0">Match Your Teachers' Current Class Labels</h1>

        <Button
          className="u-font-weight-medium"
          color="link"
          onClick={toggleHelpModal}>
          Need Help Importing?
        </Button>

        <HelpModal
          isOpen={showHelpModal}
          toggle={toggleHelpModal}
          jobId={uploadJob && uploadJob.id}
          onSentNotification={toggleHelpModal}
          type="Teacher"
        />
      </div>

      <div className="pt-3 pb-4">
        The following class labels were found in your Teachers file. Please
        match these to the class labels in Class Solver that were generated
        based on the Student file previously imported.
      </div>

      <div className="pt-3 pb-4">
        Note: If a teacher’s current class was not imported on the Students
        page, please tick ‘Ignore’ for this class label (e.g. non-homeroom
        teachers)
      </div>

      <div className="container">
        <div className="row pb-2 mb-4 u-content-border-bottom">
          <div className="col-6 font-weight-bold">
            Class labels found in your Teachers file
          </div>

          <div className="col-4 justify-between font-weight-bold">
            Class labels in Class Solver
          </div>

          <div className="col-1 pl-5 font-weight-bold">Ignore</div>
        </div>

        {sourceClasses.map(sourceClass => {
          // Helper to map this class when the dropdown changes
          const handleClassSelect = event => {
            handleClassMapped(sourceClass.label, event.target.value)
          }

          const selectedClass = mappedClasses[sourceClass.label]
          const classIsIgnored = !!classesToIgnore[sourceClass.label]

          return (
            <div className="row mt-3">
              <div className="col-6 pt-1 d-flex">
                <div className="w-75 pr-4">
                  <div className="text-center mb-1 c-grade-label-mapping__gradeLabel">
                    {sourceClass.label}
                  </div>
                </div>

                <div className="w-25 text-center">
                  <em>match to</em>
                </div>
              </div>

              <div className="col-4">
                <div className="d-flex">
                  <Input
                    type="select"
                    value={selectedClass}
                    disabled={classIsIgnored}
                    onChange={handleClassSelect}>
                    <option value="empty">Select</option>

                    {possibleClasses.map(c => {
                      return (
                        <option key={c.label} value={c.label}>
                          {c.label}
                        </option>
                      )
                    })}
                  </Input>

                  <div className="pl-3 pr-5 text-success-or-danger">
                    {selectedClass && selectedClass !== "empty" ? (
                      <i
                        className={`fa fa-check text-success ${classIsIgnored ? "invisible" : ""}`}
                      />
                    ) : (
                      <i
                        className={`fa fa-warning text-danger ${classIsIgnored ? "invisible" : ""}`}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div
                className="mx-4 pl-5 col-1"
                style={{
                  display: "flex",
                  justifyContent: "middle",
                  alignItems: "middle",
                }}>
                <Input
                  type="checkbox"
                  checked={classesToIgnore[sourceClass.label]}
                  onChange={() => {
                    const value = classesToIgnore[sourceClass.label]
                    setClassesToIgnore({
                      ...classesToIgnore,
                      [sourceClass.label]: !value,
                    })
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>

      <div className="u-content-border-bottom">&nbsp;</div>

      <div className="mt-3 d-flex align-items-center">
        <span className="text-primary mr-a cursor-pointer" onClick={handleBack}>
          <i className="fa fa-chevron-left mr-2" /> Back to Mapping Columns
        </span>

        <ButtonBar
          buttonText="Import Now"
          cancelText="Cancel Import"
          className="p-3"
          disabled={isUpdating || isImportingJob || !isMappingComplete}
          onButtonClick={handleContinue}
          onCancelClick={handleCancel}
        />
      </div>

      {uploadError && (
        <UploadErrorModal
          isOpen
          title={(knownWarnings[uploadError.message] || defaultWarning).title}
          type="Teachers"
          jobId={uploadJob && uploadJob.id}
          toggle={() => setUploadError()}
          actions={[
            {
              color: "link",
              onClick: handleCancel,
              text: "Cancel",
            },
          ]}>
          {(knownWarnings[uploadError.message] || defaultWarning).body(
            uploadError
          )}
        </UploadErrorModal>
      )}
    </div>
  )
}
