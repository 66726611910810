import React, { Component } from "react"
import { Modal, ModalBody } from "reactstrap"
import { ModalHeader, GradeSystemList } from "components"

export class GradeModal extends Component {
  render() {
    const { isOpen, schoolGrades, toggle } = this.props

    return (
      <Modal isOpen={isOpen} className="c-grade-modal modal-dialog-centered">
        <ModalHeader title="Grade Settings" className="p-4" toggle={toggle} />
        <ModalBody>
          <GradeSystemList
            schoolGrades={schoolGrades}
            toggle={toggle}
            fromSettings
          />
        </ModalBody>
      </Modal>
    )
  }
}
