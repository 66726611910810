import React, { useState } from "react"
import { Button, Row } from "reactstrap"

import { VideoModal } from "components"

import { INSTRUCTION_SURVEY_INVITE_PLAYER_URL } from "constants/videos"

export const InviteSurvey = ({ toggle, navToCharacteristics }) => {
  const [showVideo, setShowVideo] = useState(false)

  const toggleVideo = () => {
    setShowVideo(!showVideo)
  }

  return (
    <div className="c-teachers-invite">
      <Row>
        <span className="c-teachers-invite__heading">SURVEY</span>
      </Row>
      <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
        <h1>Invite teachers to complete Survey</h1>
        <a
          className="color-blue-mid mx-4 mt-1 font-size-14"
          onClick={toggleVideo}>
          <i className="fa fa-play mr-2" />
          {"Watch Video"}
        </a>
        <div className="mt-3 w-75 text-center">
          If you have confirmed the&nbsp;
          <a onClick={navToCharacteristics} className="text-primary">
            Characteristics
          </a>
          &nbsp;you would like to use to balance your classes, and all of your
          students and teachers have been added, it is time to invite your
          Teachers to complete the Survey.
        </div>
        <Button color="primary" className="mt-4" onClick={toggle}>
          <span className="pr-5 pl-5 text-white">Invite Teachers</span>
        </Button>
        <p className="c-text-small pt-2">
          {"(You will be asked which teachers before the survey goes out)"}
        </p>
      </div>
      {showVideo && (
        <VideoModal
          toggle={toggleVideo}
          videoUrl={INSTRUCTION_SURVEY_INVITE_PLAYER_URL}
          title="Instruction Step 1"
        />
      )}
    </div>
  )
}
