import React, { Component } from "react"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { allGradesWithNewClassesQuery } from "domains/solver/graphql"

import { Loader } from "components"
import { ReportHeader, ReportTable } from "domains/reports/components"

import { SCHOOL_ID } from "constants/storageTokens"

class ReportSchool extends Component {
  render() {
    const { loadingGrades, ...others } = this.props

    if (loadingGrades) {
      return <Loader />
    }
    return (
      <div className="container-fluid">
        <ReportHeader reportTitle="School Summary Report" />
        <ReportTable {...others} />
      </div>
    )
  }
}

export const ReportSchoolPage = compose(
  graphql(allGradesWithNewClassesQuery, {
    options: () => ({
      variables: { schoolId: sessionStorage.getItem(SCHOOL_ID) },
    }),
    props: ({ data: { loading, error, allGradesWithNewClasses } }) => ({
      loadingGrades: loading,
      allGradesWithNewClasses,
      error,
    }),
  })
)(withApollo(ReportSchool))
