import React from "react"

export const UnassignedTeacherContent = () => {
  return (
    <div className="p-1">
      <h2> Important: Teachers have not been assigned</h2>
      <div className="c-unassigned-teacher-content">
        There are Requests for students to be paired or separated from teachers,
        but the teachers have not yet been assigned to classes. Therefore, these
        Requests have not been considered.
      </div>

      <div>
        <div>Please assign teachers by:</div>
        <ul>
          <li>Clicking on the pencil next to “No Teacher Assigned”</li>
          <li>Selecting the teacher’s name</li>
          <li>Click “Save”</li>
        </ul>
      </div>

      <div>
        You may need to Boost or Rerun the Solver to ensure these Requests are
        considered.
      </div>
    </div>
  )
}
