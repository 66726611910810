import React, { Component } from "react"
import { VideoModal } from "components"
import { useNavigate } from "react-router-dom"
import { Button, Label } from "reactstrap"
import compose from "lodash.flowright"
import { UploadLoader, SuccessModal, InfoNote } from "components"
import { UploadErrorModal } from "domains/upload"

import ExcelIcon from "images/excel-icon.svg"
import { isDefinedNotNull } from "util/objUtil"
import { getSchoolId } from "util/app"

const initialState = {
  uploadFile: undefined,
  successModal: false,
  warningModal: false,
  warningCode: null,
  warningDetails: null,
  error: null,
  showStepVideo: false,
  showStepVideo2: false,
}

class ColdStartComponent extends Component {
  state = initialState

  openStepVideo = () => {
    this.setState({
      showStepVideo: true,
    })
  }

  openStepVideo2 = () => {
    this.setState({
      showStepVideo2: true,
    })
  }

  closeStepVideo = () => {
    this.setState({
      showStepVideo: false,
    })
  }

  closeStepVideo2 = () => {
    this.setState({
      showStepVideo2: false,
    })
  }

  fileSelect = e => {
    this.setState({
      uploadFile: e.target.files[0],
    })
  }

  fileUpload = () => {
    const { fileUploadMutation } = this.props
    const { uploadFile } = this.state

    this.setState({
      loading: true,
    })
    const variables = {
      file: uploadFile,
      schoolId: getSchoolId(),
    }

    fileUploadMutation({ variables })
      .then(data => {
        if (!data) {
          this.setState({ loading: false, successModal: true })
          // for teacher we upload and import straight away
          return
        }
        this.setState({
          loading: false,
          successModal: true,
          uploadFile: undefined,
        })
      })
      .catch(error => {
        // we show one error at the time
        this.setState({
          loading: false,
          warningModal: true,
          error: error.graphQLErrors && error.graphQLErrors[0],
          warningCode:
            error.graphQLErrors.length > 0
              ? error.graphQLErrors[0].message
              : "generic",
          warningDetails: error.graphQLErrors.length
            ? error.graphQLErrors[0].details
            : "",
          uploadFile: undefined,
        })
      })
  }

  toggle = key => () => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }))
  }

  reset = key => () => {
    this.setState({
      [key]: initialState[key],
    })
  }

  hasKnownWarning = (warningCode, knownWarnings) =>
    isDefinedNotNull(warningCode) &&
    isDefinedNotNull(knownWarnings) &&
    knownWarnings[warningCode]

  render() {
    const {
      type,
      file,
      title,
      step1,
      backText,
      knownWarnings,
      uploadsDisabled = false,
      toggleBulkUploadScreen = () => {},
      toggleTeacherModal,
      displayBulkUploadScreen,
      requiredHeaders,
      videoUrl,
      videoUrl2,
      videoDescriptionText,
      videoDescriptionText2,
      videoOpenText,
      videoOpenText2,
    } = this.props
    const {
      loading,
      successModal,
      warningModal,
      uploadFile,
      warningCode,
      warningDetails,
      error,
    } = this.state

    if (loading) return <UploadLoader type={type} />

    let content
    if (uploadFile) {
      content = (
        <>
          <h2 className="m-2">
            3. You have selected the following file to upload:
          </h2>
          <div className="m-2 row flex-column w-100 align-items-center">
            <div className="m-2 u-text-20 u-bold">{uploadFile.name}</div>
            <div className="btn btn-primary m-2" onClick={this.fileUpload}>
              Confirm and Upload
            </div>
            <input
              type="file"
              id="fileUpload"
              name="fileUpload"
              hidden
              onChange={this.fileSelect}
            />
            <Label for="fileUpload" className="btn btn-secondary m-2">
              Choose Another File
            </Label>

            {displayBulkUploadScreen ? (
              <Button color="link" onClick={toggleBulkUploadScreen}>
                {backText}
              </Button>
            ) : type === "Teachers" ? (
              <span className="m-2">
                &nbsp; Alternatively, you can also&nbsp;
                <span
                  className="text-primary cursor-pointer"
                  onClick={toggleTeacherModal}>
                  Add Teachers Manually
                </span>
              </span>
            ) : null}

            <InfoNote className="my-3" heading="Important Tips">
              Please ensure your file contains column headings.
              <p
                className="mt-2 mb-0 text-uppercase"
                style={{ fonSize: "12px" }}>
                Required Column Headers
              </p>
              <pre className="mb-2">
                {requiredHeaders.replaceAll(",", ", ")}
              </pre>
              If you are using a Mac, please save the file format as CSV UTF-8
              (.csv)
            </InfoNote>
          </div>
        </>
      )
    } else {
      content = (
        <>
          <h2 className="m-2">{step1}</h2>
          <a href={file} download={`${type}_template.csv`}>
            <Button className="btn-200-width" color="primary m-2">
              Download Template
            </Button>
          </a>
          <h2 className="m-2">2. Then upload the completed file</h2>
          {uploadsDisabled ? (
            <Label className="btn-200-width disabled">
              Upload Completed File
            </Label>
          ) : (
            <>
              <input
                type="file"
                id="fileUpload"
                name="fileUpload"
                hidden
                onChange={this.fileSelect}
              />
              <Label
                for="fileUpload"
                className="btn-200-width btn-secondary m-2">
                {uploadFile ? "Choose Another File" : "Upload Completed File"}
              </Label>
            </>
          )}

          {displayBulkUploadScreen ? (
            <Button color="link" onClick={toggleBulkUploadScreen}>
              {backText}
            </Button>
          ) : type === "Teachers" ? (
            <span className="m-2">
              &nbsp; Alternatively, you can also&nbsp;
              <span
                className="text-primary cursor-pointer"
                onClick={toggleTeacherModal}>
                Add Teachers Manually
              </span>
            </span>
          ) : null}
        </>
      )
    }

    return (
      <>
        <div className="row m-4">
          <h1 className="mx-4">{title}</h1>
          <div>
            <a
              className="color-blue-mid mx-4 mt-1 font-size-14"
              onClick={this.openStepVideo}>
              {videoDescriptionText}
              <i className="fa fa-play mr-2 pl-2" />
              {videoOpenText}
            </a>
            <div></div>
            {videoOpenText2 && (
              <a
                className="color-blue-mid mx-4 mt-1 font-size-14"
                onClick={this.openStepVideo2}>
                {videoDescriptionText2}
                <i className="fa fa-play mr-2 pl-2" />
                {videoOpenText2}
              </a>
            )}
          </div>
        </div>

        <div className="c-solver u-row-fix u-layout-component-grow ">
          <div className="c-solver__classes-container container-fluid ">
            <div className="container-fluid d-flex justify-content-center align-items-center">
              <div className="flex-column text-center">
                <img height="150px" src={ExcelIcon} alt="Excel Icon" />
                <div className="d-flex flex-column justify-content-center align-items-center align-self-center u-layout-component-grow">
                  {content}
                  {this.props.children}
                </div>
                <SuccessModal
                  isOpen={successModal}
                  toggle={compose(
                    () => this.props.navigate(this.props.nextStep),
                    toggleBulkUploadScreen,
                    this.toggle("successModal")
                  )}
                  heading="Almost There!"
                  buttonText="Continue"
                  text=""
                />
                {!this.hasKnownWarning(warningCode, knownWarnings) ? (
                  <UploadErrorModal
                    isOpen={warningModal}
                    type={type}
                    toggle={this.toggle("warningModal")}>
                    jobId={error && error.job_id}
                    <p>
                      We’re sorry, but something went wrong with your {type}{" "}
                      record upload.
                      <br />
                      <br />
                      Please click "Need help importing?" to let us know and we
                      will upload the file for you.
                    </p>
                  </UploadErrorModal>
                ) : (
                  <UploadErrorModal
                    title={knownWarnings[warningCode].title}
                    isOpen={warningModal}
                    type={type}
                    jobId={error && error.job_id}
                    toggle={compose(
                      this.reset("warningDetails"),
                      this.reset("warningCode"),
                      this.toggle("warningModal")
                    )}>
                    {knownWarnings[warningCode].body(type, warningDetails)}
                  </UploadErrorModal>
                )}
              </div>
              {this.state.showStepVideo && (
                <VideoModal
                  toggle={this.closeStepVideo}
                  videoUrl={videoUrl}
                  title="Instruction Step 1"
                />
              )}
              {this.state.showStepVideo2 && (
                <VideoModal
                  toggle={this.closeStepVideo2}
                  videoUrl={videoUrl2}
                  title="Instruction Step 2"
                />
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const ColdStart = props => {
  const navigate = useNavigate()

  return <ColdStartComponent {...props} navigate={navigate} />
}
