import { gql } from "@apollo/client"

export const schoolReportQuery = gql`
  query ($solution: SolutionParams!, $adminOnly: Boolean) {
    solution(solution: $solution) {
      ... on Solution {
        id
        metrics(adminOnly: $adminOnly) {
          characteristicScores {
            aggregate {
              count
              total
            }
            characteristicMetrics {
              characteristic {
                id
                name
              }
              metric {
                count
                total
              }
            }
          }
          atLeastOneFriend {
            count
            total
          }
          differentTeacher {
            count
            total
          }
          importantRequests {
            count
            total
          }
          mandatoryRequests {
            count
            total
          }
        }
        studentMetrics(adminOnly: $adminOnly) {
          metrics {
            mandatoryRequests {
              count
              total
            }
            importantRequests {
              count
              total
            }
            differentTeacher
            friendshipPreferences {
              count
              total
            }
          }
          requestsMet {
            mandatoryRequests
            importantRequests
            differentTeacher
            friendshipPreferences
          }
          student {
            id
          }
        }
        activeGrade {
          id
          label
          order
        }
        classes {
          classDetails {
            label
            id
            schoolGrades {
              id
              code
            }
          }
          teachers {
            id
            firstName
            lastName
          }
          students {
            id
            firstName
            lastName
            gender
            studentCode
            newGrade {
              id
            }
            currentClass {
              id
              label
            }
            studentConstraints(adminOnly: $adminOnly) {
              id
              constraintId
              description
              mandatory
              pair
              status {
                reason
                status
              }
              studentTo {
                id
                firstName
                lastName
                newClass {
                  id
                }
              }
              studentFrom {
                id
                firstName
                lastName
                newClass {
                  id
                }
              }
            }
            teacherConstraints(adminOnly: $adminOnly) {
              id
              constraintId
              description
              mandatory
              pair
              status {
                reason
                status
              }
              teacherTo {
                id
                firstName
                lastName
              }
            }
            characteristicResponses {
              id
              label
              description
              value
              colour
              characteristic {
                id
                name
              }
            }
          }
          counts {
            total
            genders {
              activeMaleCount
              totalMaleCount
              activeFemaleCount
              totalFemaleCount
              activeNonBinaryCount
              totalNonBinaryCount
            }
          }
        }
      }
      ... on SolutionStatus {
        id
        state
      }
    }
  }
`

export const unmetConstraintsQuery = gql`
  query ($adminOnly: Boolean, $schoolId: ID!) {
    constraints(adminOnly: $adminOnly, schoolId: $schoolId) {
      unmetConstraints {
        __typename
        ... on ConstraintsStudent {
          constraintId
          met
        }
        ... on ConstraintsTeacher {
          constraintId

          met
        }
      }
    }
  }
`
