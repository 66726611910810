export const SECTIONS = {
  pairing: {
    name: "PAIRING/SEPARATING",
  },
  requestDetails: {
    name: "REQUEST DETAILS",
  },
}

export const HEADERS = [
  {
    name: "Student",
    id: "studentName",
    section: "pairing",
    headerClass: "requests-header-studentName",
    dataClass: "requests-data-studentName",
  },
  {
    name: "",
    id: "studentClass",
    section: "pairing",
    headerClass: "requests-header-studentClass",
    dataClass: "requests-data-studentClass",
  },
  {
    name: `Pair / Separate`,
    id: "studentPair",
    section: "pairing",
    headerClass: "requests-header-studentPair",
    dataClass: "requests-data-studentPair",
  },
  {
    name: "Student/Teacher",
    id: "studentTo",
    section: "pairing",
    headerClass: "requests-header-studentTo",
    dataClass: "requests-data-studentTo",
  },
  {
    name: "",
    id: "studentToClass",
    section: "pairing",
    headerClass: "requests-header-studentToClass",
    dataClass: "requests-data-studentToClass",
  },
  {
    name: "Importance",
    id: "studentRequestImportance",
    section: "pairing",
    headerClass: "requests-header-studentRequestImportance",
    dataClass: "requests-data-studentRequestImportance",
  },
  {
    name: "Comments",
    id: "studentDescription",
    section: "pairing",
    headerClass: "requests-header-studentDescription",
    dataClass: "requests-data-studentDescription",
  },
  {
    name: "Input By",
    id: "studentRequestInputBy",
    section: "requestDetails",
    headerClass: "requests-header-inputBy",
    dataClass: "requests-data-inputBy",
  },
  {
    name: "Input On",
    id: "studentRequestInputOn",
    section: "requestDetails",
    headerClass: "requests-header-inputOn",
    dataClass: "requests-data-inputOn",
  },
  {
    name: "Status",
    id: "studentRequestStatus",
    section: "requestDetails",
    headerClass: "requests-header-requestStatus",
    dataClass: "requests-data-requestStatus",
  },
]
