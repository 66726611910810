export const isNonEmptyArray = arr =>
  !!(arr && Array.isArray(arr) && arr.length)

export const swapElement = (array, a, b) => {
  const newArray = Array.from(array)
  const x = newArray[a]
  newArray[a] = newArray[b]
  newArray[b] = x
  return newArray
}

export const isLast = (array, index) => array.length - 1 === index

export const areEqualArrays = (array1, array2) =>
  JSON.stringify(array1) === JSON.stringify(array2)

export const hasDuplicates = array =>
  [...new Set(array)].length !== array.length
