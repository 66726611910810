import React, { Component } from "react"
import {
  FormGroup,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import { BadgeWithTooltip } from "components"
import { CharacteristicResponses } from "domains/characteristics/components/characteristicResponses"

import { PrioritySelector } from "domains/characteristics/components"

import { ModalHeader } from "components"

const GenderMoreInfoModal = ({ toggle }) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        title="Gender already included"
        toggle={toggle}
        className="p-3"
      />

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between mr-2">
          <i className="fa fa-info-circle u-caution-icon mr-3" />
          <div>
            <h2>Why can’t I add Gender here?</h2>
            <p>
              Gender is a default Characteristic when you import your students
              and therefore does not need to be added here.
            </p>
            <h2>
              How do I check if I included the Gender of each student when I
              imported them?
            </h2>
            <p>{"1) Click on the Students tab"}</p>
            <p>
              {"2) Select a grade using the Filter (towards the top right)"}
            </p>
            <p>{"3) Check that 4th column has a Gender for students"}</p>
            <h2>What if there is no Gender for students?</h2>
            <p>
              Repeat the process of importing Students using our template
              (covered in the Step 1 video), ensuring that there is a gender for
              each student.
            </p>
            <p>
              This process will not delete information, or duplicate students,
              if you use the same student ID.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const FeederSchoolMoreInfoModal = ({ toggle }) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        title="Use Current Class field for Feeder School"
        toggle={toggle}
        className="p-3"
      />

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between mr-2">
          <i className="fa fa-info-circle u-caution-icon mr-3" />
          <div>
            <h2>What is the Current Class field?</h2>
            <p>
              {
                "Current Class is a default field in Class Solver and is bulk imported in Step 1.  This field is also used to define the students that each teacher will complete a survey for (e.g. Mrs Smith completes a survey on the 1A students, as she is currently teaching 1A)."
              }
            </p>
            <h2>Why should I put Feeder Schools in the Current Class field?</h2>
            <p>
              {
                "If you are sending surveys to feeder schools, you only want to send a survey of the students at their school.  So, by using the Current Class field, you will be able to define which feeder school gets which students (e.g. the email recipient at Smithville Primary will only get a survey on the Smithville Primary students)."
              }
            </p>
            <h2>
              How do I put the Feeder School for each student in the Current
              Class field?
            </h2>
            <p>
              Using the Student template, reimport the list of your students,
              and include the Feeder School in the Current Class column.
            </p>
            <p>
              This process will not delete information, or duplicate students,
              if you use the same student ID.
            </p>
            <p>
              If you have any hesitation or are unsure about this, please
              contact us at{" "}
              <a
                className="u-underline u-font-weight-medium u-blue-mid"
                href="mailto: support@classsolver.com">
                support@classsolver.com
              </a>
              .
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const FriendMoreInfoModal = ({ toggle }) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        title="Setting Friendship Nominations"
        toggle={toggle}
        className="p-3"
      />

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between mr-2">
          <i className="fa fa-info-circle u-caution-icon mr-3" />
          <div>
            <h2>Why don’t I add “friends” as a Characteristic?</h2>
            <p>
              {
                "The Characteristics settings is where you add information used for "
              }{" "}
              <b>balancing</b>{" "}
              {
                " your class lists (not friends).  This includes information that describes a student, e.g. academic, behavior, social/emotional."
              }
            </p>
            <h2>Where do we add friends to Class Solver?</h2>
            <p>
              {
                "The default number of friends is five.  You can change (or check) this by clicking on "
              }{" "}
              <b>Settings</b> {"and scrolling down to "}{" "}
              <b>Friendship Preferences</b>.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const nameContainsGenderOrSex = name => {
  const lowerCaseName = name.toLowerCase()
  return lowerCaseName === "gender" || lowerCaseName === "sex"
}

const nameContainsFeederOrSchool = name => {
  const lowerCaseName = name.toLowerCase()
  return lowerCaseName.includes("feeder") || lowerCaseName.includes("school")
}

const nameContainsFriend = name => {
  const lowerCaseName = name.toLowerCase()
  return lowerCaseName.includes("friend")
}

export class Characteristic extends Component {
  state = {
    showGenderMoreInfo: false,
    showFeederSchoolMoreInfo: false,
    showFriendMoreInfo: false,
  }

  displayColourPicker = () => {
    const { index, displayColourPicker } = this.props

    if (
      Array.isArray(displayColourPicker) &&
      displayColourPicker[0] === index
    ) {
      return displayColourPicker[1]
    }
  }

  toggleGenderMoreInfoModal = () => {
    this.setState({
      showGenderMoreInfo: !this.state.showGenderMoreInfo,
    })
  }

  toggleFeederSchoolMoreInfoModal = () => {
    this.setState({
      showFeederSchoolMoreInfo: !this.state.showFeederSchoolMoreInfo,
    })
  }

  toggleFriendMoreInfoModal = () => {
    this.setState({
      showFriendMoreInfo: !this.state.showFriendMoreInfo,
    })
  }

  render() {
    const {
      index,
      last,
      characteristic,
      onCharacteristicChange,
      onIncreasePriorityClick,
      onDecreasePriorityClick,
      onDeleteCharacteristicClick,
      toggleColourPicker,
      toggleAdminOnly,
      toggleTagOnly,
    } = this.props

    const { showGenderMoreInfo, showFeederSchoolMoreInfo, showFriendMoreInfo } =
      this.state

    const forbiddenUseOfGender = nameContainsGenderOrSex(characteristic.name)

    const forbiddenUseOfFeederOrSchool = nameContainsFeederOrSchool(
      characteristic.name
    )
    const forbiddenUseOfFriend = nameContainsFriend(characteristic.name)

    return (
      <div className="c-chars__body row pb-5">
        <i
          className="fa fa-trash c-chars__delete-icon"
          onClick={onDeleteCharacteristicClick}
        />
        <div className="c-chars__body-item col-md-1 pr-0">
          <div className="d-flex align-items-center mt-3">
            <div className="c-chars__icon">{characteristic.priority}</div>
            <PrioritySelector
              index={index}
              last={last}
              onDecreasePriorityClick={onDecreasePriorityClick}
              onIncreasePriorityClick={onIncreasePriorityClick}
            />
          </div>
        </div>
        <FormGroup className="c-chars__body-item col-md-3">
          <Label>Characteristic Name</Label>
          <Input
            placeholder="Characteristic name"
            type="text"
            name="name"
            onChange={e => {
              onCharacteristicChange({
                ...characteristic,
                name: e.target.value,
              })
            }}
            value={characteristic.name}
          />
          <BadgeWithTooltip
            id={`characteristic-tag-only-${index}`}
            badgeType={characteristic.tag_only ? "tag-only" : ""}
            onClick={toggleTagOnly}
            labelText="Tag Only"
            tooltipHeader="Use this Characteristic as a Tag Only"
            tooltipBody="Turn this on if you would like to use this Characteristic as a tag only, rather than for balancing your classes."
          />
          <BadgeWithTooltip
            id={`characteristic-admin-only-${index}`}
            badgeType={characteristic.sensitive ? "admin-only" : ""}
            onClick={toggleAdminOnly}
            labelText="Admin Only"
            tooltipHeader="Allow teachers to see this Characteristic"
            tooltipBody="Turn this on if you don't want teachers to see this Characteristic in surveys or when sharing class lists."
          />
          {forbiddenUseOfGender && (
            <div>
              <p className="mx-2 text-danger">
                Gender is already included by default and does not need to be
                added here.{" "}
                <span
                  className="cursor-pointer u-underline"
                  onClick={this.toggleGenderMoreInfoModal}>
                  Learn more
                </span>
              </p>
              {showGenderMoreInfo && (
                <GenderMoreInfoModal toggle={this.toggleGenderMoreInfoModal} />
              )}
            </div>
          )}

          {forbiddenUseOfFeederOrSchool && (
            <div>
              <p className="mx-2 text-danger">
                If you would like to send feeder school surveys please use the
                Current Class field.{" "}
                <span
                  className="cursor-pointer u-underline"
                  onClick={this.toggleFeederSchoolMoreInfoModal}>
                  Learn more
                </span>
              </p>
              {showFeederSchoolMoreInfo && (
                <FeederSchoolMoreInfoModal
                  toggle={this.toggleFeederSchoolMoreInfoModal}
                />
              )}
            </div>
          )}

          {forbiddenUseOfFriend && (
            <div>
              <p className="mx-2 text-danger">
                ‘Friends’ are not captured here as a Characteristic.  Please go
                to the Surveys Settings.{" "}
                <span
                  className="cursor-pointer u-underline"
                  onClick={this.toggleFriendMoreInfoModal}>
                  Learn more
                </span>
              </p>
              {showFriendMoreInfo && (
                <FriendMoreInfoModal toggle={this.toggleFriendMoreInfoModal} />
              )}
            </div>
          )}
        </FormGroup>

        <div className="c-chars__body-item col-md d-flex">
          <div className="w-100">
            <div className="w-100 d-flex justify-content-between">
              <Label>Response Labels</Label>
            </div>
            <div>
              <div className="d-flex flex-column">
                <CharacteristicResponses
                  characteristicResponses={
                    characteristic.characteristicResponses
                  }
                  onCharacteristicResponsesChange={characteristicResponses => {
                    onCharacteristicChange({
                      ...characteristic,
                      characteristicResponses,
                    })
                  }}
                  displayColourPicker={this.displayColourPicker()}
                  toggleColourPicker={responseIndex => {
                    toggleColourPicker([index, responseIndex])
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
