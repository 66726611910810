import { isDefinedNotNull } from "util/objUtil"

export const validateNonEmptyString = (
  input,
  minLength = 1,
  maxLength = Number.MAX_SAFE_INTEGER
) => {
  if (isDefinedNotNull(input) && typeof input === "string") {
    const string = input.trim()
    // check if defined and string
    if (string) {
      // check non empty String
      return string.length >= minLength && string.length < maxLength
    }
  }
  return false
}

export const validateStringAsNumber = string => {
  // TODO: use Regex to validate instead of this weird way
  const num = parseInt(string, 10)
  return !isNaN(num) && String(num) === String(string)
}

export const validateEmail = email => {
  // Regex From: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const validateSchoolData = (data, withContact = true) => {
  const errors = {}
  if (!validateNonEmptyString(data.countryCode)) {
    errors.countryCode = "Country is required"
  }
  if (!validateNonEmptyString(data.schoolName)) {
    errors.schoolName = "School Name is required"
  }
  if (!validateNonEmptyString(data.stateCode)) {
    errors.stateCode = "State Code is required"
  }
  if (withContact) {
    if (!validateNonEmptyString(data.contactFirstName)) {
      errors.contactFirstName = "First Name is required"
    }
    if (!validateNonEmptyString(data.contactLastName)) {
      errors.contactLastName = "Last Name is required"
    }
    if (!validateNonEmptyString(data.contactPositionTitle)) {
      errors.contactPositionTitle = "Position Title is required"
    }
    if (!validateEmail(data.contactEmail)) {
      errors.contactEmail = "Invalid Email"
    }
  }

  if (!validateNonEmptyString(data.contactNumber)) {
    errors.contactNumber = "Contact Number is required"
  }

  if (!validateStringAsNumber(data.numberOfStudents)) {
    errors.numberOfStudents = "Invalid Number Of Students"
  }

  return errors
}

export const validateStudent = student => {
  const errors = {}
  if (!validateNonEmptyString(student.firstName)) {
    errors.firstName = "Cannot be blank"
  }
  if (!validateNonEmptyString(student.lastName)) {
    errors.lastName = "Cannot be blank"
  }
  if (!validateNonEmptyString(student.gender)) {
    errors.gender = "Cannot be blank"
  }
  if (!validateNonEmptyString(student.newGrade)) {
    errors.newGrade = "Must select a New Grade"
  }

  return errors
}

export const validateStrongPassword = password => {
  // regex from: https://stackoverflow.com/questions/5142103/regex-to-validate-password-strength
  const regex = new RegExp(
    "^(?=.*[A-Z])(?=.*[!@#$%^&*().,?;:])(?=.*[0-9])(?=.*[a-z]).{6,}$"
  )
  return regex.test(password)
}
