import React, { useState } from "react"

import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"

import { ModalHeader } from "components"

const MoreInfoModal = ({ toggle }) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        title="Updating teachers after rollover"
        toggle={toggle}
        className="p-3"
      />

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between mr-2">
          <i className="fa fa-info-circle u-caution-icon mr-3" />
          <div>
            <h2>How do I update the list?</h2>
            <ol className="pl-4">
              <li className="mb-2">
                Delete any teachers who have left the school.
              </li>
              <li className="mb-2">Add any teachers who are not listed.</li>
              <li>
                Assign each teacher’s Current Class by clicking on a teacher and
                selecting ‘Current Class’ from the dropdown. If they do not
                teach a homeroom, then leave the current class as ’N/A’.
              </li>
            </ol>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const RolledOverNote = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const toggle = () => setShowMoreInfo(prevState => !prevState)

  return (
    <>
      <div className="c-modal-note text-center">
        Your account has been rolled over and the following teachers were found
        from last year. Please update the list and assign their current classes.{" "}
        <span className="cursor-pointer text-primary" onClick={toggle}>
          Learn more
        </span>
      </div>
      {showMoreInfo && <MoreInfoModal toggle={toggle} />}
    </>
  )
}
