import React from "react"
import * as d3 from "d3"
import { useD3 } from "./hooks/useD3"

function unitVector([x1, y1], [x2, y2]) {
  const mag = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
  return [(x2 - x1) / mag, (y2 - y1) / mag]
}

function unitVectorForEdge({ source, target }) {
  const [x, y] = unitVector([source.x, source.y], [target.x, target.y])
  return { x, y }
}

// constrain x between min and max values
function clamp(x, min, max) {
  return x < min ? min : x > max ? max : x
}

export const Sociogram = ({
  width,
  height,
  data,
  _selectedCharacteristic,
  layered = false,
}) => {
  const numNodes = data.nodes.length
  const nodeStrokeWidth = 0

  // Would be nice to see how these values could scale smoothly
  let baseRadius, arrowheadSize, nodeSeparationStrength
  if (numNodes > 120) {
    baseRadius = 8
    arrowheadSize = 3
    nodeSeparationStrength = -50
  } else if (numNodes > 70) {
    baseRadius = 10
    arrowheadSize = 4
    nodeSeparationStrength = -75
  } else if (numNodes > 35) {
    baseRadius = 15
    arrowheadSize = 5
    nodeSeparationStrength = -250
  } else {
    baseRadius = 20
    arrowheadSize = 7
    nodeSeparationStrength = -500
  }
  const boundsRadius = baseRadius * 4
  const layerSeparationStrength = 0.5

  const colorScale = d3
    .scaleOrdinal()
    .domain(data.metadata.groups.map(({ name }) => name))
    .range(data.metadata.groups.map(({ color }) => color))

  const simulation = d3
    .forceSimulation()
    .force(
      "link",
      d3
        .forceLink() // This force provides links between nodes
        .id(d => d.id) // This sets the node id accessor to the specified function. If not specified, will default to the index of a node.
        .distance(80)
    )
    .force("charge", d3.forceManyBody().strength(nodeSeparationStrength)) // This adds repulsion (if it's negative) between nodes.
    .force("x", d3.forceX())
    .force("y", d3.forceY())
    // prevent jitter by stopping the simulation earlier
    .alpha(1)
    .alphaMin(0.005)
    .alphaDecay(0.03)

  if (layered) {
    // this force pushes isolates/ghosts to the top of the box
    simulation.force(
      "force0",
      d3
        .forceY(-height / 2 + 50)
        .strength(d => (d.incoming === 0 ? layerSeparationStrength : 0))
    )
  }

  //When the drag gesture starts, the targeted node is fixed to the pointer
  //The simulation is temporarily “heated” during interaction by setting the target alpha to a non-zero value.
  function dragstarted(event, d) {
    d.fy = d.y
    d.fx = d.x
  }

  //When the drag gesture starts, the targeted node is fixed to the pointer
  function dragged(event, d) {
    const padding = baseRadius * 2
    const x = width / 2 - padding
    const y = height / 2 - padding
    d.fx = clamp(event.x, -x, x)
    d.fy = clamp(event.y, -y, y)
    simulation.alpha(0.5).restart() //sets the current target alpha to the specified number in the range [0,1].
  }

  const ref = useD3(
    svg => {
      const link = svg
        .selectAll(".links")
        .data(data.links)
        .enter()
        .append("line")
        .attr("class", "links")
        .attr("marker-end", "url(#arrowhead)") //The marker-end attribute defines the arrowhead or polymarker that will be drawn at the final vertex of the given shape.
        .attr("stroke", "#999")
        .attr("stroke-opacity", 0.6)
        .attr("stroke-width", "1px")

      // Initialize the nodes
      const node = svg
        .selectAll(".nodes")
        .data(data.nodes)
        .enter()
        .append("g")
        .attr("class", "nodes")
        .call(
          d3
            .drag() //sets the event listener for the specified typenames and returns the drag behavior.
            .on("start", dragstarted) //start - after a new pointer becomes active (on mousedown or touchstart).
            .on("drag", dragged) //drag - after an active pointer moves (on mousemove or touchmove).
        )

      node
        .append("circle")
        .attr("r", baseRadius)
        .style("stroke", "#555")
        .style("stroke-opacity", d => 0.3 + d.incoming * 0.2)
        .style("stroke-width", nodeStrokeWidth)
        .style("fill", d => colorScale(d.group))

      node.append("title").text(d => d.name)

      node
        .append("text")
        .attr("dominant-baseline", "middle")
        .attr("text-anchor", "middle")
        // .style("fill", (d) =>
        //   d3.hsl(colorScale(d.group)).l > 0.5 ? "#000" : "#fff"
        // )
        .text(d => d.label)

      // add legend color boxes
      const legend = svg
        .select(".legend")
        .attr("transform", `translate(${10 - width / 2}, ${(20 - height) / 2})`)
        .selectAll("g")
        .data(data.metadata.groups)
        .enter()
        .append("g")
        .attr("transform", (d, i) => `translate(0,${i * 25})`)

      // add legend colour swatches
      legend
        .append("rect")
        .attr("width", 30)
        .attr("height", 20)
        .style("fill", d => colorScale(d.name))

      // add legend text
      legend
        .append("text")
        .attr("x", 36)
        .attr("y", 10)
        .attr("text-anchor", "start")
        .attr("dominant-baseline", "middle")
        .text(d => d.name)

      //Listen for tick events
      simulation.nodes(data.nodes).on("tick", tick)
      simulation.force("link").links(data.links)

      // This function is run at each iteration of the force algorithm, updating the nodes position (the nodes data array is directly manipulated).
      function tick() {
        link
          .attr("x1", d => {
            const unit = unitVectorForEdge(d)
            return d.source.x + baseRadius * unit.x
          })
          .attr("y1", d => {
            const unit = unitVectorForEdge(d)
            return d.source.y + baseRadius * unit.y
          })
          .attr("x2", d => {
            const unit = unitVectorForEdge(d)
            return d.target.x - (baseRadius + arrowheadSize) * unit.x
          })
          .attr("y2", d => {
            const unit = unitVectorForEdge(d)
            return d.target.y - (baseRadius + arrowheadSize) * unit.y
          })

        node.attr("transform", d => `translate(${d.x},${d.y})`)

        // ensure nodes stay within bounds
        node
          .attr("cx", d => {
            return (d.x = Math.max(
              -(width / 2) + boundsRadius,
              Math.min(width / 2 - boundsRadius, d.x)
            ))
          })
          .attr("cy", d => {
            return (d.y = Math.max(
              -(height / 2) + boundsRadius,
              Math.min(height / 2 - boundsRadius, d.y)
            ))
          })
      }

      // Return a cleanup function for the useEffect function in useD3.
      // The D3 data-DOM bindings need to be removed because they exist
      // outside of React and need to be reconstructed when data changes.
      // Otherwise old nodes are retained and the new nodes are added to previous graph.
      //
      // i.e. on reload or when a different class group is selected
      return () => {
        svg.selectAll(".nodes").remove()
        svg.selectAll(".links").remove()
        // We need to delete the children in here since they already exist, but keep the node so we can target it to insert new children
        svg.select(".legend").selectChildren().remove()
      }
    },
    [data]
  )

  return (
    <svg
      id="sociogram"
      ref={ref}
      viewBox={`-${width / 2} -${height / 2} ${width} ${height}`}
      width={width}
      height={height}
      className="sociogram">
      <defs>
        <marker
          id="arrowhead"
          viewBox="-0 -5 10 10"
          refX="0"
          refY="0"
          orient="auto"
          markerWidth={arrowheadSize}
          markerHeight={arrowheadSize}
          xoverflow="visible">
          <path d="M 0,-5 L 10,0 L 0,5" fill="#999" stroke="none"></path>
        </marker>
      </defs>
      <g className="legend"></g>
    </svg>
  )
}
