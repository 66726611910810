import React, { useState } from "react"

import { InlineError } from "components"
import { SettingItem } from "domains/accountSettings/components"
import { useAuth0 } from "domains/auth/auth0Wrapper"
import { internalCsvExport } from "util/exportUtil"

const ExportFile = ({
  schoolId,
  type,
  description,
  filterSensitive = true,
}) => {
  const context = useAuth0()
  const [error, setError] = useState()

  const csvExport = async () => {
    setError(null)

    const [response, errorMessage] = await internalCsvExport(
      type,
      schoolId,
      context,
      filterSensitive
    )

    if (errorMessage) {
      setError(`Error: ${response.status} - ${errorMessage}`)
    }
  }

  return (
    <>
      <SettingItem onButtonClick={csvExport} buttonText="Export">
        {description}
      </SettingItem>
      {error && <InlineError className="mx-3" text={error} />}
    </>
  )
}

export const ExportDataFiles = ({ schoolId, isSuper }) => {
  return (
    <div className="u-content-border-bottom pb-5">
      <h2 className="mb-5 mt-5 u-blue-primary">Export CSV files</h2>
      {isSuper && (
        <>
          <ExportFile
            description="Export Active Students CSV"
            type="students"
            schoolId={schoolId}
          />
          <ExportFile
            description="Export All Students CSV"
            type="all_students"
            schoolId={schoolId}
          />
          <ExportFile
            description="Export Teachers CSV"
            type="teachers"
            schoolId={schoolId}
          />
          <ExportFile
            description="Export Teacher Requests CSV"
            type="teacher_requests"
            schoolId={schoolId}
            filterSensitive={false}
          />
          <ExportFile
            description="Export Student Requests CSV"
            type="student_requests"
            schoolId={schoolId}
            filterSensitive={false}
          />
          <ExportFile
            description="Export Characteristic Responses CSV"
            type="characteristic_responses"
            schoolId={schoolId}
          />
          <ExportFile
            description="Export Friendships CSV"
            type="friendships"
            schoolId={schoolId}
          />
          <ExportFile
            description="Export Solutions CSV"
            type="solutions"
            schoolId={schoolId}
          />
        </>
      )}

      <ExportFile
        description="Export SC Settings CSV"
        type="school_characteristics"
        schoolId={schoolId}
      />
      <ExportFile
        description="Export Classes CSV"
        type="new_classes"
        schoolId={schoolId}
      />
    </div>
  )
}
