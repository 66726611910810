import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import moment from "moment"

import { Input, FormGroup, Label, Modal, Button } from "reactstrap"
import { useNavigate, Navigate } from "react-router-dom"

import { ModalHeader, Loader } from "components"
import {
  TERMS_OF_USE_LINK,
  PRIVACY_POLICY_LINK,
} from "domains/app/constants/documentLinks"
import { updateSelfMutation } from "domains/accountSettings/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { hasAcceptedTerms } from "util/userUtil"

export const ConsentPage = () => {
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [acceptedUpdatesAndMarketing, setAcceptedUpdatesAndMarketing] =
    useState(false)
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false)

  const { data: userProfileData, loading: loadingUser } = useQuery(
    userProfileQuery,
    { fetchPolicy: "network-only" }
  )

  const navigate = useNavigate()

  const [updateSelf] = useMutation(updateSelfMutation, {
    onCompleted: () => navigate("/"),
    onError: () => navigate("/"),
  })

  if (loadingUser) {
    return <Loader />
  }

  const allAccepted = acceptedTerms && acceptedPrivacy

  const handleContinue = () => {
    if (allAccepted) {
      const nowString = moment().utc().toISOString()

      const variables = {
        self: {
          id: userProfileData.myprofile.id,
          acceptedCustomerTermsAt: nowString,
          acceptedPrivacyPolicyAt: nowString,
          acceptedUpdatesAndMarketing: acceptedUpdatesAndMarketing
            ? nowString
            : null,
        },
      }

      updateSelf({ variables })
    }
  }

  if (hasAcceptedTerms(userProfileData.myprofile)) {
    return <Navigate to="/" />
  }

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <div className="p-4">
        <ModalHeader
          title={"Terms and Conditions"}
          className="mb-4"
          toggle={() => navigate("/Logout")}
        />
        <div
          className="d-flex flex-column align-items-center"
          style={{ gap: "1rem" }}>
          <div>
            Please accept the user terms to continue, or if you have any
            questions, contact
            <a href="mailto:support@classsolver.com" className="ml-1">
              <u>support@classsolver.com</u>
            </a>
            .
          </div>
          <FormGroup check>
            <Label htmlFor="acceptTerms">
              <Input
                type="checkbox"
                name="acceptTerms"
                id="acceptTerms"
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms(!acceptedTerms)}
              />
              I have read and accept the{" "}
              <a
                href={TERMS_OF_USE_LINK}
                target="_blank"
                rel="noopener noreferrer">
                Customer Terms
              </a>
              , and I am authorized to accept the Customer Terms on behalf of my
              school or institution.
            </Label>
            <Label htmlFor="acceptPrivacy">
              <Input
                type="checkbox"
                name="acceptPrivacy"
                id="acceptPrivacy"
                checked={acceptedPrivacy}
                onChange={() => setAcceptedPrivacy(!acceptedPrivacy)}
              />
              I understand that my information will be used and processed as
              detailed in the{" "}
              <a
                href={PRIVACY_POLICY_LINK}
                target="_blank"
                rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </Label>
            <Label htmlFor="acceptUpdatesAndMarketing">
              <Input
                type="checkbox"
                name="acceptUpdatesAndMarketing"
                id="acceptUpdatesAndMarketing"
                checked={acceptedUpdatesAndMarketing}
                onChange={() =>
                  setAcceptedUpdatesAndMarketing(!acceptedUpdatesAndMarketing)
                }
              />
              I opt-in to receiving important product updates and the occasional
              marketing related email (note: you can always elect to opt out
              later).
            </Label>
          </FormGroup>

          <Button
            color="primary"
            className="w-50"
            disabled={!allAccepted}
            onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}
