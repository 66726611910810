import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import { useNavigate } from "react-router-dom"
import compose from "lodash.flowright"
import {
  requestQuery,
  studentRequestMutation,
  teacherRequestMutation,
  uiRequestsPageQuery,
  deleteConstraintsStudent,
  deleteConstraintsTeacher,
} from "domains/requests/graphql"
import { studentNameQuery } from "domains/students/graphql"
import { teacherNameQuery } from "domains/teachers/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"
import { currentSchoolGradesQuery } from "domains/classes/graphql"
import { userProfileQuery } from "domains/auth/graphql"

import { Loader } from "components"
import { Requests } from "domains/requests/components"

import { handleSessionExpired, getSchoolId } from "util/app"

import { useQuery, useApolloClient } from "@apollo/client"

const setCurrentGrade = client => {
  return currentGrade => {
    client.writeQuery({
      query: uiRequestsPageQuery,
      data: {
        requestsPage: {
          __typename: "requestsPage",
          currentGrade: currentGrade,
        },
      },
    })
  }
}

const RequestsPageComponent = ({
  loadingGrades,
  loadingRequests,
  ...others
}) => {
  const client = useApolloClient()
  const navigate = useNavigate()
  const { data } = useQuery(uiRequestsPageQuery)

  handleSessionExpired(others.error)

  if (loadingRequests || loadingGrades) return <Loader />

  const notMetRequests = others.requestsData.unmetConstraints
    ? others.requestsData.unmetConstraints.map(r => r.constraintId)
    : []

  let currentGrade = "all-grades"
  if (data) {
    currentGrade = data.requestsPage.currentGrade
  }

  return (
    <Requests
      notMetRequests={notMetRequests}
      setCurrentGrade={setCurrentGrade(client)}
      currentGrade={currentGrade}
      navigate={navigate}
      {...others}
    />
  )
}

export const RequestsPage = compose(
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(requestQuery, {
    options: ({ settings }) => ({
      variables: {
        adminOnly: settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { loading, constraints } }) => ({
      loadingRequests: loading,
      requestsData: constraints,
    }),
  }),
  graphql(currentSchoolGradesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId(), includeNew: true },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { loading, currentSchoolGrades } }) => ({
      loadingGrades: loading,
      currentSchoolGrades,
    }),
  }),
  graphql(userProfileQuery, {
    props: ({ data: { myprofile } }) => ({
      myprofile,
    }),
  }),
  graphql(studentNameQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { students } }) => ({
      students,
    }),
  }),
  graphql(teacherNameQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { teachers } }) => ({
      teachers,
    }),
  }),
  graphql(deleteConstraintsStudent, {
    name: "deleteConstraintsStudent",
  }),
  graphql(deleteConstraintsTeacher, {
    name: "deleteConstraintsTeacher",
  }),
  graphql(studentRequestMutation, { name: "studentRequestMutation" }),
  graphql(teacherRequestMutation, { name: "teacherRequestMutation" })
)(RequestsPageComponent)
