import React from "react"
import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { emailQuery } from "domains/auth/graphql"

import Logo from "images/logo.png"

const logoStyles = {
  maxWidth: 150,
}

export const SignUpSuccessPage = () => {
  const {
    data: {
      email: { email },
    },
  } = useQuery(emailQuery)

  return (
    <div className="c-email-success">
      <Link to="/">
        <img
          src={Logo}
          alt="Class Solver Logo"
          style={logoStyles}
          className="ml-5 mt-5"
        />
      </Link>
      <div className="c-email-success__container">
        <div className="c-email-success__icon-container">
          <i className="fa fa-envelope" />
        </div>
        <h1 className="mt-3">Thank you for signing up!</h1>
        <h2 className="mt-5">We've sent an email to confirm your account.</h2>
        <div className="c-email-success__email-container mt-3">
          <span>{email}</span>
        </div>
        <div className="c-email-success__highlighted-fine-print mt-3">
          <h5>Don't see a message in your inbox?</h5>
          <ul>
            <li>Check your email is correct</li>
            <li>
              Check your <b>junk</b> or <b>spam</b> folder
            </li>
            <li>
              Contact your IT to whitelist{" "}
              <a
                href="https://classsolver.com"
                target="_blank"
                rel="noopener noreferrer">
                classsolver.com
              </a>
            </li>
          </ul>
          <h5>
            Need help?
            <a
              href="mailto:support@classsolver.com"
              className="ml-1 color-grey-mid-light">
              <u>support@classsolver.com</u>
            </a>
          </h5>
        </div>
      </div>
    </div>
  )
}
