import React from "react"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  schoolWithSisQuery,
  createOrUpdateSisConfigPowerschoolMutation,
  preferencesQuery,
  administratorsQuery,
  settingsClientQuery,
  updateSettings,
  updateSchoolSettingMutation,
  bulkUploadMiscMutation,
  invitationsForSchoolQuery,
  revokeSchoolInvitationMutation,
  removeUserFromSchoolsMutation,
} from "domains/accountSettings/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { currentSchoolGradesQuery } from "domains/classes/graphql"

import { AccountSettings } from "domains/accountSettings/components"

import { handleSessionExpired, getSchoolId } from "util/app"
import { ADMIN } from "domains/app/constants/roleTypes"

class AccountSettingsPageComponent extends React.Component {
  UNSAFE_componentWillReceiveProps = nextProps => {
    handleSessionExpired(nextProps.error)
  }

  render() {
    const schoolId = getSchoolId()
    return (
      <AccountSettings
        schoolId={schoolId}
        userRefetchQueries={[
          {
            query: administratorsQuery,
            variables: { schoolId, filterRole: ADMIN },
          },
          {
            query: invitationsForSchoolQuery,
            variables: { schoolId },
          },
          {
            query: schoolWithSisQuery,
            variables: { schoolId },
          },
        ]}
        {...this.props}
      />
    )
  }
}

export const AccountSettingsPage = compose(
  graphql(userProfileQuery, {
    props: ({ data: { myprofile } }) => ({
      myprofile,
    }),
  }),
  graphql(administratorsQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        filterRole: ADMIN,
      },
    }),
    props: ({ data: { users, loading, error } }) => ({
      loadingAdmins: loading,
      error,
      users,
    }),
  }),
  graphql(preferencesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { schoolSettings, loading } }) => ({
      loadingPreferences: loading,
      schoolSettings,
    }),
  }),
  graphql(currentSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeNew: true,
        includeLeaving: true,
      },
    }),
    props: ({ data: { currentSchoolGrades, loading } }) => ({
      loadingSchoolGrades: loading,
      currentSchoolGrades,
    }),
  }),
  graphql(schoolWithSisQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
      errorPolicy: "all",
    }),
    props: ({ data: { school, loading } }) => ({
      loadingSchool: loading,
      school,
    }),
  }),
  graphql(invitationsForSchoolQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { invitationsForSchool, loading } }) => ({
      loadingInvitations: loading,
      invitations: invitationsForSchool,
    }),
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(updateSchoolSettingMutation, { name: "updateSchoolSettingMutation" }),
  graphql(bulkUploadMiscMutation, { name: "bulkUploadMiscMutation" }),
  graphql(updateSettings, { name: "updateSettings" }),
  graphql(revokeSchoolInvitationMutation, {
    name: "revokeSchoolInvitationMutation",
  }),
  graphql(removeUserFromSchoolsMutation, {
    name: "removeUserFromSchoolsMutation",
  }),
  graphql(createOrUpdateSisConfigPowerschoolMutation, {
    name: "createOrUpdateSisConfigPowerschoolMutation",
    options: () => ({
      refetchQueries: [
        {
          query: schoolWithSisQuery,
          variables: { schoolId: getSchoolId() },
        },
      ],
    }),
  })
)(withApollo(AccountSettingsPageComponent))
