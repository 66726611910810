import React from "react"
import { Button } from "reactstrap"
import { Tooltip } from "react-tooltip"

import {
  Loader,
  InlineError,
  SearchInput,
  CautionModal,
  VideoModal,
} from "components"
import { StudentModal, StudentsTable } from "domains/students/components"
import { Footer } from "domains/dashboard/components"

import {
  SURVEY_INSTRUCTIONS_WITH_RESPONSES_URL,
  SURVEY_INSTRUCTIONS_WITHOUT_RESPONSES_URL,
} from "constants/videos"

import { handleSessionExpired } from "util/app"
import { sortStudentsByClass } from "util/sortUtil"

export class SurveyStudents extends React.Component {
  constructor() {
    super()
    this.state = {
      studentModal: false,
      caution: false,
      studentId: "",
      searchValue: "",
      errors: {},
      showSurveyInstructionsVideo: false,
    }
  }

  toggle = () => {
    this.setState({
      studentModal: !this.state.studentModal,
    })
  }

  toggleCaution = () => {
    this.setState({
      caution: !this.state.caution,
    })
  }

  onSearchChange = e => {
    if (e === undefined) {
      this.setState({
        searchValue: "",
      })
    } else {
      this.setState({
        searchValue: e.target.value,
      })
    }
  }

  onRowClick = studentId => {
    this.setState({
      studentModal: true,
      studentId: studentId,
    })
  }

  onAddRequestClick = studentId => {
    this.setState({
      studentModal: true,
      studentId: studentId,
    })
  }

  logOut = () => {
    localStorage.clear()
    sessionStorage.clear()
    this.props.client.resetStore()
    window.location.reload()
  }

  onCompleteSurvey = () => {
    const { closeOrOpenTeacherSurveyMutation, teacherId } = this.props

    const variables = {
      surveyCloseOpenParams: {
        teacherId: teacherId,
        completed: true,
      },
    }

    this.setState({
      loading: true,
    })

    closeOrOpenTeacherSurveyMutation({ variables })
      .then(() => {
        this.setState({
          loading: false,
        })
        this.logOut()
      })
      .catch(error => {
        handleSessionExpired(error)

        this.setState({
          loading: false,
          errors: { mutation: "Could not mark survey as complete" },
        })
      })
  }

  nextStudent = () => {
    const { surveyStudents } = this.props
    const { studentId } = this.state

    const studentIndex = surveyStudents.findIndex(
      student => student.id === studentId
    )
    const newStudentIndex =
      studentIndex === surveyStudents.length - 1 ? 0 : studentIndex + 1

    const nextStudent = surveyStudents[newStudentIndex]
    this.setState({
      studentId: nextStudent.id,
    })
  }

  prevStudent = () => {
    const { surveyStudents } = this.props
    const { studentId } = this.state
    const studentIndex = surveyStudents.findIndex(
      student => student.id === studentId
    )

    const newStudentIndex =
      studentIndex === 0 ? surveyStudents.length - 1 : studentIndex - 1

    const nextStudent = surveyStudents[newStudentIndex]
    this.setState({
      studentId: nextStudent.id,
    })
  }

  toggleShowSurveyInstructionsVideo = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showSurveyInstructionsVideo: !prevState.showSurveyInstructionsVideo,
      }
    })
  }

  render() {
    const {
      surveyStudents,
      loadingOne,
      loadingTwo,
      schoolSettings,
      schoolCharacteristics,
      refetchQueries,
    } = this.props
    const { loading, errors, studentId, studentModal, searchValue, caution } =
      this.state

    if (loading || loadingOne || loadingTwo) {
      return <Loader />
    }

    const students =
      searchValue === ""
        ? Array.from(surveyStudents)
        : Array.from(surveyStudents).filter(s => {
            // searching of students using firstName, lastName, and student Code
            const searchString = [s.firstName, s.lastName, s.studentCode]
              .join(" ")
              .toLowerCase()
            return searchString.includes(searchValue.toLowerCase())
          })

    sortStudentsByClass(students)

    const videoUrlForInformationModal =
      schoolSettings.teachersToAddTeacherRequests ||
      schoolSettings.teachersToAddStudentRequests
        ? SURVEY_INSTRUCTIONS_WITH_RESPONSES_URL
        : SURVEY_INSTRUCTIONS_WITHOUT_RESPONSES_URL

    return (
      <>
        <div className="u-row-fix">
          <div className="d-flex justify-content-between align-items-center w-100 py-2 px-3">
            <Button color="primary" onClick={this.toggleCaution}>
              Mark Survey complete
            </Button>
            <InlineError text={errors.mutation} />
            <div>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <i
                  className="fa fa-info-circle color-blue-mid mt-3"
                  data-tooltip-id="survey-info"
                />
                <b className="d-block align-middle color-blue-mid mx-2 mt-2 survey-learn-more">
                  Instructions: Learn more
                </b>
                <SearchInput
                  onChange={this.onSearchChange}
                  value={searchValue}
                  placeholder="Search students..."
                />
                <Tooltip
                  style={{}}
                  data-tooltip-id="survey-info"
                  className="survey-tooltip"
                  id="survey-info"
                  variant="light"
                  clickable
                  opacity={1}>
                  <div className="d-flex flex-row justify-content-between">
                    <b>INSTRUCTIONS:</b>

                    <a
                      className="d-block mb-3 color-blue-mid"
                      onClick={this.toggleShowSurveyInstructionsVideo}>
                      <i className="fa fa-play ml-2 mr-1 color-blue-mid" />
                      Watch Video
                    </a>
                  </div>

                  <p className="">
                    Click on each student and then 'Edit' their details.
                  </p>
                  <p>
                    Your responses are automatically saved as you go, and you
                    don't need to finish the survey in one session.
                  </p>
                  <p>
                    Once you have completed the survey for all students click
                    'Mark Survey Complete'. This will notify your Administrator
                    that you have finished.
                  </p>
                  <p>
                    You will not be able to access the survey after you have
                    clicked 'Mark Survey complete’. However your Administrator
                    can resend a link to your Survey if required.
                  </p>
                </Tooltip>
              </div>
            </div>
          </div>
          <StudentsTable
            teacherMode
            teacherRequestsEditable={
              schoolSettings.teachersToAddTeacherRequests
            }
            studentRequestsEditable={
              schoolSettings.teachersToAddStudentRequests
            }
            students={students}
            onAddRequestClick={this.onAddRequestClick}
            maxFriends={schoolSettings.maxFriends}
            schoolCharacteristics={schoolCharacteristics}
            onRowClick={this.onRowClick}
          />
          <span className="c-students__total">
            Total - {surveyStudents.length} Students
          </span>

          {this.state.showSurveyInstructionsVideo && (
            <VideoModal
              toggle={this.toggleShowSurveyInstructionsVideo}
              videoUrl={videoUrlForInformationModal}
              title="Survey Instructions"
            />
          )}

          <CautionModal
            isOpen={caution}
            toggle={this.toggleCaution}
            heading="Mark As Complete?"
            text="Are you sure you want to mark this survey as complete? The survey will only be accessible by the school administrator afterward."
            buttonText="Yes"
            onButtonClick={this.onCompleteSurvey}
          />
          <StudentModal
            studentEditable
            showFriendships
            friendshipEditable
            showCharacteristics
            characteristicsEditable
            teacherRequestsEditable={
              schoolSettings.teachersToAddTeacherRequests
            }
            studentRequestsEditable={
              schoolSettings.teachersToAddStudentRequests
            }
            showTeacherRequests={schoolSettings.teachersToAddTeacherRequests}
            showStudentRequests={schoolSettings.teachersToAddStudentRequests}
            isOpen={studentModal}
            studentId={studentId}
            toggle={this.toggle}
            refetchQueries={refetchQueries}
            isTeacher
            nextStudent={this.nextStudent}
            prevStudent={this.prevStudent}
          />
        </div>
        <Footer className="ml-0" />
      </>
    )
  }
}
