export const getOpacity = colourHexString => {
  if (!colourHexString) {
    return null
  }

  const opacityHexString = colourHexString.substring(7, 9)

  if (opacityHexString === "") {
    return 255
  }

  return parseInt(opacityHexString, 16)
}

// Stolen from https://stackoverflow.com/a/39077686
export const hexToRgba = hex =>
  hex
    // expands 3-digit to 6-digit Hex codes e.g. #123 => #112233
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d]?)$/i,
      (m, r, g, b, a) => "#" + r + r + g + g + b + b + a + a
    )
    .padEnd(9, "f")
    .substring(1, 9)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))

// Stolen from https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
export const luminance = (r, g, b) => {
  const a = [r, g, b].map(v => {
    v /= 255
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
  })
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export const contrast = (rgb1, rgb2) => {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05
  return Math.max(lum1, lum2) / Math.min(lum1, lum2)
}

export const rgbaToRgb = ([r, g, b, a]) => {
  // Assuming a white opaque background
  const [bgR, bgG, bgB] = [1, 1, 1]
  const [sourceR, sourceG, sourceB, sourceA] = [r, g, b, a].map(v => (v /= 255))

  const newR = (1 - sourceA) * bgR + sourceA * sourceR
  const newG = (1 - sourceA) * bgG + sourceA * sourceG
  const newB = (1 - sourceA) * bgB + sourceA * sourceB
  return [newR, newG, newB].map(v => Math.floor(v * 255))
}

// Stolen from https://stackoverflow.com/a/39077686
export const rgbToHex = ([r, g, b]) =>
  "#" +
  [r, g, b]
    .map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? "0" + hex : hex
    })
    .join("")

// Selects the text color with the correct contrast compared
// to the background colour
export const contrastedTextColor = backgroundColorRgb => {
  return (
    [
      ["color-black", [0, 0, 0]], // #000000
      ["color-white", [255, 255, 255]], // #ffffff
    ] // Work out the contrast of the text colour to the background
      .map(x => [...x, contrast(x[1], backgroundColorRgb)])
      // Pick the text colour with the highest contrast
      .reduce((acc, x) => {
        const maxContrast = acc[2]
        const contrast = x[2]
        return maxContrast > contrast ? acc : x
      })[0]
  )
}
