import React from "react"

import { CharacteristicHeaders } from "domains/reports/components"

import { pluralize } from "util/nameUtil"

import { AccountTypeContext } from "config/accountTypeContext"

export const SchoolTableHeader = ({
  schoolCharacteristics,
  hasGenderX,
  schoolSettings,
}) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <thead className="c-reports-table__header bg-table-header">
      <tr>
        <th
          colSpan="4"
          className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
          {`${gettextObj.gettext("CLASS")}`}
        </th>
        <th
          colSpan={hasGenderX ? "3" : "2"}
          className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
          GENDER
        </th>
        <th
          colSpan="4"
          className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
          PERFORMANCE
        </th>
        <th
          colSpan={schoolCharacteristics.length}
          className="c-reports-table__header__title color-total-text py-3 pl-3">
          CHARACTERISTICS
        </th>
      </tr>
      <tr>
        <th className="c-reports-table__header__label" />
        <th className="c-reports-table__header__label" />
        <th className="c-reports-table__header__label" />
        <th className="c-reports-table__header__label border-right" />
        <th className="c-reports-table__header__label">Male</th>
        {hasGenderX ? (
          <>
            <th className="c-reports-table__header__label">Female</th>
            <th className="c-reports-table__header__label border-right">
              Non-Binary
            </th>
          </>
        ) : (
          <>
            <th className="c-reports-table__header__label border-right">
              Female
            </th>
          </>
        )}
        <th className="c-reports-table__header__label c-reports-table__header__label--performance">
          At Least{" "}
          {pluralize(schoolSettings.minFriendPrefs, "Friend", "Friends")}
        </th>
        <th className="c-reports-table__header__label c-reports-table__header__label--performance">
          Mandatory Requests
        </th>
        <th className="c-reports-table__header__label c-reports-table__header__label--performance">
          Important Requests
        </th>
        <th className="c-reports-table__header__label c-reports-table__header__label--performance border-right">
          Different Teacher
        </th>
        <CharacteristicHeaders schoolCharacteristics={schoolCharacteristics} />
      </tr>
    </thead>
  )
}
