import React from "react"
import { Modal, ModalBody } from "reactstrap"

export const VideoModal = ({ toggle, videoUrl, title }) => (
  <Modal
    isOpen
    className="modal-dialog-centered c-video-modal-dialog"
    contentClassName="c-video-modal-content">
    <ModalBody>
      <button className="close c-video-close-button" onClick={toggle}>
        <i className="fa fa-lg fa-times-circle-o" aria-hidden="true" />
      </button>
      <div className="c-video-modal-embed-responsive">
        <iframe
          src={videoUrl}
          allow="autoplay; fullscreen; picture-in-picture"
          sandbox="allow-scripts allow-same-origin allow-presentation"
          title={title}
        />
      </div>
    </ModalBody>
  </Modal>
)
