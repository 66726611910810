import React from "react"
import { Tooltip } from "react-tooltip"
import { NavLink } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import { TEACHER } from "domains/app/constants/roleTypes"
import { NotificationsList } from "domains/notifications/components/notificationsList"
import { userInitials } from "util/nameUtil"
import { isPrivilegedRole } from "util/userUtil"

const HelpTooltip = () => {
  return (
    <Tooltip
      id="helpTooltip"
      variant="light"
      // border colour is equivalent to $color-grey-class-header
      border="1px solid #dce1e8"
      clickable
      openEvents={{ click: true, focus: true }}
      closeEvents={{ click: true, blur: true }}
      globalCloseEvents={{ clickOutsideAnchor: true, scroll: true }}>
      <div className="justify-content-left color-blue-primary c-footer__need-help">
        <i className="c-footer__need-help__icon mr-2" />
        Need help?
      </div>
      <div className="justify-content-end c-footer__email color-grey-dark">
        Email us on
        <a
          href="mailto:support@classsolver.com"
          className="ml-1 color-blue-mid">
          support@classsolver.com
        </a>
      </div>
    </Tooltip>
  )
}

export const UserDropdown = ({
  dropdownOpen,
  toggle,
  toggleSchoolListModal,
  school,
  onLogOut,
  adminMode,
  myprofile,
}) => {
  const isSuper = isPrivilegedRole(myprofile.role)
  const isTeacher = myprofile.role === TEACHER
  const hasMultipleSchools = myprofile.schools.length > 1

  const schoolName = school
    ? `${school.id} - ${school.stateCode} ${school.suburb || ""} - ${
        school.name
      }`
    : "Choose Account"

  return (
    <Dropdown
      className="nav-right pr-4 d-print-none"
      isOpen={dropdownOpen}
      toggle={toggle}>
      <div className="d-flex align-items-center">
        {isSuper || hasMultipleSchools ? (
          <div className="d-inline-block mr-4">
            <div
              className={`bg-requests-mandatory btn btn-light u-white-space-pre-wrap
            ${school ? "" : "bg-choose-account color-white"}`}
              onClick={toggleSchoolListModal}>
              {schoolName}
            </div>
          </div>
        ) : (
          <div />
        )}
        <NavLink
          className={`font-size-16 nav-link justify-content-between d-inline-flex ${school ? "" : "btn disabled"}`}
          to="/AccountSettings">
          Settings
        </NavLink>
        <p
          className="font-size-16 nav-link justify-content-between d-inline-flex"
          data-tooltip-id="helpTooltip">
          Help
        </p>
        <HelpTooltip />
        <NotificationsList />
        {adminMode ? (
          <div className="d-flex u-nav-border pl-3">
            <NavLink
              className="align-self-center c-nav__admin-link"
              to="/AccountSettings">
              <span className="c-nav__admin-badge">Admin Mode</span>
            </NavLink>
            <DropdownToggle className={`pr-0 d-inline-block`} nav caret>
              <span className="class-badge">{userInitials(myprofile)}</span>
            </DropdownToggle>
          </div>
        ) : (
          <DropdownToggle
            className={`pr-0 pl-4 ml-1 d-inline-block u-nav-border`}
            nav
            caret>
            <span className="class-badge">{userInitials(myprofile)}</span>
          </DropdownToggle>
        )}
      </div>
      <div className="dropdown-menu-container">
        {/* Only show log out option for teachers */}
        {isTeacher ? (
          <DropdownMenu className="mr-5">
            <DropdownItem className="mt-1 mb-1" onClick={onLogOut}>
              Log out
            </DropdownItem>
          </DropdownMenu>
        ) : (
          <DropdownMenu className="mr-5">
            <DropdownItem tag="div">
              <NavLink to="/UserProfile">
                <div className="mt-0 mb-1 u-settings-link">My Profile</div>
                <div className="color-grey-dropdown-header font-weight-medium">
                  {myprofile.email}
                </div>
              </NavLink>
            </DropdownItem>
            {school && (
              <DropdownItem tag="div">
                <NavLink
                  className="d-block mt-1 mb-1 u-settings-link w-100"
                  to="/BillingSettings">
                  Account and Billing
                </NavLink>
              </DropdownItem>
            )}
            {isSuper && (
              <DropdownItem tag="div">
                <NavLink
                  className="d-block mt-1 mb-1 u-settings-link w-100"
                  to="/SuperAdmin">
                  Support Admin Menu
                </NavLink>
              </DropdownItem>
            )}
            <DropdownItem divider />
            <DropdownItem className="mt-1 mb-1" onClick={onLogOut}>
              Log out
            </DropdownItem>
          </DropdownMenu>
        )}
      </div>
    </Dropdown>
  )
}
