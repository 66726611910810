import React, { Component, createRef } from "react"

import { AddButton } from "components"
import { Characteristic } from "domains/characteristics/components"
import { swapElement, isLast, areEqualArrays } from "util/array"
import {
  CHARACTERISTIC_COLOUR_PALETTE,
  CHARACTERISTIC_COLOURS,
} from "domains/characteristics/constants"

export class CharacteristicSettings extends Component {
  constructor() {
    super()
    this.state = {
      displayColourPicker: null,
    }
    this.listBottomRef = createRef()
  }

  toggleColourPicker = indices => {
    this.setState(state => ({
      displayColourPicker: areEqualArrays(indices, state.displayColourPicker)
        ? null
        : indices,
    }))
  }

  resetPriorities = characteristics =>
    characteristics.map((c, index) => ({ ...c, priority: index + 1 }))

  addCharacteristic = () => {
    const { characteristics, onCharacteristicsChange } = this.props

    const colour =
      CHARACTERISTIC_COLOUR_PALETTE[
        characteristics.length % CHARACTERISTIC_COLOURS.length
      ]

    onCharacteristicsChange(
      this.resetPriorities([
        ...characteristics,
        {
          name: "",
          characteristicResponses: [
            {
              label: "Yes",
              colour: colour,
            },
            {
              label: "No (Neutral)",
            },
          ],
        },
      ])
    )
  }

  deleteCharacteristic = index => {
    const { characteristics, onCharacteristicsChange } = this.props

    const newCharacteristics = Array.from(characteristics)
    newCharacteristics.splice(index, 1)

    onCharacteristicsChange(this.resetPriorities(newCharacteristics))
  }

  onCharacteristicChange = (index, characteristic) => {
    const { characteristics, onCharacteristicsChange } = this.props

    const newCharacteristics = Array.from(characteristics)
    newCharacteristics[index] = characteristic

    onCharacteristicsChange(newCharacteristics)
  }

  increasePriority = index => {
    if (index === 0) {
      return
    }

    const { characteristics, onCharacteristicsChange } = this.props

    onCharacteristicsChange(
      this.resetPriorities(swapElement(characteristics, index, index - 1))
    )
  }

  decreasePriority = index => {
    const { characteristics, onCharacteristicsChange } = this.props

    if (isLast(characteristics, index)) {
      return
    }

    onCharacteristicsChange(
      this.resetPriorities(swapElement(characteristics, index, index + 1))
    )
  }

  toggleAdminOnly = index => {
    const { characteristics, onCharacteristicsChange } = this.props
    const newCharacteristics = Array.from(characteristics)
    const characteristic = newCharacteristics[index]

    newCharacteristics[index] = {
      ...characteristic,
      sensitive: !characteristic.sensitive,
    }

    onCharacteristicsChange(newCharacteristics)
  }

  toggleTagOnly = index => {
    const { characteristics, onCharacteristicsChange } = this.props
    const newCharacteristics = Array.from(characteristics)
    const characteristic = newCharacteristics[index]

    newCharacteristics[index] = {
      ...characteristic,
      tag_only: !characteristic.tag_only,
    }
    onCharacteristicsChange(newCharacteristics)
  }

  render() {
    const { characteristics } = this.props
    const { displayColourPicker } = this.state

    return (
      <div className="u-content-border-bottom pb-5 mb-3">
        <AddButton
          text="Add characteristic"
          onClick={() => {
            this.addCharacteristic()
            this.listBottomRef.current.scrollIntoView()
          }}
        />
        <div className="c-chars mt-3">
          <div className="c-chars__header pb-2 row">
            <div className="c-chars__header-item col-md-1">
              <div>Priority</div>
            </div>
            <div className="c-chars__header-item col-md-3">
              <div>Characteristic</div>
            </div>
            <div className="c-chars__header-item col-md-8">
              <div>Responses</div>
            </div>
          </div>
        </div>
        <div className="mb-3">
          {characteristics.map((c, index) => (
            <Characteristic
              key={index}
              index={index}
              characteristic={c}
              onCharacteristicChange={characteristic =>
                this.onCharacteristicChange(index, characteristic)
              }
              onIncreasePriorityClick={() => this.increasePriority(index)}
              onDecreasePriorityClick={() => this.decreasePriority(index)}
              onDeleteCharacteristicClick={() => {
                this.deleteCharacteristic(index)
              }}
              displayColourPicker={displayColourPicker}
              toggleColourPicker={this.toggleColourPicker}
              toggleAdminOnly={() => this.toggleAdminOnly(index)}
              toggleTagOnly={() => this.toggleTagOnly(index)}
            />
          ))}
        </div>
        <div ref={this.listBottomRef} />
        <AddButton
          text="Add characteristic"
          onClick={() => {
            this.addCharacteristic()
            this.listBottomRef.current.scrollIntoView()
          }}
        />
      </div>
    )
  }
}
