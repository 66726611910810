export const ConditionalDisplay = function ({
  show = true,
  hide = false,
  children,
}) {
  if (show && !hide) {
    return children
  }
  return null
}
