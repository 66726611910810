import React from "react"
import { useQuery } from "@apollo/client"
import { dashboardQuery } from "domains/dashboard/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"

import { Loader } from "components"
import { Dashboard } from "domains/dashboard/components"

import { handleSessionExpired, getSchoolId } from "util/app"

export const DashboardPage = () => {
  const { data: settingsData, loading: loadingSettings } =
    useQuery(settingsClientQuery)

  const { data: myprofileData, loading: loadingProfile } =
    useQuery(userProfileQuery)

  const {
    data: dashboardData,
    loading: loadingDashboard,
    error: dashboardError,
  } = useQuery(dashboardQuery, {
    variables: {
      adminOnly: settingsData.settings.adminOnlyRequests,
      schoolId: getSchoolId(),
    },
    skip: !getSchoolId(),
    fetchPolicy: "network-only",
  })

  handleSessionExpired(dashboardError)

  if (loadingSettings || loadingProfile || loadingDashboard) {
    return <Loader />
  }

  return (
    <Dashboard
      myprofile={myprofileData?.myprofile}
      settings={settingsData?.settings}
      dashboard={dashboardData?.dashboard}
    />
  )
}
