import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Select from "react-select"

import { newSchoolGradesQuery } from "domains/classes/graphql"
import { removeSolutionsForGradesMutation } from "domains/accountSettings/graphql"
import { Modal, ModalBody } from "reactstrap"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  Loader,
  defaultSelectStyles,
} from "components"

export const DeleteSolutionsByGradesModal = ({ toggle, schoolId }) => {
  const { data, loading: loadingGrades } = useQuery(newSchoolGradesQuery, {
    variables: { schoolId },
  })

  const [
    removeSolutionsForGrades,
    { error: errorRemoving, loading: removingSolutions },
  ] = useMutation(removeSolutionsForGradesMutation, {
    onError: () => {},
  })

  const [selectedGrades, setSelectedGrades] = useState([])

  const loading = loadingGrades || removingSolutions

  const availableGrades = loading
    ? []
    : data.newSchoolGrades.map(grade => {
        return {
          label: grade.label,
          value: grade.id,
        }
      })

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-35rem">
      <ModalHeader
        className="p-4"
        title="Remove Solutions by Grades"
        toggle={toggle}
      />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <ModalBody>
          <div className="d-flex flex-column align-items-center mt-2">
            <div className="mt-3 d-flex justify-content-between">
              <i className="fa fa-info-circle u-caution-icon mr-4" />
              <div className="u-white-space-pre-wrap">
                <span className="text-primary mb-3">CAUTION: </span>
                This action cannot be undone.
              </div>
            </div>
          </div>
          <div className="my-3">
            <Select
              name="current_grades"
              className="c-input__multiselect"
              placeholder="Select Grades"
              options={availableGrades}
              value={selectedGrades}
              onChange={grades => {
                setSelectedGrades(grades)
              }}
              closeMenuOnSelect={false}
              isMulti
              styles={defaultSelectStyles}
            />
            {errorRemoving && <InlineError text={errorRemoving.message} />}
          </div>
          <ButtonBar
            className="mt-5"
            buttonText="Yes, remove for selected grades"
            onButtonClick={() => {
              removeSolutionsForGrades({
                variables: {
                  schoolId: schoolId,
                  gradeIds: selectedGrades.map(option => option.value),
                },
                onCompleted: toggle,
              })
            }}
            onCancelClick={toggle}
            disabled={selectedGrades.length === 0}
          />
        </ModalBody>
      )}
    </Modal>
  )
}
