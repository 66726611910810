import React from "react"

import { Preference } from "./preference"
import { AccountTypeContext } from "config/accountTypeContext"

export const Modes = ({
  adminOnlyRequests,
  updateAdminOnly,
  showTeachersInSolver,
  updateShowTeachersInSolver,
}) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <div className="u-content-border-bottom pb-4" id="mode">
      <h2 className="mb-5 mt-5 u-blue-primary">Modes</h2>
      <div className="d-flex justify-content-between mb-4">
        <div className="u-w-55">
          <div className="mb-2">Turn On ‘Admin Only Request Mode’</div>
          <p>
            Enables all Administrators to create and view sensitive Requests
            that are only visible when this mode is turned on.
          </p>
        </div>
        <div className="u-w-45">
          <div className="c-toggle--admin">
            <input
              type="checkbox"
              className="c-toggle__checkbox"
              id="adminOnlyRequests"
              name="adminOnlyRequests"
              checked={adminOnlyRequests}
              onChange={updateAdminOnly}
            />
            <label className="c-toggle__label" htmlFor="adminOnlyRequests">
              <div className="c-toggle__on">Yes</div>
              <div className="c-toggle__off">No</div>
            </label>
          </div>
        </div>
      </div>

      <Preference
        id="showTeachersInSolver"
        name="showTeachersInSolver"
        title="Hide assigned teachers on the Solver page"
        description={`This is a useful mode if you would like to show teachers the Solver page but don’t want them to see the teachers that have been assigned to ${gettextObj.gettext(
          "classes"
        )}.`}
        checked={!showTeachersInSolver}
        onChangeHandler={updateShowTeachersInSolver}
      />
    </div>
  )
}
