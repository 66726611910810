import React from "react"
import { Input, InputGroup, Label } from "reactstrap"

import { isNewGrade } from "util/gradeUtil"

export const CurrentGradeFilterInput = ({
  currentSchoolGrades = [],
  onChange,
  value,
}) => {
  return (
    <InputGroup className="current-grade-input mr-5 pr-5 border-right">
      <Label className="align-self-center mr-2 pt-2">
        View by Current Grade
      </Label>

      <Input
        type="select"
        name="currentGradeFilter"
        value={value}
        onChange={onChange}>
        {currentSchoolGrades.map(g => {
          const label = isNewGrade(g) ? "New Students" : g.label
          return (
            <option key={g.id} value={g.id}>
              {label}
            </option>
          )
        })}
        <option value="all-grades">All Grades</option>
      </Input>
    </InputGroup>
  )
}
