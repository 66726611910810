import React from "react"
import { Tooltip } from "react-tooltip"
import { getOpacity } from "util/colours"

export const ReportCharacteristicColours = ({
  characteristicName,
  characteristicResponses,
}) => (
  <div className="d-flex justify-content-center">
    {characteristicResponses.map((response, index) => {
      const opacity = getOpacity(response.colour)
      // Set the background color and opacity for each span
      const labelStyle = {
        backgroundColor:
          opacity === 0 || response.colour === null
            ? "#BFBFBF"
            : response.colour,
      }
      const id = `characteristic-${response.id}`

      return (
        <div key={index}>
          <div
            className="c-reports-characteristic-colour"
            style={labelStyle}
            data-tooltip-id={id}
          />

          <Tooltip
            className="c-characteristic-tooltip p-0"
            float
            id={id}
            opacity={1}
            variant="light">
            <div>
              <div className="c-characteristic-tooltip__header text-primary text-left">
                Characteristics
              </div>
              <div className="c-characteristic-tooltip__body">
                <div className="d-flex align-items-center mt-2">
                  <span
                    className="u-characteristic-circle mr-2"
                    style={{
                      backgroundColor: labelStyle.backgroundColor,
                    }}
                  />
                  <div>
                    <span className="u-bold">{characteristicName}</span>
                    {` - ${response.label}`}
                  </div>
                </div>
              </div>
            </div>
          </Tooltip>
        </div>
      )
    })}
  </div>
)
