import React from "react"

import { ConstraintsBadge } from "components"
import {
  CharacteristicCircle,
  CharacteristicSummaryTooltip,
  FriendBadge,
} from "domains/solver/components"

import { genderColor } from "util/studentUtil"
import {
  getOpacity,
  hexToRgba,
  rgbaToRgb,
  rgbToHex,
  contrastedTextColor,
} from "util/colours"
import { isCurrentClassCharacteristic } from "util/characteristics"
import { MinusCircleIcon } from "images/minusCircleIcon"

export class StudentListItem extends React.Component {
  render() {
    const {
      student,
      lockState,
      onStudentClick,
      studentMetrics,
      studentDropped,
      studentToBeDragged,
      inActiveGrade,
      activeCharacteristic,
      friendConstraint,
      studentConstraint,
      teacherConstraint,
      onLockStudent,
      selectedStudentId,
      selectedTeacherId,
      schoolSettings,
      readMode,
      showTeachers,
      showStudentRequests,
      showFriendships,
      showClassEntryCriteria,
      showAllCharacteristics,
    } = this.props

    const { id, firstName, lastName, gender, currentClass } = student

    const friendPreferences = schoolSettings
      ? schoolSettings.maxFriends > 0
      : false
    const differentTeacherSetting = schoolSettings
      ? schoolSettings.avoidSameTeacher
      : false
    const includeStudentsWithNoValidFriends = schoolSettings
      ? schoolSettings.includeStudentsWithNoValidFriends
      : false

    // If student is selected
    const studentSelected = selectedStudentId === id
    // Boolean values for showing flags, badges, etc
    const showNotMetFlags =
      !selectedStudentId && !selectedTeacherId && inActiveGrade

    const characteristicResponses = student.characteristicResponses || []

    // Build classNames
    // Gender Name Colors
    let studentColor = genderColor(gender)

    // Selected/Dropped classNames
    const listItemClassName = [
      studentSelected && !studentToBeDragged && "c-student-list-item--selected",
      studentDropped && "c-student-list-item--dropped",
      studentToBeDragged && "c-student-list-item--dropped",
      inActiveGrade || "c-student-list-item--inactive",
      readMode && "cursor-pointer",
    ]
      .filter(c => c)
      .join(" ")

    const studentNameClassName = showAllCharacteristics
      ? "c-student-list-item--all-characteristics "
      : ""

    const characteristicResponsesWithColours = characteristicResponses.filter(
      response => getOpacity(response.colour) > 0
    )

    // If a characteristic is selected
    let styles = {}
    if (activeCharacteristic) {
      // Find if student has response to selected characteristic
      const characteristicResponse = characteristicResponses.find(
        response => response.characteristic.id === activeCharacteristic.id
      )

      if (characteristicResponse && characteristicResponse.colour) {
        // create style
        const opacity = getOpacity(characteristicResponse.colour)

        if (opacity > 0) {
          const backgroundColor = rgbaToRgb(
            hexToRgba(characteristicResponse.colour)
          )

          styles = {
            backgroundColor: rgbToHex(backgroundColor),
          }

          studentColor = contrastedTextColor(backgroundColor)
        }
      }
    }

    // For the not met flags
    let requestsMet = []
    if (studentMetrics && studentMetrics.requestsMet) {
      const {
        mandatoryRequests,
        importantRequests,
        differentTeacher,
        friendshipPreferences,
        classEntryCriteria,
      } = studentMetrics.requestsMet

      const friendshipPreferencesMet =
        !includeStudentsWithNoValidFriends &&
        studentMetrics.metrics.friendshipPreferences.total === 0
          ? true // we ignore students with no valid friends
          : friendshipPreferences

      requestsMet = [
        {
          isMet: mandatoryRequests,
          className: "mandatory",
          isVisible: showStudentRequests,
        },
        {
          isMet: importantRequests,
          className: "important",
          isVisible: showStudentRequests,
        },
        {
          isMet: differentTeacher,
          className: "teacher",
          isVisible: showTeachers,
        },
        {
          isMet: friendshipPreferencesMet,
          className: "friendship",
          isVisible: showFriendships,
          friendshipPreferences: studentMetrics.metrics.friendshipPreferences,
        },
        {
          isMet: classEntryCriteria,
          className: "class-entry-criteria",
          isVisible: showClassEntryCriteria,
        },
      ]
    }

    return (
      <div
        className={
          "c-student-list-item d-flex justify-content-between align-items-center " +
          listItemClassName
        }
        style={styles}
        onClick={e => {
          onStudentClick(e, id, studentSelected)
        }}>
        {/* Lock Status and Name */}
        <div
          className={`d-inline-flex u-opacity-100 pr-1 text-truncate ${studentNameClassName}`}>
          {!readMode && (
            <i
              className={
                "d-print-none d-flex align-items-center " +
                (lockState ? "fa fa-lock" : "fa fa-unlock")
              }
              onClick={
                studentToBeDragged
                  ? null
                  : e => onLockStudent(e, id, !lockState)
              }
            />
          )}

          <span
            className={`c-student-list-item__name c-student-list-item__name--${gender} ${studentColor} text-truncate`}>{`${firstName} ${lastName}`}</span>
        </div>
        {/* Badges */}
        <div className="d-inline-flex">
          {/* Only show constraint badges if not showing characteristics */}
          {showAllCharacteristics ? (
            <>
              <span data-tooltip-id={`student-${id}`} className="p-0">
                {characteristicResponsesWithColours
                  .slice(0, 5)
                  .map((response, index) => (
                    <CharacteristicCircle key={index} {...response} />
                  ))}
                {characteristicResponsesWithColours.length > 5 &&
                  `+${characteristicResponsesWithColours.length - 5}`}
              </span>
              <CharacteristicSummaryTooltip
                id={`student-${id}`}
                className="c-characteristic-tooltip p-0"
                place="right"
                variant="light"
                float
                characteristicResponses={characteristicResponses}
              />
            </>
          ) : (
            <div className="c-student-list-item__flags d-flex">
              {showNotMetFlags ? (
                <span className="c-student-list-item__flags__container">
                  {/* For the unmet request flags */}
                  {requestsMet.map((request, index) => {
                    if (
                      request.isMet ||
                      !request.isVisible ||
                      (!friendPreferences &&
                        request.className === "friendship") ||
                      (!differentTeacherSetting &&
                        request.className === "teacher")
                    ) {
                      return null
                    }

                    // For friendships, we show the number of friends in the class
                    // if the minimum amount has not been met
                    if (request.className === "friendship") {
                      if (request.friendshipPreferences.count > 0) {
                        return (
                          <div
                            key={index}
                            className="c-badge__circle not-met-friend">
                            {request.friendshipPreferences.count}
                          </div>
                        )
                      }

                      if (request.friendshipPreferences.total === 0) {
                        return (
                          <div key={index} className="c-badge__no-friend-pref">
                            <MinusCircleIcon />
                          </div>
                        )
                      }

                      return (
                        <i
                          className={`c-student-list-item__flags__not-met
                        c-student-list-item__flags__not-met--friendship
                        fa fa-ban d-print-none`}
                          key={index}
                        />
                      )
                    }

                    return (
                      <i
                        className={`c-student-list-item__flags__not-met
                        c-student-list-item__flags__not-met--${request.className}
                        fa fa-ban d-print-none `}
                        key={index}
                      />
                    )
                  })}
                </span>
              ) : (
                <span className="d-flex align-items-center">
                  {/* Constraint/Friendship Badges */}
                  {friendConstraint && friendConstraint.isFriend && (
                    <FriendBadge isMet={friendConstraint.isMet} />
                  )}
                  {studentConstraint && (
                    <ConstraintsBadge
                      mandatory={studentConstraint.mandatory}
                      pair={studentConstraint.pair}
                      isMet={studentConstraint.isMet}
                    />
                  )}
                  {teacherConstraint && (
                    <ConstraintsBadge
                      mandatory={teacherConstraint.mandatory}
                      pair={teacherConstraint.pair}
                      isMet={teacherConstraint.isMet}
                    />
                  )}
                </span>
              )}
              {isCurrentClassCharacteristic(activeCharacteristic) && (
                <span
                  className={`c-student-list-item--current-class ${studentColor}`}>
                  {currentClass ? currentClass.label : "N/A"}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
