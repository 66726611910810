import React from "react"

export const UploadLoader = ({ type }) => (
  <div className="c-upload-loader">
    <div className="d-flex mb-3">
      <div className="c-upload-loader__block" />
      <div className="c-upload-loader__block" />
      <div className="c-upload-loader__block" />
      <div className="c-upload-loader__block" />
      <div className="c-upload-loader__block" />
    </div>
    <h2>Uploading your {type} list...</h2>
  </div>
)
