import React, { useState } from "react"
import { Col } from "reactstrap"

import { VideoModal } from "components"
import {
  Footer,
  Bar,
  Circle,
  CircleLarge,
  SubscriptionExpiredNote,
} from "domains/dashboard/components"

import {
  INSTRUCTION_STEP_1_PLAYER_URL,
  INSTRUCTION_STEP_2_PLAYER_URL,
  INSTRUCTION_STEP_3_PLAYER_URL,
} from "constants/videos"

import { AccountTypeContext } from "config/accountTypeContext"

export const Dashboard = ({ dashboard = {}, myprofile, settings }) => {
  const [showStep1Video, setShowStep1Video] = useState(false)
  const [showStep2Video, setShowStep2Video] = useState(false)
  const [showStep3Video, setShowStep3Video] = useState(false)

  const toggleStep1Video = () => setShowStep1Video(prevState => !prevState)
  const toggleStep2Video = () => setShowStep2Video(prevState => !prevState)
  const toggleStep3Video = () => setShowStep3Video(prevState => !prevState)

  const adminMode = settings.adminOnlyRequests

  const {
    newClassCount = 0,
    requestCount = 0,
    studentCount = 0,
    teacherCount = 0,
    studentsPlacedCount = 0,
    surveySentCount = 0,
    surveyCompletedCount = 0,
    newAccountLock,
  } = dashboard

  // Boolean values for progress bars
  const hasStudents = studentCount > 0
  const hasStudentsAndTeachers = hasStudents && teacherCount > 0
  const hasStudentOrTeachers = hasStudents || teacherCount > 0

  const hasClasses = newClassCount > 0
  const hasClassesAndStudentsPlaced = hasClasses && studentsPlacedCount > 0

  const surveySent = surveySentCount > 0
  const hasRequestsOrSurveysSent = requestCount > 0 || surveySent
  const hasRequestsAndSurveysSent = requestCount > 0 && surveySent
  const hasRequestsAndSurveysCompleted =
    requestCount > 0 && surveyCompletedCount > 0

  const percentageCompleted = surveySent
    ? Math.round((surveyCompletedCount / surveySentCount) * 100)
    : 0

  const showSubscriptionExpiredNote =
    "overrideSolverLock" in dashboard && !dashboard.overrideSolverLock

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <div className="row c-dashboard">
      {showSubscriptionExpiredNote && <SubscriptionExpiredNote />}
      {adminMode && <div className="c-dashboard__admin-banner" />}
      <div className="c-dashboard__banner w-100 pb-5">
        <div className="container mb-4">
          <h1 className="u-bold u-grey-dark mt-4">
            Welcome to Class Solver{myprofile && `, ${myprofile.firstName}!`}
          </h1>
          <div
            className={`js-dashboard-banner-content ${
              true ? "d-block" : "d-none"
            }`}>
            <div className="c-dashboard__timeline mt-5">
              <CircleLarge condition={hasStudentsAndTeachers} content="1" />
              <Bar condition={hasStudentOrTeachers} />
              <Circle condition={hasStudentOrTeachers} />
              <Bar condition={hasStudentsAndTeachers} />
              <Circle condition={hasStudentsAndTeachers} />
              <Bar condition={hasStudentsAndTeachers} />

              <CircleLarge
                condition={hasRequestsAndSurveysCompleted}
                content="2"
              />
              <Bar condition={hasRequestsOrSurveysSent} />
              <Circle condition={hasRequestsOrSurveysSent} />
              <Bar condition={hasRequestsAndSurveysSent} />
              <Circle condition={hasRequestsAndSurveysSent} />
              <Bar condition={hasRequestsAndSurveysCompleted} />

              <CircleLarge
                condition={hasClassesAndStudentsPlaced}
                content="3"
              />
              <Bar condition={hasClasses} />
              <Circle condition={hasClasses} />
              <Bar condition={hasClassesAndStudentsPlaced} />
              <Circle condition={hasClassesAndStudentsPlaced} />
              <Bar condition={hasClassesAndStudentsPlaced} />
              <Circle condition={hasClassesAndStudentsPlaced} />
            </div>
            <div className="row mt-4">
              <Col sm="12" md={{ size: 4 }}>
                <h6 className="mt-3 mb-3 u-blue-primary">Import & Setup</h6>
                <p className="mb-4">
                  Import your current Students and Teachers. Then setup the
                  Characteristics you use to balance your{" "}
                  {gettextObj.gettext("classes")}.
                </p>
              </Col>
              <Col sm="12" md={{ size: 4 }}>
                <h6 className="mt-3 mb-3 u-blue-primary">Survey & Requests</h6>
                <p className="mb-4">
                  Invite your Teachers to complete a Survey about their
                  Students. Add any other Requests (e.g. from parents).
                </p>
              </Col>
              <Col sm="12" md={{ size: 4 }}>
                <h6 className="mt-3 mb-3 u-blue-primary">
                  Solve {gettextObj.gettext("Classes")}
                </h6>
                <p className="mb-4">
                  Set the {gettextObj.gettext("class")} structure and run the
                  Solver. Make manual changes and use "Boost" to fix issues.
                  Print, share or export your lists.
                </p>
              </Col>
            </div>
            <div className="row">
              <div className="col-4">
                <a className="color-blue-mid" onClick={toggleStep1Video}>
                  <i className="fa fa-play mr-2" />
                  Step 1 Video
                </a>
              </div>
              <div className="col-4">
                <a
                  className="color-blue-mid"
                  onClick={toggleStep2Video}
                  disabled={newAccountLock}>
                  <i className="fa fa-play mr-2" />
                  Step 2 Video
                </a>
              </div>
              <div className="col-4">
                <a
                  className="color-blue-mid"
                  onClick={toggleStep3Video}
                  disabled={newAccountLock}>
                  <i className="fa fa-play mr-2" />
                  Step 3 Video
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-dashboard__stats w-100 pt-5">
        <div className="container">
          <div className="row">
            <Col
              className="c-dashboard__stats-raised c-dashboard__stats-container"
              sm="12"
              md={{ size: 4 }}>
              <h2 className="mb-5 c-dashboard__stats-heading">Your School</h2>
              <h3 className="mt-3 mb-2 text-uppercase">Students Added</h3>
              <span className="c-dashboard__stats-text">{studentCount}</span>
              <h3 className="mt-5 mb-2 text-uppercase">Teachers added</h3>
              <span className="c-dashboard__stats-text">{teacherCount}</span>
            </Col>
            <Col
              className="c-dashboard__stats-container"
              sm="12"
              md={{ size: 4 }}>
              <div className="d-flex align-items-top">
                <div>
                  <h3 className="mt-3 mb-2 text-primary text-uppercase">
                    Survey completed
                  </h3>
                  <span className="c-dashboard__stats-text">{`${percentageCompleted}%`}</span>
                  <div className="c-dashboard__stats-text-small">
                    {`${surveySentCount} sent, ${surveyCompletedCount} completed`}
                  </div>
                </div>
              </div>

              <h3 className="mt-5 mb-2 text-primary text-uppercase">
                Requests Submitted
              </h3>
              <span className="c-dashboard__stats-text">{requestCount}</span>
            </Col>
            <Col
              className="c-dashboard__stats-container"
              sm="12"
              md={{ size: 4 }}>
              <h3 className="mt-3 mb-3 text-primary text-uppercase">
                Students placed
              </h3>
              <span className="c-dashboard__stats-text">
                {studentsPlacedCount}
              </span>

              <h3 className="mt-6 mb-2 text-primary text-uppercase">
                {gettextObj.gettext("Classes")}
              </h3>
              <span className="c-dashboard__stats-text">{newClassCount}</span>
            </Col>
          </div>
        </div>
      </div>
      <Footer />
      {showStep1Video && (
        <VideoModal
          toggle={toggleStep1Video}
          videoUrl={INSTRUCTION_STEP_1_PLAYER_URL}
          title="Instruction Step 1"
        />
      )}
      {showStep2Video && (
        <VideoModal
          toggle={toggleStep2Video}
          videoUrl={INSTRUCTION_STEP_2_PLAYER_URL}
          title="Instruction Step 2"
        />
      )}
      {showStep3Video && (
        <VideoModal
          toggle={toggleStep3Video}
          videoUrl={INSTRUCTION_STEP_3_PLAYER_URL}
          title="Instruction Step 3"
        />
      )}
    </div>
  )
}
