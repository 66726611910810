import React from "react"
import { times } from "lodash"

const MAX_FRIENDS = 8

export const FriendshipsRadio = ({ onChange, value }) => (
  <div className="c-friendship-radio">
    {times(MAX_FRIENDS + 1, i => (
      <div className="c-friendship-radio__option" key={i}>
        <input
          className="c-friendship-radio__input"
          type="radio"
          onChange={onChange}
          checked={value === i}
          id={`friendship-${i}`}
          name="friendship"
          value={i.toString()}
        />

        <label
          className="c-friendship-radio__label"
          htmlFor={`friendship-${i}`}>
          {i.toString()}
        </label>
      </div>
    ))}
  </div>
)
