export const campTranslations = {
  translations: {
    "": {
      Class: { msgstr: ["Cabin"], msgid_plural: ["Cabins"] },
      class: { msgstr: ["cabin"], msgid_plural: ["cabins"] },
      CLASS: { msgstr: ["CABIN"], msgid_plural: ["CABINS"] },
      Classes: { msgstr: ["Cabins"] },
      classes: { msgstr: ["cabins"] },
      CLASSES: { msgstr: ["CABINS"] },
      "Class/es": { msgstr: ["Cabin/s"] },
      Grade: { msgstr: ["Group"], msgid_plural: ["Groups"] },
      grade: { msgstr: ["group"], msgid_plural: ["groups"] },
      grades: { msgstr: ["groups"] },
      Grades: { msgstr: ["Groups"] },
      GRADES: { msgstr: ["GROUPS"] },
      "grade(s)": { msgstr: ["group(s)"] },
      "Grade/s": { msgstr: ["Group/s"] },
      "grade (or mixed/composite grade)": { msgstr: ["student groups"] },
      "New Grade": { msgstr: ["Student Group"] },
      "New Grade/s": { msgstr: ["Student Group/s"] },
    },
  },
}
