import React from "react"
import Logo from "images/logo.png"

const logoStyles = {
  maxWidth: 150,
}

export const UnavailableSurveyPage = () => {
  return (
    <div className="c-survey-login-error">
      <img
        src={Logo}
        alt="Class Solver Logo"
        style={logoStyles}
        className="ml-5 mt-5"
      />
      <div className="c-survey-login-error__container">
        <div className="h4 pb-2 w-75 text-center">
          Sorry, this survey does not exist. Please resend the teacher a survey
          on the Teacher page if you require a new one.
        </div>
      </div>
    </div>
  )
}
