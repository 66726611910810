import React from "react"
import { Modal, Button, ModalBody } from "reactstrap"
import { ModalHeader, InlineError, Loader } from "components"

export const SaveCautionModal = ({
  isOpen,
  loading,
  toggle,
  heading,
  text,
  buttonText,
  onButtonClick,
  showCancel = true,
  error,
}) => (
  <Modal isOpen={isOpen} className="modal-dialog-centered u-modal__w-30rem">
    <ModalHeader className="p-4" title={heading} toggle={toggle} />
    {loading ? (
      <div className="position-relative p-5">
        <Loader />
      </div>
    ) : (
      <ModalBody className="px-4 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between">
          <i className="fa fa-info-circle u-caution-icon mr-4" />
          <div>
            <div className="text-primary mb-3">IMPORTANT NOTICE</div>
            <div>{text}</div>
          </div>
        </div>
        <Button color="primary" className="mt-5 w-50" onClick={onButtonClick}>
          {buttonText}
        </Button>
        {showCancel && (
          <span
            className="text-primary p-3 pl-5 pr-5 mt-2 cursor-pointer"
            onClick={toggle}>
            Cancel
          </span>
        )}
        <InlineError text={error} />
      </ModalBody>
    )}
  </Modal>
)
