export const CHARACTERISTIC_COLOURS = [
  "#60c3e2",
  "#1d2ed2",
  "#7bf031",
  "#ce3fd9",
  "#fca613",
  "#3b2584",
  "#e7181c",
  "#fbd90b",
]

export const CHARACTERISTIC_OPACITY_LEVELS = [
  "ff",
  "e8",
  "d1",
  "ba",
  "a3",
  "8c",
  "75",
  "5e",
  "47",
  "30",
  "19",
  "00",
]

export const CURRENT_CLASS_CHARACTERISTIC_PALETTE = [
  "#60c3e28c",
  "#1d2ed25e",
  "#7bf0318c",
  "#ce3fd975",
  "#fca6138c",
  "#3b258475",
  "#e7181c75",
  "#fbd90ba3",
  "#60c3e23F",
  "#1d2ed294",
  "#7bf031e6",
  "#ce3fd8c4",
  "#fca613d0",
  "#3b25849d",
  "#e7181cb8",
  "#fbd90bef",
  "#60c3e2ff",
  "#1d2ed2a3",
  "#7bf031ff",
  "#ce3fd9d1",
  "#fca613e8",
  "#3b2584ba",
  "#e7181cd1",
  "#fbd90bff",

  // Other
]

export const NO_CURRENT_CLASS_COLOUR = "#80debcff"

// flatMap is not supported until Node 11 so the tests fail
export const CHARACTERISTIC_COLOUR_PALETTE =
  CHARACTERISTIC_OPACITY_LEVELS.reduce(
    (acc, opacity) =>
      acc.concat(CHARACTERISTIC_COLOURS.map(colour => colour + opacity)),
    []
  )
