import { gql } from "@apollo/client"

export const countriesQuery = gql`
  query country {
    countries {
      code
      name
      states {
        code
        name
      }
    }
  }
`

export const signupMutation = gql`
  mutation signup(
    $addressLine1: String!
    $contactEmail: String!
    $contactFirstName: String!
    $contactLastName: String!
    $contactNumber: String!
    $countryCode: String!
    $numberOfStudents: Int
    $postcode: String!
    $schoolName: String!
    $stateCode: String!
    $suburb: String!
    $administrationSystem: String
    $contactPositionTitle: String
    $referrer: String
  ) {
    signup(
      addressLine1: $addressLine1
      contactEmail: $contactEmail
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactNumber: $contactNumber
      countryCode: $countryCode
      numberOfStudents: $numberOfStudents
      postcode: $postcode
      schoolName: $schoolName
      stateCode: $stateCode
      suburb: $suburb
      administrationSystem: $administrationSystem
      contactPositionTitle: $contactPositionTitle
      referrer: $referrer
    ) {
      message
    }
  }
`

export const emailQuery = gql`
  query {
    email @client {
      email
    }
  }
`

export const updateEmailMutation = gql`
  mutation updateEmail($email: String!) {
    updateEmail(email: $email) @client {
      email
    }
  }
`

export const deleteUserMutation = gql`
  mutation ($id: ID!, $role: RoleTypes!, $schoolId: ID) {
    deleteUser(id: $id, role: $role, schoolId: $schoolId) {
      id
    }
  }
`

export const userProfileQuery = gql`
  query {
    myprofile {
      id
      firstName
      lastName
      email
      role
      schools {
        id
        name
        requireMfa
      }
      schoolInvitations {
        id
        school {
          name
        }
        inviterFullName
      }
      acceptedCustomerTermsAt
      acceptedUpdatesAndMarketing
      acceptedPrivacyPolicyAt
      positionTitle
      mfa
    }
  }
`

export const requestNewTokenMutation = gql`
  mutation (
    $currentToken: String!
    $tokenType: LoginTokenTypes!
    $ccList: [String!]
  ) {
    requestNewToken(
      currentToken: $currentToken
      tokenType: $tokenType
      ccList: $ccList
    )
  }
`

export const requestNewTokenForTeacherMutation = gql`
  mutation ($teacherId: ID!, $tokenType: LoginTokenTypes!, $ccList: [String!]) {
    requestNewTokenForTeacher(
      teacherId: $teacherId
      tokenType: $tokenType
      ccList: $ccList
    )
  }
`

export const requestOtpMutation = gql`
  mutation request_otp($currentToken: String!, $tokenType: LoginTokenTypes!) {
    requestOtp(currentToken: $currentToken, tokenType: $tokenType)
  }
`

export const loginWithSolutionTokenMutation = gql`
  mutation ($token: String!, $password: String) {
    loginWithSolutionToken(solutionToken: $token, password: $password) {
      token
      user {
        id
        role
        schoolId
        firstName
      }
      permissions {
        showStudentRequests
        showTeacherRequests
        showMetrics
        showCharacteristics
        showTeachers
        showFriendships
        writable
      }
    }
  }
`

export const loginWithSurveyTokenMutation = gql`
  mutation ($token: String!, $password: String) {
    loginWithSurveyToken(surveyToken: $token, password: $password) {
      token
      user {
        id
        role
        schoolId
        firstName
      }
    }
  }
`

export const verifyBearerTokenMutation = gql`
  mutation verifyBearerToken($bearerToken: String!, $temporaryToken: String!) {
    verifyBearerToken(
      bearerToken: $bearerToken
      temporaryToken: $temporaryToken
    )
  }
`
