export const dateToString = date => {
  const newDate = parseToDateObject(date)
  const options = { year: "numeric", month: "short", day: "2-digit" }
  // Use default locale
  return newDate.toLocaleDateString(undefined, options)
}

export const shortDateToString = date => {
  const newDate = parseToDateObject(date)
  const options = { year: "2-digit", month: "numeric", day: "numeric" }
  // Use default locale
  return newDate.toLocaleDateString(undefined, options)
}

export const timeFromDateTimeString = dateTime => {
  const newDateTime = parseToDateObject(dateTime)
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }
  // Use default locale
  const time = newDateTime.toLocaleTimeString(undefined, options)
  return `${time}`
}

export const dateTimeToString = dateTime => {
  const newDateTime = parseToDateObject(dateTime)
  const options = {
    hour: "numeric",
    minute: "numeric",
  }
  // Use default locale
  const date = dateToString(dateTime)
  const time = newDateTime.toLocaleTimeString(undefined, options)
  return `${date} ${time}`
}

const parseToDateObject = dateTimeString => {
  // We assume the string either has the timezone set as UTC
  // or it doesn't have a timezone at all
  if (dateTimeString.endsWith("Z")) {
    return new Date(dateTimeString)
  } else {
    return new Date(dateTimeString + "Z")
  }
}
