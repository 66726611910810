import React, { Component } from "react"
import { Modal, ModalBody, Input, Label } from "reactstrap"
import {
  Loader,
  ModalHeader,
  ButtonBar,
  InlineError,
  ConditionalDisplay,
} from "components"

import { validateNonEmptyString } from "util/validators"

export class TextInputModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        text: "",
        textArea: "",
      },

      errors: {},
    }
  }

  static defaultProps = {
    verifyText: text => ({
      valid: validateNonEmptyString(text),
      hint: "Cannot be empty",
    }),
    withTextArea: false,
  }

  onChange = e => {
    const { verifyText } = this.props

    const text = e.target.value

    const trimmedText = text.trim()

    const { valid, hint } = verifyText(trimmedText)

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: text,
      },

      errors: {
        text: valid ? "" : hint,
      },
    })
  }

  onButtonClick = () => {
    const {
      data: { text, textArea },
    } = this.state

    const { verifyText, onSubmit } = this.props

    const { valid } = verifyText(text)

    const trimmedText = text.trim()
    const trimmedTextArea = textArea.trim()
    if (valid) {
      onSubmit(trimmedText, trimmedTextArea)
    }
  }

  render() {
    const {
      isOpen,
      toggle,
      modalTitle,
      textInputLabel,
      textAreaLabel,
      submitButtonText,
      withTextArea,
      loading,
      error,
    } = this.props
    const { data, errors } = this.state
    return (
      <Modal isOpen={isOpen} className="u-modal__w-30rem modal-dialog-centered">
        <ModalHeader title={modalTitle} toggle={toggle} className="p-4" />

        <ModalBody className="p-4">
          {loading ? (
            <div className="p-5">
              <Loader />
            </div>
          ) : (
            <React.Fragment>
              <Label>{textInputLabel}</Label>
              <Input
                type="text"
                className="input-sm"
                name="text"
                value={data.text}
                onFocus={e => e.target.select()}
                onChange={this.onChange}
              />
              <InlineError text={errors.text} className="d-block" />
              <ConditionalDisplay show={withTextArea}>
                <React.Fragment>
                  <Label className="mt-3">{textAreaLabel}</Label>
                  <Input
                    type="textarea"
                    className="input-sm"
                    name="textArea"
                    id="textArea"
                    placeholder="Add description..."
                    value={data.textArea}
                    onChange={this.onChange}
                    maxLength={255}
                  />
                </React.Fragment>
              </ConditionalDisplay>
              <ButtonBar
                onCancelClick={toggle}
                buttonText={submitButtonText}
                onButtonClick={this.onButtonClick}
                className="mt-3"
              />
              <InlineError text={error} />
            </React.Fragment>
          )}
        </ModalBody>
      </Modal>
    )
  }
}
