import React from "react"
import { UncontrolledAlert } from "reactstrap"

export const RecommendedBrowserAlert = () => {
  // https://stackoverflow.com/a/9851769 no longer works properly, switching to
  // user agent detection instead
  const isChrome = navigator.userAgent.indexOf("Chrome") > -1 && !!window.chrome

  if (!isChrome) {
    return (
      <UncontrolledAlert color="primary">
        Please use the Google Chrome browser for best results. Some features may
        not work properly with other browsers.
      </UncontrolledAlert>
    )
  } else {
    return null
  }
}
