import { gql } from "@apollo/client"

export const maxFriendshipQuery = gql`
  query ($schoolId: ID!) {
    schoolSettings(schoolId: $schoolId) {
      id
      maxFriends
    }
  }
`

export const createOrUpdateConstraintsFriend = gql`
  mutation ($constraintsFriend: ConstraintsFriendParams) {
    createOrUpdateConstraintsFriend(constraintsFriend: $constraintsFriend) {
      constraintsFriends {
        id
        constraintId
      }
    }
  }
`

export const deleteAllConstraintsFriends = gql`
  mutation deleteAllConstraintsFriends($schoolId: ID!) {
    deleteAllConstraintsFriends(schoolId: $schoolId)
  }
`

export const deleteConstraintsFriendsByCurrentGradesMutation = gql`
  mutation deleteConstraintsFriendsByCurrentGrades(
    $schoolId: ID!
    $currentGradeIds: [ID!]!
  ) {
    deleteConstraintsFriendsByCurrentGrades(
      schoolId: $schoolId
      currentGradeIds: $currentGradeIds
    )
  }
`

export const pruneMaxFriendsMutation = gql`
  mutation ($schoolId: ID!) {
    pruneMaxFriends(schoolId: $schoolId)
  }
`

export const constraintsFriendQuery = gql`
  query ($adminOnly: Boolean, $schoolId: ID!) {
    constraints(adminOnly: $adminOnly, schoolId: $schoolId) {
      constraintsFriend {
        id
        studentFromId
      }
    }
  }
`
