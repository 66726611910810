import React from "react"
import { Input, ListGroup, ListGroupItem } from "reactstrap"
import { isDefinedNotNull } from "util/objUtil"
import { sortByName } from "util/sortUtil"

export class FilteredInput extends React.Component {
  constructor() {
    super()

    this.state = {
      tableData: [],
      search: "",
    }
  }

  componentDidMount = () => {
    this.getFilteredRecords(this.props)
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    // check if a new student/teacher has been added to exclude list
    if (
      JSON.stringify(this.props.studentsToExclude) !==
        JSON.stringify(nextProps.studentsToExclude) ||
      JSON.stringify(this.props.teachersToExclude) !==
        JSON.stringify(nextProps.teachersToExclude)
    ) {
      this.getFilteredRecords(nextProps)
    }
  }

  getFilteredRecords = props => {
    const { students, studentsToExclude, teachers, teachersToExclude } = props

    // filter students and teachers
    const filteredStudents = this.filterData(students, studentsToExclude)
    const filteredTeachers = this.filterData(teachers, teachersToExclude)

    // create filtered data
    const tableData = []
    if (isDefinedNotNull(filteredStudents)) {
      tableData.push(...filteredStudents)
    }
    if (isDefinedNotNull(filteredTeachers)) {
      const clonedTeachers = Array.from(filteredTeachers)
      sortByName(clonedTeachers)
      tableData.push(...clonedTeachers)
    }
    this.setState({ tableData })
  }

  filterData(people, toExclude) {
    if (isDefinedNotNull(people)) {
      if (isDefinedNotNull(toExclude)) {
        // filter list if an exclusion list is defined
        return people.filter(person => !toExclude.includes(person.id))
      } else {
        return people
      }
    }
    return undefined
  }

  _changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  _getRecords = () => {
    this.setState({ tableData: this.props.filteredData })
  }

  // Changes a state to show the filtered list of friends
  _focusHandler = () => {
    this.setState({ focused: true })
  }

  // Changes a state to hide the filtered list of friends
  _blurHandler = () => {
    //
  }

  _updateSearch = event => {
    this.setState({ search: event.target.value.substr(0, 20) })
  }

  _changeHandelerParent = e => {
    this.props.changeHandler && this.props.changeHandler(e, this.props.index)
  }

  _clickHandeler = objToReturn => {
    this.setState({ focused: false })
    this.props.selectFunction(objToReturn, this.props.index)
  }

  onClearClick = () => {
    this.props.selectFunction(undefined, this.props.index)
    this.setState({ focused: false })
  }

  onKeyDown = (e, value) => {
    // on enter click
    if (e.keyCode === 13 && isDefinedNotNull(value)) {
      this.props.selectFunction(value, this.props.index)
      this.setState({ focused: false })
      e.target.blur()
    }
  }

  render() {
    let filteredTableData = this.state.tableData.filter(tData => {
      if (this.props.selectedValue) {
        return (
          Object.values(tData)
            .join(" ")
            .toLowerCase()
            .indexOf(this.props.selectedValue.toLowerCase()) !== -1
        )
      } else {
        return (
          Object.values(tData)
            .join(" ")
            .toLowerCase()
            .indexOf(this.state.search.toLowerCase()) !== -1
        )
      }
    })

    return (
      <div className={"c-filtered-list " + this.props.containerClass}>
        <Input
          className="c-filtered-list__input"
          autoComplete="off"
          name={this.props.nameVal}
          onFocus={this._focusHandler}
          onBlur={this._blurHandler}
          id="search"
          value={this.props.selectedValue}
          onChange={e => this._changeHandelerParent(e)}
          type="text"
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          onKeyDown={e => {
            this.onKeyDown(e, filteredTableData[0])
          }}
        />
        <i
          className="fa fa-times color-grey-dark c-filtered-list__icon"
          onClick={this.onClearClick}
        />
        <span
          className={`c-filtered-list__list ${
            this.state.focused ? "" : "d-none"
          }`}>
          <ListGroup className="c-filtered-list__list__list-group">
            {Object.values(filteredTableData).map((tableDataArray, index) => {
              let fullName = tableDataArray.currentClass
                ? `${tableDataArray.firstName} ${tableDataArray.lastName} (${tableDataArray.currentClass.label})`
                : `${tableDataArray.firstName} ${tableDataArray.lastName}`

              let searchTerm = this.props.selectedValue
                ? this.props.selectedValue.toLowerCase()
                : this.state.search.toString().toLowerCase()
              let indexMarchStart = fullName.slice(
                0,
                fullName.toLowerCase().indexOf(searchTerm)
              )
              let indexMarchMid = searchTerm
              let indexMarchEnd = fullName.slice(
                fullName.toLowerCase().indexOf(searchTerm) + searchTerm.length,
                fullName.length
              )
              let result = ""
              indexMarchStart =
                indexMarchStart.charAt(0).toUpperCase() +
                indexMarchStart.slice(1)

              if (indexMarchStart.length === 0) {
                indexMarchMid = indexMarchMid.replace(/([^ \t]+)/g, s => {
                  return s.charAt(0).toUpperCase() + s.slice(1)
                })
              }

              // check if contains a space as last character then capitalise the new word
              if (indexMarchStart.indexOf(" ") === indexMarchStart.length - 1) {
                indexMarchMid =
                  indexMarchMid.charAt(0).toUpperCase() + indexMarchMid.slice(1)
              }

              if (
                fullName.toString().toLowerCase().indexOf(searchTerm) !== -1
              ) {
                result = (
                  <ListGroupItem
                    className="c-filtered-list__list__list-group__list-group-item"
                    key={index}
                    onClick={() => {
                      this._clickHandeler(tableDataArray, this.props.index)
                    }}
                    action>
                    <span>
                      <span>{indexMarchStart}</span>
                      <strong>{indexMarchMid}</strong>
                      <span>{indexMarchEnd}</span>
                    </span>
                  </ListGroupItem>
                )
              } else {
                result = ""
              }

              return result
            })}
            {Object.values(filteredTableData).length < 1 && (
              <ListGroupItem
                onClick={() => {
                  this._clickHandeler(undefined)
                }}
                action>
                <span>No Matches</span>
              </ListGroupItem>
            )}
          </ListGroup>
        </span>
      </div>
    )
  }
}
