import { NEW_GRADE, LEAVING_GRADE } from "domains/app/constants/gradeFields"

export const isNewGrade = grade => {
  return grade.code === NEW_GRADE
}

export const isLeavingGrade = grade => {
  return grade.code === LEAVING_GRADE
}

export const isSpecialGrade = grade => {
  return isNewGrade(grade) || isLeavingGrade(grade)
}

export const hasNewGradesByGender = grade => {
  return grade.defaultNewGrade.__typename === "SchoolGradesByGender"
}

export const defaultNewGradeForStudent = (currentGrade, student) => {
  let newGrade

  const defaultNewGrade = currentGrade.defaultNewGrade

  if (defaultNewGrade.__typename === "SchoolGrade") {
    newGrade = defaultNewGrade
  } else if (hasNewGradesByGender(currentGrade)) {
    switch (student && student.gender) {
      case "M":
        newGrade = defaultNewGrade.maleGrade
        break

      case "F":
        newGrade = defaultNewGrade.femaleGrade
        break

      case "X":
        newGrade = defaultNewGrade.nonBinaryGrade
        break

      default:
        newGrade = defaultNewGrade.femaleGrade
    }
  }

  return newGrade
}
