import React from "react"

export const Bar = ({ condition }) => (
  <span
    className={
      condition ? "c-dashboard__bar" : "c-dashboard__bar c-dashboard__bar--alt"
    }
  />
)

export const Circle = ({ condition }) => (
  <span
    className={
      condition
        ? "c-dashboard__circle-mini"
        : "c-dashboard__circle-mini c-dashboard__circle-mini--alt"
    }
  />
)

export const CircleLarge = ({ condition, content }) => (
  <span
    className={
      condition
        ? "c-dashboard__circle"
        : "c-dashboard__circle c-dashboard__circle--alt"
    }>
    {condition ? <i className="fa fa-check" /> : <span>{content}</span>}
  </span>
)
