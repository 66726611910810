import React from "react"
import { Modal, Button } from "reactstrap"
import { useNavigate } from "react-router-dom"

import { ModalHeader } from "components"
import { removeSchoolId } from "util/app"

export const RequireMfaPage = () => {
  const navigate = useNavigate()

  const handleContinue = () => {
    navigate("/UserProfile")
  }

  const handleCancel = () => {
    removeSchoolId()
    navigate("/")
  }

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <div className="p-4">
        <ModalHeader title="Account requires MFA" className="mb-4" />
        <div className="d-flex flex-column align-items-center">
          <div className="mb-4">
            The account you are trying to access requires MFA and you currently
            do not have MFA turned on. <br />
            <br />
            Please go to your profile page and turn on MFA to access this
            account.
          </div>
          <Button color="primary" className="w-50" onClick={handleContinue}>
            Go to Profile Page
          </Button>
          <Button color="link" className="w-50" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}
