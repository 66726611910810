import React from "react"

import { AccountDetails } from "domains/accountSettings/components"
import { BillingDetails } from "../components/billingDetails"
import { Referral } from "../components/referral"

export const BillingSettingsPage = () => {
  return (
    <div className="container u-mw-750 o-component">
      <AccountDetails isSuper={false} />
      <div className="py-5 u-content-border-bottom">
        <BillingDetails />
      </div>
      <div className="my-5">
        <Referral
          heading="Please help us spread the word"
          message="If you refer another school, they receive a $100 discount this year and a $100 credit will be applied to your school for next year."
        />
      </div>
    </div>
  )
}
