import { gql } from "@apollo/client"

export const characteristicsQuery = gql`
  query ($schoolId: ID!, $showCurrentClassCharacteristic: Boolean) {
    schoolCharacteristics(
      schoolId: $schoolId
      showCurrentClassCharacteristic: $showCurrentClassCharacteristic
    ) {
      id
      name
      priority
      schoolId
      sensitive
      tag_only
      characteristicResponses {
        characteristicId
        description
        id
        label
        value
        colour
      }
    }
  }
`

export const updateSchoolCharacteristicsMutation = gql`
  mutation ($schoolCharacteristics: [CharacteristicParams!]!, $schoolId: ID!) {
    updateSchoolCharacteristics(
      schoolCharacteristics: $schoolCharacteristics
      schoolId: $schoolId
    ) {
      id
      characteristicResponses {
        characteristicId
        description
        id
        label
        value
        colour
      }
      schoolId
      name
      priority
      sensitive
      tag_only
    }
  }
`

export const deleteAllStudentCharacteristicResponses = gql`
  mutation deleteAllStudentCharacteristicResponses($schoolId: ID!) {
    deleteAllStudentCharacteristicResponses(schoolId: $schoolId)
  }
`

export const deleteStudentCharacteristicResponsesFromSchoolMutation = gql`
  mutation deleteStudentCharacteristicResponsesFromSchool(
    $schoolId: ID!
    $characteristicIds: [ID!]!
  ) {
    deleteStudentCharacteristicResponsesFromSchool(
      schoolId: $schoolId
      characteristicIds: $characteristicIds
    )
  }
`

export const deleteStudentCharacteristicResponsesByCurrentGradeMutation = gql`
  mutation deleteStudentCharacteristicResponsesByCurrentGrade(
    $schoolId: ID!
    $characteristicIds: [ID!]!
    $currentGradeIds: [ID!]!
  ) {
    deleteStudentCharacteristicResponsesByCurrentGrade(
      schoolId: $schoolId
      characteristicIds: $characteristicIds
      currentGradeIds: $currentGradeIds
    )
  }
`
