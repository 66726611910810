import { Tooltip } from "react-tooltip"

export const CharacteristicResponseTooltip = ({
  activeCharacteristic,
  characteristicResponseCounts,
  ...others
}) => {
  if (activeCharacteristic) {
    return (
      <Tooltip {...others}>
        <div>
          <div className="c-characteristic-tooltip__header text-primary">
            {activeCharacteristic.name}
          </div>
          <div className="c-characteristic-tooltip__body">
            {characteristicResponseCounts &&
              characteristicResponseCounts.map(
                ({ characteristicResponse, studentCount }, index) => (
                  <div key={index} className="d-flex align-items-center mt-2">
                    <span
                      className="u-characteristic-circle mr-2"
                      style={{
                        backgroundColor: characteristicResponse.colour,
                      }}
                    />
                    <div>
                      <span className="u-bold">
                        {characteristicResponse.label}
                      </span>
                      {` - ${studentCount}`}
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </Tooltip>
    )
  } else {
    return null
  }
}
