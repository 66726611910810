import React, { useState } from "react"
import moment from "moment"
import { useMutation } from "@apollo/client"

import { Preference } from "domains/accountSettings/components/preference"
import { updateSelfMutation } from "domains/accountSettings/graphql"
import { isPrivilegedRole } from "util/userUtil"
import { CautionModal } from "components"

export const ProfilePreferences = ({ userProfile, refetchQueries }) => {
  const [showMfaWarning, setShowMfaWarning] = useState(false)
  const [mfaError, setMfaError] = useState()
  const [updateSelf] = useMutation(updateSelfMutation, { refetchQueries })

  const acceptedUpdatesAndMarketing = !!userProfile.acceptedUpdatesAndMarketing

  const handleUpdatesAndMarketingChange = () => {
    const nowString = moment().utc().toISOString()

    const variables = {
      self: {
        id: userProfile.id,
        acceptedUpdatesAndMarketing: acceptedUpdatesAndMarketing
          ? null
          : nowString,
      },
    }
    updateSelf({ variables })
  }

  const closeMfaWarningModal = () => setShowMfaWarning(false)
  const openMfaWarningModal = () => setShowMfaWarning(true)

  const handleMfaToggle = () => {
    changeMfaSetting(!userProfile.mfa).then(response => {
      if (response.errors) {
        setMfaError("There was an error turning MFA on, please try again.")
      } else {
        window.location.href = "/logout"
      }
    })
  }

  const changeMfaSetting = mfaOn => {
    const variables = {
      self: {
        id: userProfile.id,
        mfa: mfaOn,
      },
    }
    return updateSelf({ variables })
  }

  const needsMFA =
    isPrivilegedRole(userProfile.role) ||
    userProfile.schools.some(school => school.requireMfa)

  return (
    <>
      <h2 className="mb-5 u-blue-primary">Profile Preferences</h2>
      <Preference
        id="updateProductUpdateAndMarketingConsent"
        name="updateProductUpdateAndMarketingConsent"
        title="Product updates and marketing"
        description="I'd like to receive important product updates (e.g. new features) and the occasional marketing related email (e.g. new products)"
        checked={acceptedUpdatesAndMarketing}
        onChangeHandler={handleUpdatesAndMarketingChange}
      />
      {/* For now, we only show the MFA preference if the user needs to have it */}
      {needsMFA && (
        <Preference
          id="updateMfa"
          name="updateMfa"
          title="Turn on Multi Factor Authentication (MFA)"
          description="As an added security measure, you will be asked for a One Time Password from your Authenticator app (e.g. Google Authenticator) when you login to Class Solver"
          checked={userProfile.mfa}
          onChangeHandler={openMfaWarningModal}
        />
      )}
      {showMfaWarning && (
        <CautionModal
          toggle={closeMfaWarningModal}
          onButtonClick={handleMfaToggle}
          buttonText="Yes"
          showCancel
          heading="Change MFA Setting?"
          text={
            <div>
              You are about to change your MFA setting. In order for it to take
              effect, you will be logged out. Please log back in to activate the
              change.
              <br />
              <br />
              Are you sure you want to change it?
            </div>
          }
          error={mfaError}
        />
      )}
    </>
  )
}
