import React, { useState } from "react"
import { Button } from "reactstrap"
import ExcelIcon from "images/excel-icon.svg"
import { VideoModal } from "components"

import { INSTRUCTION_RUN_SOLVER_PLAYER_URL } from "constants/videos"

export const ColdStartView = ({ buttonClick }) => {
  const [showVideo, setShowVideo] = useState(false)

  const toggleVideo = () => {
    setShowVideo(!showVideo)
  }

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center">
      <div className="flex-column text-center">
        <div>
          <h1 className="mt-3">Run Solver for this Grade</h1>
          <a
            className="color-blue-mid mx-4 mt-1 font-size-14"
            onClick={toggleVideo}>
            <i className="fa fa-play mr-2" />
            {"Watch Video"}
          </a>
        </div>
        <div>
          <img
            height="150px"
            className="mt-4"
            src={ExcelIcon}
            alt="Excel Icon"
          />
        </div>
        <Button className="mt-3" color="primary" onClick={buttonClick}>
          Run Solver
        </Button>
      </div>
      {showVideo && (
        <VideoModal
          toggle={toggleVideo}
          videoUrl={INSTRUCTION_RUN_SOLVER_PLAYER_URL}
          title="Instruction Step 1"
        />
      )}
    </div>
  )
}
