import React from "react"
import { SearchInput } from "components"
import { isDefinedNotNull } from "util/objUtil"

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"

const ClickableAction = ({ onClick, text, icon }) => {
  return (
    <div className="mr-4 d-flex align-items-center">
      <div className="cursor-pointer d-print-none" onClick={onClick}>
        <i className={`fa ${icon} mr-2 text-primary font-size-16`} />
        <span className="text-primary u-font-weight-medium font-size-16">
          {text}
        </span>
      </div>
    </div>
  )
}

export const ActionBar = ({
  type,
  searchPlaceholder,
  onSearchChange,
  searchValue,
  addText,
  adminMode,
  filterComponent,
  onAddClick,
  onImportClick,
  bulkImportText,
  additionalImportActions,
  additionalRemoveActions,
  additionalActions,
}) => {
  // Keep a piece of state which indicates whether the dropdown is open
  const [isOpen1, setIsOpen1] = React.useState(false)
  const [isOpen2, setIsOpen2] = React.useState(false)
  const [isOpen3, setIsOpen3] = React.useState(false)

  // Handlers for toggling the dropdown
  const handleToggle1 = React.useCallback(() => {
    setIsOpen1(isOpen1 => !isOpen1)
  }, [])

  const handleToggle2 = React.useCallback(() => {
    setIsOpen2(isOpen2 => !isOpen2)
  }, [])

  const handleToggle3 = React.useCallback(() => {
    setIsOpen3(isOpen3 => !isOpen3)
  }, [])

  let importActions = []
  let removeActions = []
  let actions = []

  let importClickActions = []
  if (onImportClick) {
    importClickActions.push({
      onClick: onImportClick,
      text: bulkImportText,
      icon: "fa-upload",
    })
  }

  if (type === "Students") {
    if (additionalImportActions) {
      importActions = importClickActions.concat(additionalImportActions)
    }

    if (additionalRemoveActions) {
      removeActions = additionalRemoveActions
    }

    if (additionalActions) {
      actions = additionalActions
    }
  } else {
    actions = importClickActions.concat(additionalActions)
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-between pt-2 pb-2 pr-4 pl-4 w-100 ${
        adminMode ? "bg-admin" : ""
      }`}>
      <div className="d-flex">
        <ClickableAction onClick={onAddClick} text={addText} icon="fa-plus" />
        {importActions.length !== 0 && (
          <Dropdown className="mr-4" isOpen={isOpen1} toggle={handleToggle1}>
            <DropdownToggle className="btn-120-width btn btn-primary" caret>
              Import ...
            </DropdownToggle>

            <DropdownMenu>
              {importActions.map((action, index) => {
                return (
                  <DropdownItem
                    className="color-blue-mid px-3"
                    key={index}
                    onClick={action.onClick}>
                    <i className={`fa ${action.icon} mr-2`} />
                    <span>{action.text}</span>
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </Dropdown>
        )}

        {removeActions.length !== 0 && (
          <Dropdown className="mr-4" isOpen={isOpen2} toggle={handleToggle2}>
            <DropdownToggle className="btn-120-width btn btn-primary" caret>
              Remove ...
            </DropdownToggle>

            <DropdownMenu>
              {removeActions.map((action, index) => {
                return (
                  <DropdownItem
                    className="color-blue-mid px-3"
                    key={index}
                    onClick={action.onClick}>
                    <i className={`fa ${action.icon} mr-2`} />
                    <span>{action.text}</span>
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </Dropdown>
        )}

        {actions.length !== 0 && (
          <Dropdown className="mr-4" isOpen={isOpen3} toggle={handleToggle3}>
            <DropdownToggle className="btn-120-width btn btn-primary" caret>
              More ...
            </DropdownToggle>

            <DropdownMenu>
              {actions.map((action, index) => {
                return (
                  <DropdownItem
                    className="color-blue-mid px-3"
                    key={index}
                    onClick={action.onClick}>
                    <i className={`fa ${action.icon} mr-2`} />
                    <span>{action.text}</span>
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
      <div className="d-flex">
        {isDefinedNotNull(filterComponent) && filterComponent}
        {onSearchChange && (
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={onSearchChange}
          />
        )}
      </div>
    </div>
  )
}
