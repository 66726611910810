import React from "react"
import { Tooltip } from "react-tooltip"

export const CharacteristicSummaryTooltip = ({
  characteristicResponses,
  ...others
}) => (
  <Tooltip opacity={1} {...others}>
    <div>
      <div className="c-characteristic-tooltip__header text-primary">
        Characteristics
      </div>
      <div className="c-characteristic-tooltip__body">
        {characteristicResponses &&
          characteristicResponses.map((response, index) => (
            <div key={index} className="d-flex align-items-center mt-2">
              <span
                className="u-characteristic-circle mr-2"
                style={{
                  backgroundColor: response.colour,
                }}
              />
              <div>
                <span className="u-bold">{response.characteristic.name}</span>
                {response.characteristic.name && " - "}
                {`${response.label}`}
              </div>
            </div>
          ))}
      </div>
    </div>
  </Tooltip>
)
