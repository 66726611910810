import React from "react"

export const Preference = props => {
  const {
    id,
    name,
    title,
    description,
    checked = true,
    onChangeHandler,
  } = props

  return (
    <div className="d-flex justify-content-between mb-4">
      <div className="u-w-55">
        <div className="mb-2">{title}</div>
        <p>{description}</p>
      </div>
      <div className="u-w-45">
        <div className="c-toggle">
          <input
            type="checkbox"
            className="c-toggle__checkbox"
            id={id}
            name={name}
            checked={checked}
            onChange={onChangeHandler}
          />
          <label className="c-toggle__label" htmlFor={id}>
            <div className="c-toggle__on">Yes</div>
            <div className="c-toggle__off">No</div>
          </label>
        </div>
      </div>
    </div>
  )
}
