import { gql } from "@apollo/client"

export const sendAdminNotificationMutation = gql`
  mutation (
    $notificationType: NotificationTypes!
    $uploadJobId: ID
    $message: String
    $schoolId: ID
  ) {
    sendAdminNotification(
      notificationType: $notificationType
      uploadJobId: $uploadJobId
      message: $message
      schoolId: $schoolId
    )
  }
`

export const getNotificationsQuery = gql`
  query ($schoolId: ID!) {
    getNotifications(schoolId: $schoolId) {
      notificationId
      severity
      title
      message
    }
  }
`
