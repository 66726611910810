import React from "react"
import { useMutation } from "@apollo/client"
import { requestNewTokenMutation } from "domains/auth/graphql"
import { Button } from "reactstrap"

export const RequestNewToken = ({ currentToken, tokenType }) => {
  const [requestNewToken, { data, error, loading }] = useMutation(
    requestNewTokenMutation,
    {
      variables: { currentToken, tokenType },
      onError: () => {},
    }
  )

  const isDisabled = !!(data || loading || error)

  return (
    <div className="mt-4 d-flex flex-column align-items-center">
      <div>
        <Button
          color="primary"
          onClick={() => requestNewToken()}
          disabled={isDisabled}>
          Send email with new link
        </Button>
      </div>
      <div className="mt-2" style={{ fontSize: "1rem" }}>
        {data && "Success! A new link has been sent to your email."}
        {error &&
          "There was an error requesting a new link. Please contact your school administrator."}
      </div>
    </div>
  )
}
