import React, { useState } from "react"

import { useMutation, useQuery } from "@apollo/client"
import Select from "react-select"

import { Loader, CautionModal } from "components"

import {
  getServerModeQuery,
  setServerModeMutation,
} from "domains/serverSettings/graphql"

const transformToOptions = mode => {
  return { label: mode, value: mode }
}

export const ServerSettings = () => {
  const [nextMode, setNextMode] = useState()

  const { data, loading: loadingServerMode } = useQuery(getServerModeQuery)
  const [setServerMode] = useMutation(setServerModeMutation, {
    refetchQueries: [{ query: getServerModeQuery }],
  })

  if (loadingServerMode) {
    return <Loader />
  }

  const serverMode = data.getServerMode

  const serverModeOpts = ["PRIVATE", "PUBLIC"].map(transformToOptions)

  const handleModeChange = selected => {
    setNextMode(selected.value)
  }

  const changeMode = () => {
    setServerMode({
      variables: { serverMode: nextMode },
      onCompleted: () => {
        setNextMode(null)
      },
    })
  }

  const cautionText =
    nextMode && nextMode === "PUBLIC"
      ? "Are you sure you want to change the server mode to PUBLIC? This will allow all users to access the app."
      : "Are you sure you want to change the server mode to PRIVATE? This will only allow support and super users to access the app."
  return (
    <>
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">Server Settings</h2>
        <div>Server Mode: </div>
        <Select
          options={serverModeOpts}
          onChange={handleModeChange}
          value={transformToOptions(serverMode)}
          closeMenuOnSelect={true}
          hideSelectedOptions
          isSearchable={false}
          clearable={false}
        />
      </div>
      {nextMode && nextMode !== serverMode && (
        <CautionModal
          isOpen
          toggle={() => setNextMode(null)}
          onButtonClick={changeMode}
          buttonText="Change"
          heading="Change server mode?"
          text={cautionText}
        />
      )}
    </>
  )
}
