import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Select from "react-select"

import { activeCurrentClassesQuery } from "domains/classes/graphql"
import {
  studentsQuery,
  removeStudentsByCurrentClassesMutation,
} from "domains/students/graphql"
import { Modal, ModalBody, Label } from "reactstrap"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  ModalNote,
  Loader,
  defaultSelectStyles,
} from "components"
import { sortCurrentClasses } from "util/sortUtil"

import { DangerousMutation } from "domains/accountSettings/components"

export const RemoveStudentsByCurrentClassesModal = ({
  toggle,
  schoolId,
  adminOnly,
}) => {
  const [selectClassesModalOpen, setSelectClassesModalOpen] = useState(true)

  const { data, loading: loadingClasses } = useQuery(
    activeCurrentClassesQuery,
    { variables: { schoolId } }
  )

  const [
    removeStudentsByCurrentClass,
    { error: errorRemoving, loading: removingClasses },
  ] = useMutation(removeStudentsByCurrentClassesMutation, { onError: () => {} })

  const [selectedClasses, setSelectedClasses] = useState([])

  const loading = loadingClasses || removingClasses

  const availableClasses = loading
    ? []
    : sortCurrentClasses(data.activeCurrentClasses).map(currentClass => {
        return {
          label: currentClass.label,
          value: currentClass.id,
        }
      })

  if (selectClassesModalOpen) {
    return (
      <Modal isOpen className="modal-dialog-centered u-modal__w-45rem">
        <ModalHeader
          className="p-4"
          title="Remove Students by Current Class"
          toggle={toggle}
        />
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <>
            <ModalNote text="This function removes students based on the Current Class or classes that you select below. Students in class/s not selected will not be affected." />

            <ModalBody className="pb-4">
              <div className="px-4 pt-4">
                <Label>Current Class/s</Label>
                <Select
                  name="current_classes"
                  className="c-input__multiselect"
                  placeholder="Select Current Classes"
                  options={availableClasses}
                  value={selectedClasses}
                  onChange={classes => {
                    setSelectedClasses(classes)
                  }}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={defaultSelectStyles}
                />
              </div>
              <ButtonBar
                className="mt-5 px-4"
                buttonText="Remove Students"
                onButtonClick={() => setSelectClassesModalOpen(false)}
                onCancelClick={toggle}
                disabled={selectedClasses.length === 0}
              />
              {errorRemoving && (
                <InlineError
                  className="mt-5 px-4"
                  text={errorRemoving.message}
                />
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    )
  } else {
    return (
      <DangerousMutation
        dangerousMutation={() => {
          return removeStudentsByCurrentClass({
            variables: {
              schoolId: schoolId,
              currentClassIds: selectedClasses.map(option => option.value),
            },
            refetchQueries: [
              {
                query: studentsQuery,
                variables: {
                  adminOnly: adminOnly,
                  schoolId: schoolId,
                },
              },
              {
                query: activeCurrentClassesQuery,
                variables: { schoolId: schoolId },
              },
            ],
            onCompleted: toggle,
          })
        }}
        cautionHeading="Remove Students"
        cautionText="You can not 'undo' this operation. Are you sure you would like to permanently remove all students by class?"
        cautionButtonText="Yes, Proceed"
        successMessage="Students by class have been removed."
        errorMessage="Could not remove data"
        toggleComponent={toggle}
      />
    )
  }
}
