import { gql } from "@apollo/client"

export const getImportableStudentsQuery = gql`
  query ($schoolId: ID!, $sis: SisTypes!) {
    getImportableStudents(schoolId: $schoolId, sis: $sis) {
      students {
        firstName
        lastName
        gender
        studentCode
        currentClasses {
          name
        }
        currentGrade {
          name
        }
      }
    }
  }
`

export const importStudentsMutation = gql`
  mutation ($schoolId: ID!, $students: [ImportableStudent!]!) {
    importStudents(schoolId: $schoolId, students: $students) {
      importedStudents {
        id
      }
    }
  }
`
