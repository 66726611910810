import React from "react"
import { InputGroup } from "reactstrap"

import {
  Loader,
  InlineError,
  ClassBadge,
  ButtonBar,
  FilteredInput,
} from "components"

import { handleSessionExpired } from "util/app"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { SCHOOL_ID } from "constants/storageTokens"
import { fullName } from "util/nameUtil"
import * as resolverTypes from "constants/resolverTypes"

const FriendBadge = ({ name, studentClass }) => (
  <div className="c-friendships__friend-badge">
    <span className="pr-2">{name}</span>
    <ClassBadge label={studentClass} />
  </div>
)

// TODO: Deduplicate the same function in requests.js
const formatStudentName = student => {
  const fName = fullName(student)

  return student.currentClass
    ? `${fName} (${student.currentClass.label})`
    : fName
}

export class Friendships extends React.Component {
  constructor(props) {
    super(props)

    const friends = []
    this.props.friends.forEach(friend => {
      // add all friends of student into friends array
      if (friend && friend.studentTo.id !== this.props.studentId) {
        friends.push({
          ...friend.studentTo,
          name: formatStudentName(friend.studentTo),
        })
      }
    })

    for (let i = 0; i < this.props.maxFriends; i++) {
      // Need to push object for remaining amount of friends up to max friends
      if (!isDefinedNotNull(friends[i])) {
        friends.push({ firstName: "", lastName: "", name: "" })
      }
    }
    this.state = {
      errors: {},
      loading: false,
      edit: false,
      friends,
    }
  }

  toggleEdit = () => {
    this.props.toggleEdit()
    this.setState({
      edit: !this.state.edit,
    })
  }

  onChange = (e, index) => {
    const { friends } = this.state
    friends[index].name = e.target.value
    this.setState({
      friends,
    })
  }

  onSelectPersonTo = (value, index) => {
    const { friends } = this.state

    if (value) {
      friends.splice(index, 1, {
        ...value,
        name: formatStudentName(value),
      })
    } else {
      friends.splice(index, 1, { name: "" })
    }

    this.setState({
      friends,
    })
  }

  saveFriendships = () => {
    const {
      createOrUpdateConstraintsFriend,
      studentId,
      refetchQueries,
      updateStudentEditFlag,
    } = this.props
    const { friends } = this.state
    const studentToIds = []
    friends.forEach(friend => {
      if (friend.id) {
        studentToIds.push(friend.id)
      }
    })
    const variables = {
      constraintsFriend: {
        schoolId: sessionStorage.getItem(SCHOOL_ID),
        studentFromId: studentId,
        studentToIds,
      },
    }

    this.setState({ loading: true, errors: {} })
    createOrUpdateConstraintsFriend({ variables, refetchQueries })
      .then(() => {
        this.toggleEdit()
        this.setState({ loading: false })

        updateStudentEditFlag({
          variables: { type: resolverTypes.STUDENT_UPDATED, value: true },
        })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
          errors: {
            mutation:
              "Could not update friends, please contact your School Administrator",
          },
        })
      })
  }

  renderEditStudents(studentsToExclude) {
    const { maxFriends, students } = this.props
    const { friends } = this.state

    return friends.slice(0, maxFriends).map((friend, i) => (
      <div
        key={i}
        className="d-flex justify-content-between align-items-center w-100 mb-3">
        <span className="c-friendships__friend-label">Friend {i + 1}</span>
        <InputGroup className="w-50">
          <FilteredInput
            containerClass="flex-grow-1"
            nameVal={i}
            index={i}
            selectedValue={friend.name}
            changeHandler={this.onChange}
            selectFunction={this.onSelectPersonTo}
            placeholder="Type to select a student..."
            studentsToExclude={studentsToExclude}
            students={students}
            filteredData={students}
          />
        </InputGroup>
      </div>
    ))
  }

  render() {
    const { studentId, editable } = this.props
    const { friends, loading, errors } = this.state

    // build array of students to exclude
    const studentsToExclude = friends.map(f => {
      return f.id
    })
    studentsToExclude.push(studentId)

    if (this.state.edit) {
      return (
        <div className="c-friendships__edit px-2rem">
          <div className="c-friendships__header mb-4">FRIENDSHIPS</div>

          {loading ? (
            <div className="position-relative p-5">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="d-flex flex-column justify-content-between align-items-center">
                {this.renderEditStudents(studentsToExclude)}
              </div>

              <ButtonBar
                onButtonClick={this.saveFriendships}
                onCancelClick={this.toggleEdit}
                buttonText="Save Friendships"
              />
              <InlineError text={errors.mutation} />
            </div>
          )}
        </div>
      )
    }

    return (
      <div className="c-friendships px-2rem">
        <div className="d-flex justify-content-between">
          <span className="c-friendships__header">FRIENDSHIPS</span>
          {editable && (
            <span
              className="text-primary cursor-pointer"
              onClick={this.toggleEdit}>
              Edit
            </span>
          )}
        </div>
        <div className="c-friendships__friends-container">
          {friends.map((f, index) => {
            if (f.firstName) {
              // Only show if firstName is defined
              return (
                <FriendBadge
                  name={`${f.firstName} ${f.lastName}`}
                  studentClass={getPropertyIfDefined(f, "currentClass.label")}
                  key={index}
                />
              )
            }
            return ""
          })}
        </div>
      </div>
    )
  }
}
