import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { Loader } from "components"
import { EditBillingDetailsModal } from "./editBillingDetailsModal"
import { schoolWithSisQuery } from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"

export const BillingDetails = () => {
  const { data, loading } = useQuery(schoolWithSisQuery, {
    variables: { schoolId: getSchoolId() },
    errorPolicy: "all",
  })

  const [editBillingDetailsModal, setEditBillingDetailsModal] = useState(false)

  const toggleEditBillingDetailsModal = () =>
    setEditBillingDetailsModal(prevState => !prevState)

  const school = data && data.school

  return (
    <div className="c-account-details">
      <h2 className="mb-3 u-blue-primary">Billing Email Addresses</h2>

      {loading ? (
        <div className="position-relative">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="my-1 ml-3">
            <div className="d-flex flex-column justify-content-start">
              <div>{school.billingEmailAddresses}</div>
            </div>
            <a
              className="c-account-details__edit text-primary"
              onClick={toggleEditBillingDetailsModal}>
              {school.billingEmailAddresses ? "Edit" : "Add"}
            </a>
          </div>
          {editBillingDetailsModal && (
            <EditBillingDetailsModal
              toggle={toggleEditBillingDetailsModal}
              school={school}
            />
          )}
        </div>
      )}
    </div>
  )
}
