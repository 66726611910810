import React, { useState } from "react"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import { useQuery } from "@apollo/client"
import compose from "lodash.flowright"
import { Link } from "react-router-dom"
import { Row, Input } from "reactstrap"
import { Loader, QueryError } from "components"
import { AccountTypeContext } from "config/accountTypeContext"

import {
  solverQuery,
  allGradesWithNewClassesQuery,
  updateSolverActiveGrade,
  solverClientQuery,
} from "domains/solver/graphql"
import { characteristicsQuery } from "domains/characteristics/graphql"
import { SCHOOL_ID } from "constants/storageTokens"
import { NO_SOLUTION_FOUND } from "domains/solver/errorFields"
import { hasAsyncSolveInProgress } from "util/solverUtil"

import { Sociogram } from "domains/reports/components/sociogram/Sociogram"
import { convertClassToSociogram } from "domains/reports/components/sociogram/hooks/useSociogramData"

const ReportSociogram = ({
  loading,
  solverGrades,
  activeGrade,
  updateSolverActiveGrade,
  solverPath = "/Solver",
}) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj
  const schoolCharacteristicsQuery = useQuery(characteristicsQuery, {
    variables: {
      schoolId: sessionStorage.getItem(SCHOOL_ID),
      showCurrentClassCharacteristic: true,
    },
  })

  const [currentClass, setCurrentClass] = useState(null)
  const [currentCharacteristic, setCurrentCharacteristic] = useState("")

  const setCurrentClassOptionByClassId = (id, options) => {
    setCurrentClass(options.find(option => option.id === id))
  }

  const setDefaultClassOption = data => {
    const options = classOptions(data)
    const sortedClasses = sortClassesByLabel(data)
    if (sortedClasses.length > 0) {
      setCurrentClassOptionByClassId(sortedClasses[0].classDetails.id, options)
    } else {
      setCurrentClass(null)
    }
  }

  const onActiveGradeChange = value => {
    updateSolverActiveGrade({
      variables: { activeGrade: parseInt(value, 10) },
    }).then(data => {
      setDefaultClassOption(data)
    })
  }

  const classOptions = data => {
    const allClasses = sortClassesByLabel(data)

    const allStudents = allClasses.reduce(
      (result, c) => result.concat(c.students),
      []
    )

    const classOptions = [
      ...allClasses.map(c => ({
        id: c.classDetails.id,
        label: c.classDetails.label,
        students: c.students,
      })),
    ]

    return allClasses.length > 1
      ? classOptions.concat({
          id: "all",
          label: `All ${gettextObj.gettext("classes")}`,
          students: allStudents,
        })
      : classOptions
  }

  const characteristicsOptions = () => {
    if (schoolCharacteristicsQuery.data) {
      return [
        ...schoolCharacteristicsQuery.data.schoolCharacteristics.map(c => ({
          id: c.id,
          label: c.name,
        })),
      ]
    } else {
      return null
    }
  }

  const sortClassesByLabel = data => {
    return [...(data?.solution?.classes || [])].sort((a, b) =>
      a.classDetails.label.localeCompare(b.classDetails.label)
    )
  }

  const findSelectedCharacteristic = () => {
    if (currentCharacteristic) {
      return schoolCharacteristicsQuery.data.schoolCharacteristics.find(
        c => c.id === currentCharacteristic
      )
    }
  }

  const solution = {
    gradeId:
      activeGrade !== ""
        ? activeGrade.toString()
        : solverGrades
          ? solverGrades[0]?.id
          : null,
    schoolId: sessionStorage.getItem(SCHOOL_ID),
  }

  const {
    loading: solverQueryLoading,
    error,
    data,
    refetch,
  } = useQuery(solverQuery, {
    skip: loading,
    variables: { solution },
    fetchPolicy: "network-only",
    onCompleted: data => setDefaultClassOption(data),
  })

  if (loading || solverQueryLoading) {
    return <Loader />
  }

  // we need to leave out cross class links to make the classification
  // of students stay the same in grade (all classes) view
  // to do this we construct the nodes and links by class then compose
  // rather than just converting all students in grade to a graph
  // const gradeSociogramData =
  //   allClasses.length > 0
  //     ? allClasses
  //         .map(c => convertClassToSociogram(c.students))
  //         .reduce((result, classSociogramData) => {
  //           return {
  //             ...result,
  //             links: result.links.concat(classSociogramData.links),
  //             nodes: result.nodes.concat(classSociogramData.nodes),
  //           }
  //         })
  //     : {}

  const sociogramData = currentClass
    ? convertClassToSociogram(
        currentClass.students,
        findSelectedCharacteristic(currentCharacteristic)
      )
    : convertClassToSociogram(
        [],
        findSelectedCharacteristic(currentCharacteristic)
      )

  let body
  if (error) {
    if (error.message.includes(NO_SOLUTION_FOUND.key)) {
      body = (
        <div>
          <b>
            No solution has been generated. Please run the solver for this
            grade.
          </b>
        </div>
      )
    } else {
      return <QueryError refetch={refetch} />
    }
  } else if (hasAsyncSolveInProgress(data.solution)) {
    body = (
      <div>
        <b>
          A solution is currently being generated. Please wait for it to
          complete.
        </b>
      </div>
    )
  } else {
    body = sociogramData && (
      <>
        <Sociogram data={sociogramData} width={1000} height={600} />
        {/* Only render Sociogram Classification when Friend Preference is the selected characteristic option */}
        {!currentCharacteristic && (
          <section>
            <h2 className="d-inline-block u-blue-primary mr-3">
              Sociogram Classification
            </h2>
            <h6 className="mt-4 mb-2 u-blue-primary">Severely Isolated</h6>
            <p>
              This student wasn’t placed with any of their friendship
              preferences and wasn’t nominated by anyone in the entire grade.
            </p>
            <h6 className="mt-4 mb-2 u-blue-primary">Moderately Isolated</h6>
            <p>
              This student wasn’t placed with any of their friendship
              preferences but was nominated by at least one other student within
              their {gettextObj.gettext("class")}.
            </p>
            <h6 className="mt-4 mb-2 u-blue-primary">Partially Connected</h6>
            <p>
              This student was placed with one or more of their friendship
              preferences but wasn’t nominated by anyone in their{" "}
              {gettextObj.gettext("class")}.
            </p>
            <h6 className="mt-4 mb-2 u-blue-primary">Connected</h6>
            <p>
              This student was placed with one or more of their friendship
              preferences and was nominated by at least one other student in
              their {gettextObj.gettext("class")}.
            </p>
            <h6 className="mt-4 mb-2 u-blue-primary">Connected Star</h6>
            <p>
              This student was placed with one or more of their friendship
              preferences and was nominated most often within their{" "}
              {gettextObj.gettext("class")}.
            </p>
            <h6 className="mt-4 mb-2 u-blue-primary">Not Applicable</h6>
            <p>
              No friendship preferences were listed for this student. They are
              likely a new student or were absent at the time of the survey.
            </p>
          </section>
        )}
      </>
    )
  }

  // The available classes to pick
  const classDropdownOptions = classOptions(data)

  // Available school characteristics to pick
  // We add a dummy option for FPs as this is the default option
  const characteristicDropdownOptions = [
    <option key={""} value={""}>
      Friendship Preferences
    </option>,
    characteristicsOptions().map((o, index) => (
      <option key={index} value={o.id}>
        {o.label}
      </option>
    )),
  ]

  return (
    <div className="container-fluid">
      <div className="d-print-none">
        <Row>
          <div className="col-12">
            <div className="py-3 pl-2">
              <Link
                className="solve-page-link color-blue-mid font-weight-bold"
                to={solverPath}>
                <i className="fa fa-angle-left pr-2 color-blue-mid font-weight-bold" />
                Back to Solver
              </Link>
            </div>
          </div>
        </Row>
        <Row className="pb-4 col-12">
          <div className="col-sm-4 col-lg-2">
            <Input
              type="select"
              value={solution.gradeId}
              onChange={e => onActiveGradeChange(e.target.value)}>
              {solverGrades.map((g, index) => (
                <option key={index} value={g.id}>
                  {g.label}
                </option>
              ))}
            </Input>
          </div>
          {currentClass && (
            <div className="col-sm-4 col-lg-2">
              <Input
                type="select"
                value={currentClass.id}
                onChange={e => {
                  setCurrentClassOptionByClassId(
                    e.target.value,
                    classDropdownOptions
                  )
                }}>
                {classDropdownOptions.map((o, index) => (
                  <option key={index} value={o.id}>
                    {o.label}
                  </option>
                ))}
              </Input>
            </div>
          )}
          {currentClass && (
            <div className="col-sm-4 col-lg-2">
              <Input
                type="select"
                value={currentCharacteristic}
                onChange={e => {
                  setCurrentCharacteristic(e.target.value)
                }}>
                {characteristicDropdownOptions}
              </Input>
            </div>
          )}
        </Row>
      </div>
      {body}
    </div>
  )
}

export const ReportSociogramPage = compose(
  graphql(allGradesWithNewClassesQuery, {
    options: () => ({
      variables: { schoolId: sessionStorage.getItem(SCHOOL_ID) },
    }),
    props: ({ data: { loading, allGradesWithNewClasses } }) => ({
      loading,
      solverGrades: allGradesWithNewClasses,
    }),
  }),
  graphql(solverClientQuery, {
    props: ({
      data: {
        solver: { activeGrade },
      },
    }) => ({
      activeGrade,
    }),
  }),

  graphql(updateSolverActiveGrade, { name: "updateSolverActiveGrade" })
)(withApollo(ReportSociogram))
