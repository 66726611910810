import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Loader, ButtonBar, InlineError, VideoModal, Prompt } from "components"
import {
  CharacteristicSettings,
  SaveCautionModal,
} from "domains/characteristics/components"

import { hasAllOldIds } from "util/characteristics"
// import { Prompt } from "react-router"
import { areEqualObjects } from "util/objUtil"
import { hasDuplicates } from "util/array"
import { getSchoolId } from "util/app"

import { INSTRUCTION_CHARACTERISTICS_AND_RESPONSE_PLAYER_URL } from "constants/videos"

export const Characteristics = ({
  characteristics: initialCharacteristics,
  updateSchoolCharacteristicsMutation,
  refetchCharacteristics,
  navFrom,
}) => {
  // use the React router history
  const navigate = useNavigate()

  const [characteristics, setCharacteristics] = useState(initialCharacteristics)

  const [errors, setErrors] = useState({})

  const [showVideo, setShowVideo] = useState(false)

  const [cautionModal, setCautionModal] = useState(false)

  const [loading, setLoading] = useState(false)

  // Keep track of whether changes are saved for prompting purposes
  const [saved, setSaved] = useState(false)

  const toggleVideo = () => {
    setShowVideo(!showVideo)
  }

  const toggleCautionModal = () => {
    setCautionModal(!cautionModal)
  }

  const goBack = () => {
    navigate(navFrom || "/AccountSettings")
  }

  const onSaveButtonClick = () => {
    const isDestructiveChange = !hasAllOldIds(
      initialCharacteristics,
      characteristics
    )

    if (isDestructiveChange) {
      toggleCautionModal()
    } else {
      saveSettings()
    }
  }

  const saveSettings = () => {
    setLoading(true)

    // model the object to send to what is required on backend
    const schoolCharacteristics = characteristics.map(c => {
      let characteristicResponses = []
      if (c.characteristicResponses.length > 0) {
        characteristicResponses = c.characteristicResponses.map(r => ({
          id: r.id,
          label: r.label,
          description: r.description,
          colour: r.colour,
        }))
      }
      return {
        id: c.id,
        name: c.name,
        sensitive: c.sensitive,
        tag_only: c.tag_only,
        characteristicResponses,
      }
    })

    updateSchoolCharacteristicsMutation({
      variables: {
        schoolCharacteristics,
        schoolId: getSchoolId(),
      },
      refetchQueries: refetchCharacteristics,
    })
      .then(() => {
        setLoading(false)
        setSaved(true)
        goBack()
      })
      .catch(() => {
        setLoading(false)
        setCautionModal(false)
        setErrors({
          mutation: "There was an error saving characteristic settings",
        })
      })
  }

  if (loading) return <Loader />

  // Must check names are not blank
  let characteristicErrors
  characteristics.forEach(c => {
    if (c.name === "") {
      characteristicErrors = "Characteristic Name Cannot be Blank"
    } else {
      const responseLabels = c.characteristicResponses.map(r => r.label.trim())

      if (c.characteristicResponses.some(r => r.label === "")) {
        characteristicErrors = "Characteristic Response Label Cannot be Blank"
      } else if (hasDuplicates(responseLabels)) {
        characteristicErrors =
          "Characteristic Responses cannot have duplicate labels"
      }
    }
  })

  if (hasDuplicates(characteristics.map(c => c.name.trim()))) {
    characteristicErrors = "Characteristics cannot have duplicate names"
  }

  // Sort by priority
  characteristics.sort((a, b) => a.priority - b.priority)

  const characteristicsHaveChanged = !areEqualObjects(
    characteristics,
    initialCharacteristics
  )

  return (
    <div className="container o-component">
      {/* Show any mutation error at top */}
      {errors.mutation && (
        <div className="mb-4">
          <InlineError text={errors.mutation} />
        </div>
      )}

      <div
        className="mt-5 mb-5 u-blue-primary"
        style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="row">
          <h2 className="m-0">Characteristics & Responses</h2>
          <a
            className="color-blue-mid mx-4 mt-1 font-size-14"
            onClick={toggleVideo}>
            <i className="fa fa-play mr-2" />
            {"Watch Video"}
          </a>
        </div>
        <div className="d-flex flex-column align-items-end">
          <ButtonBar
            buttonText="Save Settings"
            onCancelClick={goBack}
            onButtonClick={onSaveButtonClick}
            disabled={characteristicErrors}
          />
          <InlineError text={characteristicErrors} />
          <InlineError text={errors.mutation} />
        </div>
      </div>

      <CharacteristicSettings
        characteristics={characteristics}
        onCharacteristicsChange={setCharacteristics}
      />

      <div className="d-flex flex-column align-items-end w-100">
        <ButtonBar
          buttonText="Save Settings"
          onCancelClick={goBack}
          onButtonClick={onSaveButtonClick}
          disabled={characteristicErrors}
        />
        <InlineError text={characteristicErrors} />
        <InlineError text={errors.mutation} />
      </div>

      {cautionModal && (
        <SaveCautionModal
          isOpen
          toggle={toggleCautionModal}
          heading="Change Settings?"
          text="If survey information has been entered by teachers, Selecting Save Settings will delete any responses for ALL students for the Characteristics that you are changing. "
          buttonText="Save Settings"
          onButtonClick={() => saveSettings()}
        />
      )}
      {characteristicsHaveChanged && !saved && (
        <Prompt message="Characteristic settings have not been saved, are you sure you want to leave?" />
      )}
      {showVideo && (
        <VideoModal
          toggle={toggleVideo}
          videoUrl={INSTRUCTION_CHARACTERISTICS_AND_RESPONSE_PLAYER_URL}
          title="Instruction Step 1"
        />
      )}
    </div>
  )
}
