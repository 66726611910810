export const pluralize = (count, singular, plural) => {
  if (count === 1) {
    return `${count} ${singular}`
  } else {
    return `${count} ${plural}`
  }
}

export const fullName = user => {
  return [user.firstName, user.lastName].join(" ").trim()
}

export const userInitials = user => {
  const name = fullName(user)

  return name
    ? name
        .split(/\s+/, 2)
        .map(n => n[0].toUpperCase())
        .join("")
    : user.email[0].toUpperCase()
}

export const normalizeEmails = emails => {
  return emails
    .map(email => email.trim().toLowerCase())
    .filter(email => !!email)
}
