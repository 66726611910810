import React, { useState } from "react"
import { GradeSystemList } from "components"
import { HelpModal } from "domains/upload"
import { Button } from "reactstrap"
import { useNavigate } from "react-router-dom"

export const GradeSystem = ({
  currentUploadJob,
  currentSchoolGrades: { currentSchoolGrades },
  updateBulkUploadJob,
  updateStudentImportMatchers,
}) => {
  const navigate = useNavigate()
  const [helpModal, setHelpModal] = useState(false)

  const toggleHelpModal = () => {
    setHelpModal(!helpModal)
  }

  const onContinue = () => {
    navigate("/Students/Upload/GradeLabels")
  }

  const cancel = () => {
    const variables = {
      jobParams: {
        id: currentUploadJob.id,
        status: "CANCELLED",
        type: currentUploadJob.type,
      },
    }

    updateBulkUploadJob({ variables }).then(() => {
      // clear out cached state
      updateStudentImportMatchers({
        variables: {
          newMatchers: {
            grades: [],
            columns: [],
          },
        },
      }).then(() => {
        navigate("/Students")
      })
    })
  }

  return (
    <div className="container">
      <div className="mt-5 d-flex flex-row align-items-center">
        <h1 className="mb-0">Create Your Grade System</h1>
        <Button
          className="u-font-weight-medium"
          color="link"
          onClick={toggleHelpModal}>
          Need Help Importing?
        </Button>
        <HelpModal
          isOpen={helpModal}
          toggle={toggleHelpModal}
          onSentNotification={cancel}
          jobId={currentUploadJob.id}
          type="Students"
        />
      </div>
      <div className="mt-3">
        Here’s a default Grade System. If your school uses the same Grade
        System, just click Continue. Or, you can modify the Grades according to
        your school.
      </div>
      <GradeSystemList
        onContinue={onContinue}
        className="mt-5"
        schoolGrades={currentSchoolGrades}
        cancel={cancel}
      />
    </div>
  )
}
