import React, { useMemo } from "react"
import { UncontrolledTooltip } from "reactstrap"
// import { v4 as uuidv4 } from "uuid"

import { isDefinedNotNull } from "util/objUtil"

export const ClassBadge = ({ label, className, backgroundColor }) => {
  // We need to memoise the ID otherwise we'll get errors where the browser tries
  // to reference an ID which no longer exists.
  // See https://github.com/reactstrap/reactstrap/issues/773#issuecomment-577976353
  const id = useMemo(() => {
    // We're not using the uuidv4 library because it blows up on some computers and I can't
    // figure out why. So we'll just generate a large random number to work around it
    const uniqueNumber = Math.trunc(Math.random() * 10000000)
    return "classbadge" + uniqueNumber
  }, [])

  return (
    <>
      <div
        id={id}
        style={backgroundColor ? { backgroundColor } : null}
        className={`${className} c-class-badge ${
          isDefinedNotNull(label) ? "" : "c-class-badge--new"
        }`}>
        <span className="c-class-badge__label">{label || "N/A"}</span>
      </div>
      <UncontrolledTooltip
        delay={{ show: 400, hide: 50 }}
        innerClassName="c-class-badge-tooltip"
        hideArrow
        placement="top"
        target={id}>
        {label}
      </UncontrolledTooltip>
    </>
  )
}
