import React, { useState } from "react"

import { InlineError } from "components"
import { SettingItem } from "domains/accountSettings/components"
import { useAuth0 } from "domains/auth/auth0Wrapper"
import { adminReportCsvExport } from "util/exportUtil"

const AdminReport = ({ reportType, description }) => {
  const context = useAuth0()
  const [error, setError] = useState()

  const csvExport = async () => {
    setError(null)

    const [response, errorMessage] = await adminReportCsvExport(
      reportType,
      context
    )

    if (errorMessage) {
      setError(`Error: ${response.status} - ${errorMessage}`)
    }
  }

  return (
    <>
      <SettingItem onButtonClick={csvExport} buttonText="Download">
        {description}
      </SettingItem>
      {error && <InlineError className="mx-3" text={error} />}
    </>
  )
}

export const AdminReports = () => {
  return (
    <div className="u-content-border-bottom pb-5">
      <h2 className="mb-5 mt-5 u-blue-primary">Admin Reports</h2>
      <div className="mb-4">
        <AdminReport
          description="Placed Students Report"
          reportType="placed_students_report"
        />
      </div>
      <div className="mb-4">
        <AdminReport
          description="Admin Users Report"
          reportType="admin_users_report"
        />
      </div>
      <div>
        <AdminReport
          description="Account Status Report"
          reportType="account_status_report"
        />
      </div>
    </div>
  )
}
