import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  characteristicsQuery,
  updateSchoolCharacteristicsMutation,
} from "domains/characteristics/graphql"

import { useLocation } from "react-router-dom"

import { Loader } from "components"
import { Characteristics } from "domains/characteristics/components"

import { newClassesQuery } from "domains/classes/graphql"

import { getSchoolId } from "util/app"

const CharacteristicsPageComponent = ({
  loading,
  schoolCharacteristics,
  updateSchoolCharacteristicsMutation,
}) => {
  const { state } = useLocation()

  if (loading) {
    return <Loader />
  }

  const schoolId = getSchoolId()

  // Clone array to allow for sorting
  const characteristics = schoolCharacteristics
    ? Array.from(schoolCharacteristics)
    : []

  const refetchCharacteristics = [
    { query: characteristicsQuery, variables: { schoolId } },
    { query: newClassesQuery, variables: { schoolId } },
  ]

  return (
    <Characteristics
      characteristics={characteristics}
      updateSchoolCharacteristicsMutation={updateSchoolCharacteristicsMutation}
      refetchCharacteristics={refetchCharacteristics}
      navFrom={state && state.navFrom}
    />
  )
}

export const CharacteristicsPage = compose(
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolCharacteristics } }) => ({
      loading,
      schoolCharacteristics,
    }),
  }),
  graphql(updateSchoolCharacteristicsMutation, {
    name: "updateSchoolCharacteristicsMutation",
  })
)(CharacteristicsPageComponent)
