import React from "react"
import { Tooltip } from "react-tooltip"

export const RestrictedSurveyTooltip = ({ id }) => {
  return (
    <>
      <i
        className={`fa fa-exclamation-circle px-2 cursor-pointer color-red`}
        data-event="click focus"
        data-tooltip-id={id}
      />
      <Tooltip
        className="c-teachers-invite-modal__restricted-survey-tooltip "
        id={id}
        clickable
        variant="light"
        opacity={1}
        openEvents={{ click: true, focus: true }}
        closeEvents={{ click: true, blur: true }}
        globalCloseEvents={{ clickOutsideAnchor: true, scroll: true }}>
        <div className="p-1">
          <h2>Feeder School Survey</h2>
          <p className="mb-0">
            Select ‘Feeder School Survey’ if you are sending your surveys to
            another school (e.g. students transitioning from another school or
            external kindergarten).
            <br />
            <br />
            This ensures that the survey recipient will only be able to view
            information about their school (e.g. they will not be able to enter
            friends or requests for students at another school).
            <br />
            <br />
            If this school would like to enter information about students from a
            different school, they can either enter this in the “Notes section”
            or contact you directly as the Administrator.
          </p>
        </div>
      </Tooltip>
    </>
  )
}
