import {
  CURRENT_CLASS_CHARACTERISTIC_PALETTE,
  NO_CURRENT_CLASS_COLOUR,
} from "domains/characteristics/constants"

export const getStudentResponse = (characteristic, studentResponses) => {
  // Find the corresponding response from student
  const charResponse = studentResponses.find(
    r => r.characteristicId === characteristic.id
  )

  // If their is a response for characteristic
  if (charResponse) {
    // Match the value of response to get label
    return characteristic.characteristicResponses.find(
      r => r.value === charResponse.value
    )
  }

  // else return undefined
  return undefined
}

export const hasAllOldIds = (oldList, newList) => {
  const oldIds = convertToIdMap(oldList)
  const newIds = convertToIdMap(newList)

  return Object.entries(oldIds).every(([scId, oldResponseIds]) => {
    const newResponseIds = newIds[scId]

    return (
      newResponseIds && oldResponseIds.every(y => newResponseIds.includes(y))
    )
  })
}

const convertToIdMap = characteristicsList => {
  return characteristicsList.reduce(
    (acc, sc) =>
      Object.assign(
        { [sc.id]: sc.characteristicResponses.map(r => r.id) },
        acc
      ),
    {}
  )
}

export const isCurrentClassCharacteristic = characteristic =>
  characteristic && characteristic.id === "-1"

// Generates the current class responses from a list of current classes
export const getCurrentClassResponses = currentClasses => {
  return currentClasses
    ? currentClasses
        .slice()
        .sort((a, b) => b.studentCount - a.studentCount)
        .map((currentClass, index) => {
          const colour =
            CURRENT_CLASS_CHARACTERISTIC_PALETTE[
              // Rotate back to the first colour if we run out of colours
              index % CURRENT_CLASS_CHARACTERISTIC_PALETTE.length
            ]

          return {
            id: currentClass.label,
            label: currentClass.label,
            colour,
            characteristic: { id: "-1", name: "Current Class" },
            value: currentClasses.length - index,
          }
        })
        .concat([
          {
            id: "NO_CURRENT_CLASS",
            label: "No Current Class",
            colour: NO_CURRENT_CLASS_COLOUR,
            characteristic: { id: "-1" },
            value: 0,
          },
        ])
    : []
}
