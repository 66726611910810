import React from "react"
import { Modal } from "reactstrap"
import { InlineError, Loader } from "components"

export const LoadingModal = ({
  isOpen,
  toggle,
  loading,
  heading,
  error,
  text,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={loading ? undefined : toggle}
    className="modal-dialog-centered u-modal__w-30rem">
    <div className="p-4">
      <h1>{heading}</h1>
      <span className="mt-3">{text}</span>
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <div className="mt-4">
          <InlineError text={error} />
        </div>
      )}
    </div>
  </Modal>
)
