import React from "react"
import { Referral } from "domains/billingSettings/components/referral"

export class ErrorInfo extends React.Component {
  render() {
    const { info, promoBanner } = this.props
    return (
      <div className="text-left w-50">
        {info && (
          <div className="font-weight-bold h5">
            <i className="fa fa-info-circle mr-2 text-warning"></i>
            {info.title}
          </div>
        )}
        <div className="u-grey-dark">{info && info.body}</div>

        {promoBanner && (
          <Referral
            heading={promoBanner.title}
            message={promoBanner.message}
            button={promoBanner.button}
          />
        )}
      </div>
    )
  }
}
