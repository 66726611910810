import React from "react"

export const FriendBadge = ({ isMet }) => (
  <div className="d-inline">
    {isMet ? (
      <div className="c-badge__circle c-badge__is-met-friend">F</div>
    ) : (
      <div className="c-badge__circle c-badge__friend">F</div>
    )}
  </div>
)
