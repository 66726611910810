import React from "react"

import {
  teachersWithSharedSolutions,
  shareSolutionClassesWithTeachersMutation,
  unshareSolutionMutation,
  allGradesWithNewClassesQuery,
} from "domains/solver/graphql"
import { teachersQuery } from "domains/teachers/graphql"
import { newClassesQuery } from "domains/classes/graphql"
import { Loader } from "components"
import { useQuery, useMutation } from "@apollo/client"
import { ShareSolutionModalContent } from "./"

export const ShareSolutionModal = ({
  toggle,
  schoolId,
  user,
  allowWritableSharedSolutions,
}) => {
  const { data: sharedData, loading: loadingShared } = useQuery(
    teachersWithSharedSolutions,
    { variables: { schoolId }, fetchPolicy: "network-only" }
  )

  const { data: teacherData, loading: loadingTeachers } = useQuery(
    teachersQuery,
    { variables: { schoolId }, fetchPolicy: "network-only" }
  )

  const { data: gradeData, loading: loadingGrades } = useQuery(
    allGradesWithNewClassesQuery,
    { variables: { schoolId } }
  )

  const { data: newClassesData, loading: loadingNewClasses } = useQuery(
    newClassesQuery,
    { variables: { schoolId } }
  )

  const refetchQueries = [
    { query: teachersWithSharedSolutions, variables: { schoolId } },
  ]

  const [shareSolution, { error: shareError }] = useMutation(
    shareSolutionClassesWithTeachersMutation,
    {
      refetchQueries: refetchQueries,
      // This is the only way I can think of to stop apollo from
      // throwing errors into the console for a useMutation error
      // since `useMutation` does not support `errorPolicy`
      //
      // https://github.com/apollographql/react-apollo/issues/3375
      // https://github.com/apollographql/react-apollo/issues/3643
      onError: () => {},
    }
  )

  const [unshareSolution, { error: unshareError }] = useMutation(
    unshareSolutionMutation,
    {
      refetchQueries: refetchQueries,
      // See explanation above
      onError: () => {},
    }
  )

  if (loadingShared || loadingTeachers || loadingNewClasses || loadingGrades)
    return <Loader />

  const availableGrades = gradeData.allGradesWithNewClasses.map(grade => {
    const defaultTeachers = grade.newClasses.flatMap(
      newClass => newClass.teachers
    )

    return { ...grade, defaultTeachers }
  })
  const availableClasses = newClassesData.newClasses.map(newClass => {
    return {
      ...newClass,
      defaultTeachers: newClass.teachers,
    }
  })

  return (
    <ShareSolutionModalContent
      teachers={teacherData.teachers}
      teachersWithSharedSolutions={sharedData.teachersWithSharedSolutions}
      shareSolution={shareSolution}
      unshareSolution={unshareSolution}
      toggle={toggle}
      schoolId={schoolId}
      user={user}
      availableGrades={availableGrades}
      availableClasses={availableClasses}
      shareError={shareError}
      unshareError={unshareError}
      allowWritableSharedSolutions={allowWritableSharedSolutions}
    />
  )
}
