// Apollo GQL Related imports
import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { createLink } from "apollo-absinthe-upload-link"

// Apollo Link State
import { defaultQuery, DefaultState } from "config/defaultState"
import { Resolvers } from "config/resolvers"

// constants
import { AUTH_TOKEN } from "constants/storageTokens"
import { getApiRegion, regionApiUris } from "util/apiUtil"

const uri = process.env.REACT_APP_API_URL || "http://localhost:4000/api"

// Dynamically choose which API from sessionStorage
const customFetch = (uri, options) => {
  const apiUris = regionApiUris[process.env.REACT_APP_ENV || "development"]

  if (!apiUris) {
    return fetch(uri, options)
  }

  const region = getApiRegion()

  return fetch(apiUris[region] || uri, options)
}

export const createApolloClient = authContext => {
  const cache = new InMemoryCache()

  const absintheUploadLink = createLink({ uri: uri, fetch: customFetch })

  const auth0TokenLink = setContext(async (_, prevContext) => {
    if (authContext.isAuthenticated) {
      const authToken = await authContext.getTokenSilently()
      return { ...prevContext, authToken }
    }
    return prevContext
  })

  const tokenLink = setContext((_, prevContext) => {
    const authToken = sessionStorage.getItem(AUTH_TOKEN)
    return { ...prevContext, authToken }
  })

  const setAuthLink = setContext((query, context) => {
    const { authToken, headers } = context

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : "",
      },
    }
  })

  const link = ApolloLink.from([
    // This is only here until we migrate off private links
    window.location.pathname.startsWith("/surveys/") ||
    window.location.pathname.startsWith("/sharedsolution/")
      ? tokenLink
      : auth0TokenLink,
    setAuthLink,
    absintheUploadLink,
  ])

  const client = new ApolloClient({ link, cache, resolvers: Resolvers })

  // Restores the defaultState when the store is reset
  cache.writeQuery({ query: defaultQuery, data: DefaultState })

  return client
}
