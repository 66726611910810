import * as React from "react"
import { Input, Button } from "reactstrap"

export const ColumnSelect = props => {
  const {
    options,
    mapTo,
    mappedColumns,
    onChange,
    onClear,
    optional,
    isDisabled = false,
  } = props

  // Keep a piece of state to track the latest option the user selected
  // in this instance of ColumnSelect. This is not to control the value
  // of the dropdown, it is only used for some filtering logic
  //
  // This piece of state should default to the selected option passed in
  // as it's possible automatic mapping on mount has taken place
  const [selectedOption, setSelectedOption] = React.useState(
    mappedColumns[mapTo] || ""
  )

  // Handle when the user selects an option
  const handleChange = React.useCallback(
    event => {
      setSelectedOption(event.target.value)
      onChange({ selectedOption: event.target.value, mapTo: mapTo })
    },
    [mapTo, onChange]
  )

  // Handle when the user wishes to clear this option
  const handleClear = React.useCallback(() => onClear(mapTo), [onClear, mapTo])

  // Get the value that this column has been mapped to
  const mappedTo = React.useMemo(() => {
    return mappedColumns[mapTo] || "empty"
  }, [mappedColumns, mapTo])

  return (
    <div className="d-flex align-items-center">
      <Button
        color="link"
        onClick={handleClear}
        disabled={mappedTo === "empty"}>
        Clear
      </Button>

      <Input
        value={mappedTo}
        type="select"
        onChange={handleChange}
        disabled={isDisabled}>
        <option key="empty" value="empty" disabled>
          Select
        </option>

        {options.map(userColumn => {
          // This option should be omitted if it has already been mapped
          // elsewhere
          const isMappedElsewhere = Object.keys(mappedColumns).some(
            column => mappedColumns[column] === userColumn
          )

          // Render the option, or not, if it has already been mapped
          if (isMappedElsewhere && selectedOption !== userColumn) {
            return null
          } else {
            return (
              <option key={userColumn} value={userColumn}>
                {userColumn}
              </option>
            )
          }
        })}
      </Input>

      <div className="pl-3 text-center">
        {mappedTo !== "empty" ? (
          <i className="fa fa-check text-success" />
        ) : (
          <i
            className={`fa fa-warning text-danger ${optional ? "invisible" : ""}`}
          />
        )}
      </div>
    </div>
  )
}
