import React, { Component } from "react"

import { Loader } from "components"
import { GradeModal } from "domains/accountSettings/components"

export class GradeSettings extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
  }

  toggleGradeModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { currentSchoolGrades, loadingSchoolGrades } = this.props
    const { isOpen } = this.state

    return (
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">School Grades</h2>
        {loadingSchoolGrades ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <div className="d-flex justify-content-between mb-4 align-items-center">
            <div className="">Edit & set school grades</div>
            <div
              className="c-button--secondary"
              onClick={this.toggleGradeModal}>
              School Grades Settings
            </div>
            <GradeModal
              isOpen={isOpen}
              toggle={this.toggleGradeModal}
              schoolGrades={currentSchoolGrades}
            />
          </div>
        )}
      </div>
    )
  }
}
