import React from "react"
import { Link } from "react-router-dom"

import Logo from "images/logo.png"
import { ErrorInfo } from "./errorInfo"

const logoStyles = {
  maxWidth: 150,
}

export class LoginError extends React.Component {
  render() {
    const { message, additional, info, promoBanner } = this.props
    return (
      <div className="c-survey-login-error">
        <Link to="/">
          <img
            src={Logo}
            alt="Class Solver Logo"
            style={logoStyles}
            className="ml-5 mt-5"
          />
        </Link>
        <div className="c-survey-login-error__container">
          <div className="h4 pb-2 w-75 text-center">{message}</div>
          <div className="pb-4">{additional}</div>

          <ErrorInfo info={info} promoBanner={promoBanner} />
        </div>
      </div>
    )
  }
}
