import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Modal, ModalBody, Input, Label } from "reactstrap"
import Select from "react-select"

import { activeSchoolGradesQuery } from "domains/classes/graphql"
import { studentsQuery } from "domains/students/graphql"

import {
  ButtonBar,
  InlineError,
  ModalHeader,
  ModalNote,
  Loader,
  defaultSelectStyles,
} from "components"

import { DangerousMutation } from "domains/accountSettings/components"

export const RemoveCharacteristicResponsesModal = ({
  schoolId,
  toggle,
  adminOnly,
  deleteStudentCharacteristicResponsesByCurrentGradeMutation,
  characteristics,
}) => {
  const [
    selectCharacteristicResponsesModalOpen,
    setSelectCharacteristicResponsesModalOpen,
  ] = useState(true)

  // This is to stop React warning: A component is changing an uncontrolled input to be controlled.
  const responsesToDeleteDefault = {}
  for (let i = 0; i < characteristics.length; i++) {
    responsesToDeleteDefault[characteristics[i].id] = false
  }
  const [responsesToDelete, setResponsesToDelete] = useState(
    responsesToDeleteDefault
  )

  const { data, loading: loadingGrades } = useQuery(activeSchoolGradesQuery, {
    variables: { schoolId },
  })

  const [
    removeByCurrentGrade,
    { error: errorRemoving, loading: removingGrades },
  ] = useMutation(deleteStudentCharacteristicResponsesByCurrentGradeMutation, {
    onError: () => {},
  })

  const [selectedGrades, setSelectedGrades] = useState([])

  const loading = loadingGrades || removingGrades

  const availableGrades = loading
    ? []
    : data.activeSchoolGrades.map(grade => {
        return {
          label: grade.label,
          value: grade.id,
        }
      })

  const handleCheck = e => {
    setResponsesToDelete({
      ...responsesToDelete,
      [e.target.id]: !responsesToDelete[e.target.id],
    })
  }

  const deleteResponses = () => {
    const characteristicIds = Object.entries(responsesToDelete)
      .filter(([_k, v]) => v === true)
      .map(([k, _v]) => k)

    return removeByCurrentGrade({
      variables: {
        schoolId: schoolId,
        characteristicIds,
        currentGradeIds: selectedGrades.map(option => option.value),
      },
      refetchQueries: [
        {
          query: studentsQuery,
          variables: {
            adminOnly: adminOnly,
            schoolId: schoolId,
          },
        },
        {
          query: activeSchoolGradesQuery,
          variables: { schoolId: schoolId },
        },
      ],
      onCompleted: toggle,
    })
  }

  if (selectCharacteristicResponsesModalOpen) {
    return (
      <Modal isOpen className="modal-dialog-centered u-modal__w-45rem">
        <ModalHeader
          className="p-4"
          title={"Remove Characteristic Responses"}
          toggle={toggle}
        />

        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <div>
            <ModalNote
              text={
                "This function removes the Characteristic Responses for students in the Current Grade/s you have selected.  Students in the Current Grade/s not selected will not be affected.  "
              }
            />

            <ModalBody className="pb-4">
              <div className="px-4 pt-4">
                <Label>Select Characteristic Responses to remove:</Label>

                <div className="row u-heading-separator-border-bottom align-items-center py-2 mx-2">
                  <p className="col-10 font-weight-bold my-1 p-0">
                    Characteristic
                  </p>
                  <p className="col-2 font-weight-bold text-center my-0">
                    Remove
                  </p>
                </div>
                {characteristics.map(characteristic => {
                  return (
                    <div
                      key={characteristic.id}
                      className="row u-separator-border-bottom align-items-center py-2 mx-2">
                      <p className="col-10 p-0 my-0">{characteristic.name}</p>
                      <div className="col-2 font-weight-bold text-center">
                        <Input
                          className="mx-0 c-checkbox-lg"
                          type="checkbox"
                          id={characteristic.id}
                          name={characteristic.name}
                          checked={responsesToDelete[characteristic.id]}
                          onChange={handleCheck}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="my-3 px-4 pt-4">
                <Label>Select Current Grade/s to apply to:</Label>
                <Select
                  name="current_grades"
                  className="c-input__multiselect"
                  placeholder="Select Current Grades"
                  options={availableGrades}
                  value={selectedGrades}
                  onChange={grades => {
                    setSelectedGrades(grades)
                  }}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={defaultSelectStyles}
                />
              </div>
              <ButtonBar
                className="mt-5 px-4"
                buttonText="Remove Responses"
                onButtonClick={() =>
                  setSelectCharacteristicResponsesModalOpen(false)
                }
                onCancelClick={toggle}
                disabled={
                  selectedGrades.length === 0 ||
                  !Object.values(responsesToDelete).includes(true)
                }
              />
              {errorRemoving && (
                <InlineError
                  className="mt-5 px-4"
                  text={errorRemoving.message}
                />
              )}
            </ModalBody>
          </div>
        )}
      </Modal>
    )
  } else {
    return (
      <DangerousMutation
        dangerousMutation={deleteResponses}
        cautionHeading={"Remove Characteristic Responses"}
        cautionText={
          "You can not ‘undo’ the operation.  Responses for the selected Characteristics will be permanently deleted for students in selected Current Grade/s."
        }
        cautionButtonText={"Yes, Proceed"}
        successMessage={"Characteristic Responses by grade have been removed."}
        errorMessage={"Could not remove data"}
        toggleComponent={toggle}
      />
    )
  }
}
