const headerClassName = "c-classes__table-header-"
const dataClassName = "c-classes__table-data-"

export const SECTIONS = {
  "new-classes": {
    name: "CLASSES TO BE SOLVED",
  },
  "assigned-teachers": {
    name: "ASSIGNED TEACHERS",
  },
  "advanced-settings": {
    name: "ADVANCED SETTINGS (Optional)",
  },
}

export const HEADERS = [
  {
    name: "Class Label",
    id: "labelElement",
    section: "new-classes",
    headerClass: headerClassName + "label",
    dataClass: dataClassName + "label",
  },
  {
    name: "New Grade/s",
    id: "grades",
    section: "new-classes",
    headerClass: headerClassName + "grades",
    dataClass: dataClassName + "grades",
  },
  {
    name: "Teacher/s",
    id: "teacherNames",
    section: "assigned-teachers",
    headerClass: headerClassName + "teachers",
    dataClass: dataClassName + "teachers",
  },
  {
    name: "Max. Class Size",
    id: "classSizeSummary",
    section: "advanced-settings",
    headerClass: headerClassName + "classSizeSummary",
    dataClass: dataClassName + "classSizeSummary",
  },
  {
    name: "Class Entry Criteria",
    id: "classEntryCriteria",
    section: "advanced-settings",
    headerClass: headerClassName + "classEntryCriteria",
    dataClass: dataClassName + "classEntryCriteria",
  },
]
