import React, { useState } from "react"
import { DangerousMutation } from "domains/accountSettings/components"

const Item = props => {
  const { children, buttonText, onButtonClick } = props

  return (
    <div className="d-flex mb-2 justify-content-between align-items-center">
      <div style={{ flex: 1 }}>{children}</div>
      <div className="c-button--secondary" onClick={onButtonClick}>
        {buttonText}
      </div>
    </div>
  )
}

export const RollOver = ({
  rollOverDataMutation,
  deactivateAllStudentsMutation,
  isSuper,
}) => {
  const [showRollOverData, setShowRollOverData] = useState(false)
  const [showDeactivateAllStudents, setShowDeactivateAllStudents] =
    useState(false)

  return (
    <div className="py-5 u-content-border-bottom">
      <h2 className="mb-5 u-blue-primary">Rollover Options</h2>
      <div>
        <Item
          buttonText="Roll over"
          onButtonClick={() => setShowRollOverData(true)}>
          Roll school over
        </Item>
        {isSuper && (
          <Item
            buttonText="Deactivate All Students"
            onButtonClick={() => setShowDeactivateAllStudents(true)}>
            Deactivate All Students
          </Item>
        )}
      </div>
      {showRollOverData && (
        <DangerousMutation
          dangerousMutation={rollOverDataMutation}
          cautionHeading="Remove Previous Students & Teachers"
          cautionText="You can not 'undo' this operation. Are you sure you would like to permanently remove your previous students and teachers?"
          cautionButtonText="Yes, Proceed"
          successMessage="All previous students and teachers have been removed."
          errorMessage="Could not remove data"
          toggleComponent={() => setShowRollOverData(false)}
        />
      )}
      {showDeactivateAllStudents && (
        <DangerousMutation
          dangerousMutation={deactivateAllStudentsMutation}
          cautionHeading="Deactivate All Students"
          cautionText="You can not 'undo' this operation. Are you sure you would like to deactivate all students for this school?"
          cautionButtonText="Yes, Proceed"
          successMessage="All students have been deactivated."
          errorMessage="Could not deactivate students"
          toggleComponent={() => setShowDeactivateAllStudents(false)}
        />
      )}
    </div>
  )
}
