import React, { Component } from "react"

import {
  PerformanceCell,
  CharacteristicTotalCells,
} from "domains/reports/components"

import { isDefinedNotNull, getPropertyIfDefined } from "util/objUtil"
import { naturalSortByClassLabels } from "util/sortUtil"

const METRIC_FIELDNAMES = [
  "atLeastOneFriend",
  "mandatoryRequests",
  "importantRequests",
  "differentTeacher",
]

export class SchoolTableBody extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      formattedData: [],
    }
  }

  componentDidMount() {
    const { data, schoolCharacteristics, studentMetrics, unmetConstraints } =
      this.props

    const activeGrade = data.activeGrade.id

    const sortedClasses = naturalSortByClassLabels(data.classes)

    const formattedData = sortedClasses.reduce((acc, current) => {
      const {
        classDetails: { label },
        counts: {
          genders: { activeMaleCount, activeFemaleCount, activeNonBinaryCount },
        },
      } = current

      // Initialise an array
      let characteristicTotals = schoolCharacteristics.map(characteristic => {
        return new Array(characteristic.characteristicResponses.length).fill(0)
      })

      let metricTotals = {
        atLeastOneFriend: { count: 0, total: 0 },
        mandatoryRequests: { count: 0, total: 0 },
        importantRequests: { count: 0, total: 0 },
        differentTeacher: { count: 0, total: 0 },
      }

      current.students.forEach(student => {
        const { studentConstraints, teacherConstraints, newGrade } = student
        const inActiveGrade = newGrade.id === activeGrade
        if (!inActiveGrade) return

        // Constaints For Student
        const constraints = [...studentConstraints, ...teacherConstraints]

        constraints.forEach(c => {
          const { constraintId, mandatory, studentTo, studentFrom, status } = c
          if (status && status.status === "valid") {
            const field = mandatory ? "mandatoryRequests" : "importantRequests"
            const isMet = !unmetConstraints.includes(constraintId)

            if (
              studentTo &&
              getPropertyIfDefined(studentTo, "newClass.id") ===
                getPropertyIfDefined(studentFrom, "newClass.id")
            ) {
              if (student.id === studentFrom.id) {
                metricTotals[field].total++
                if (isMet) {
                  metricTotals[field].count++
                }
              }
            } else {
              metricTotals[field].total++
              if (isMet) {
                metricTotals[field].count++
              }
            }
          }
        })

        // get the metrics for the student
        const currentStudentMetrics = studentMetrics.find(
          studentMetric => studentMetric.student.id === student.id
        )

        if (isDefinedNotNull(currentStudentMetrics)) {
          const {
            metrics: { differentTeacher, friendshipPreferences },
          } = currentStudentMetrics

          if (isDefinedNotNull(friendshipPreferences)) {
            // increment atLeastOneFriend
            metricTotals.atLeastOneFriend.total++
            if (friendshipPreferences.count > 0) {
              metricTotals.atLeastOneFriend.count++
            }
          }

          if (isDefinedNotNull(differentTeacher)) {
            // increment different teacher
            metricTotals.differentTeacher.total++
            if (differentTeacher === true) {
              metricTotals.differentTeacher.count++
            }
          }
        }
        schoolCharacteristics.forEach((characteristic, index) => {
          // get the students response for that characteristic
          const studentResponse = student.characteristicResponses.find(
            response => response.characteristic.id === characteristic.id
          )
          for (
            let i = 0;
            i < characteristic.characteristicResponses.length;
            i++
          ) {
            const { id } = characteristic.characteristicResponses[i]
            if (
              isDefinedNotNull(studentResponse) &&
              id === studentResponse.id
            ) {
              // increment the total
              characteristicTotals[index][i]++
            }
          }
        })
      })

      acc.push({
        label,
        metricTotals,
        characteristicTotals,
        activeFemaleCount,
        activeMaleCount,
        activeNonBinaryCount,
      })
      return acc
    }, [])
    this.setState({
      formattedData,
    })
  }

  toggleExpanded = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  renderPerformanceCells = metricObject => {
    return METRIC_FIELDNAMES.map(field => (
      <PerformanceCell
        key={field}
        metric={getPropertyIfDefined(metricObject, field)}
      />
    ))
  }

  render() {
    const { data, schoolCharacteristics, rowLength, hasGenderX } = this.props
    const { expanded, formattedData } = this.state

    const icon = expanded ? "fa-chevron-up" : "fa-chevron-down"

    let metricSubtotals = {
      atLeastOneFriend: { count: 0, total: 0 },
      mandatoryRequests: { count: 0, total: 0 },
      importantRequests: { count: 0, total: 0 },
      differentTeacher: { count: 0, total: 0 },
    }

    let characteristicSubtotals = schoolCharacteristics.map(characteristic => {
      return new Array(characteristic.characteristicResponses.length).fill(0)
    })

    let genderSubtotals = {
      male: 0,
      female: 0,
      nonBinary: 0,
    }

    // else school report
    return (
      <tbody className="c-reports-table-body">
        <tr className="c-reports-table-body__row--divider bg-table-header">
          <td
            colSpan={rowLength}
            className="c-reports-table-body__row__class-label">
            <i
              className={`fa ${icon} cursor-pointer p-2`}
              onClick={this.toggleExpanded}
            />
            {data.activeGrade.label}
          </td>
        </tr>
        {formattedData
          ? formattedData.map((classData, index) => {
              const {
                label,
                metricTotals,
                characteristicTotals,
                activeFemaleCount,
                activeMaleCount,
                activeNonBinaryCount,
              } = classData

              METRIC_FIELDNAMES.forEach(field => {
                metricSubtotals[field].count += metricTotals[field].count
                metricSubtotals[field].total += metricTotals[field].total
              })

              for (let i = 0; i < characteristicTotals.length; i++) {
                const currentChar = characteristicTotals[i]
                for (let j = 0; j < currentChar.length; j++) {
                  characteristicSubtotals[i][j] += currentChar[j]
                }
              }

              genderSubtotals.male += activeMaleCount
              genderSubtotals.female += activeFemaleCount
              genderSubtotals.nonBinary += activeNonBinaryCount

              const rowClassName = expanded ? "" : "d-none"

              return (
                <tr
                  className={`c-reports-table-body__row ${rowClassName}`}
                  key={index}>
                  <td
                    className="c-reports-table-body__row__data text-left pl-2"
                    colSpan={4}>
                    {label}
                  </td>
                  <td className="c-reports-table-body__row__data">
                    {activeMaleCount}
                  </td>
                  <td className="c-reports-table-body__row__data">
                    {activeFemaleCount}
                  </td>
                  {hasGenderX && (
                    <td className="c-reports-table-body__row__data">
                      {activeNonBinaryCount}
                    </td>
                  )}
                  {this.renderPerformanceCells(metricTotals)}
                  <CharacteristicTotalCells data={characteristicTotals} />
                </tr>
              )
            })
          : null}

        <tr className="c-reports-table-body__row--total bg-grey-requests">
          <td colSpan="4" className="text-right pr-2 border-right">
            Subtotal
          </td>
          <td className="c-reports-table-body__row__data">
            {genderSubtotals.male}
          </td>
          <td className="c-reports-table-body__row__data">
            {genderSubtotals.female}
          </td>
          {hasGenderX && (
            <td className="c-reports-table-body__row__data">
              {genderSubtotals.nonBinary}
            </td>
          )}
          {this.renderPerformanceCells(metricSubtotals)}
          <CharacteristicTotalCells data={characteristicSubtotals} />
        </tr>
        <tr className="c-reports-table-body__row--empty">
          <th colSpan={rowLength}>Empty Row</th>
        </tr>
      </tbody>
    )
  }
}
