import React from "react"
import { Input, InputGroup, Label } from "reactstrap"

export const StudentFilterInput = ({ onChange, value, options }) => {
  return (
    <InputGroup className="current-grade-input mr-5 pr-5 border-right">
      <Label className="align-self-center mr-2 pt-2">Filter:</Label>

      <Input
        type="select"
        name="studentFilter"
        value={value}
        onChange={onChange}>
        {options.map(option => {
          return (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          )
        })}
      </Input>
    </InputGroup>
  )
}
