import * as React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Loader } from "components"
import { MapColumnHeadings } from "components"
import { useSchoolCharacteristics } from "domains/schools/hooks/useSchoolCharacteristics"
import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"
import { EMPTY } from "domains/characteristicsImport/constants"
import { UploadErrorModal } from "domains/upload"

const defaultWarning = {
  body: error => {
    return (
      <div>
        <p>
          Sorry, there was an error with your uploaded file. Please try
          uploading again and if the error persists, please contact us by
          clicking "Need help importing?" with the following error details:
        </p>
        <pre>{error.message}</pre>
      </div>
    )
  },
}

export const MapColumns = () => {
  // Fetch the current school's characteristics
  const [characteristics, { loading: isCharacteristicsLoading }] =
    useSchoolCharacteristics()

  // Utility for manipulating the router
  const navigate = useNavigate()

  // Bring in the router location so we can grab the jobId from state
  const { state: routeState } = useLocation()

  // Load up the upload job
  const {
    fetch: [uploadJob, { error: uploadJobError }],
    update: [updateBulkUploadJob, { loading: isUpdatingJob }],
    cancel: [cancelUploadJob],
  } = useBulkUploadJob(routeState.jobId)

  // Handle when the user presses the Continue button
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Students")
    })
  }, [cancelUploadJob, navigate])

  if (uploadJobError) {
    return (
      <UploadErrorModal
        isOpen
        title="Error with uploaded file"
        type="Student Characteristics"
        jobId={routeState.jobId}
        toggle={handleCancel}
        actions={[
          {
            color: "warning",
            onClick: handleCancel,
            text: "Ok",
          },
        ]}>
        {defaultWarning.body(uploadJobError)}
      </UploadErrorModal>
    )
  }

  if (!uploadJob) {
    return <Loader />
  }

  return (
    <MapColumnPresentation
      characteristics={characteristics}
      isCharacteristicsLoading={isCharacteristicsLoading}
      navigate={navigate}
      uploadJob={uploadJob}
      updateBulkUploadJob={updateBulkUploadJob}
      isUpdatingJob={isUpdatingJob}
      cancelUploadJob={cancelUploadJob}
    />
  )
}

const MapColumnPresentation = ({
  characteristics,
  isCharacteristicsLoading,
  navigate,
  uploadJob,
  updateBulkUploadJob,
  isUpdatingJob,
  cancelUploadJob,
}) => {
  // Memoize a list of suggestions we can automatically apply
  const automaticSuggestions = React.useMemo(() => {
    return {
      student_code: ["student id", "student_code", "eq id", "eq_id"],
    }
  }, [])

  // Keep a piece of state which determines if the help modal should be displaed
  const [showHelpModal, setShowHelpModal] = React.useState(false)

  // Keep a piece of state which tracks the characteristic that the
  // user is mapping their values to.
  //
  // Type is "EMPTY" | {id: string, name: string}
  const [selectedCharId, setSelectedCharId] = React.useState(EMPTY)

  // Memoize the available column headers to map to. Default to an empty
  // array if the query has not finished resolving yet
  const availableColumns = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      return uploadJob.fileSummary.headers
    } else {
      return []
    }
  }, [uploadJob])

  const initialMapping = () => {
    return Object.entries(automaticSuggestions).reduce(
      (acc, [key, suggestions]) => {
        const suggestion = availableColumns.find(column => {
          const lowerCase = column.toLowerCase()
          const noSpaces = column.replace(/\s/g, "")

          return (
            suggestions.includes(column) ||
            suggestions.includes(lowerCase) ||
            suggestions.includes(noSpaces)
          )
        })

        if (suggestion) {
          return { ...acc, [key]: suggestion }
        } else {
          return acc
        }
      },
      {}
    )
  }

  // Keep track of which columns have been mapped
  const [mappedColumns, setMappedColumns] = React.useState(() => {
    return initialMapping()
  })

  // Memoize whether the Save & Continue button should be disabled because
  // the user is yet to map some fields
  const isMappingComplete = React.useMemo(() => {
    return Object.keys(mappedColumns).length >= 2 && selectedCharId !== EMPTY
  }, [mappedColumns, selectedCharId])

  // Handle when the user presses the Continue button
  const handleContinue = React.useCallback(() => {
    // Compute the meta we want to set on this job from this step
    const meta = JSON.stringify({
      characteristic_id: selectedCharId,
      student_code_column: mappedColumns["student_code"],
      characteristic_responses_column:
        mappedColumns["characteristic_column_name"],
      ignore_student_code_errors: false,
    })

    // Put params together in a nice way
    const params = { meta: meta, overwriteMeta: false }

    // Update the job and either continue to the next step or show an error
    return updateBulkUploadJob(params)
      .then(result => {
        if (result.data && result.data.updateBulkUploadJob) {
          navigate("/Students/Characteristics/MapResponseLabels", {
            state: {
              jobId: result.data.updateBulkUploadJob.id,
            },
          })
        } else {
          // TODO: Show an error
        }
      })
      .catch(() => {
        // TODO: Show an error
      })
  }, [updateBulkUploadJob, mappedColumns, selectedCharId, navigate])

  // Handle when the user presses the Continue button
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Students")
    })
  }, [cancelUploadJob, navigate])

  // Helper to toggle the help modal
  const toggleHelpModal = React.useCallback(() => {
    setShowHelpModal(showModal => !showModal)
  }, [])

  // Handle when the target characteristic is changed
  const handleCharChange = React.useCallback(event => {
    setSelectedCharId(event.target.value)
  }, [])

  // Handle when the user maps a particular column
  const handleColumnMapped = React.useCallback(({ selectedOption, mapTo }) => {
    // Update state to record this mapped column
    setMappedColumns(columns => ({ ...columns, [mapTo]: selectedOption }))
  }, [])

  // Handle when the user wishes to clear a column
  const handleColumnClear = React.useCallback(columnName => {
    // Update state such the column is unmapped
    setMappedColumns(columns => {
      delete columns[columnName]
      return { ...columns }
    })
  }, [])

  const requiredColumns = {
    student_code: "Student ID",
    characteristic_column_name: {
      placeholderText: "-- Please select a characteristic --",
      selectableRowItems: characteristics,
      selectedId: selectedCharId,
      handleChange: handleCharChange,
      isSelectableRowItemsLoading: isCharacteristicsLoading,
    },
  }

  return (
    <MapColumnHeadings
      importType={"Characteristics"}
      handleToggleHelpModal={toggleHelpModal}
      showHelpModal={showHelpModal}
      uploadJob={uploadJob}
      requiredColumns={requiredColumns}
      mappedColumns={mappedColumns}
      options={availableColumns}
      handleColumnMapped={handleColumnMapped}
      handleColumnClear={handleColumnClear}
      isMappingComplete={isMappingComplete}
      isUpdatingJob={isUpdatingJob}
      handleContinue={handleContinue}
      handleCancel={handleCancel}
    />
  )
}
