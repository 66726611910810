import React from "react"
import { Row, Col } from "reactstrap"
import { ClassBadge, defaultSelectStyles } from "components"
import Select from "react-select"
import { pluralize } from "util/nameUtil"

export class InviteTeachersTable extends React.Component {
  render() {
    const { classes, teachers, onDataChange } = this.props

    return (
      <div className="c-teachers-invite-table mt-4">
        <div className="container-fluid">
          <Row>
            <Col xs="2" className="c-teachers-invite-table__header">
              Class
            </Col>
            <Col
              xs="2"
              className="c-teachers-invite-table__header text-right pr-4">
              # of Students
            </Col>
            <Col xs="4" className="c-teachers-invite-table__header">
              Allocated Teachers
            </Col>
          </Row>
          <div className="c-teachers-invite-table__data-container px-3">
            {classes.map(c => {
              return (
                <Row key={c.id} className="c-teachers-invite-table__data py-1">
                  <Col xs="2">
                    <ClassBadge label={c.label} />
                  </Col>
                  <Col className="text-right pr-4" xs="2">{`${pluralize(
                    c.studentCount,
                    "student",
                    "students"
                  )}`}</Col>
                  <Col xs="8">
                    <Select
                      name="teacherId"
                      className="c-teachers-invite-table__multiselect"
                      placeholder="Select Teacher(s)"
                      options={teachers}
                      value={c.teachers}
                      onChange={e => onDataChange(e, c.id)}
                      closeMenuOnSelect={false}
                      isMulti
                      styles={defaultSelectStyles}
                    />
                  </Col>
                </Row>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
