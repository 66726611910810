import React from "react"
import { pendingImport } from "../pendingImport"

const genericMessage = (
  <p>
    <b>Please try again by fixing the following issue:</b>
  </p>
)

export const warnings = {
  "invalid-encoding": {
    title: undefined,
    body: () => (
      <div>
        {genericMessage}
        <p>
          This is not a valid CSV file. Please check that the file extension is{" "}
          <code>.csv</code> and that the file only contains valid characters
          (e.g A-z, ‘-‘, ‘_’) and try again.
        </p>
        <p>
          If you are using a Mac, please save the file format as{" "}
          <code>CSV UTF-8 (.csv)</code>
        </p>
      </div>
    ),
    actions: () => [],
  },
  "invalid-csv": {
    title: undefined,
    body: () => (
      <div>
        {genericMessage}
        <p>
          The file was not saved as a .CSV <b>OR</b> does not have the correct
          number of columns. Please use the template provided and try again.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "invalid-number-of-columns": {
    title: undefined,
    body: (_, details) => (
      <div>
        {genericMessage}
        <p>
          The number of columns is wrong
          {details && details.expected
            ? `, you should have at least ${details.expected} columns`
            : ""}
          . Please check that your file is a valid comma (,) separated file and
          try again.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "file-not-found": {
    title: undefined,
    body: () => (
      <div>
        {genericMessage}
        <p>
          File is no longer found at the specified location. Please cancel the
          upload and try again.
        </p>
      </div>
    ),
    actions: ({ schoolUploads, updateBulkUploadJob, navigate }) => {
      const clickHandler = () => {
        const { job: currentUploadJob } = pendingImport(
          schoolUploads,
          "STUDENT"
        )

        const variables = {
          jobParams: {
            id: currentUploadJob.id,
            status: "CANCELLED",
            type: currentUploadJob.type,
          },
        }

        updateBulkUploadJob({ variables })
        navigate("/Students")
      }
      return [
        {
          color: "warning",
          onClick: clickHandler,
          text: "Cancel Upload",
        },
      ]
    },
  },
  "grade-does-not-exist": {
    title: undefined,
    body: () => (
      <div>
        {genericMessage}
        <p>
          The file contains a student grade that is not recognised. Please check
          your school’s Grades list in Settings and try again.
        </p>
      </div>
    ),
    actions: ({ navigate }) => {
      const clickHandler = () => {
        navigate("/Students/Upload/GradeLabels")
      }
      return [
        {
          color: "primary",
          onClick: clickHandler,
          text: "Go To Grade Settings",
        },
      ]
    },
  },
  "class-does-not-exist": {
    title: undefined,
    body: () => (
      <div>
        {genericMessage}
        <p>
          One or more classes do not exist. Please check with your school’s
          Classes list in Settings and try again.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "missing-required-data": {
    title: undefined,
    body: (_, details) => (
      <div>
        {genericMessage}
        <p>The following row is missing some required data:</p>
        <pre>
          {JSON.stringify(details.rows[0], null, 2).replace(/["{}]/g, "")}
        </pre>
      </div>
    ),
    actions: () => [],
  },
  generic: {
    title: undefined,
    body: dataType => (
      <div>
        <p>
          We’re sorry, but something went wrong with your {dataType} record
          upload.
        </p>
        <p>Please contact an administrator.</p>
      </div>
    ),
    actions: () => [],
  },
  "duplicate-email": {
    title: undefined,
    body: (_, duplicateEmail) => (
      <div>
        {genericMessage}
        <p>
          We found a duplicate email in your list: <i>{duplicateEmail.email}</i>
          , please remove/change it and try again.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "invalid-email-format": {
    title: undefined,
    body: (_, invalidEmail) => (
      <div>
        {genericMessage}
        <p>
          We found an email with an invalid format in your list:{" "}
          <code>{invalidEmail.email}</code>, please remove/change it and try
          again.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "file-has-no-data": {
    title: undefined,
    body: () => (
      <div>
        <p>
          <b>The file does not contain any data.</b>
        </p>
        <p>
          You may have accidently selected a blank template. Please check you
          are importing the correct file, that it contains data, and try again.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "invalid-gender-format": {
    title: undefined,
    body: () => (
      <div>
        {genericMessage}
        <p>
          The gender format in your CSV is incorrect. It must be either{" "}
          <code>M</code> or <code>F</code>.
        </p>
      </div>
    ),
    actions: () => [],
  },
  "empty-file": {
    title: undefined,
    body: () => (
      <div>
        <p>Uploaded file is empty. Please try again with a non-empty file.</p>
      </div>
    ),
    actions: ({ schoolUploads, updateBulkUploadJob, navigate }) => {
      const clickHandler = () => {
        const { job: currentUploadJob } = pendingImport(
          schoolUploads,
          "STUDENT"
        )

        const variables = {
          jobParams: {
            id: currentUploadJob.id,
            status: "CANCELLED",
            type: currentUploadJob.type,
          },
        }

        updateBulkUploadJob({ variables })
        navigate("/Students")
      }
      return [
        {
          color: "warning",
          onClick: clickHandler,
          text: "Cancel Upload",
        },
      ]
    },
  },
  "missing-required-headers": {
    title: undefined,
    body: (_, details) => (
      <div>
        {genericMessage}
        <p>
          The CSV file is missing some column headers. Please make sure the CSV
          file contains the correct headers in the first row.
        </p>
        {details && details.missing && (
          <p>
            Missing headers:
            <br />
            <code>{details.missing.join(", ").replace(/_/g, " ")}</code>
          </p>
        )}
      </div>
    ),
    actions: () => [],
  },
}
