import { gql } from "@apollo/client"

export const getServerModeQuery = gql`
  query getServerMode {
    getServerMode
  }
`

export const setServerModeMutation = gql`
  mutation setServerMode($serverMode: ServerMode!) {
    setServerMode(serverMode: $serverMode)
  }
`
