import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { HelpModal } from "domains/upload"

const actionView = ({ color, onClick, disabled, text }, index) => (
  <Button key={index} color={color} onClick={onClick} disabled={disabled}>
    {text}
  </Button>
)

export const UploadErrorModal = ({
  isOpen = false,
  toggle,
  title = "Oops, something went wrong",
  children,
  actions = [],
  jobId,
  type = "",
}) => {
  const [showHelp, setShowHelp] = useState(false)

  return (
    <>
      <Modal isOpen={isOpen} className="modal-dialog-centered u-modal__w-35rem">
        <ModalHeader tag="h2" toggle={toggle}>
          <span>{title}</span>
          <Button
            className="u-font-weight-medium"
            style={{ fontSize: "0.7rem" }}
            color="link"
            onClick={() => setShowHelp(true)}>
            Need help importing?
          </Button>
        </ModalHeader>

        <ModalBody className="px-4 d-flex flex-column align-items-center">
          <div className="d-flex justify-content-between mr-4">
            <i className="fa fa-info-circle u-caution-icon mr-4" />
            <div>{children || "Something went wrong."}</div>
          </div>
        </ModalBody>
        <ModalFooter className="px-4 pb-4">
          {actions.map(actionView)}
        </ModalFooter>
      </Modal>
      {showHelp && (
        <HelpModal
          isOpen
          toggle={() => setShowHelp(false)}
          jobId={jobId}
          onSentNotification={() => {
            setShowHelp(false)
            toggle()
          }}
          type={type}
        />
      )}
    </>
  )
}
