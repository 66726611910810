import * as React from "react"
import { Button, Label } from "reactstrap"
import { SCHOOL_ID } from "constants/storageTokens"
import { useNavigate } from "react-router-dom"

import { warnings } from "domains/students/upload/warnings"

import ExcelIcon from "images/excel-icon.svg"
import { useCreateBulkUploadJob } from "domains/upload/hooks/useCreateBulkUploadJob"

import studentNotesTemplate from "templateFiles/class_list_template.csv"

import { UploadErrorModal } from "domains/upload"

export const ImportSolutionPage = () => {
  // Keep a piece of state which equals the file which the user selected
  const [selectedFile, setSelectedFile] = React.useState(undefined)

  // Bring in a hook which allows us to create a new upload job
  const [createUploadJob, { loading }] = useCreateBulkUploadJob()

  // Utility for manipulating the router
  const navigate = useNavigate()

  // Handle when a user selects a new file by updating state accordingly
  const handleFileSelect = event => {
    setSelectedFile(event.target.files[0])
  }

  // State to hold onto create job errors
  const [createJobError, setCreateJobError] = React.useState()

  const [warningCode, warningDetails, errorJobId] =
    createJobError && createJobError.graphQLErrors.length > 0
      ? [
          createJobError.graphQLErrors[0].message,
          createJobError.graphQLErrors[0].details,
          createJobError.graphQLErrors[0].job_id,
        ]
      : [undefined, undefined, undefined]

  // Handle when the user clicks the 'Confirm and Upload' button
  const handleConfirm = React.useCallback(() => {
    // Attempt to create the new upload job. On success, redirect to the next
    // screen or show the user an error

    return createUploadJob({
      variables: {
        file: selectedFile,
        schoolId: sessionStorage.getItem(SCHOOL_ID),
        type: "UPLOAD_SOLUTION",
      },
    })
      .then(result => {
        if (result.data && result.data.createBulkUploadJob) {
          const jobId = result.data.createBulkUploadJob.id
          navigate("/Solver/MapColumns", {
            state: { jobId: jobId },
          })
        }
      })
      .catch(error => {
        setCreateJobError(error)
      })
  }, [createUploadJob, selectedFile, navigate])

  // This is to navigate back to Account Settings page by navigating backwards by 1 step
  const handleBack = () => navigate(-1)

  // Render the file picker interface if no file is currently selected
  if (selectedFile === undefined) {
    return (
      <>
        <div className="row m-4">
          <h1 className="mx-4">Import Class Lists</h1>
        </div>

        <div className="c-solver u-row-fix u-layout-component-grow ">
          <div className="c-solver__classes-container container-fluid ">
            <div className="container-fluid d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
                <img height="150px" src={ExcelIcon} alt="Excel Icon" />

                <h2 className="my-2 text-center">
                  1. Download and complete the class list template
                </h2>

                <p className="py-2 text-center">
                  <a
                    href={studentNotesTemplate}
                    download={`class_list_template.csv`}>
                    <Button className="btn-200-width" color="primary">
                      Download Template
                    </Button>
                  </a>
                </p>

                <h2 className="my-2 text-center">
                  2. Then upload the completed file
                </h2>

                <input
                  type="file"
                  id="studentNotesCSV"
                  name="studentNotesCSV"
                  onChange={handleFileSelect}
                  hidden
                />

                <p className="py-2 mb-0 text-center">
                  <a href="#">
                    <Label
                      htmlFor="studentNotesCSV"
                      className="btn-200-width btn-secondary">
                      Upload Completed File
                    </Label>
                  </a>
                </p>

                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleBack}>
                  {"< Back to Settings Page"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  // A file has been selected, so let's render the confirmation interface
  else {
    return (
      <>
        <div className="row m-4">
          <h1 className="mx-4">Import Class Lists</h1>
        </div>

        <div className="c-solver u-row-fix u-layout-component-grow ">
          <div className="c-solver__classes-container container-fluid ">
            <div className="container-fluid d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center align-self-center u-layout-component-grow">
                <img height="150px" src={ExcelIcon} alt="Excel Icon" />

                <h2 className="m-2">
                  3. You have selected the following file to upload:
                </h2>

                <div className="m-2 row flex-column w-75 align-items-center">
                  <div className="m-2 u-text-20 u-bold">
                    {selectedFile.name}
                  </div>
                  <button
                    onClick={handleConfirm}
                    disabled={loading}
                    className="btn btn-primary m-2">
                    Confirm and Upload
                  </button>
                  <input
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    onChange={handleFileSelect}
                    hidden
                  />
                  <label htmlFor="fileUpload" className="btn btn-secondary m-2">
                    Choose Another File
                  </label>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handleBack}>
                    {"< Back to Settings Page"}
                  </button>
                  <div className="c-note d-flex flex-column mx-auto my-3">
                    <div className="my-2">
                      <i className="fa fa-info-circle mr-2"></i>
                      <b>Important Tips</b>
                    </div>

                    <ul>
                      <li>
                        Ensure that the imported file includes the following
                        column headings: Student ID and Student Notes
                      </li>
                    </ul>

                    <p className="pb-0 mb-0 text-uppercase font-weight-bold">
                      Warning
                    </p>

                    <p>
                      If student notes already exist in Class Solver for a
                      student listed in your file, this function will over-write
                      the existing student notes for this student. Any existing
                      student notes will not be affected if the Student ID does
                      not appear in your file.
                    </p>

                    <p>
                      If you are using a Mac, please save the file format as CSV
                      UTF-8 (.csv)
                    </p>
                  </div>
                  {createJobError && (
                    <UploadErrorModal
                      isOpen
                      title={warnings[warningCode].title}
                      type="Student Notes"
                      jobId={errorJobId}
                      toggle={() => setCreateJobError(undefined)}
                      actions={warnings[warningCode].actions()}>
                      {warnings[warningCode].body(
                        "Student Notes",
                        warningDetails
                      )}
                    </UploadErrorModal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
