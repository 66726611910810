import React, { useState } from "react"
import { useMutation } from "@apollo/client"

import { DeleteModal } from "components"
import { removeUserFromSchoolsMutation } from "domains/accountSettings/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { getSchoolId, removeSchoolId } from "util/app"

export const LinkedAccounts = ({ userProfile }) => {
  const currentSchoolId = getSchoolId()
  const [errorUnlinking, setErrorUnlinking] = useState("")
  const [removeUserFromSchools] = useMutation(removeUserFromSchoolsMutation, {
    refetchQueries: [{ query: userProfileQuery }],
    onError: () => {},
  })

  const [unlinkUserModal, setUnlinkUserModal] = useState(false)
  const [schoolId, setSchoolId] = useState()

  const unlinkUser = () => {
    const variables = {
      userId: userProfile.id,
      schoolIds: [schoolId],
    }

    removeUserFromSchools({ variables }).then(response => {
      if (response.errors) {
        setErrorUnlinking("Error unlinking user from school. Please try again.")
      } else {
        if (schoolId === currentSchoolId) {
          removeSchoolId()
        }
        setUnlinkUserModal(false)
        setErrorUnlinking("")
      }
    })
  }

  return (
    <>
      <div className="c-account-details pb-5 u-content-border-bottom">
        <h2 className="mb-4 u-blue-primary">Accounts Linked to My Profile</h2>
        {userProfile.schools.length === 0 && (
          <div>You do not have any schools linked to your profile.</div>
        )}
        {userProfile.schools.map(school => {
          return (
            <div key={school.id} className="d-flex justify-between my-3">
              <div>{school.name}</div>
              <span
                className="cursor-pointer color-blue-mid text-primary"
                onClick={() => {
                  setSchoolId(school.id)
                  setUnlinkUserModal(true)
                }}>
                Remove
              </span>
            </div>
          )
        })}
      </div>
      {unlinkUserModal && (
        <DeleteModal
          isOpen
          toggle={() => setUnlinkUserModal(false)}
          heading="Remove Linked Account?"
          text=" Are you sure? Your profile will no longer be linked to this account. Note: If this is your only linked account, you will no longer have access to Class Solver."
          buttonText="Yes, remove"
          onButtonClick={unlinkUser}
          error={errorUnlinking}
        />
      )}
    </>
  )
}
