import React from "react"

export const GradeMappingSelection = props => {
  // Destructure the props we want to use and assign any default values
  const {
    grades,
    handleGradeSelect,
    handleNextGradeSelect,
    mappedGrade,
    isNextGrade = false,
    isDisabled = false,
  } = props

  // Memoize the options which the user can select
  const selectableGrades = React.useMemo(() => {
    return grades.map(grade => {
      // Specially render the 'LEAVING' option with proper casing
      if (grade.code === "LEAVING") {
        if (isNextGrade) {
          return (
            <option key="LEAVING" value="LEAVING">
              Leaving
            </option>
          )
        } else {
          return null
        }
      }

      // Making it this far means the grade should be valid for selection
      // without any special treatment!
      return (
        <option key={grade.id} value={grade.code}>
          {grade.label}
        </option>
      )
    })
  }, [grades, isNextGrade])

  // Determine the value which should be set for the dropdown. If it is a next
  // grade dropdown, this should be sourced from mappedGrade.nextGrade if it
  // exists. Otherwise, it should be sourced from mappedGrade.matchedGrade if it
  // exists
  const value = React.useMemo(() => {
    if (isNextGrade) {
      return mappedGrade.nextGrade ? mappedGrade.nextGrade.code : ""
    } else {
      return mappedGrade.matchedGrade ? mappedGrade.matchedGrade.code : ""
    }
  }, [mappedGrade, isNextGrade])

  // Handle when a new option is selected. Different behaviour is triggered
  // depending on whether this is a "Next Grade" dropdown or not
  const handleSelect = React.useCallback(
    event => {
      if (isNextGrade) {
        handleNextGradeSelect(event.target.value)
      } else {
        handleGradeSelect(event.target.value)
      }
    },
    [isNextGrade, handleGradeSelect, handleNextGradeSelect]
  )

  return (
    <div className="d-flex">
      <select
        className="form-control"
        onChange={handleSelect}
        value={value || "select"}
        disabled={isDisabled}>
        <option key="select" value="select" disabled></option>
        {selectableGrades}
      </select>
    </div>
  )
}
