import React, { useCallback } from "react"
import { useMutation } from "@apollo/client"
import { useNavigate, Navigate } from "react-router-dom"
import compose from "lodash.flowright"
import { graphql } from "@apollo/client/react/hoc"

import { dashboardQuery } from "domains/dashboard/graphql"
import {
  schoolUploadsQuery,
  getStudentImportMatchers,
  updateBulkUploadJob,
} from "domains/upload/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"
import { Loader } from "components"
import { IdChangeWarning } from "domains/students/components"
import { pendingImport } from "../pendingImport"
import { queriesAreLoading } from "util/app"

export const IdChangeWarningComponent = props => {
  const navigate = useNavigate()
  const [updateJob] = useMutation(updateBulkUploadJob)

  const handleContinue = useCallback(
    () => navigate("/Students/Upload/Grades"),
    [navigate]
  )

  if (queriesAreLoading(props)) return <Loader />

  const { job: currentUploadJob } = pendingImport(
    props.schoolUploads,
    "STUDENT"
  )

  if (!currentUploadJob) {
    return <Navigate to="/Students" replace />
  }

  const { dashboard } = props

  const numExistingStudents =
    currentUploadJob.fileSummary.existingStudents.length

  const numActiveStudents = dashboard.studentCount

  const numInactiveStudents = dashboard.inactiveStudentCount

  const showWarning =
    numExistingStudents === 0 &&
    numActiveStudents === 0 &&
    numInactiveStudents > 50

  if (!showWarning) {
    // Just redirect to the next step if there are no issues
    return <Navigate to="/Students/Upload/Grades" />
  }

  const handleCancel = () => {
    const variables = {
      jobParams: {
        id: currentUploadJob.id,
        type: currentUploadJob.type,
        status: "CANCELLED",
      },
    }

    updateJob({ variables }).then(() => {
      navigate("/Students")
    })
  }

  if (showWarning) {
    return (
      <IdChangeWarning
        currentUploadJob={currentUploadJob}
        handleContinue={handleContinue}
        handleCancel={handleCancel}
      />
    )
  } else {
    return null
  }
}

export const IdChangeWarningPage = compose(
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(getStudentImportMatchers, { name: "studentImportMatchers" }),
  graphql(dashboardQuery, {
    name: "dashboard",
    skip: () => getSchoolId() === null,
    options: ({ settings }) => ({
      variables: {
        adminOnly: settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(schoolUploadsQuery, {
    name: "schoolUploads",
    options: ({ studentImportMatchers }) => {
      // The Apollo cache is read-only so we need to clone the array before using it
      const columns = [...studentImportMatchers.studentsImport.columns]

      return {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: {
          schoolId: getSchoolId(),
          columnMap: columns,
        },
      }
    },
  })
)(IdChangeWarningComponent)
