import React, { useMemo, useEffect } from "react"
import { useTable, useRowSelect, useAsyncDebounce } from "react-table"
import { ClassBadge } from "components"
import Select, { components } from "react-select"
import { fullName } from "util/nameUtil"

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export const FriendshipPreferencesTable = ({
  rowData,
  canonicalHeaders,
  nameMap,
  selectedRows,
  updateSelectedRows,
  updateNameMap,
  students,
}) => {
  const columns = useMemo(() => {
    const numFriends = canonicalHeaders.filter(
      header => header && header.startsWith("friend")
    ).length

    const friendColumns = [...Array(numFriends)].map((_, index) => {
      return {
        Header: `Friend ${index + 1}`,
        Cell: NameCell,
        accessor: `friend_${index + 1}`,
        headerClassName: "friendship-column",
        className: "friend-cell",
      }
    })

    return [
      {
        Header: "Student",
        Cell: NameCell,
        accessor: "student",
      },
      ...friendColumns,
    ]
  }, [canonicalHeaders])

  const data = useMemo(() => rowData, [rowData])

  // Set up the react table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        nameMap,
        studentOptions: studentOptions(students),
        selectedRowIds: convertToSelectedRowIds(selectedRows),
        updateNameMap,
      },
      autoResetSelectedRows: false,
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: props => {
            const { getToggleAllRowsSelectedProps } = props

            return (
              <div className="d-flex flex-column align-items-center">
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            )
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: props => {
            const { row } = props
            return (
              <div className="d-flex flex-column align-items-center">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
        },
        ...columns,
      ])
    }
  )

  // Debounce the selected row update https://react-table-v7.tanstack.com/docs/faq#how-can-i-debounce-rapid-table-state-changes
  const updateSelectedRowsDebounced = useAsyncDebounce(updateSelectedRows, 200)

  // Track the selectedRowIds table state and update the parent
  // state accordingly.
  useEffect(() => {
    updateSelectedRowsDebounced(convertFromSelectedRowIds(selectedRowIds))
  }, [updateSelectedRowsDebounced, selectedRowIds])

  return (
    <div className="friendship-preferences-import-table">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className={column.headerClassName}>
                    {column.render("Header")}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column.className}>
                      {cell.render("Cell")}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const studentOptions = students => {
  return [
    // Ignored option
    {
      value: "",
      label: "--- IGNORED ---",
    },
    ...students.map(student => {
      const label = student.currentClass
        ? `${fullName(student)} (${student.currentClass.label})`
        : `${fullName(student)}`

      return {
        value: student.id,
        label: label,
      }
    }),
  ]
}

const selectMenu = name => {
  return props => {
    return (
      <>
        <components.MenuList {...props}>{props.children}</components.MenuList>
        <div className="pl-2 py-2 border-top font-size-14 text-danger">
          {name}
        </div>
      </>
    )
  }
}

const NameCell = table => {
  const {
    value: name,
    initialState: { nameMap, studentOptions, updateNameMap },
    row: { isSelected, toggleRowSelected },
    column: { id: columnId },
  } = table
  const student = nameMap[name]
  // There are 4 states that a name can be in:
  //
  // mapped - string in file has been mapped to a student id
  // ignored - string in file has been mapped to "", which means no student
  // unmapped - string in file has not been mapped yet
  // empty - string in file is ""
  const isEmpty = name === ""
  const isMapped = !isEmpty && name in nameMap && student
  const isIgnored = !isEmpty && name in nameMap && !student
  const isUnmapped = !isEmpty && !(name in nameMap)
  const isStudentColumn = columnId === "student"

  // Do not disable the student column
  const isDisabled = isStudentColumn
    ? !isIgnored && !isSelected
    : !isSelected || isEmpty

  // If the student column is ignored, then ignore the entire row
  if (isIgnored && isSelected && isStudentColumn) {
    toggleRowSelected()
  }

  let selectedValue
  if (isMapped) {
    selectedValue = {
      value: student.id,
      label: (
        <div className="d-flex justify-content-between align-items-center">
          <div className="mr-3">{fullName(student)}</div>
          {student.currentClass && (
            <ClassBadge
              label={student.currentClass.label}
              className={`${isDisabled && "c-class-badge--disabled"}`}
            />
          )}
        </div>
      ),
    }
  } else if (isIgnored) {
    selectedValue = {
      value: "",
      label: <div className="text-center align-center">--- IGNORED ---</div>,
    }
  } else if (isUnmapped) {
    selectedValue = {
      value: "",
      label: (
        <div className="d-flex justify-content-between align-items-center">
          {name}
          {!isDisabled && <i className="ml-3 fa fa-warning text-danger" />}
        </div>
      ),
    }
  } else if (isEmpty) {
    selectedValue = {
      value: "",
      label: null,
    }
  }

  const danger = isUnmapped && !isDisabled ? "border border-danger" : ""

  return (
    <>
      <Select
        name="nameMapping"
        className={`${danger}`}
        options={studentOptions}
        value={selectedValue}
        onChange={({ value: studentId }) => updateNameMap(name, studentId)}
        components={{
          MenuList: selectMenu(name),
          IndicatorSeparator: null,
          DropdownIndicator: null,
        }}
        isDisabled={isDisabled}
        // backspaceRemovesValue
        // isClearable
        styles={{
          control: baseStyles => ({
            ...baseStyles,
            backgroundColor: null,
            borderStyle: null,
          }),
        }}
      />
    </>
  )
}

// Convert to React Tables `selectedRowIds`, e.g.
// [0,3] => {0: true, 3: true}
const convertToSelectedRowIds = rowIndices =>
  Object.fromEntries(rowIndices.map(rowIndex => [rowIndex, true]))

// Convert from React Tables `selectedRowIds`, e.g.
// {0: true, 3: true} => [0,3]
const convertFromSelectedRowIds = selectedRowIds =>
  Object.keys(selectedRowIds).map(rowIndex => parseInt(rowIndex))
