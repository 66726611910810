import React from "react"

import { solverStudentQuery } from "domains/solver/graphql"

import { Input } from "reactstrap"
import {
  AttributeLabel,
  SelectedStudentDetails,
} from "domains/solver/components"
import { StudentModal } from "domains/students/components"

import { getPropertyIfDefined } from "util/objUtil"

import { getOpacity } from "util/colours"
import { NORMAL, SHARED_WRITABLE } from "domains/solver/constants"

export const EditBottomView = ({
  activeCharacteristic,
  onActiveCharacteristicChange,
  characteristicScores,
  onBoostCharacteristicClick,
  metricDifferences,
  selectedStudent,
  studentMetrics,
  onBoostStudentMetricClick,
  friendPreferences,
  differentTeacherSetting,
  studentModal,
  studentModalToggle,
  refetchQueries,
  solverMode,
  showCharacteristics,
  showTeacherRequests,
  showStudentRequests,
  showFriendships,
  currentClasses,
  toggleCharacteristicResponseSort,
  showClassEntryCriteria,
}) => {
  const colourOfFirstResponse =
    activeCharacteristic.characteristic &&
    activeCharacteristic.characteristic.characteristicResponses[0] &&
    activeCharacteristic.characteristic.characteristicResponses[0].colour
  const opacity = getOpacity(colourOfFirstResponse)

  const normalMode = solverMode === NORMAL
  const canEdit = normalMode || solverMode === SHARED_WRITABLE

  let sortIcon
  if (activeCharacteristic.id === "" || activeCharacteristic.id === "all") {
    sortIcon = null
  } else {
    sortIcon = (
      <i
        className="fa fa-sort-amount-desc color-blue-mid cursor-pointer px-2"
        aria-hidden="true"
        onClick={toggleCharacteristicResponseSort}></i>
    )
  }

  /* Determine what badge to display depending on selected characteristic */
  let badge
  if (activeCharacteristic.id === "" || activeCharacteristic.id === "all") {
    badge = (
      <AttributeLabel
        icon="_badge _badge-white mr-1"
        count={getPropertyIfDefined(characteristicScores, "aggregate.count")}
        total={getPropertyIfDefined(characteristicScores, "aggregate.total")}
        percentage
        showMetric={canEdit}
        onClick={
          canEdit &&
          (e => {
            onBoostCharacteristicClick(e, undefined)
          })
        }
        difference={metricDifferences.characteristicScores}
      />
    )
  } else if (activeCharacteristic.metric === null) {
    badge = (
      <AttributeLabel
        icon={`_badge ${opacity > 0 ? "" : "_badge-white"} mr-1`}
        iconStyle={
          opacity > 0 ? { backgroundColor: colourOfFirstResponse } : {}
        }
        count="-"
        total="-"
        showMetric={canEdit}
        onClick={
          canEdit &&
          (e => {
            onBoostCharacteristicClick(e, undefined)
          })
        }
        onBadgeClick={toggleCharacteristicResponseSort}
      />
    )
  } else {
    badge = (
      <AttributeLabel
        icon={`_badge ${opacity > 0 ? "" : "_badge-white"} mr-1`}
        iconStyle={
          opacity > 0 ? { backgroundColor: colourOfFirstResponse } : {}
        }
        count={getPropertyIfDefined(activeCharacteristic, "metric.count")}
        total={getPropertyIfDefined(activeCharacteristic, "metric.total")}
        percentage
        showMetric={canEdit}
        onClick={
          canEdit &&
          (e => {
            onBoostCharacteristicClick(
              e,
              activeCharacteristic.characteristic.id
            )
          })
        }
        difference={metricDifferences.activeCharacteristic}
        onBadgeClick={toggleCharacteristicResponseSort}
      />
    )
  }

  const studentRefetchQueries = selectedStudent
    ? refetchQueries.concat({
        query: solverStudentQuery,
        variables: { id: selectedStudent.id },
      })
    : refetchQueries

  const deleteRefetchQueries = refetchQueries

  return (
    <React.Fragment>
      {showCharacteristics && (
        <>
          <div className="c-side-bar__panel__characteristics-container w-100 d-flex justify-content-between align-items-center pt-4 d-print-none m-0">
            <Input
              type="select"
              className="c-side-bar__panel__characteristics-container__input"
              value={activeCharacteristic.id}
              onChange={onActiveCharacteristicChange}>
              <option value="">No Characteristic</option>
              {/* Map the metrics to selectable options */}
              {characteristicScores.characteristicMetrics &&
                characteristicScores.characteristicMetrics.map(
                  (metric, index) => (
                    <option value={metric.characteristic.id} key={index}>
                      {metric.characteristic.name}
                    </option>
                  )
                )}
              <option value="all">All Characteristics</option>
            </Input>

            {sortIcon}

            {badge}
          </div>
        </>
      )}
      <div className="c-side-bar__panel__divider-line d-print-none pt-4" />
      <div className="d-print-none">
        {selectedStudent ? (
          <div>
            <SelectedStudentDetails
              solverMode={solverMode}
              toggle={studentModalToggle}
              studentMetrics={getPropertyIfDefined(
                studentMetrics &&
                  studentMetrics.find(
                    studentMetric =>
                      studentMetric.student.id === selectedStudent.id
                  ),
                "metrics"
              )}
              onBoostStudentMetricClick={onBoostStudentMetricClick}
              studentBoostDifferences={metricDifferences.student}
              friendPreferences={friendPreferences}
              differentTeacherSetting={differentTeacherSetting}
              showTeacherRequests={showTeacherRequests}
              showStudentRequests={showStudentRequests}
              showCharacteristics={showCharacteristics}
              showClassEntryCriteria={showClassEntryCriteria}
              {...selectedStudent}
            />
            <StudentModal
              isOpen={studentModal}
              toggle={studentModalToggle}
              showTeacherRequests={showTeacherRequests}
              showStudentRequests={showStudentRequests}
              showCharacteristics={showCharacteristics}
              showFriendships={showFriendships}
              teacherRequestsEditable={normalMode}
              studentRequestsEditable={normalMode}
              studentEditable={normalMode}
              friendshipEditable={normalMode}
              characteristicsEditable={normalMode}
              currentClasses={currentClasses}
              refetchQueries={studentRefetchQueries}
              deleteRefetchQueries={deleteRefetchQueries}
              studentId={selectedStudent.id}
            />
          </div>
        ) : (
          <div className="c-side-bar__panel__no-student">
            No Student Selected
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
