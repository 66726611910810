import { CURRENT_CLASS_CHARACTERISTIC_PALETTE } from "domains/characteristics/constants"
import { fullName, userInitials } from "util/nameUtil"

const DefaultGroupNames = {
  NO_DATA: "Not Applicable",
  SEVERELY_ISOLATED: "Severely Isolated",
  MODERATELY_ISOLATED: "Moderately Isolated",
  PARTIALLY_CONNECTED: "Partially Connected",
  CONNECTED: "Connected",
  STAR: "Connected Star",
}

const DefaultMetadata = {
  groups: [
    {
      name: DefaultGroupNames.SEVERELY_ISOLATED,
      color: "#cc3232",
    },
    {
      name: DefaultGroupNames.MODERATELY_ISOLATED,
      color: "#db7b2b",
    },
    {
      name: DefaultGroupNames.PARTIALLY_CONNECTED,
      color: "#e7b416",
    },
    {
      name: DefaultGroupNames.CONNECTED,
      color: "#a6dbff",
    },
    {
      name: DefaultGroupNames.STAR,
      color: "#6f9ecc",
    },
    {
      name: DefaultGroupNames.NO_DATA,
      color: "#ddd",
    },
  ],
}

// Apply group label to a student based on number of connections
function groupByConnectedness(
  { incoming: incomingClass, outgoing: outgoingClass, outgoingNotInClass },
  { maxIncomingDegree }
) {
  if (outgoingClass === 0) {
    if (outgoingNotInClass === 0) {
      return DefaultGroupNames.NO_DATA
    } else {
      if (incomingClass === 0) {
        return DefaultGroupNames.SEVERELY_ISOLATED
      } else {
        return DefaultGroupNames.MODERATELY_ISOLATED
      }
    }
  } else {
    if (incomingClass === 0) {
      return DefaultGroupNames.PARTIALLY_CONNECTED
    } else {
      if (incomingClass === maxIncomingDegree) {
        return DefaultGroupNames.STAR
      }
      return DefaultGroupNames.CONNECTED
    }
  }
}

// Apply group label to a student based on the response to currentCharacteristic
function groupByCharacteristic(studentNode, { currentCharacteristic }) {
  let targetResponse
  if (currentCharacteristic.name === "Current Class") {
    targetResponse = currentCharacteristic.characteristicResponses.find(
      res =>
        res.label ===
        (studentNode.currentClass && studentNode.currentClass.label)
    )
  } else {
    // Add a group for the node based on the student characteristic response
    targetResponse = studentNode.characteristicResponses.find(
      res => res.characteristic.name === currentCharacteristic.name
    )
  }

  // If the student hasn't got a response for the currentCharacteristic, add a placeholder
  return targetResponse ? targetResponse.label : "Not Applicable"
}

function addDegrees(node, links) {
  return {
    ...node,
    incoming: links.filter(({ target }) => target === node.id).length,
    outgoing: links.filter(
      ({ source, inClass }) => inClass && source === node.id
    ).length,
    outgoingNotInClass: links.filter(
      ({ source, inClass }) => !inClass && source === node.id
    ).length,
  }
}

function addGroup(groupingFn, node, metadata = {}) {
  return {
    ...node,
    group: groupingFn(node, metadata),
  }
}

function addLabel(node) {
  return {
    ...node,
    label: userInitials(node),
    name: fullName(node),
  }
}

function checkMetadata(currentCharacteristic, students) {
  // If a "null" value comes through from the characteristics dropdown, we want to use the default friendship preferences metadata
  if (currentCharacteristic) {
    return buildMetadataFromCharacteristic(currentCharacteristic, students)
  } else {
    return DefaultMetadata
  }
}

function buildMetadataFromCharacteristic(currentCharacteristic, students) {
  let groupData
  if (currentCharacteristic.name === "Current Class") {
    groupData = currentCharacteristic.characteristicResponses.map(
      (cr, index) => {
        if (
          students &&
          students.some(
            student =>
              (student.currentClass && student.currentClass.label) === cr.label
          )
        ) {
          // Current Class responses don't have a colour by default, so we need to build them
          const currentClassColour =
            CURRENT_CLASS_CHARACTERISTIC_PALETTE[
              index % CURRENT_CLASS_CHARACTERISTIC_PALETTE.length
            ]
          return {
            name: cr.label,
            color: currentClassColour || "#FFF",
          }
        } else {
          return undefined
        }
      }
    )

    groupData = groupData
      .filter(group => group !== undefined)
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
        })
      )
  } else {
    groupData = currentCharacteristic.characteristicResponses.map(cr => {
      return {
        name: cr.label,
        // If the hex code provided is transparent, use white so the node is visible in the graph
        color: cr.colour && !cr.colour.endsWith("00") ? cr.colour : "#FFF",
      }
    })
  }

  // Fallback group and colour for students with no matching response for currentCharacteristic
  const notApplicable = { name: "Not Applicable", color: "#ddd" }

  return { groups: [...groupData, notApplicable] }
}

export function convertClassToSociogram(students, currentCharacteristic) {
  if (!students) {
    return null
  }

  const studentIds = students.map(s => s.id)

  const links = students.flatMap(({ id, friends }) =>
    friends.map(f => ({
      source: id,
      target: f.studentTo.id,
      inClass: studentIds.includes(f.studentTo.id),
    }))
  )

  const nodesWithDegrees = students.map(node => addDegrees(node, links))
  const maxIncomingDegree = Math.max(
    ...nodesWithDegrees.map(({ incoming }) => incoming)
  )
  const nodes = currentCharacteristic
    ? nodesWithDegrees.map(node =>
        addGroup(groupByCharacteristic, addLabel(node), {
          currentCharacteristic,
        })
      )
    : nodesWithDegrees.map(node =>
        addGroup(groupByConnectedness, addLabel(node), { maxIncomingDegree })
      )

  const characteristicsMetadata = checkMetadata(currentCharacteristic, students)

  return {
    links: links.filter(link => link.inClass),
    nodes,
    metadata: {
      ...characteristicsMetadata,
      maxIncomingDegree,
    },
  }
}

export function convertGradeToSociogram(students, currentCharacteristic) {
  if (!students) {
    return null
  }

  const studentIds = students.map(s => s.id)

  const links = students.flatMap(({ id, friends }) =>
    friends.flatMap(f =>
      studentIds.includes(f.studentTo.id)
        ? [{ source: id, target: f.studentTo.id }]
        : []
    )
  )

  const nodes = students.map(node =>
    addGroup(groupByConnectedness, addLabel(addDegrees(node, links)))
  )

  const characteristicsMetadata = checkMetadata(currentCharacteristic)

  return { links, nodes, characteristicsMetadata }
}
