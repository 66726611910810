import React from "react"
import { getMetricsLabel } from "util/reports"

export const PerformanceCell = ({ metric }) => {
  const color = metric && metric.total === 0 ? "color-grey-mid" : ""
  return (
    <td className={`c-reports-table-body__row__data ${color}`}>
      {getMetricsLabel(metric)}
    </td>
  )
}

export const DifferentTeacherCell = ({ differentTeacher }) => {
  const color = differentTeacher ? "" : "color-grey-mid"
  return (
    <td className={`c-reports-table-body__row__data ${color}`}>
      {differentTeacher ? "Yes" : "No"}
    </td>
  )
}

export const CharacteristicTotalCells = ({ data }) =>
  data.map((responseTotals, index) => {
    const totalsLabel =
      responseTotals.length > 1 ? responseTotals.join(" - ") : responseTotals[0]

    return (
      <td key={index} className="c-reports-table-body__row__data">
        {totalsLabel}
      </td>
    )
  })
