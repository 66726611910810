import React from "react"

export const Loader = ({ className = "" }) => {
  return (
    <div className={`loader-container ${className}`}>
      <svg
        className="loader"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 340 340">
        <circle cx="170" cy="170" r="160" stroke="#fbd90b" />
        <circle cx="170" cy="170" r="135" stroke="#fca613" />
        <circle cx="170" cy="170" r="110" stroke="#e7181c" />
        <circle cx="170" cy="170" r="85" stroke="#3b2584" />
        <circle cx="170" cy="170" r="60" stroke="#1d2ed2" />
      </svg>
    </div>
  )
}
