export const FRIENDSHIP_PREFERENCES = "FRIENDSHIP_PREFERENCES"
export const AT_LEAST_ONE_FRIEND = "AT_LEAST_ONE_FRIEND"
export const MANDATORY_REQUESTS = "MANDATORY_REQUESTS"
export const IMPORTANT_REQUESTS = "IMPORTANT_REQUESTS"
export const GENDER_BALANCE = "GENDER_BALANCE"
export const DIFFERENT_TEACHER = "DIFFERENT_TEACHER"
export const CLASS_ENTRY_CRITERIA = "CLASS_ENTRY_CRITERIA"

export const EMPTY_ASSIGNMENT = {
  gradesOrClasses: [],
  teachers: [],
}

export const GRADES = "GRADES"
export const CLASSES = "CLASSES"

// Modes which the solver page and its components can be in
export const NORMAL = "NORMAL"
export const SHARED_READ_ONLY = "SHARED_READ_ONLY"
export const SHARED_WRITABLE = "SHARED_WRITABLE"
