import React, { Component } from "react"
import { Button } from "reactstrap"
import AddIcon from "images/add-icon.svg"

import { INSTRUCTION_ADD_CLASS_PLAYER_URL } from "constants/videos"

import { VideoModal } from "components"

import { AccountTypeContext } from "config/accountTypeContext"

export class ClassesColdStart extends Component {
  static contextType = AccountTypeContext

  constructor(props) {
    super(props)

    this.state = {
      showVideo: false,
    }
  }

  openVideo = () => {
    this.setState({
      showVideo: true,
    })
  }

  closeVideo = () => {
    this.setState({
      showVideo: false,
    })
  }

  render() {
    const { onClick } = this.props
    const { showVideo } = this.state

    const gettextObj = this.context.gettextObj

    return (
      <div className="c-solver u-row-fix u-layout-component-grow">
        <div className="c-solver__classes-container container-fluid">
          <div className="container-fluid d-flex justify-content-center align-items-center">
            <div className="flex-column text-center">
              <div>
                <h1 className="mt-3">
                  Set up a list of your {gettextObj.gettext("classes")} to be
                  solved
                </h1>
                <a
                  className="color-blue-mid mx-4 mt-1 font-size-14"
                  onClick={this.openVideo}>
                  <i className="fa fa-play mr-2" />
                  {"Watch Video"}
                </a>
              </div>
              <div>
                <img
                  height="150px"
                  className="mt-4"
                  src={AddIcon}
                  alt="Add Icon"
                />
              </div>
              {/* <div className="d-flex justify-content-apart mt-5"> */}
              <Button className="mt-3" color="primary px-5" onClick={onClick}>
                Add {gettextObj.gettext("Class")}
              </Button>
              {/* </div> */}
            </div>
            {showVideo && (
              <VideoModal
                toggle={this.closeVideo}
                videoUrl={INSTRUCTION_ADD_CLASS_PLAYER_URL}
                title="Instruction Step 1"
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
