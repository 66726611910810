import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Select from "react-select"

import { activeCurrentClassesQuery } from "domains/classes/graphql"
import { deleteStudentsByCurrentClassesMutation } from "domains/students/graphql"
import { Modal, ModalBody } from "reactstrap"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  Loader,
  defaultSelectStyles,
} from "components"
import { sortCurrentClasses } from "util/sortUtil"

export const DeleteStudentsByCurrentClassesModal = ({ toggle, schoolId }) => {
  const { data, loading: loadingClasses } = useQuery(
    activeCurrentClassesQuery,
    { variables: { schoolId } }
  )

  const [
    deleteStudentsByCurrentClass,
    { error: errorDeleting, loading: deletingClasses },
  ] = useMutation(deleteStudentsByCurrentClassesMutation, { onError: () => {} })

  const [selectedClasses, setSelectedClasses] = useState([])

  const loading = loadingClasses || deletingClasses

  const availableClasses = loading
    ? []
    : sortCurrentClasses(data.activeCurrentClasses).map(currentClass => {
        return {
          label: currentClass.label,
          value: currentClass.id,
        }
      })

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        className="p-4"
        title="Delete Students by Current Class"
        toggle={toggle}
      />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <ModalBody>
          <div className="d-flex flex-column align-items-center mt-2">
            <div className="mt-3 d-flex justify-content-between">
              <i className="fa fa-info-circle u-caution-icon mr-4" />
              <div className="u-white-space-pre-wrap">
                <span className="text-primary mb-3">CAUTION: </span>
                This action cannot be undone.
              </div>
            </div>
          </div>
          <div className="my-3">
            <Select
              name="current_classes"
              className="c-input__multiselect"
              placeholder="Select Current Classes"
              options={availableClasses}
              value={selectedClasses}
              onChange={classes => {
                setSelectedClasses(classes)
              }}
              closeMenuOnSelect={false}
              isMulti
              styles={defaultSelectStyles}
            />
            {errorDeleting && <InlineError text={errorDeleting.message} />}
          </div>
          <ButtonBar
            className="mt-5"
            buttonText="Yes, delete"
            onButtonClick={() => {
              deleteStudentsByCurrentClass({
                variables: {
                  schoolId: schoolId,
                  currentClassIds: selectedClasses.map(option => option.value),
                },
                onCompleted: toggle,
              })
            }}
            onCancelClick={toggle}
            disabled={selectedClasses.length === 0}
          />
        </ModalBody>
      )}
    </Modal>
  )
}
