import React, { useState } from "react"

import { Modal, ModalBody, FormGroup, Label, Input } from "reactstrap"

import { InlineError, ButtonBar, ModalHeader } from "components"

import { getFavouritedAssignmentsQuery } from "domains/solver/graphql"

export const MaximumNumberOfFavouritesReachedModal = ({ closeModal }) => {
  const text = "You can only have a maximum of 5 pinned times."

  const text2 =
    "To save this version, please remove an existing pinned time by clicking on the blue pin."

  return (
    <Modal
      isOpen={true}
      className="modal-dialog-centered"
      style={{ width: "33em" }}>
      <ModalHeader
        title="Maximum Pinned Times Reached"
        className="px-4 pt-4"
        toggle={closeModal}
      />

      <div className="px-2 pb-2 d-flex justify-content-center">
        <div className="px-1">
          <FormGroup className="col sm-6">
            <div className="d-flex flex-column align-items-start mt-2">
              <div className="mt-3 d-flex justify-content-between">
                <i className="fa fa-info-circle u-caution-icon mr-4" />
                <div>
                  {text}
                  <div className="mt-3">{text2}</div>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <ButtonBar
                onCancelClick={closeModal}
                buttonText="Ok, got it"
                onButtonClick={closeModal}
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </Modal>
  )
}

export const UnFavouriteAssignmentModal = ({
  assignmentIdForUnFavouriting,
  unfavouriteAnAssignmentMutation,
  setAssignmentIdForSave,
  setDataForUpdatingFavourite,
  activeGradeId,
  closeModal,
}) => {
  const [errors, setErrors] = React.useState({})

  const onSave = () => {
    let variables = {
      assignmentId: assignmentIdForUnFavouriting,
    }

    const refetchQueries = [
      {
        query: getFavouritedAssignmentsQuery,
        variables: { gradeId: activeGradeId },
      },
    ]

    unfavouriteAnAssignmentMutation({ variables, refetchQueries })
      .then(() => {
        closeModal()
        setAssignmentIdForSave(null)
        setDataForUpdatingFavourite(null)
      })
      .catch(() => {
        setErrors({
          mutation: "Unable to update pinned time",
        })
      })
  }

  const text =
    "This will remove this pinned time from your list of saved times."

  const text2 = "Are you sure?"

  return (
    <Modal
      isOpen={true}
      className="modal-dialog-centered"
      style={{ width: "33em" }}>
      <ModalHeader
        title="Remove Pinned Time"
        className="px-4 pt-4"
        toggle={closeModal}
      />

      <div className="px-2 pb-2 d-flex justify-content-center">
        <div className="px-1">
          <FormGroup className="col sm-6">
            <div className="d-flex flex-column align-items-start mt-2">
              <div className="mt-3 d-flex justify-content-between">
                <i className="fa fa-info-circle u-caution-icon mr-4" />
                <div>
                  {text}
                  <div className="mt-3">{text2}</div>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <ButtonBar
                onCancelClick={closeModal}
                buttonText="Yes, Remove"
                onButtonClick={onSave}
              />
            </div>
            <InlineError text={errors.mutation} />
          </FormGroup>
        </div>
      </div>
    </Modal>
  )
}

export const AddAssignmentModal = ({
  assignmentId,
  favouriteAnAssignment,
  toggle,
}) => {
  const [errors, setErrors] = useState({})
  const [notes, setNotes] = useState("")

  const onChange = e => {
    setNotes(e.target.value)
  }

  const onSave = () => {
    favouriteAnAssignment(assignmentId, notes)
      .then(() => {
        toggle()
      })
      .catch(() => {
        setErrors({
          mutation: "Unable to create pinned time",
        })
      })
  }

  return (
    <AssignmentModal
      toggle={toggle}
      notes={notes}
      onChange={onChange}
      onCancel={toggle}
      onSave={onSave}
      errors={errors}
    />
  )
}

export const EditAssignmentModal = ({
  assignmentId,
  updateFavourite,
  toggle,
  favouriteNotes,
}) => {
  const [errors, setErrors] = useState({})
  const [notes, setNotes] = useState(favouriteNotes || "")

  const onChange = e => {
    setNotes(e.target.value)
  }

  const onSave = () => {
    updateFavourite(assignmentId, notes)
      .then(() => {
        toggle()
      })
      .catch(() => {
        setErrors({
          mutation: "Unable to update pinned time",
        })
      })
  }

  return (
    <AssignmentModal
      toggle={toggle}
      notes={notes}
      onChange={onChange}
      onCancel={toggle}
      onSave={onSave}
      errors={errors}
    />
  )
}

const AssignmentModal = ({
  toggle,
  notes,
  onChange,
  onCancel,
  onSave,
  errors,
}) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-40rem">
      <ModalHeader
        title="Enter Pinned Time"
        className="px-4 py-3"
        toggle={toggle}
      />

      <ModalBody className="px-4 d-flex justify-content-center">
        <FormGroup className="col">
          <Label>Pinned Time Label</Label>
          <Input
            type="text"
            name="notes"
            placeholder="Enter Pinned Time Label"
            onChange={onChange}
            maxLength={40}
            value={notes}
          />

          <ButtonBar
            className="pt-4"
            onCancelClick={onCancel}
            buttonText="Save"
            onButtonClick={onSave}
            disabled={notes === ""}
          />
          <InlineError text={errors.mutation} />
        </FormGroup>
      </ModalBody>
    </Modal>
  )
}
