import React from "react"

import {
  Loader,
  ActionBar,
  SuccessModal,
  DeleteModal,
  CautionModal,
  ClassesList,
} from "components"
import {
  InviteSurvey,
  InviteTeachersModal,
  InviteSingleTeacherModal,
  RolledOverNote,
  TeacherModal,
  TeachersTable,
} from "domains/teachers/components"

import { handleSessionExpired } from "util/app"
import * as validators from "util/validators"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { dateToString } from "util/dateTimeUtil"
import { sortTeachers, sortCurrentClassesByLabel } from "util/sortUtil"
import { EMAIL } from "domains/teachers/errorFields"
import { isNonEmptyArray } from "util/array"
import { needHighlighting } from "util/teacherUtil"
import { teacherQuery, teacherSurveyQuery } from "domains/teachers/graphql"
import { DangerousMutation } from "domains/accountSettings/components"
import { Auth0Context } from "domains/auth/auth0Wrapper"
import { internalCsvExport } from "util/exportUtil"
import { useNavigate, useLocation } from "react-router-dom"
import { UploadStart } from "domains/teachersImport/UploadStart"

const teacherInitialState = {
  id: null,
  firstName: "",
  lastName: "",
  email: "",
  currentClassesToUpdate: [],
}

const initialTextAreaValue = () => `Hello,

We kindly ask you to complete the following survey by clicking the blue **Access Survey** button above.
`

const videoUrlForTeachersRequests = "https://vimeo.com/288935385/5f42cb74c3"
const videoUrlForNoTeachersRequests = "https://vimeo.com/288935635/73cbddefd6"

class TeacherComponent extends React.Component {
  static contextType = Auth0Context

  constructor(props) {
    super(props)

    const videoUrl =
      this.props.schoolSettings.teachersToAddTeacherRequests ||
      this.props.schoolSettings.teachersToAddStudentRequests
        ? videoUrlForTeachersRequests
        : videoUrlForNoTeachersRequests
    const inviteMessage = initialTextAreaValue(videoUrl)

    this.state = {
      loading: false,
      teacherModal: false,
      inviteTeachersModal: false,
      inviteSingleTeacherModal: false,
      cautionModal: { isOpen: false },
      successModal: false,
      edit: false,
      searchValue: "",
      teacher: teacherInitialState,
      errors: {},
      deleteModal: false,
      inviteMessage: inviteMessage,
      displayBulkUploadScreen: false,
      showDeleteAllTeachers: false,
      sendNotification: true,
    }
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      errors: {},
    })
  }

  toggleSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
      cautionModal: { isOpen: false },
    })
  }

  toggleTeacherModal = () => {
    this.setState({
      teacherModal: !this.state.teacherModal,
      edit: false,
      teacher: teacherInitialState,
      errors: {},
    })
  }

  toggleInviteSingleTeacherModal = () => {
    this.setState({
      inviteSingleTeacherModal: !this.state.inviteSingleTeacherModal,
    })
  }

  toggleInviteTeachersModal = () => {
    this.setState({
      inviteTeachersModal: !this.state.inviteTeachersModal,
    })
  }

  toggleCautionModal = object => {
    const cautionModal =
      object && "isOpen" in object ? object : { isOpen: false }

    this.setState({
      cautionModal: cautionModal,
      teacherModal: false,
      edit: false,
    })
  }

  changeInviteMessage = message => {
    this.setState({
      inviteMessage: message,
    })
  }

  onSendClick = (e, teacher, sendNotification) => {
    e.stopPropagation()

    this.setState({
      teacher: {
        id: teacher.id,
        firstName: teacher.firstName,
        lastName: teacher.lastName,
        email: teacher.email,
        currentClasses: teacher.currentClasses,
        allocatedClasses: isDefinedNotNull(teacher.survey)
          ? teacher.survey.allocatedClasses
          : [],
        restricted: teacher.survey
          ? teacher.survey.restricted
          : this.props.schoolSettings.feederSchool,
      },
      sendNotification,
    })
    this.toggleInviteSingleTeacherModal()
  }

  onChange = e => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        [e.target.name]: e.target.value,
      },
    })
  }

  onMultiClassSelectChange = value => {
    this.setState({
      teacher: {
        ...this.state.teacher,
        currentClassesToUpdate: value.map(c => {
          return { value: c.value, label: c.label }
        }),
      },
    })
  }

  teacherAddEdit = addAgain => {
    const {
      updateTeachersMutation,
      refetchQueries,
      schoolId,
      client,
      teachersData,
    } = this.props
    const { teacher } = this.state

    const { id, firstName, lastName, email, currentClassesToUpdate } = teacher

    // Check errors and exit function if there are any
    const errors = this.validateTeacher(teacher)
    if (Object.keys(errors).length !== 0) {
      this.setState({
        errors,
      })
      return
    }

    this.setState({
      loading: true,
      teacherModal: addAgain,
    })

    const currentClasses = currentClassesToUpdate.map(c => parseInt(c.value))

    // build teacherParams for query
    const teacherData = teachersData.find(({ id }) => id === teacher.id)

    const teacherParams = Object.fromEntries(
      Object.entries({
        id,
        firstName,
        lastName,
        email,
        currentClasses,
        schoolId,
        active: true,
      }).filter(([key, value]) => {
        // Do not update these fields if they haven't changed, this stops us from overwriting the data
        // with the truncated versions for support staff
        if (teacherData && ["lastName"].includes(key)) {
          return teacherData[key] !== value
        }

        return true
      })
    )

    updateTeachersMutation({ variables: { teacherParams }, refetchQueries })
      .then(() => {
        this.setState({
          loading: false,
        })
        this.toggleTeacherModal()
      })
      .catch(error => {
        handleSessionExpired(error)
        if (error.message.includes(EMAIL.key)) {
          this.reactivateTeacherWithNoClasses(
            client,
            email,
            schoolId,
            refetchQueries
          )
          this.setState({
            loading: false,
            errors: {
              email: EMAIL.message,
              mutation: "Could not save teacher",
            },
          })
        } else {
          this.setState({
            loading: false,
            errors: {
              mutation: "Error, please try again",
            },
          })
        }
      })
  }

  reactivateTeacherWithNoClasses = async (
    client,
    email,
    schoolId,
    refetchQueries
  ) => {
    const {
      data: { teacher },
    } = await client.query({
      query: teacherQuery,
      variables: { email, schoolId },
      fetchPolicy: "network-only",
    })

    if (!teacher.active) {
      const teacherParams = {
        id: teacher.id,
        currentClasses: [],
        schoolId: teacher.schoolId,
        active: true,
      }

      this.props.updateTeachersMutation({
        variables: { teacherParams },
        refetchQueries,
      })
    }
  }

  onSearchChange = e => {
    if (e === undefined) {
      this.setState({
        searchValue: "",
      })
    } else {
      this.setState({
        searchValue: e.target.value,
      })
    }
  }

  validateTeacher(teacher) {
    const errors = {}
    if (!validators.validateNonEmptyString(teacher.firstName)) {
      errors.firstName = "Required Field"
    }
    if (!validators.validateNonEmptyString(teacher.lastName)) {
      errors.lastName = "Required Field"
    }
    // email can be empty so first must check that it is not null
    // then check if the email is valid
    if (
      validators.validateNonEmptyString(teacher.email) &&
      !validators.validateEmail(teacher.email)
    ) {
      errors.email = "Invalid Email"
    }
    return errors
  }

  onTeacherClick = teacherData => {
    if (isDefinedNotNull(teacherData.id)) {
      this.setState({
        teacherModal: true,
        edit: true,
        teacher: {
          id: teacherData.id,
          firstName: teacherData.firstName,
          lastName: teacherData.lastName,
          email: teacherData.email,
          currentClassesToUpdate: teacherData.currentClasses.map(c => {
            return { value: getPropertyIfDefined(c, "id"), label: c.label }
          }),
          survey: teacherData.survey,
        },
      })
    } else {
      // if empty row is clicked show add modal
      this.toggleTeacherModal()
    }
  }

  deleteTeacher = () => {
    const { deleteTeacherMutation, refetchQueries } = this.props
    const { id } = this.state.teacher

    this.setState({ showComponentLoading: true })

    deleteTeacherMutation({ variables: { id }, refetchQueries })
      .then(() => {
        // close both modals
        this.setState({
          showComponentLoading: false,
          deleteModal: false,
          teacherModal: false,
          errors: {},
        })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          showComponentLoading: false,
          errors: { delete: "Could not delete Teacher, try again" },
        })
      })
  }

  resendSurvey = () => {
    const { requestNewTokenForTeacherMutation, refetchQueries } = this.props
    const { id: teacherId, survey } = this.state.teacher

    if (survey) {
      this.setState({ loading: true })

      requestNewTokenForTeacherMutation({
        variables: { teacherId, tokenType: "SURVEY", ccList: this.ccList() },
        refetchQueries,
      })
        .then(() => {
          // Open success modal and close resend modal
          this.setState({
            cautionModal: { isOpen: false },
            teacherModal: false,
            edit: false,
          })
          this.toggleSuccessModal()
          this.setState({ loading: false })
        })
        .catch(error => {
          handleSessionExpired(error)
          this.setState({
            loading: false,
            errors: { mutation: "There was an Error, please try again" },
          })
        })
    } else {
      this.setState({
        loading: false,
        errors: { mutation: "Could not resend survey invites" },
      })
    }
  }

  closeAllSurveys = () => {
    const { closeOrOpenAllSurveysMutation, refetchQueries, schoolId } =
      this.props
    this.setState({ loading: true })
    closeOrOpenAllSurveysMutation({
      variables: {
        schoolId,
        completed: true,
      },
      refetchQueries,
    })
      .then(() => {
        this.toggleCautionModal()
        this.setState({ loading: false })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
          errors: { mutation: "Could Not Close Surveys For Teachers" },
        })
      })
  }

  closeSurvey = teacherId => {
    const { closeOrOpenTeacherSurveyMutation, refetchQueries } = this.props

    const variables = {
      surveyCloseOpenParams: {
        teacherId: teacherId,
        completed: true,
      },
    }

    this.setState({ loading: true })

    closeOrOpenTeacherSurveyMutation({
      variables,
      refetchQueries,
    })
      .then(() => {
        this.toggleCautionModal()
        this.setState({ loading: false })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
          errors: { mutation: "Could Not Close Survey For teacher" },
        })
      })
  }

  navToCharacteristics = () => {
    const { navigate, location } = this.props
    navigate("/Characteristics", { state: { navFrom: location.pathname } })
  }

  getCloseAllSurveysCautionObject = () => {
    return {
      isOpen: true,
      heading: "Close all surveys?",
      text: "All teacher access will be closed. Only Admin Users will have access to the student details.",
      buttonText: "Yes, close",
      onClick: () => this.closeAllSurveys(),
    }
  }

  getCloseSurveyCautionObject = teacherId => {
    return {
      isOpen: true,
      heading: "Close survey?",
      text: "Teacher access for this survey will be closed. Only Admin Users will have access to the student details.",
      buttonText: "Yes, close",
      onClick: () => this.closeSurvey(teacherId),
    }
  }

  toggleBulkUploadScreen = () => {
    this.setState(({ displayBulkUploadScreen }) => ({
      displayBulkUploadScreen: !displayBulkUploadScreen,
    }))
  }

  ccList = () => [this.props.myprofile.email]

  csvExport = async () => {
    const [_, errorMessage] = await internalCsvExport(
      "teachers",
      this.props.schoolId,
      this.context,
      true
    )

    if (errorMessage) {
      handleSessionExpired({ errorMessage })
      this.setState({
        errors: { export: errorMessage },
      })
    }
  }

  openLatestSurvey = async (client, t) => {
    try {
      const {
        data: {
          teacher: {
            survey: { url },
          },
        },
      } = await client.query({
        query: teacherSurveyQuery,
        variables: { id: t.id },
        fetchPolicy: "network-only",
      })

      window.open(url, "_blank")
    } catch (e) {
      window.open("/UnavailableSurvey", "_blank")
    }
  }

  render() {
    const {
      teachersData,
      currentClasses,
      settings,
      schoolId,
      firstAdmin,
      deactivateAllTeachersMutation,
      dashboard,
      schoolSettings,
      client,
    } = this.props
    const {
      loading,
      showComponentLoading,
      edit,
      searchValue,
      teacher,
      teacherModal,
      inviteTeachersModal,
      inviteSingleTeacherModal,
      errors,
      successModal,
      cautionModal,
      deleteModal,
      inviteMessage,
      displayBulkUploadScreen,
      showDeleteAllTeachers,
      sendNotification,
    } = this.state

    if (loading) {
      return <Loader />
    }

    const teachersHaveNoCurrentClasses = teachersData.every(
      ({ currentClasses }) => currentClasses.length === 0
    )

    const notAllTeachersHaveAllBeenSentSurveys = teachersData.some(
      ({ survey }) => survey === null
    )

    const schoolHasBeenRolledOver =
      dashboard.inactiveStudentCount > 0 &&
      teachersHaveNoCurrentClasses &&
      notAllTeachersHaveAllBeenSentSurveys

    const adminMode = settings.adminOnlyRequests
    const emailDomainFromUser = admin => {
      if (admin === undefined) {
        return null
      }
      const [, emailDomain] = admin.email.split("@")
      return emailDomain
    }
    const adminEmailDomain = emailDomainFromUser(firstAdmin)

    //    const resendCautionModalObject = {
    //      isOpen: true,
    //      heading: "Resend?",
    //      text: "Are you sure you want to resend email invites for this teacher?",
    //      buttonText: "Yes, send",
    //      onClick: this.resendSurvey,
    //    }

    const sortedClasses = sortCurrentClassesByLabel(currentClasses)

    if (teachersData.length < 1 || displayBulkUploadScreen) {
      return (
        <>
          <UploadStart toggleTeacherModal={this.toggleTeacherModal} />
          {teacherModal && (
            <TeacherModal
              classOptions={sortedClasses}
              toggle={this.toggleTeacherModal}
              isOpen
              edit={edit}
              onChange={this.onChange}
              teacher={teacher}
              onAddEditClick={this.teacherAddEdit}
              errors={errors}
              loading={this.state.loading}
              onMultiClassSelectChange={this.onMultiClassSelectChange}
            />
          )}
        </>
      )
    }

    let surveysSent = false // for checking if surveys have been sent (doing together with filtering to limit how many times array is iterated through)
    let allCompleted = true
    let teachers = teachersData // For searching by teacher name

    if (searchValue !== "") {
      teachers = teachersData.filter(t => {
        // For survey checking
        if (isDefinedNotNull(t.survey)) {
          surveysSent = true
          // checks if all the teachers with surveys have completed the survey
          allCompleted = allCompleted && t.survey.completed
        }

        // For Filtering
        const searchString = [t.firstName, t.lastName].join(" ").toLowerCase()
        return searchString.includes(searchValue.toLowerCase())
      })
    } else {
      // check if surveys have been sent
      for (let i = 0; i < teachers.length; i++) {
        if (isDefinedNotNull(teachers[i].survey)) {
          // set surveys sent to true a exit forloop
          surveysSent = true
          if (teachers[i].survey.completed === false) {
            // sets all completed to false
            allCompleted = false
            // exits forloop
            break
          }
        }
      }
    }

    teachers = teachers.map(t => {
      // Check if current teacher has surveys
      const hasSurveys = t.survey !== null
      const classesAssigned =
        hasSurveys && isNonEmptyArray(t.survey.allocatedClasses)
      const surveyCompleted = hasSurveys && t.survey.completed

      let status
      if (surveyCompleted) {
        status = <span className="color-green-light">Completed</span>
      } else if (hasSurveys) {
        status = <span className="color-purple">Sent</span>
      } else {
        status = <span className="color-grey-dark">Not Sent</span>
      }

      const editButton = (
        <span
          className="mr-2 cursor-pointer color-blue-mid u-bold"
          onClick={e => this.onSendClick(e, t, false)}>
          Edit
        </span>
      )

      // NOTE: This is not used at the moment because users are asking for the ability to
      // edit the message, so we are just showing the Edit modal instead.
      // Remove this and dependencies if it turns out that is the way forward.
      //const resendButton = (
      //  <span
      //    className="mr-2 cursor-pointer color-blue-mid u-bold"
      //    onClick={() => this.toggleCautionModal(resendCautionModalObject)}>
      //    Re-send
      //  </span>
      //)

      // They wanted a `resend` button which does the same thing as the
      // Edit button to stop users from asking questions.
      const resendButton = (
        <span
          className="mr-2 cursor-pointer color-blue-mid u-bold"
          onClick={e => this.onSendClick(e, t, true)}>
          Resend
        </span>
      )

      const openSurveyButton = teacher => (
        <span
          className="mr-2 cursor-pointer color-blue-mid u-bold"
          onClick={e => {
            e.stopPropagation()
            this.openLatestSurvey(client, teacher)
          }}>
          View
        </span>
      )

      const closeSurveyButton = teacherId => (
        <span
          className="mr-2 cursor-pointer color-blue-mid u-bold"
          onClick={() =>
            this.toggleCautionModal(this.getCloseSurveyCautionObject(teacherId))
          }>
          Close
        </span>
      )

      let resend
      if (surveyCompleted) {
        resend = <div>{resendButton}</div>
      } else if (classesAssigned) {
        resend = (
          <div>
            {editButton}
            {resendButton}
            {schoolSettings.requireOtp || openSurveyButton(t)}
            {closeSurveyButton(t.id)}
          </div>
        )
      } else {
        resend = <span onClick={e => this.onSendClick(e, t, true)}>New</span>
      }

      // Create the teacher object that related to fields defined in constants/teachers
      let teacherDetail = {
        ...t,
        allClasses: (
          <ClassesList
            classes={t.currentClasses}
            rowId={`allClasses-${t.id}`}
          />
        ),
        status: status,
        resend: resend,
        highlighted: { email: needHighlighting(t.email, adminEmailDomain) },
      }

      if (hasSurveys) {
        return {
          ...teacherDetail,
          date: dateToString(t.survey.updatedAt),
          allocated: (
            <ClassesList
              classes={t.survey.allocatedClasses}
              rowId={`allocatedClasses-${t.id}`}
            />
          ),
        }
      }

      // If no survey object for teacher
      // return the teacher detail
      return teacherDetail
    })

    if (teachers.length < 1) {
      teachers.push({
        firstName: "",
        lastName: "",
        email: "",
      })
    } else {
      sortTeachers(teachers)
    }

    // TODO: change this to a real button
    const closeAllSurveysButton = (
      <span
        className="c-teachers__close-survey"
        onClick={() =>
          this.toggleCautionModal(
            this.getCloseAllSurveysCautionObject(allCompleted)
          )
        }>
        {allCompleted ? "" : "Close all Surveys"}
      </span>
    )
    // Get a map of classes with their allocated teachers
    // { c.id: [t] }
    const classTeacherMap = teachers.reduce((acc, teacher) => {
      const classes = isDefinedNotNull(teacher.survey)
        ? teacher.survey.allocatedClasses
        : []

      return classes.reduce((acc2, c) => {
        return {
          ...acc2,
          [c.id]: [...(acc2[c.id] || []), teacher],
        }
      }, acc)
    }, {})

    const sortedCurrentClasses = sortedClasses
      // add the allocated teachers
      .map(c => ({ ...c, teachers: classTeacherMap[c.id] || [] }))

    const additionalActions = [
      {
        onClick: () => this.props.navigate("/Teachers/Upload"),
        text: "Bulk Import Teachers",
        icon: "fa-upload",
      },
      {
        onClick: this.csvExport,
        text: "Export all Teachers",
        icon: "fa-download",
      },
      {
        onClick: () => this.setState({ showDeleteAllTeachers: true }),
        text: "Remove all Teachers",
        icon: "fa-trash",
      },
    ]

    return (
      <div className="c-teachers u-row-fix">
        {schoolHasBeenRolledOver && <RolledOverNote />}
        <div>
          <ActionBar
            addText="Add Teacher"
            onAddClick={this.toggleTeacherModal}
            searchValue={searchValue}
            adminMode={adminMode}
            onSearchChange={this.onSearchChange}
            searchPlaceholder="Search teachers..."
            additionalActions={additionalActions}
          />
          {surveysSent ? (
            <div className="c-teachers__table-container">
              <TeachersTable
                adminEmailDomain={adminEmailDomain}
                teachers={teachers}
                closeSurveysButton={closeAllSurveysButton}
                onRowClick={this.onTeacherClick}
                onSendMultipleSurveysClick={this.toggleInviteTeachersModal}
                surveysSent={true}
              />
              <CautionModal
                isOpen={cautionModal.isOpen}
                toggle={this.toggleCautionModal}
                heading={cautionModal.heading}
                text={cautionModal.text}
                buttonText={cautionModal.buttonText}
                onButtonClick={cautionModal.onClick}
                error={errors.mutation}
              />
              <SuccessModal
                isOpen={successModal}
                toggle={this.toggleSuccessModal}
                heading="Survey invite sent to Teacher"
                text="A copy of the invite is also sent to Administrators."
              />
            </div>
          ) : (
            <div className="d-flex">
              <div className="c-teachers__table-container">
                <TeachersTable
                  adminEmailDomain={adminEmailDomain}
                  teachers={teachers}
                  closeSurveysButton={closeAllSurveysButton}
                  onRowClick={this.onTeacherClick}
                  onSendMultipleSurveysClick={this.toggleInviteTeachersModal}
                  surveysSent={false}
                />
              </div>
              <InviteSurvey
                toggle={this.toggleInviteTeachersModal}
                navToCharacteristics={this.navToCharacteristics}
              />
            </div>
          )}

          <div className="u-total-text mt-2 ml-2">
            Total - {teachersData.length} Teachers
          </div>
          <div>
            {inviteTeachersModal && (
              <InviteTeachersModal
                classes={sortedCurrentClasses}
                teachers={teachers}
                toggle={this.toggleInviteTeachersModal}
                inviteMessage={inviteMessage}
                isOpen
                ccList={this.ccList()}
                schoolId={schoolId}
                feederSchool={schoolSettings.feederSchool}
              />
            )}
            {inviteSingleTeacherModal && (
              <InviteSingleTeacherModal
                classes={sortedCurrentClasses}
                toggle={this.toggleInviteSingleTeacherModal}
                inviteMessage={inviteMessage}
                changeInviteMessage={this.changeInviteMessage}
                teacher={teacher}
                ccList={this.ccList()}
                schoolId={schoolId}
                feederSchool={schoolSettings.feederSchool}
                sendNotification={sendNotification}
              />
            )}
            {/* Must do this to stop it showing up when clicking resend, but cannot stopPropagation as the data is needed */}
            {!cautionModal.isOpen && (
              <TeacherModal
                classOptions={sortedCurrentClasses}
                currentClasses={currentClasses}
                toggle={this.toggleTeacherModal}
                isOpen={teacherModal}
                edit={edit}
                onChange={this.onChange}
                onMultiClassSelectChange={this.onMultiClassSelectChange}
                teacher={teacher}
                onAddEditClick={this.teacherAddEdit}
                onDeleteTeacherClick={this.toggleDeleteModal}
                errors={errors}
                loading={this.state.loading}
              />
            )}
          </div>
        </div>

        <DeleteModal
          toggle={this.toggleDeleteModal}
          isOpen={deleteModal}
          loading={showComponentLoading}
          heading="Delete Teacher?"
          text="Deleting a current teacher will also delete any Requests associated with this teacher, unassign this teacher from any classes they have been assigned to next year, and remove the ability for this teacher to complete surveys about students."
          onButtonClick={this.deleteTeacher}
          error={errors.delete}
        />
        {showDeleteAllTeachers && (
          <DangerousMutation
            dangerousMutation={deactivateAllTeachersMutation}
            cautionHeading="Remove all Teachers"
            cautionText={
              <span>
                Deleting teachers will also delete any Requests associated with
                the teachers, unassign the teachers from any classes they have
                been assigned to next year, and remove the ability for the
                teachers to complete surveys about students.
                <br />
                <br />
                You can not 'undo' this operation. Are you sure you would like
                to remove all the teachers?
              </span>
            }
            cautionButtonText="Yes, Proceed"
            successMessage="All teachers have been removed."
            errorMessage="Could not remove data"
            toggleComponent={() =>
              this.setState({ showDeleteAllTeachers: false })
            }
            showSuccess={false}
          />
        )}
      </div>
    )
  }
}

export const Teachers = props => {
  const navigate = useNavigate()
  const location = useLocation()
  return <TeacherComponent {...props} location={location} navigate={navigate} />
}
