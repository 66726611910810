import React from "react"
import { useMutation } from "@apollo/client"
import { signupMutation, updateEmailMutation } from "domains/auth/graphql"

import { SignUp } from "domains/auth/components"

import { useNavigate } from "react-router-dom"

export const SignUpPage = () => {
  const [signUp] = useMutation(signupMutation)
  const [updateEmail] = useMutation(updateEmailMutation)
  const navigate = useNavigate()
  return (
    <SignUp
      signupMutation={signUp}
      updateEmail={updateEmail}
      navigate={navigate}
    />
  )
}
