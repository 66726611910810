import React from "react"
import { Tooltip } from "react-tooltip"
import { Row, FormGroup, Label, Input, InputGroup } from "reactstrap"
import Select from "react-select"
import { InlineError, defaultSelectStyles } from "components"
import { isSystemGenerated } from "util/teacherUtil"

export const TeacherInputs = ({
  firstName,
  lastName,
  email,
  currentClassesToUpdate,
  classOptions,
  onMultiClassSelectChange,
  onChange,
  errors,
  editMode,
}) => {
  const showInfoIcon = currentClassesToUpdate.length === 0

  const classOptionsList = classOptions
    ? classOptions.map(c => {
        return {
          value: c.id,
          label: c.label,
        }
      })
    : []

  const currentClassesToSet = currentClassesToUpdate
    ? currentClassesToUpdate.map(c => {
        return {
          value: c.value,
          label: c.label,
        }
      })
    : []

  const isSystemGeneratedEmail = isSystemGenerated(email)
  return (
    <div className="container-fluid">
      <Row>
        <FormGroup className="col sm-6 pl-0">
          <Label>First Name</Label>
          <Input
            type="text"
            placeholder="First Name"
            value={firstName}
            name="firstName"
            onChange={onChange}
            maxLength={35}
          />
          {errors.firstName && <InlineError text={errors.firstName} />}
        </FormGroup>
        <FormGroup className="col sm-6 pl-0">
          <Label>Last Name</Label>
          <Input
            type="text"
            placeholder="Last Name"
            value={lastName}
            name="lastName"
            onChange={onChange}
            maxLength={35}
          />
          {errors.lastName && <InlineError text={errors.lastName} />}
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6 pl-0">
          <Label>Email Address</Label>
          <Input
            className={
              isSystemGeneratedEmail
                ? "c-teacher-modal-details__teacher-email-generated"
                : ""
            }
            type="text"
            placeholder="Email Address"
            value={email}
            name="email"
            onChange={onChange}
            maxLength={100}
          />
          <span className="u-text-12 u-grey-mid">
            {isSystemGeneratedEmail &&
              "[System-generated Email] - Please update with the correct email when available"}
          </span>
          {email.length === 0 && editMode && (
            <InlineError text="Teacher email cannot be empty" />
          )}
          {errors.email && <InlineError text={errors.email} />}
        </FormGroup>
        <FormGroup className="col sm-6 pl-0">
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <Label>{"Current Class/s (Current School Year)"}</Label>

            {showInfoIcon && (
              <>
                <i
                  className="fa fa-info-circle color-blue-mid m-2"
                  data-tooltip-id="optional-info"
                />
                <Tooltip
                  className="c-badge-tooltip"
                  style={{ zIndex: 100 }}
                  id="optional-info"
                  variant="light"
                  opacity={1}>
                  <p>
                    This drop-down list is automatically created off the Current
                    Class column when students are imported. 
                  </p>
                  <p>
                    For example, if there were no students imported with the
                    Current Class “1A” then this class will not be shown in the
                    drop-down list.
                  </p>
                </Tooltip>
              </>
            )}
          </div>
          <InputGroup>
            <React.Fragment>
              <Select
                className="c-input__multiselect"
                placeholder={`Select classes`}
                options={classOptionsList}
                value={currentClassesToSet}
                onChange={onMultiClassSelectChange}
                closeMenuOnSelect={false}
                isMulti
                name="currentClasses"
                styles={defaultSelectStyles}
              />
              {errors.schoolGrades && (
                <InlineError text={errors.schoolGrades} />
              )}
            </React.Fragment>
          </InputGroup>
        </FormGroup>
      </Row>
    </div>
  )
}
