import React, { useState } from "react"

import { Loader, AdminList, DeleteModal } from "components"
import { InviteUsersModal } from "domains/accountSettings/components"
import { removeSchoolId } from "util/app"
import { isPrivilegedRole } from "util/userUtil"

export const Administrators = ({
  apolloClient,
  loading,
  errors,
  users,
  userRefetchQueries,
  currentSchool,
  invitations,
  revokeSchoolInvitationMutation,
  removeUserFromSchoolsMutation,
  myprofile,
}) => {
  const [inviteUserModal, setInviteUserModal] = React.useState(false)

  const [userId, setUserId] = useState()
  const [removeAdminModal, setRemoveAdminModal] = React.useState(false)

  const [inviteId, setInviteId] = useState()
  const [revokeInvitationModal, setRevokeInvitationModal] =
    React.useState(false)

  const onRemoveAdminClick = user => {
    setUserId(user.id)
    setRemoveAdminModal(true)
  }

  const removeUserFromSchool = () => {
    removeUserFromSchoolsMutation({
      variables: {
        userId,
        schoolIds: [currentSchool.id],
      },
      refetchQueries: userRefetchQueries,
    }).then(() => {
      setUserId()
      setRemoveAdminModal(false)
      if (myprofile.id === userId) {
        removeSchoolId()
        // We get the browser to navigate instead of React Router
        // because we want the state to be clean. Using React Router
        // will have stale queries which still has the old schoolId as a variable.
        window.location.href = "/"
      }
    })
  }

  const onRevokeInvitationClick = invitation => {
    setInviteId(invitation.id)
    setRevokeInvitationModal(true)
  }

  const revokeInvitation = () => {
    revokeSchoolInvitationMutation({
      variables: { inviteId },
      refetchQueries: userRefetchQueries,
      onError: () => {
        // If have have any errors revoking an invitation (e.g. the invitation has already been deleted in the database)
        // we should refetch the user queries to get the latest data.
        // However, Apollo by default only refetches after a *successful* mutation so we need to manually do it
        // in this `onError` callback. See https://github.com/apollographql/apollo-feature-requests/issues/288
        apolloClient.refetchQueries({ include: userRefetchQueries })
        setRevokeInvitationModal(false)
      },
    }).then(() => {
      setInviteId()
      setRevokeInvitationModal(false)
    })
  }

  const canAddAdmin =
    isPrivilegedRole(myprofile.role) || currentSchool.adminCanInviteUser

  return (
    <div className="u-content-border-bottom">
      <h2 className="mb-5 mt-5 u-blue-primary">Administrators</h2>
      <div className="p-2 pb-5">
        {loading ? (
          <div className="position-relative">
            <Loader />
          </div>
        ) : (
          <AdminList
            users={users}
            errors={errors}
            invitations={invitations}
            allowRemove
            allowEdit={false}
            onAddAdminClick={() => setInviteUserModal(true)}
            onRevokeInvitationClick={onRevokeInvitationClick}
            onRemoveAdminClick={onRemoveAdminClick}
            allowAdd={canAddAdmin}
          />
        )}
      </div>
      <div>
        {inviteUserModal && (
          <InviteUsersModal
            toggle={() => setInviteUserModal(false)}
            school={currentSchool}
            refetchQueries={userRefetchQueries}
          />
        )}
        {removeAdminModal && (
          <DeleteModal
            isOpen
            toggle={() => setRemoveAdminModal(false)}
            heading="Remove Administrator?"
            text="This administrator will no longer be able to access this account. If the Administrator is linked to other Class Solver accounts, they will still be able to access them."
            buttonText="Yes, remove"
            onButtonClick={removeUserFromSchool}
          />
        )}
        {revokeInvitationModal && (
          <DeleteModal
            isOpen
            toggle={() => setRevokeInvitationModal(false)}
            heading="Remove Invitation?"
            text="The invitation for this administrator will be removed. You can send a new invitation by clicking on 'Add new administrator'"
            buttonText="Yes, remove"
            onButtonClick={revokeInvitation}
          />
        )}
      </div>
    </div>
  )
}
