import React, { Component } from "react"
import { Modal, Row, Col, Input, Label } from "reactstrap"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { schoolListQuery } from "domains/accountSettings/graphql"

import { Loader, ModalHeader } from "components"

import { validateNonEmptyString } from "util/validators"

class SchoolListModalComponent extends Component {
  state = {
    search: "",
  }

  onChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value,
    })
  }

  render() {
    const { isOpen, toggle, onSchoolClick, loading, schools, isSuper } =
      this.props
    const { search } = this.state

    let filteredSchools = []
    if (schools) {
      filteredSchools = Array.from(schools)

      if (validateNonEmptyString(search)) {
        filteredSchools = filteredSchools.filter(school => {
          const searchString = [
            school.id,
            school.name,
            school.stateCode,
            school.suburb,
            school.crm_account_name,
          ]
            .join(" ")
            .toLowerCase()

          return searchString.includes(search.toLowerCase())
        })
      }

      filteredSchools.sort((a, b) => a.id - b.id)
    }

    return (
      <Modal isOpen={isOpen}>
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <div>
            <ModalHeader
              title="School Clients"
              toggle={toggle}
              className="p-3"
            />
            <div className="modal-body">
              <div className="container-fluid">
                <Row className="mt-2 mb-4">
                  <Label>Search</Label>
                  <Input
                    placeholder="Search Schools"
                    type="text"
                    name="search"
                    value={search}
                    onChange={this.onChange}
                  />
                </Row>
                <Row>
                  <Col className="title-border-underline">
                    <strong className="pb-1 pl-3">School Name</strong>
                  </Col>
                  {isSuper && (
                    <Col className="title-border-underline">
                      <strong className="pb-1 pl-3">CRM_Name</strong>
                    </Col>
                  )}
                </Row>
                {filteredSchools.map((schoolDetails, index) => {
                  const { id, stateCode, suburb, name, crm_account_name } =
                    schoolDetails

                  return (
                    <a
                      key={index}
                      onClick={() => {
                        onSchoolClick(id, name)
                      }}>
                      <Row className="school-border-underline">
                        <Col>
                          <div className="py-2 pl-3 u-white-space-pre-wrap">
                            {id} - {stateCode}, {suburb} - {name}
                          </div>
                        </Col>
                        <Col>
                          {isSuper && (
                            <div className="py-2 pl-3 u-white-space-pre-wrap">
                              {crm_account_name}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

export const SchoolListModal = compose(
  graphql(schoolListQuery, {
    options: () => ({
      fetchPolicy: "network-only",
    }),
    props: ({ data: { loading, schools } }) => ({
      loading,
      schools,
    }),
  })
)(SchoolListModalComponent)
