import React from "react"
import { Tooltip } from "react-tooltip"

import { ClassBadge } from "components"

export const ClassesList = ({ classes, rowId }) => {
  // Build the allocated classes
  const classesArray = []
  for (let i = 0; i < classes.length; i++) {
    // only show a max of three classes
    if (classesArray.length < 2) {
      classesArray.push(
        <ClassBadge key={i} className="mr-2" label={classes[i].label} />
      )
    } else {
      const tooltipKey = `${rowId}-info`
      const tooltipInfo = classes.map(c => {
        return <p key={c.label}>{c.label}</p>
      })
      classesArray.push(
        <div key="more">
          <span
            className="mr-2 badge-extra"
            key={i}
            data-tooltip-id={tooltipKey}>
            <span>{`+${classes.length - 2}`}</span>
          </span>
          <Tooltip
            className="c-badge-tooltip"
            style={{ zIndex: 100 }}
            id={tooltipKey}
            variant="light"
            opacity={1}>
            {tooltipInfo}
          </Tooltip>
        </div>
      )
      // exit loop
      break
    }
  }

  return <div className="d-flex">{classesArray}</div>
}
