import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { characteristicsQuery } from "domains/characteristics/graphql"
import {
  directlyImportStudentFileMutation,
  directlyImportTeacherFileMutation,
  directlyImportTeacherRequestsFileMutation,
  directlyImportStudentRequestsFileMutation,
  directlyImportCharacteristicResponsesFileMutation,
  directlyImportFriendshipFileMutation,
  directlyImportSolutionFileMutation,
  directlyImportSchoolCharacteristicsFileMutation,
  directlyImportNewClassesFileMutation,
  importChangeStudentCodesFileMutation,
  importChangeTeacherEmailsFileMutation,
  importDeleteStudentsFileMutation,
  mutationsIntrospectionQuery,
  syncStudentsFromFileMutation,
} from "domains/upload/graphql"
import { Form, FormGroup, Button } from "reactstrap"
import { getFormattedDescription } from "util/apiUtil"

import { Label, Input, Modal, ModalBody } from "reactstrap"
import { ModalHeader, InlineError } from "components"
import {
  ADD_NEW,
  DELETE_MISSING,
  UPDATE_EXISTING,
} from "domains/accountSettings/constants"

const ImportFile = ({
  title,
  description,
  mutation,
  schoolId,
  refetchQuery,
  additionalRetryVariables,
}) => {
  // inputKey allows us to reset the state of the file input
  const [inputKey, setInputKey] = useState(Date.now())
  const resetFileInput = () => setInputKey(Date.now())
  const fileInput = React.createRef()

  const [showDetails, setShowDetails] = useState(false)

  const [doMutation, { data, error }] = useMutation(mutation, {
    onError: () => {
      additionalRetryVariables || resetFileInput()
    },
    onCompleted: () => {
      resetFileInput()
    },
    refetchQueries: [{ query: refetchQuery, variables: { schoolId } }],
  })

  const onSubmit = e => {
    e.preventDefault()
    const uploadedFile = fileInput.current.files[0]
    if (uploadedFile) {
      doMutation({ variables: { schoolId, file: uploadedFile } })
    }
  }

  const retryMutation = () => {
    const uploadedFile = fileInput.current.files[0]
    const normalVariables = { schoolId, file: uploadedFile }
    const variables = additionalRetryVariables
      ? { ...normalVariables, ...additionalRetryVariables }
      : normalVariables

    if (uploadedFile) {
      doMutation({
        variables,
        onError: () => {
          resetFileInput()
        },
      })
    }
  }

  const formatError = error => {
    return (
      <>
        {additionalRetryVariables && (
          <Button
            color="link"
            className="c-button--secondary mb-3"
            onClick={retryMutation}>
            Ignore error and retry
          </Button>
        )}
        <div>{error.message}</div>
        <div>Details:</div>
        <pre>{JSON.stringify(error.graphQLErrors[0].details, null, 2)}</pre>
      </>
    )
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-4">
        <div>
          <div>{title}</div>

          <div
            className="cursor-pointer"
            onClick={() => setShowDetails(!showDetails)}>
            {showDetails ? (
              <>
                <i className="fa fa-minus text-primary mr-2" />
                <span className="text-primary">Less details</span>
              </>
            ) : (
              <>
                <i className="fa fa-plus text-primary mr-2" />
                <span className="text-primary">More details</span>
              </>
            )}
          </div>
          {showDetails && <div className="border">{description}</div>}
        </div>
        <Form onSubmit={onSubmit}>
          <FormGroup className="mb-1">
            <input
              style={{ width: "15rem" }}
              key={inputKey}
              type="file"
              name="file"
              ref={fileInput}
            />
          </FormGroup>
          <Button className="c-button--secondary ">Upload</Button>
        </Form>
      </div>
      {error && <InlineError className="mx-3" text={formatError(error)} />}
      {data && <span className="text-success mx-3">Success!</span>}
    </div>
  )
}

const SyncStudentFile = ({ schoolId }) => {
  const { data: introspectionData, loading } = useQuery(
    mutationsIntrospectionQuery
  )
  const [showSyncStudentModal, setShowSyncStudentModal] = useState(false)
  const toggleSyncStudentModal = () =>
    setShowSyncStudentModal(!showSyncStudentModal)

  const [addNew, setAddNew] = useState(true)
  const [deleteMissing, setDeleteMissing] = useState(true)
  const [updateExisting, setUpdateExisting] = useState(false)

  // inputKey allows us to reset the state of the file input
  const [inputKey, setInputKey] = useState(Date.now())
  const resetFileInput = () => setInputKey(Date.now())
  const [showDetails, setShowDetails] = useState(false)
  const fileInput = React.createRef()

  const [
    syncStudentsFromFile,
    { data: syncStudentData, error: syncStudentError },
  ] = useMutation(syncStudentsFromFileMutation, {
    onError: () => {
      toggleSyncStudentModal()
      resetFileInput()
    },
    onCompleted: () => {
      toggleSyncStudentModal()
      resetFileInput()
    },
  })

  if (loading) return null

  const syncStudents = () => {
    let syncActions = []
    if (addNew) syncActions.push(ADD_NEW)
    if (deleteMissing) syncActions.push(DELETE_MISSING)
    if (updateExisting) syncActions.push(UPDATE_EXISTING)

    const variables = {
      schoolId,
      file: fileInput.current.files[0],
      syncActions,
    }

    syncStudentsFromFile({ variables })
  }

  const setSyncOption = e => {
    if (e.target.name === ADD_NEW) {
      setAddNew(e.target.checked)
    } else if (e.target.name === DELETE_MISSING) {
      setDeleteMissing(e.target.checked)
    } else if (e.target.name === UPDATE_EXISTING) {
      setUpdateExisting(e.target.checked)
    }
  }

  const description = getFormattedDescription(
    introspectionData,
    "syncStudentsFromFile"
  )

  const openSyncStudentModal = e => {
    e.preventDefault()
    const file = fileInput.current.files[0]
    if (file) {
      setShowSyncStudentModal(true)
    }
  }

  const formatError = error => {
    return (
      <>
        <div>{error.message}</div>
        <div>Details:</div>
        <pre>{JSON.stringify(error.graphQLErrors[0].details, null, 2)}</pre>
      </>
    )
  }

  return (
    <>
      <div>
        <div className="d-flex justify-content-between mb-4">
          <div>
            <div>Sync Students from file</div>

            <div
              className="cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? (
                <>
                  <i className="fa fa-minus text-primary mr-2" />
                  <span className="text-primary">Less details</span>
                </>
              ) : (
                <>
                  <i className="fa fa-plus text-primary mr-2" />
                  <span className="text-primary">More details</span>
                </>
              )}
            </div>
            {showDetails && <div className="border">{description}</div>}
          </div>
          <Form onSubmit={openSyncStudentModal}>
            <FormGroup className="mb-1">
              <input
                style={{ width: "15rem" }}
                key={inputKey}
                type="file"
                name="file"
                ref={fileInput}
              />
            </FormGroup>
            <Button className="c-button--secondary ">Upload</Button>
          </Form>
        </div>
        {syncStudentError && (
          <InlineError className="mx-3" text={formatError(syncStudentError)} />
        )}
        {syncStudentData && <span className="text-success mx-3">Success!</span>}
      </div>
      {showSyncStudentModal && (
        <Modal isOpen className="modal-dialog-centered u-modal__w-40rem">
          <ModalHeader
            className="p-4"
            title="Sync students from CSV file"
            toggle={toggleSyncStudentModal}
          />
          <ModalBody className="px-4 d-flex flex-column align-items-center">
            <h2 className="mb-3">Please select the options for this sync:</h2>
            <div className="px-5">
              <Label key={ADD_NEW} className="m-3">
                <Input
                  type="checkbox"
                  id={ADD_NEW}
                  name={ADD_NEW}
                  checked={addNew}
                  onChange={setSyncOption}
                />
                <div>Add students</div>
                <div>
                  Students which are in the file but not in the system will be
                  added
                </div>
              </Label>

              <Label key={DELETE_MISSING} className="m-3">
                <Input
                  type="checkbox"
                  id={DELETE_MISSING}
                  name={DELETE_MISSING}
                  checked={deleteMissing}
                  onChange={setSyncOption}
                />
                <div>Deactivate students</div>
                <div>
                  Students which are in the system but not in the file will be
                  deactivated
                </div>
              </Label>

              <Label key={UPDATE_EXISTING} className="m-3">
                <Input
                  type="checkbox"
                  id={UPDATE_EXISTING}
                  name={UPDATE_EXISTING}
                  checked={updateExisting}
                  onChange={setSyncOption}
                />
                <div>Update students</div>
                <div>Students which are in both the file and system</div>
                will be updated
              </Label>
            </div>

            <Button
              color="primary"
              className="mt-5 w-50"
              onClick={syncStudents}>
              Sync Students
            </Button>

            <span
              className="text-primary p-3 pl-5 pr-5 mt-2 cursor-pointer"
              onClick={toggleSyncStudentModal}>
              Cancel
            </span>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export const ImportDataFiles = ({ schoolId }) => {
  const { data, loading } = useQuery(mutationsIntrospectionQuery)
  if (loading) return null

  return (
    <div className="u-content-border-bottom pb-5">
      <h2 className="mb-5 mt-5 u-blue-primary">Import CSV files</h2>
      <SyncStudentFile schoolId={schoolId} />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportStudentFileMutation}
        title="Import Student"
        description={getFormattedDescription(data, "directlyImportStudentFile")}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportTeacherFileMutation}
        title="Import Teacher CSV"
        description={getFormattedDescription(data, "directlyImportTeacherFile")}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportTeacherRequestsFileMutation}
        title="Import Teacher Requests CSV"
        description={getFormattedDescription(
          data,
          "directlyImportTeacherRequestsFile"
        )}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportStudentRequestsFileMutation}
        title="Import Student Requests CSV"
        description={getFormattedDescription(
          data,
          "directlyImportStudentRequestsFile"
        )}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportCharacteristicResponsesFileMutation}
        title="Import Characteristic Responses CSV"
        description={getFormattedDescription(
          data,
          "directlyImportCharacteristicResponsesFile"
        )}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportFriendshipFileMutation}
        title="Import Friendship CSV"
        description={getFormattedDescription(
          data,
          "directlyImportFriendshipFile"
        )}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportSolutionFileMutation}
        title="Import Solution CSV"
        description={getFormattedDescription(
          data,
          "directlyImportSolutionFile"
        )}
      />

      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportNewClassesFileMutation}
        title="Import Classes CSV"
        description={getFormattedDescription(
          data,
          "directlyImportNewClassesFile"
        )}
      />

      <ImportFile
        schoolId={schoolId}
        mutation={importChangeStudentCodesFileMutation}
        title="Import Change Student Codes CSV"
        description={getFormattedDescription(
          data,
          "importChangeStudentCodesFile"
        )}
        additionalRetryVariables={{ validateStudentCodes: false }}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={importChangeTeacherEmailsFileMutation}
        title="Import Change Teacher Emails CSV"
        description={getFormattedDescription(
          data,
          "importChangeTeacherEmailsFile"
        )}
      />
      <ImportFile
        schoolId={schoolId}
        mutation={importDeleteStudentsFileMutation}
        title="Import Delete Students CSV"
        description={getFormattedDescription(data, "importDeleteStudentsFile")}
      />

      <ImportFile
        schoolId={schoolId}
        mutation={directlyImportSchoolCharacteristicsFileMutation}
        title="Import SC Settings CSV"
        description={getFormattedDescription(
          data,
          "directlyImportSchoolCharacteristicsFile"
        )}
        refetchQuery={characteristicsQuery}
      />
    </div>
  )
}
