import * as React from "react"
import { Button, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { InfoNote, VideoModal } from "components"

import ExcelIcon from "images/excel-icon.svg"
import { useCreateBulkUploadJob } from "domains/upload/hooks/useCreateBulkUploadJob"

import teacherTemplate from "templateFiles/teachers_template.csv"

import { UploadErrorModal } from "domains/upload"

import { warnings } from "domains/students/upload/warnings"

import { getSchoolId } from "util/app"

import { INSTRUCTION_ADD_TEACHERS_PLAYER_URL } from "constants/videos"

export const UploadStart = ({ toggleTeacherModal }) => {
  // Keep a piece of state which equals the file which the user selected
  const [selectedFile, setSelectedFile] = React.useState(undefined)

  const [showHelpVideo, setShowHelpVideo] = React.useState(false)

  // Bring in a hook which allows us to create a new upload job
  const [createUploadJob, { loading }] = useCreateBulkUploadJob()

  // Utility for manipulating the router
  const navigate = useNavigate()

  // Handle when a user selects a new file by updating state accordingly
  const handleFileSelect = event => {
    setSelectedFile(event.target.files[0])
  }

  // State to hold onto create job errors
  const [createJobError, setCreateJobError] = React.useState()

  const [warningCode, warningDetails, errorJobId] =
    createJobError && createJobError.graphQLErrors.length > 0
      ? [
          createJobError.graphQLErrors[0].message,
          createJobError.graphQLErrors[0].details,
          createJobError.graphQLErrors[0].job_id,
        ]
      : [undefined, undefined, undefined]

  // Handle when the user clicks the 'Confirm and Upload' button
  const handleConfirm = React.useCallback(() => {
    // Attempt to create the new upload job. On success, redirect to the next
    // screen or show the user an error

    return createUploadJob({
      variables: {
        file: selectedFile,
        schoolId: getSchoolId(),
        type: "TEACHER",
      },
    })
      .then(result => {
        if (result.data && result.data.createBulkUploadJob) {
          const jobId = result.data.createBulkUploadJob.id
          navigate("/Teachers/MapColumns", {
            state: { jobId: jobId },
          })
        } else {
          // TODO: Show some sort of error
        }
      })
      .catch(error => {
        setCreateJobError(error)
      })
  }, [createUploadJob, selectedFile, navigate])

  // Go back to Teachers page
  const handleBackToTeachersPage = () => navigate("/Teachers")

  const requiredHeaders = "First Name,Surname,Email,Current Class 1"

  return (
    <>
      <div className="row m-4">
        <h1 className="mx-4">Import Teachers</h1>
        <div>
          <a
            className="color-blue-mid mx-4 mt-1 font-size-14"
            onClick={() => setShowHelpVideo(true)}>
            <i className="fa fa-play mr-2 pl-2" />
            Watch Video
          </a>
        </div>
      </div>

      <div className="c-solver u-row-fix u-layout-component-grow ">
        <div className="c-solver__classes-container container-fluid ">
          <div className="container-fluid d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
              <img height="150px" src={ExcelIcon} alt="Excel Icon" />

              {/* A file has been selected, so let's render the confirmation interface */}
              {selectedFile ? (
                <>
                  <h2 className="m-2">
                    3. You have selected the following file to upload:
                  </h2>

                  <div className="m-2 row flex-column w-75 align-items-center">
                    <div className="m-2 u-text-20 u-bold">
                      {selectedFile.name}
                    </div>
                    <button
                      onClick={handleConfirm}
                      disabled={loading}
                      className="btn btn-primary m-2">
                      Confirm and Upload
                    </button>
                    <input
                      type="file"
                      id="fileUpload"
                      name="fileUpload"
                      onChange={handleFileSelect}
                      hidden
                    />
                    <label
                      htmlFor="fileUpload"
                      className="btn btn-secondary m-2">
                      Choose Another File
                    </label>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={handleBackToTeachersPage}>
                      {"< Back to Teachers page"}
                    </button>
                    <InfoNote className="my-3" heading="Important Tips">
                      Please ensure your file contains column headings.
                      <p
                        className="mt-2 mb-0 text-uppercase"
                        style={{ fonSize: "12px" }}>
                        Required Column Headers
                      </p>
                      <pre className="mb-2">
                        {requiredHeaders.replaceAll(",", ", ")}
                      </pre>
                      If you are using a Mac, please save the file format as CSV
                      UTF-8 (.csv)
                    </InfoNote>
                    {createJobError && (
                      <UploadErrorModal
                        isOpen
                        title={warnings[warningCode].title}
                        type="Teachers"
                        jobId={errorJobId}
                        toggle={() => setCreateJobError(undefined)}
                        actions={warnings[warningCode].actions()}>
                        {warnings[warningCode].body("Teachers", warningDetails)}
                      </UploadErrorModal>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {/* Render the file picker interface if no file is currently selected */}
                  <h2 className="my-2 text-center">
                    1. Download and complete the teachers template
                  </h2>

                  <p className="py-2 text-center">
                    <a
                      href={teacherTemplate}
                      download={`teachers_template.csv`}>
                      <Button className="btn-200-width" color="primary">
                        Download Template
                      </Button>
                    </a>
                  </p>

                  <h2 className="my-2 text-center">
                    2. Then upload the completed file
                  </h2>

                  <input
                    type="file"
                    id="teacherCSV"
                    name="teacherCSV"
                    onChange={handleFileSelect}
                    hidden
                  />

                  <p className="py-2 mb-0 text-center">
                    <a href="#">
                      <Label
                        htmlFor="teacherCSV"
                        className="btn-200-width btn-secondary">
                        Upload Completed File
                      </Label>
                    </a>
                  </p>

                  {toggleTeacherModal ? (
                    <span className="m-2">
                      &nbsp; Alternatively, you can also&nbsp;
                      <span
                        className="text-primary cursor-pointer"
                        onClick={toggleTeacherModal}>
                        Add Teachers Manually
                      </span>
                    </span>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={handleBackToTeachersPage}>
                      {"< Back to Teachers page"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showHelpVideo && (
        <VideoModal
          toggle={() => setShowHelpVideo(false)}
          videoUrl={INSTRUCTION_ADD_TEACHERS_PLAYER_URL}
          title="Import Teachers"
        />
      )}
    </>
  )
}
