import React from "react"

export const Toggle = ({
  className = "",
  nameForId,
  trueValue,
  falseValue,
  changeFunction,
  defaultState,
  fullWidth,
}) => (
  <div className={`c-toggle ${fullWidth && "w-100"} ${className}`}>
    <input
      type="checkbox"
      checked={defaultState === true}
      name={nameForId}
      onChange={changeFunction}
      value={defaultState}
      className="c-toggle__checkbox"
      id={nameForId}
    />
    <label className="c-toggle__label" htmlFor={nameForId}>
      <div className="c-toggle__on">{trueValue}</div>
      <div className="c-toggle__off">{falseValue}</div>
    </label>
  </div>
)
