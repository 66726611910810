import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { currentSchoolGradesQuery } from "domains/classes/graphql"

import { GradeSystem } from "domains/students/components"

import {
  schoolUploadsQuery,
  updateBulkUploadJob,
  updateStudentImportMatchers,
} from "domains/upload/graphql"
import { getSchoolId } from "util/app"
import { Loader } from "components"
import { pendingImport } from "../pendingImport"
import { Navigate } from "react-router-dom"
import { queriesAreLoading } from "util/app"

const GradeSystemPageComponent = props => {
  if (queriesAreLoading(props)) return <Loader />

  const { job: currentUploadJob } = pendingImport(
    props.schoolUploads,
    "STUDENT"
  )

  if (!currentUploadJob) {
    return <Navigate to="/Students" replace />
  }

  return <GradeSystem {...props} currentUploadJob={currentUploadJob} />
}

export const GradeSystemPage = compose(
  graphql(schoolUploadsQuery, {
    options: () => ({
      fetchPolicy: "network-only",
      errorPolicy: "all",
      variables: { schoolId: getSchoolId() },
    }),
    name: "schoolUploads",
  }),
  graphql(updateStudentImportMatchers, { name: "updateStudentImportMatchers" }),
  graphql(currentSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeNew: true,
        includeLeaving: true,
      },
    }),
    name: "currentSchoolGrades",
  }),
  graphql(updateBulkUploadJob, { name: "updateBulkUploadJob" })
)(GradeSystemPageComponent)
