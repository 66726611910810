import React from "react"
import { Input } from "reactstrap"

import { InlineError } from "components"

import {
  NORMAL,
  SHARED_WRITABLE,
  SHARED_READ_ONLY,
} from "domains/solver/constants"

import { SolutionsDropDown } from "domains/solver/components"

import * as resolverTypes from "constants/resolverTypes"

export const ActionBar = ({
  canUndo,
  canRedo,
  onUndoRedoClick,
  asyncSolveInProgress,
  activeGradeId,
  onActiveGradeChange,
  solverGrades,
  toggle,
  errors,
  hasSolution,
  solverMode,
  hasAsyncSolveInProgress,
  assignments,
  favourites,
  favouriteAnAssignmentMutation,
  unfavouriteAnAssignmentMutation,
  updateFavouritedAssignmentMutation,
  pickAnAssignment,
}) => {
  const [solutionsDropDownOpen, setSolutionsDropDownOpen] =
    React.useState(false)

  const canAccessTimeline = solverMode !== SHARED_READ_ONLY

  const toggleSolutionsDropDown = () => {
    if (solutionsDropDownOpen) {
      setSolutionsDropDownOpen(false)
    } else {
      setSolutionsDropDownOpen(true)
    }
  }

  const undoClassName =
    canUndo && !asyncSolveInProgress
      ? "color-blue-mid cursor-pointer"
      : "color-grey-mid"
  const redoClassName =
    canRedo && !asyncSolveInProgress
      ? "color-blue-mid cursor-pointer"
      : "color-grey-mid"

  const runClassName = hasAsyncSolveInProgress
    ? "color-grey-mid"
    : "color-blue-mid cursor-pointer"

  return (
    <React.Fragment>
      {(solverMode === NORMAL || solverMode === SHARED_WRITABLE) && (
        <div className="c-side-bar__panel__menu justify-content-start d-print-none">
          <div
            className={`c-side-bar__panel__menu__run ${runClassName}`}
            onClick={() => {
              hasAsyncSolveInProgress || toggle()
            }}>
            <i className="fa fa-play mr-2" />
            <span className="d-inline-block">
              {hasSolution ? "Re-Run" : "Run"}
            </span>
          </div>
          <div
            className={`c-side-bar__panel__menu__undo ${undoClassName}`}
            onClick={() => {
              canUndo &&
                !asyncSolveInProgress &&
                onUndoRedoClick(resolverTypes.UNDO)
            }}>
            <i className={`fa fa-undo mr-2`} />
            <span className="d-inline-block">Undo</span>
          </div>
          <div
            className={`c-side-bar__panel__menu__redo ${redoClassName}`}
            onClick={() => {
              canRedo &&
                !asyncSolveInProgress &&
                onUndoRedoClick(resolverTypes.REDO)
            }}>
            <i className={`fa fa-repeat mr-2`} />
            <span className="d-inline-block">Redo</span>
          </div>
        </div>
      )}
      {errors.solverRun && <InlineError text={errors.solverRun} />}
      {errors.move && <InlineError text={errors.move} />}
      {errors.lock && <InlineError text={errors.lock} />}

      <div className="d-flex flex-row">
        <Input
          type="select"
          className="c-side-bar__panel__grade-select"
          name="gradeId"
          value={activeGradeId}
          onChange={e => {
            onActiveGradeChange(e.target.value)
          }}>
          {solverGrades.map((g, index) => (
            <option key={index} value={g.id}>
              {g.label}
            </option>
          ))}
        </Input>
        {canAccessTimeline && (
          <SolutionsDropDown
            solutionsDropDownOpen={solutionsDropDownOpen}
            toggleSolutionsDropDown={toggleSolutionsDropDown}
            assignments={assignments ? assignments : []}
            favourites={favourites ? favourites : []}
            favouriteAnAssignmentMutation={favouriteAnAssignmentMutation}
            unfavouriteAnAssignmentMutation={unfavouriteAnAssignmentMutation}
            updateFavouritedAssignmentMutation={
              updateFavouritedAssignmentMutation
            }
            pickAnAssignment={pickAnAssignment}
            activeGradeId={activeGradeId}
            // PREVIEW FEATURE: This is a beta feature which allows the user to preview an assignment without actually updating the solution
            // assignmentWithClasses={assignmentWithClasses}
          />
        )}
      </div>
    </React.Fragment>
  )
}
