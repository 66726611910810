import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Label } from "reactstrap"
import { UploadLoader, InlineError } from "components"
import { UploadErrorModal } from "domains/upload"
import { useAuth0 } from "domains/auth/auth0Wrapper"

import {
  directlyImportStudentRequestsFileMutation,
  directlyImportTeacherRequestsFileMutation,
} from "domains/upload/graphql"

import ExcelIcon from "images/excel-icon.svg"
import { getSchoolId } from "util/app"
import { internalCsvExport } from "util/exportUtil"
import { useMutation } from "@apollo/client"

const knownWarnings = {
  "duplicate-column-value": {
    body: ({ details }) => {
      const firstColumnName = details.column_key
      const otherColumnName = Object.keys(details.rows[0]).find(
        columnName => columnName !== firstColumnName
      )
      const errorDetails = details.rows
        .map(row => `${row[firstColumnName]},${row[otherColumnName]}`)
        .join("\n")

      return (
        <div>
          <p>
            The following rows were duplicated in your file. Please double check
            and remove them from the CSV file.
          </p>

          <div>
            <pre className="mb-0">{errorDetails}</pre>
          </div>
        </div>
      )
    },
  },
  "file-has-no-data": {
    body: () => (
      <div>
        <p>There is no data to import.</p>
      </div>
    ),
  },
  "student-code-does-not-exist": {
    title: "Student IDs Not Found",
    body: ({ details }) => (
      <div>
        <p>
          The file you uploaded includes requests for students that are not
          listed in Class Solver:
        </p>
        <p>{details.missing_student_codes.join(", ")}</p>

        <p>
          These students need to be added to Class Solver, with their correct
          Student ID, before importing their requests.
        </p>

        <p>
          Would you like to <b>Import</b> the other requests in your file (if
          any) or <b>Cancel</b> the import and add the students or fix the csv
          file?
        </p>
      </div>
    ),
    missingErrorMessage: true,
  },
  "teacher-email-does-not-exist": {
    title: "Teacher Emails Not Found",
    body: ({ details }) => (
      <div>
        <p>
          The file you uploaded includes requests for teachers that are not
          listed in Class Solver:
        </p>
        <p>{details.missing_teacher_emails.join(", ")}</p>

        <p>
          These teachers need to be added to Class Solver, with their correct
          email address, before importing their requests.
        </p>

        <p>
          Would you like to <b>Import</b> the other requests in your file (if
          any) or <b>Cancel</b> the import and add the teachers or fix the csv
          file?
        </p>
      </div>
    ),
    missingErrorMessage: true,
  },
}

const RequestsImportComponent = ({
  buttonImportText,
  buttonExportText,
  exportType,
  genericErrorMessage,
  title,
  loading,
  navigate,
  importRequestsFileMutation,
  children,
}) => {
  const schoolId = getSchoolId()
  const context = useAuth0()

  const [uploadFile, setUploadFile] = React.useState(undefined)
  const [warningModal, setWarningModal] = React.useState(false)
  const [warningCode, setWarningCode] = React.useState()
  const [uploadError, setUploadError] = React.useState()
  const [exportError, setExportError] = React.useState()

  const fileSelect = e => {
    setUploadFile(e.target.files[0])
  }

  const fileUpload = ignoreMissingIndividualsErrors => {
    const variables = {
      file: uploadFile,
      schoolId: getSchoolId(),
      ignoreMissingIndividualsErrors,
    }

    importRequestsFileMutation({ variables })
      .then(() => {
        // This is to navigate back to Account Settings page by navigating backwards by 1 step
        navigate(-1)
        return
      })
      .catch(error => {
        setWarningModal(true)
        setUploadError(error.graphQLErrors && error.graphQLErrors[0])
        setWarningCode(
          error.graphQLErrors.length > 0
            ? error.graphQLErrors[0].message
            : "generic"
        )
      })
  }

  const hasKnownWarning = (warningCode, knownWarnings) =>
    warningCode && knownWarnings && knownWarnings[warningCode]

  const handleCancel = () => {
    setWarningModal(false)
    setUploadError(null)
    setWarningCode(null)
    setUploadFile(undefined)
  }

  const csvExport = async () => {
    setExportError(null)

    const [response, errorMessage] = await internalCsvExport(
      exportType,
      schoolId,
      context,
      false
    )

    if (errorMessage) {
      setExportError(`Error: ${response.status} - ${errorMessage}`)
    }
  }

  const errorModalActions =
    uploadError &&
    knownWarnings[warningCode] &&
    knownWarnings[warningCode].missingErrorMessage
      ? [
          {
            color: "link",
            onClick: handleCancel,
            text: "Cancel",
          },
          {
            color: "primary",
            onClick: () => fileUpload(true),
            text: "Import",
          },
        ]
      : [
          {
            color: "link",
            onClick: handleCancel,
            text: "Cancel",
          },
        ]

  if (loading) return <UploadLoader />

  let content
  if (uploadFile) {
    content = (
      <>
        <h2 className="m-2">
          3. You have selected the following file to import:
        </h2>
        <div className="m-2 row flex-column w-100 align-items-center">
          <div className="m-2 u-text-20 u-bold">{uploadFile.name}</div>
          <div
            className="btn btn-200-width btn-primary m-2"
            onClick={() => fileUpload(false)}>
            Confirm and Import
          </div>
          <input
            type="file"
            id="fileUpload"
            name="fileUpload"
            hidden
            onChange={fileSelect}
          />
          <Label
            for="fileUpload"
            className="btn btn-secondary btn-200-width m-2">
            Choose Another File
          </Label>

          <Button
            color="link"
            onClick={
              // This is to navigate back to Account Settings page by navigating backwards by 1 step
              () => navigate(-1)
            }>
            {"< Back to Settings Page"}
          </Button>
        </div>
      </>
    )
  } else {
    content = (
      <>
        <h2 className="m-2 pt-4">
          1. Go to the account containing the requests and export the request
          file
        </h2>
        <a>
          <Button
            className="btn-200-width"
            color="primary m-2"
            onClick={csvExport}>
            {buttonExportText}
          </Button>
          {exportError && <InlineError className="mx-3" text={exportError} />}
        </a>

        <h2 className="m-2 pt-4">
          2. Go to the destination account and import the request file
        </h2>

        <div className="py-2">
          <input
            type="file"
            id="fileUpload"
            name="fileUpload"
            hidden
            onChange={fileSelect}
          />
          <Label for="fileUpload" className="btn-200-width btn-secondary m-2">
            {uploadFile ? "Choose Another File" : buttonImportText}
          </Label>
        </div>

        <Button
          color="link"
          onClick={
            // This is to navigate back to Account Settings page by navigating backwards by 1 step
            () => navigate(-1)
          }>
          {"< Back to Settings Page"}
        </Button>
      </>
    )
  }

  return (
    <>
      <div className="row m-4">
        <h1 className="mx-4">{title}</h1>
      </div>
      <div className="c-solver u-row-fix u-layout-component-grow ">
        <div className="c-solver__classes-container container-fluid ">
          <div className="container-fluid d-flex justify-content-center align-items-center">
            <div className="flex-column text-center">
              <img height="150px" src={ExcelIcon} alt="Excel Icon" />
              <div className="d-flex flex-column justify-content-center align-items-center align-self-center u-layout-component-grow">
                {content}
                {children}
              </div>
              {!hasKnownWarning(warningCode, knownWarnings) ? (
                <UploadErrorModal
                  isOpen={warningModal}
                  actions={errorModalActions}>
                  <p>
                    {genericErrorMessage}
                    <br />
                    <br />
                    Please click "Need help importing?" to let us know and we
                    will upload the file for you.
                  </p>
                </UploadErrorModal>
              ) : (
                <UploadErrorModal
                  title={knownWarnings[warningCode].title}
                  isOpen={warningModal}
                  jobId={uploadError && uploadError.job_id}
                  actions={errorModalActions}>
                  {knownWarnings[warningCode].body(uploadError)}
                </UploadErrorModal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const TeacherRequestsImport = () => {
  const navigate = useNavigate()

  const [importRequestsFileMutation, { loading: importingRequestsFiles }] =
    useMutation(directlyImportTeacherRequestsFileMutation, {
      variables: { schoolId: getSchoolId() },
    })

  const title = "Transfer Teacher Requests"
  const buttonExportText = "Export Teacher Requests"
  const buttonImportText = "Import Teacher Requests"
  const exportType = "teacher_requests"
  const genericErrorMessage =
    "We’re sorry, but something went wrong with your teacher requests upload."

  return (
    <RequestsImportComponent
      title={title}
      buttonExportText={buttonExportText}
      buttonImportText={buttonImportText}
      exportType={exportType}
      genericErrorMessage={genericErrorMessage}
      importRequestsFileMutation={importRequestsFileMutation}
      loading={importingRequestsFiles}
      navigate={navigate}></RequestsImportComponent>
  )
}

export const StudentRequestsImport = () => {
  const navigate = useNavigate()

  const [importRequestsFileMutation, { loading: importingRequestsFiles }] =
    useMutation(directlyImportStudentRequestsFileMutation, {
      variables: { schoolId: getSchoolId() },
    })

  const title = "Transfer Student Requests"
  const buttonExportText = "Export Student Requests"
  const buttonImportText = "Import Student Requests"
  const exportType = "student_requests"
  const genericErrorMessage =
    "We’re sorry, but something went wrong with your student requests upload."

  return (
    <RequestsImportComponent
      title={title}
      buttonExportText={buttonExportText}
      buttonImportText={buttonImportText}
      exportType={exportType}
      genericErrorMessage={genericErrorMessage}
      importRequestsFileMutation={importRequestsFileMutation}
      loading={importingRequestsFiles}
      navigate={navigate}></RequestsImportComponent>
  )
}
