import React from "react"
import { Tooltip } from "react-tooltip"
import { FormGroup, Label, Input } from "reactstrap"
import CreatableSelect from "react-select/creatable"
import { useQuery } from "@apollo/client"

import { InlineError } from "components"
import { solverQuery } from "domains/solver/graphql"

import { getSchoolId } from "util/app"
import { getPropertyIfDefined } from "util/objUtil"
import { validateNonEmptyString } from "util/validators"
import { NEW_GRADE, LEAVING_GRADE } from "domains/app/constants/gradeFields"
import { isLeavingGrade, isNewGrade, isSpecialGrade } from "util/gradeUtil"
import { isSystemGenerated } from "util/studentUtil"
import { AccountTypeContext } from "config/accountTypeContext"

const getFilteredClasses = (classes, selectedGrade, property) => {
  if (classes) {
    return classes.filter(c =>
      getPropertyIfDefined(c, property).some(
        grade => grade.code === selectedGrade
      )
    )
  } else {
    return []
  }
}

const hasSolution = (solution, gradeId) =>
  solution &&
  solution.activeGrade.id === gradeId &&
  solution.classes.some(c => c.students.length > 0)

const createOption = label => {
  return {
    label: label,
    value: label,
  }
}

export const StudentInputs = ({
  errors,
  student: {
    firstName,
    lastName,
    studentCode,
    currentGrade,
    currentClass,
    newGrade,
    newClass,
    gender,
    comments,
  },
  onChange,
  onCurrentClassChange,
  onGenderChange,
  currentSchoolGrades,
  newSchoolGrades,
  currentClasses,
  newClasses,
  addStudent,
  isTeacher,
}) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj
  const newGradeId = newSchoolGrades.find(grade => grade.code === newGrade)?.id
  const solutionParams = { gradeId: newGradeId, schoolId: getSchoolId() }
  const { data: solutionData } = useQuery(solverQuery, {
    variables: { solution: solutionParams },
    fetchPolicy: "network-only",
    skip: isTeacher,
  })
  const solution = getPropertyIfDefined(solutionData, "solution")
  const solutionNotAvailable = !hasSolution(solution, newGradeId)

  const unassignedNewClass = !solutionNotAvailable && !newClass

  const gradeClasses = getFilteredClasses(
    currentClasses,
    currentGrade,
    "grades"
  )

  const notApplicableOption = {
    label: "N/A",
    value: "N/A",
  }

  gradeClasses.unshift(notApplicableOption)

  const newGradeClasses = getFilteredClasses(
    newClasses,
    newGrade,
    "schoolGrades"
  )

  // create options for input select
  let currentGradeOptions = currentSchoolGrades
    ? currentSchoolGrades
        .filter(grade => !isSpecialGrade(grade))
        .map((g, index) => {
          return (
            <option value={g.code} key={index}>
              {g.label}
            </option>
          )
        })
    : null

  // Add in the NEW_GRADE if the student is currently
  // assigned to it but do not allow it as an option to be
  // selected
  if (currentGrade === NEW_GRADE) {
    const index = currentGradeOptions.length
    const newGrade = currentSchoolGrades.find(isNewGrade)
    currentGradeOptions.push(
      <option value={newGrade.code} key={index} disabled>
        {newGrade.code}
      </option>
    )
  }

  // create options for input select
  const newGradeOptions = newSchoolGrades
    ? newSchoolGrades
        .filter(grade => !isNewGrade(grade))
        .map((g, index) => {
          // Do not show the leaving grade if adding a student
          if (isLeavingGrade(g) && addStudent) {
            return null
          } else {
            return (
              <option value={g.code} key={index}>
                {g.label}
              </option>
            )
          }
        })
    : null

  const currentClassDisabled = currentGrade === NEW_GRADE
  const newClassDisabled =
    isTeacher ||
    newGrade === LEAVING_GRADE ||
    !validateNonEmptyString(newGrade) ||
    solutionNotAvailable
  const isSystemGeneratedId = studentCode && isSystemGenerated(studentCode)

  const currentClassOption = currentClass ? createOption(currentClass) : null

  const gradeClassesOptions = gradeClasses.map(x => createOption(x.label))

  return (
    <div>
      <div className="row">
        <FormGroup className="col-6">
          <Label>First Name</Label>
          <Input
            name="firstName"
            onChange={onChange}
            value={firstName}
            maxLength={255}
            disabled={isTeacher}
          />
          {errors.firstName && <InlineError text={errors.firstName} />}
        </FormGroup>
        <FormGroup className="col-6">
          <Label>Student ID</Label>
          <Input
            className={
              isSystemGeneratedId && !addStudent
                ? ""
                : "c-student-modal-details__student-id-generated"
            }
            name="studentCode"
            onChange={onChange}
            value={studentCode}
            maxLength={255}
            disabled={isTeacher}
          />
          <span className="u-text-12 u-grey-mid">
            {studentCode.length <= 0
              ? "If you don't yet have a student ID, please update this later."
              : isSystemGeneratedId &&
                "[System-generated ID] - Please update with the correct ID when available"}
          </span>

          {errors.studentId && (
            <div>
              <InlineError text={errors.studentId} />
            </div>
          )}
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-6">
          <Label>Last Name</Label>
          <Input
            name="lastName"
            onChange={onChange}
            value={lastName}
            maxLength={255}
            disabled={isTeacher}
          />
          {errors.lastName && <InlineError text={errors.lastName} />}
        </FormGroup>

        <FormGroup className="col-6">
          <Label>Gender</Label>
          <div className="c-toggle ml-0 w-100">
            <Input
              type="select"
              name="gender"
              id="gender"
              value={gender}
              onChange={onGenderChange}
              disabled={isTeacher}>
              <option disabled value=""></option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="X">Non-Binary</option>
            </Input>
          </div>
          {errors.gender && <InlineError text={errors.gender} />}
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-6">
          <div className="d-flex justify-content-between">
            <Label>Current Grade</Label>

            <i
              className="fa fa-info-circle color-blue-mid m-2"
              data-tooltip-id="optional-info"
            />
            <Tooltip
              className="c-badge-tooltip"
              id="optional-info"
              variant="light"
              style={{ zIndex: 100 }}
              opacity={1}>
              <p>This drop-down is based off your School Grade Settings.</p>
              <p>
                If you would like to add another grade: click on the Settings,
                scroll down to School Grade Settings and add the desired grade
                (e.g. Pre-kinder, Pre-primary). 
              </p>
              <p>
                Please make sure they are in the correct order by using the
                arrows.
              </p>
            </Tooltip>
          </div>
          <Input
            name="currentGrade"
            type="select"
            onChange={onChange}
            value={currentGrade}
            disabled={isTeacher}>
            {currentGradeOptions}
          </Input>
        </FormGroup>
        <FormGroup className="col-6">
          <Label>Current Class</Label>

          <CreatableSelect
            className="react-select-container"
            name="currentClass"
            onChange={e => onCurrentClassChange(e)}
            isDisabled={currentClassDisabled || isTeacher}
            options={gradeClassesOptions}
            placeholder="Select or Type Current Class"
            value={currentClassOption}
          />
        </FormGroup>
      </div>
      <div className="row">
        <FormGroup className="col-6">
          <Label>{gettextObj.gettext("New Grade")}</Label>
          <Input
            name="newGrade"
            type="select"
            onChange={onChange}
            value={newGrade}
            disabled={isTeacher}>
            {!validateNonEmptyString(newGrade) && <option value="" />}
            {newGradeOptions}
          </Input>
          <InlineError text={errors.newGrade} />
        </FormGroup>
        {isTeacher || (
          <FormGroup className="col-6">
            <Label>New {gettextObj.gettext("Class")}</Label>
            <Input
              type="select"
              name="newClass"
              onChange={onChange}
              value={newClass || ""}
              disabled={newClassDisabled}>
              <option />
              {newGradeClasses.length > 0 &&
                newGradeClasses.map((c, index) => (
                  <option value={c.label} key={index}>
                    {c.label}
                  </option>
                ))}
            </Input>
            {solutionNotAvailable && (
              <span className="u-text-12 u-grey-mid">
                Please run Solver first to assign new{" "}
                {gettextObj.gettext("classes")}
              </span>
            )}
            {unassignedNewClass && (
              <span className="u-text-12 text-danger">
                Please manually assign this student into a{" "}
                {gettextObj.gettext("class")}
              </span>
            )}
          </FormGroup>
        )}
      </div>
      <div className="row justify-content-end">
        <FormGroup className="col-12">
          <Label>Placement Notes (optional)</Label>
          <Input
            name="comments"
            onChange={onChange}
            type="textarea"
            value={comments || ""}
            maxLength={5000}
          />
        </FormGroup>
      </div>
    </div>
  )
}
