import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap"
import { useQuery } from "@apollo/client"
import { getNotificationsQuery } from "../graphql"
import { getSchoolId } from "util/app"
import ReactMarkdown from "react-markdown"

const MISSING_BILLING_EMAIL_ADDRESS = "MISSING_BILLING_EMAIL_ADDRESS"
const STUDENTS_MISSING_CODES = "STUDENTS_MISSING_CODES"
const UNALLOCATED_STUDENTS = "UNALLOCATED_STUDENTS"

const CRITICAL = "CRITICAL"
const MAJOR = "MAJOR"
const MINOR = "MINOR"
const NORMAL = "NORMAL"

const Notification = ({ icon, title, action, message }) => {
  return (
    <div className="c-notification">
      <div className="c-notification-title">
        <div>
          <span className="mr-1">{icon}</span>
          <span>{title}</span>
        </div>
        <div>{action}</div>
      </div>
      <ReactMarkdown className="c-notification-body">{message}</ReactMarkdown>
    </div>
  )
}

const getIcon = severity => {
  let className

  if (severity === CRITICAL) {
    className = "fa fa-warning text-danger"
  } else if (severity === MAJOR) {
    className = "fa fa-warning text-danger"
  } else if (severity === MINOR) {
    className = "fa fa-warning text-warning"
  } else if (severity === NORMAL) {
    className = "fa fa-info-circle color-blue-mid"
  }

  return <i className={className} />
}

const Notifications = ({ notifications }) => {
  if (notifications.length === 0) {
    const icon = <i className="fa fa-check text-success" />
    return <Notification icon={icon} title="You have no notifications" />
  } else {
    return notifications.map(
      ({ notificationId, title, severity, message }, index) => {
        const icon = getIcon(severity)

        let action

        switch (notificationId) {
          case MISSING_BILLING_EMAIL_ADDRESS:
            action = <Link to="/BillingSettings">View</Link>
            break

          case STUDENTS_MISSING_CODES:
            action = (
              <Link
                to={{
                  pathname: "/Students",
                  search: new URLSearchParams({
                    filterId: "missing-ids",
                  }).toString(),
                }}>
                View
              </Link>
            )
            break

          case UNALLOCATED_STUDENTS:
            action = (
              <Link
                to={{
                  pathname: "/Students",
                  search: new URLSearchParams({
                    filterId: "unallocated-students",
                  }).toString(),
                }}>
                View
              </Link>
            )
            break

          // Ignore any unrecognised notifications
          default:
            return null
        }

        return (
          <Notification
            key={index}
            icon={icon}
            title={title}
            action={action}
            message={message}
          />
        )
      }
    )
  }
}

export const NotificationsList = () => {
  const { data, loading } = useQuery(getNotificationsQuery, {
    variables: { schoolId: getSchoolId() },
  })

  const notifications = !loading && data ? data.getNotifications : []

  return (
    <>
      <div
        className="text-primary cursor-pointer mx-4"
        id="notificationsPopover">
        {notifications.length > 0 ? (
          <span className="fa-stack c-notification-icon">
            <i className="fa fa-bell-o fa-lg fa-stack-1x color-grey-mid" />
            <div className="fa fa-stack-2x c-notification-badge-text">
              {notifications.length === 1
                ? `${notifications.length} action`
                : `${notifications.length} actions`}
            </div>
          </span>
        ) : (
          <span className="fa-stack">
            <i className="fa fa-bell-o fa-lg fa-stack-1x color-grey-mid" />
          </span>
        )}
      </div>
      <UncontrolledPopover
        trigger="legacy"
        placement="bottom"
        target="notificationsPopover"
        popperClassName="c-notifications">
        <PopoverHeader className="text-primary">Notifications</PopoverHeader>
        <PopoverBody className="c-notifications-body">
          <Notifications notifications={notifications} />
        </PopoverBody>
      </UncontrolledPopover>
    </>
  )
}
