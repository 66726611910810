import React from "react"
import { Modal, Button } from "reactstrap"
import { ModalHeader, InlineError, Loader } from "components"

export const UnsavedChangesModal = ({
  loading,
  toggle,
  heading,
  text,
  onDontSaveButtonClick,
  error,
}) => (
  <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
    <div className="p-4">
      <ModalHeader title={heading} toggle={toggle} />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="mt-4 d-flex justify-content-between">
            <i className="fa fa-info-circle u-caution-icon mr-4" />
            <div>
              <div className="text-primary mb-3">IMPORTANT NOTICE</div>
              <div>{text}</div>
            </div>
          </div>
          <Button color="primary" className="mt-5 w-50" onClick={toggle}>
            Go Back
          </Button>
          <span
            className="text-primary p-3 pl-5 pr-5 mt-2 cursor-pointer"
            onClick={onDontSaveButtonClick}>
            Don't Save & Close
          </span>
          <InlineError text={error} />
        </div>
      )}
    </div>
  </Modal>
)
