import React, { Component } from "react"
import { Query } from "@apollo/client/react/components"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  solverQuery,
  allGradesWithNewClassesQuery,
  updateSolverActiveGrade,
  solverClientQuery,
} from "domains/solver/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"

import { ReportHeader, ReportTable } from "domains/reports/components"
import { Loader, QueryError } from "components"

import { getPropertyIfDefined } from "util/objUtil"
import { handleSessionExpired, getSchoolId } from "util/app"
import { getApiBaseUri } from "util/apiUtil"
import { downloadAuthenticatedFile } from "util/downloadUtil"
import { Auth0Context } from "domains/auth/auth0Wrapper"
import { SCHOOL_ID } from "constants/storageTokens"
import { NO_SOLUTION_FOUND } from "domains/solver/errorFields"
import { hasAsyncSolveInProgress } from "util/solverUtil"

import { AccountTypeContext } from "config/accountTypeContext"

class ReportClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: {},
    }
  }

  componentDidMount() {
    // Expand all the students on print
    window.addEventListener("beforeprint", this.expandAllClasses)
  }

  componentWillUnmount() {
    // Remove all the print event handlers
    window.removeEventListener("beforeprint", this.expandAllClasses)
  }

  expandAllClasses = () => this.setAllClassesExpand(true)

  setAllClassesExpand = (expanded, callback) => {
    this.setState(prevState => {
      let newExpanded = {}
      Object.keys(prevState.expanded).forEach(key => {
        newExpanded[key] = expanded
      })

      return {
        expanded: newExpanded,
      }
    }, callback)
  }

  setExpanded = (key, value) => {
    this.setState(prevState => {
      return {
        expanded: {
          ...prevState.expanded,
          [key]: value || false,
        },
      }
    })
  }

  onActiveGradeChange = value => {
    const { updateSolverActiveGrade } = this.props
    updateSolverActiveGrade({
      variables: { activeGrade: parseInt(value, 10) },
    })
  }

  csvExport = async () => {
    const { solverGrades, user, settings, activeGrade } = this.props
    const token = await this.context.getTokenSilently()
    const schoolId = getSchoolId()
    const userRole = user.role

    if (!schoolId || !userRole || !token) {
      return
    }

    const defaultGradeId = getPropertyIfDefined(solverGrades[0], "id")

    const selectedGradeId = activeGrade || defaultGradeId

    const baseUrl = getApiBaseUri()
    const filterSensitive = settings.adminOnlyRequests ? "false" : "true"
    const url = `${baseUrl}/export_class_summary/${schoolId}/${selectedGradeId}?filter_sensitive=${filterSensitive}`

    const response = await downloadAuthenticatedFile(token, url)

    if (!response.ok) {
      const message = await response.text()
      handleSessionExpired({ message })
      this.setState({
        errors: { export: "Network error attempting to export" },
      })
    }
  }

  static contextType = Auth0Context

  render() {
    const { client, loading, activeGrade, solverGrades, gettextObj } =
      this.props

    if (loading) {
      return <Loader />
    }

    // build variables for query
    const solution = {
      gradeId:
        activeGrade !== ""
          ? activeGrade.toString()
          : getPropertyIfDefined(solverGrades[0], "id"),
      schoolId: sessionStorage.getItem(SCHOOL_ID),
    }

    return (
      <Query
        query={solverQuery}
        variables={{ solution }}
        fetchPolicy={"network-only"}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loader />

          let body
          if (error) {
            if (error.message.includes(NO_SOLUTION_FOUND.key)) {
              body = (
                <div>
                  <b>
                    No solution has been generated. Please run the solver for
                    this grade.
                  </b>
                </div>
              )
            } else {
              return <QueryError refetch={refetch} />
            }
          } else if (hasAsyncSolveInProgress(data.solution)) {
            body = (
              <div>
                <b>
                  A solution is currently being generated. Please wait for it to
                  complete.
                </b>
              </div>
            )
          } else {
            body = (
              <ReportTable
                isClassReport
                activeGradeId={solution.gradeId}
                client={client}
                studentMetrics={data.solution.studentMetrics}
                data={data.solution.classes}
                expanded={this.state.expanded}
                setExpanded={this.setExpanded}
              />
            )
          }

          return (
            <div className="container-fluid">
              <ReportHeader
                reportTitle={`${gettextObj.gettext("Class")} Summary Report`}
                isClassReport
                solverGrades={solverGrades}
                gradeId={solution.gradeId}
                onActiveGradeChange={this.onActiveGradeChange}
                setAllClassesExpand={this.setAllClassesExpand}
                csvExport={this.csvExport}
              />
              {body}
            </div>
          )
        }}
      </Query>
    )
  }
}

const ReportClassPreCompose = props => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return <ReportClass gettextObj={gettextObj} {...props} />
}

export const ReportClassPage = compose(
  graphql(allGradesWithNewClassesQuery, {
    options: () => ({
      variables: { schoolId: sessionStorage.getItem(SCHOOL_ID) },
    }),
    props: ({ data: { loading, allGradesWithNewClasses } }) => ({
      loading,
      solverGrades: allGradesWithNewClasses,
    }),
  }),
  graphql(solverClientQuery, {
    props: ({
      data: {
        solver: { activeGrade },
      },
    }) => ({
      activeGrade,
    }),
  }),
  graphql(updateSolverActiveGrade, { name: "updateSolverActiveGrade" }),
  graphql(userProfileQuery, {
    props: ({ data: { myprofile: user } }) => ({
      user,
    }),
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  })
)(withApollo(ReportClassPreCompose))
