export const DUPLICATE_FRIENDSHIP = {
  key: "duplicate-friendship",
  message: "This student has already been nominated as a friend.",
}
export const DUPLICATE_STUDENT_REQUEST = {
  key: "duplicate-student-request",
  message:
    "A request already exists for these two students to be paired or separated.",
}
export const DUPLICATE_TEACHER_REQUEST = {
  key: "duplicate-teacher-request",
  message:
    "A request already exists for this student to be paired or separated from this teacher.",
}
export const OUTSIDE_GRADE_GROUP = {
  key: "outside-grade-group",
  message:
    "Outside Grade Group: The students/teacher are not in grades that enable them to be paired or separated.",
}
export const TEACHER_NOT_ASSIGNED = {
  key: "teacher-not-assigned-class",
  message:
    "Teacher Unassigned: The teacher has not yet been assigned to a class to enable the pairing or separation to be assessed.",
}
