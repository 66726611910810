import React, { Component } from "react"
import { graphql } from "@apollo/client/react/hoc"
import {
  schoolGradesMutation,
  currentSchoolGradesQuery,
} from "domains/classes/graphql"

import {
  AddButton,
  ButtonBar,
  InlineError,
  Loader,
  GradeSystemListItem,
} from "components"

import { NEW_GRADE, LEAVING_GRADE } from "domains/app/constants/gradeFields"
import { GRADES_HAVE_STUDENTS } from "constants/errorFields"
import { getSchoolId } from "util/app"
import { isSpecialGrade } from "util/gradeUtil"

class GradeSystemListComponent extends Component {
  constructor(props) {
    super(props)

    const schoolGrades = this.props.schoolGrades
      ? this.props.schoolGrades.filter(grade => !isSpecialGrade(grade))
      : null

    this.state = {
      schoolGrades,
      loading: false,
      errors: {},
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState(prevState => ({
        schoolGrades: prevState.schoolGrades.map(grade => ({
          ...grade,
          active: false,
        })),
      }))
    }
  }

  toggleActive = () => {
    this.setState(prevState => ({
      schoolGrades: prevState.schoolGrades.map(grade => ({
        ...grade,
        active: false,
      })),
    }))
  }

  onClick = index => {
    this.setState(prevState => ({
      schoolGrades: prevState.schoolGrades.map((grade, i) => ({
        ...grade,
        active: index === i,
      })),
    }))
  }

  onChange = (index, targetObject) => {
    const { schoolGrades } = this.state

    // Clone from state
    const newSchoolGrades = Array.from(schoolGrades)

    const newGrade = {
      ...newSchoolGrades[index],
      [targetObject.name]: targetObject.value,
    }

    newSchoolGrades.splice(index, 1, newGrade)

    this.setState({
      schoolGrades: newSchoolGrades,
    })
  }

  deleteGrade = index => {
    const { schoolGrades } = this.state

    // Clone from state
    const newSchoolGrades = Array.from(schoolGrades)

    // Delete grade at index
    newSchoolGrades.splice(index, 1)

    this.setState({
      schoolGrades: newSchoolGrades,
    })
  }

  changeOrder = (index, type) => {
    const { schoolGrades } = this.state

    // Clone from state
    const newSchoolGrades = Array.from(schoolGrades)

    const newIndex = type === "asc" ? index - 1 : index + 1

    const temp = newSchoolGrades[index]
    newSchoolGrades[index] = newSchoolGrades[newIndex]
    newSchoolGrades[newIndex] = temp

    this.setState({
      schoolGrades: newSchoolGrades,
    })
  }

  addGrade = () => {
    const { schoolGrades } = this.state

    // Clone from state
    const newSchoolGrades = Array.from(schoolGrades)

    newSchoolGrades.push({
      code: "NEW",
      label: "New Grade",
    })

    this.setState({
      schoolGrades: newSchoolGrades,
    })
  }

  saveGrades = () => {
    const { schoolGradesMutation, fromSettings, toggle, onContinue } =
      this.props
    const { schoolGrades } = this.state
    this.setState({
      loading: true,
    })

    const grades = schoolGrades.map((grade, index) => ({
      id: grade.id,
      code: grade.code || grade.label,
      order: index + 1,
      label: grade.label,
    }))

    // TODO: try to deal with this on the backend
    const extraGrades = [
      this.props.schoolGrades.find(grade => grade.code === NEW_GRADE) || {
        code: NEW_GRADE,
        label: NEW_GRADE,
      },
      this.props.schoolGrades.find(grade => grade.code === LEAVING_GRADE) || {
        code: LEAVING_GRADE,
        label: LEAVING_GRADE,
      },
    ]

    extraGrades.forEach(grade => {
      grades.push({
        id: grade.id,
        code: grade.code,
        order: grades.length + 1,
        label: grade.label,
      })
    })

    const variables = {
      schoolGradeParams: {
        schoolId: getSchoolId(),
        grades,
      },
    }
    const refetchQueries = [
      {
        query: currentSchoolGradesQuery,
        variables: {
          schoolId: getSchoolId(),
          includeNew: true,
          includeLeaving: true,
        },
      },
    ]
    schoolGradesMutation({ variables, refetchQueries })
      .then(() => {
        this.setState({
          loading: false,
          errors: {},
        })
        if (fromSettings) {
          toggle()
        } else {
          onContinue()
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          errors: {
            mutation: error.message.includes(GRADES_HAVE_STUDENTS.key)
              ? GRADES_HAVE_STUDENTS.message
              : "Network Error",
          },
        })
      })
  }

  hasDuplicateGradeCode = schoolGrades => {
    const gradeCodes = schoolGrades.map(grade => grade.code)

    return new Set(gradeCodes).size !== gradeCodes.length
  }

  hasDuplicateGradeLabel = schoolGrades => {
    const gradeLabels = schoolGrades.map(grade => grade.label)

    return new Set(gradeLabels).size !== gradeLabels.length
  }

  render() {
    const { className = "", fromSettings, toggle, cancel } = this.props
    const { loading, errors, schoolGrades } = this.state

    const hasDuplicateGradeCodes = this.hasDuplicateGradeCode(schoolGrades)
    const hasDuplicateGradeLabels = this.hasDuplicateGradeLabel(schoolGrades)

    if (loading) {
      return (
        <div className="position-relative p-5">
          <Loader />
        </div>
      )
    }

    return (
      <div className={`student-upload-list ${className}`}>
        <div className="pb-5 u-content-border-bottom">
          <div className="container-fluid w-75">
            <div className={`c-note my-3`}>
              <div className={`d-flex`}>
                <i className="fa fa-info-circle mr-2" />
                <div>
                  Please ensure that you:
                  <br />
                  <br />
                  1. Set the order of the grades from youngest to oldest by
                  using the up/down arrows
                  <br />
                  2. Include all grades at your school, even if you are not
                  importing students for this grade
                  <br />
                  3. Add the grade 'before' students join your school (e.g. you
                  may need to have 'pre-Kinder' if you are K-6)
                </div>
              </div>
              <div className="mt-3">
                For mixed-grade composite classes, simply list grades in order.
                Do not combine grades or list classes here.
              </div>
            </div>
            <div className="student-upload-list__header row">
              <div className="student-upload-list__header__label col-3">
                Order
              </div>
              <div className="student-upload-list__header__label col-2">
                Code
              </div>
              <div className="student-upload-list__header__label col-3">
                Label
              </div>
            </div>
            <div ref={this.setWrapperRef}>
              {schoolGrades.map((grade, index) => (
                <GradeSystemListItem
                  key={index}
                  index={index}
                  length={schoolGrades.length}
                  onChange={this.onChange}
                  changeOrder={this.changeOrder}
                  deleteGrade={this.deleteGrade}
                  onClick={this.onClick}
                  toggleActive={this.toggleActive}
                  {...grade}
                />
              ))}
            </div>
            <AddButton
              className="mt-3"
              text="Add Another Grade"
              onClick={this.addGrade}
            />
          </div>
        </div>
        <ButtonBar
          className="p-3"
          buttonText={fromSettings ? "Save" : "Save & Continue"}
          cancelText={fromSettings ? "" : "Cancel Import"}
          onButtonClick={this.saveGrades}
          onCancelClick={fromSettings ? toggle : cancel}
          disabled={hasDuplicateGradeCodes || hasDuplicateGradeLabels}
        />
        <InlineError
          text={
            (hasDuplicateGradeCodes && "Grade Code must be unique") ||
            (hasDuplicateGradeLabels && "Grade Label must be unique") ||
            errors.mutation
          }
        />
      </div>
    )
  }
}

export const GradeSystemList = graphql(schoolGradesMutation, {
  name: "schoolGradesMutation",
})(GradeSystemListComponent)
