// Polyfills for IE 11
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"

// Auth0
import { Auth0Provider } from "./domains/auth/auth0Wrapper"
import { getConfig } from "config/authConfig"
import { unregister } from "./registerServiceWorker"

const auth0Config = getConfig()

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Auth0Provider
    domain={auth0Config.domain}
    clientId={auth0Config.clientId}
    audience={auth0Config.audience}
    leeway={auth0Config.leeway}>
    <App />
  </Auth0Provider>
)

// Unregister any existing service workers
// TODO: Remove this in the future when we think there are no
// more service workers lying around in client's browsers
unregister()
