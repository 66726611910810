import React from "react"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { studentsQuery } from "domains/students/graphql"
import {
  teachersQuery,
  updateTeachersMutation,
  inviteTeachersToSurveysMutation,
  deleteTeacherMutation,
  closeOrOpenAllSurveysMutation,
  closeOrOpenTeacherSurveyMutation,
  bulkUploadTeachersMutation,
  deactivateAllTeachersMutation,
} from "domains/teachers/graphql"
import { activeCurrentClassesQuery } from "domains/classes/graphql"
import {
  preferencesQuery,
  schoolQuery,
  settingsClientQuery,
  administratorsQuery,
} from "domains/accountSettings/graphql"
import { dashboardQuery } from "domains/dashboard/graphql"

import { Loader } from "components"
import { Teachers } from "domains/teachers/components"

import { handleSessionExpired, getSchoolId } from "util/app"
import { ADMIN } from "domains/app/constants/roleTypes"
import {
  requestNewTokenForTeacherMutation,
  userProfileQuery,
} from "domains/auth/graphql"

export { UnavailableSurveyPage } from "./unavailableSurveyPage"

class TeachersPageComponent extends React.Component {
  UNSAFE_componentWillReceiveProps = nextProps => {
    handleSessionExpired(nextProps.error)
  }

  render() {
    const {
      loadingOne,
      loadingTwo,
      loadingThree,
      loadingFour,
      loadingFive,
      loadingAdmins,
      loadingDashboard,
      teachers,
      students,
      settings,
      users,
      ...others
    } = this.props

    if (
      loadingOne ||
      loadingTwo ||
      loadingThree ||
      loadingFour ||
      loadingFive ||
      loadingAdmins ||
      loadingDashboard
    ) {
      return <Loader />
    }

    const [firstAdmin] = users
    const schoolId = getSchoolId()

    const refetchQueries = [
      { query: teachersQuery, variables: { schoolId } },
      {
        query: dashboardQuery,
        variables: {
          adminOnly: settings.adminOnlyRequests,
          schoolId: getSchoolId(),
        },
      },
    ]

    return (
      <Teachers
        refetchQueries={refetchQueries}
        teachersData={teachers}
        studentsData={students}
        schoolId={schoolId}
        settings={settings}
        firstAdmin={firstAdmin}
        {...others}
      />
    )
  }
}

export const TeachersPage = compose(
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(preferencesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolSettings } }) => ({
      loadingOne: loading,
      schoolSettings,
    }),
  }),
  graphql(schoolQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, school } }) => ({
      loadingTwo: loading,
      school,
    }),
  }),
  graphql(teachersQuery, {
    options: () => ({
      fetchPolicy: "network-only",
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, error, teachers, refetch } }) => ({
      loadingThree: loading,
      error,
      teachers,
      teacherRefetch: refetch,
    }),
  }),
  graphql(dashboardQuery, {
    skip: () => getSchoolId() === null,
    options: ({ settings }) => ({
      variables: {
        adminOnly: settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { dashboard, loading } }) => ({
      loadingDashboard: loading,
      dashboard,
    }),
  }),
  graphql(activeCurrentClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, activeCurrentClasses } }) => ({
      loadingFour: loading,
      currentClasses: activeCurrentClasses,
    }),
  }),
  graphql(studentsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, students } }) => ({
      loadingFive: loading,
      students,
    }),
  }),
  graphql(userProfileQuery, {
    props: ({ data: { myprofile } }) => ({
      myprofile,
    }),
  }),
  graphql(administratorsQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        filterRole: ADMIN,
      },
    }),
    props: ({ data: { users, loading, error } }) => ({
      loadingAdmins: loading,
      error,
      users,
    }),
  }),
  graphql(inviteTeachersToSurveysMutation, {
    name: "inviteTeachersToSurveysMutation",
  }),
  graphql(updateTeachersMutation, { name: "updateTeachersMutation" }),
  graphql(deleteTeacherMutation, { name: "deleteTeacherMutation" }),
  graphql(bulkUploadTeachersMutation, { name: "bulkUploadTeachersMutation" }),
  graphql(closeOrOpenAllSurveysMutation, {
    name: "closeOrOpenAllSurveysMutation",
  }),
  graphql(closeOrOpenTeacherSurveyMutation, {
    name: "closeOrOpenTeacherSurveyMutation",
  }),
  graphql(deactivateAllTeachersMutation, {
    name: "deactivateAllTeachersMutation",
    options: () => ({
      variables: { schoolId: getSchoolId() },
      refetchQueries: [
        {
          query: teachersQuery,
          variables: { schoolId: getSchoolId() },
        },
      ],
    }),
  }),
  graphql(requestNewTokenForTeacherMutation, {
    name: "requestNewTokenForTeacherMutation",
  })
)(withApollo(TeachersPageComponent))
