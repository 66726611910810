import React, { useState } from "react"
import { useMutation } from "@apollo/client"

import { Modal, ModalBody, Form, FormGroup, Label, Input } from "reactstrap"
import {
  updateSelfMutation,
  deleteSelfMutation,
} from "domains/accountSettings/graphql"
import { ButtonBar, InlineError, ModalHeader, DeleteModal } from "components"

import * as validators from "util/validators"

export const EditUserDetailsModal = ({
  user,
  toggle,
  cancelToggle,
  refetchQueries,
  title = "Edit My Profile",
  modalClass,
  showDelete = true,
  allowEditEmail = true,
}) => {
  const [userDetails, setUserDetails] = useState({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    positionTitle: user.positionTitle,
  })

  const [errors, setErrors] = useState({})

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [showEmailChangeModal, setShowEmailChangeModal] = useState(false)

  const [updateUser, { loading: updatingUser }] = useMutation(
    updateSelfMutation,
    {
      refetchQueries,
    }
  )

  const [deleteSelf, { loading: deletingUser }] =
    useMutation(deleteSelfMutation)

  const { firstName, lastName, email, positionTitle } = userDetails

  const handleUserDetailsChange = e => {
    const value = e.target.value

    setUserDetails(prevState => {
      return {
        ...prevState,
        [e.target.name]: value,
      }
    })
  }

  const validateAdmin = userDetails => {
    const { firstName, lastName, email } = userDetails
    let errors = {}
    if (!validators.validateNonEmptyString(firstName)) {
      errors.firstName = "Required"
    }
    if (!validators.validateNonEmptyString(lastName)) {
      errors.lastName = "Required"
    }
    if (!validators.validateEmail(email)) {
      errors.email = "Invalid Email"
    }

    return errors
  }

  const handleSaveUserClick = () => {
    const emailHasChanged = userDetails.email.trim() !== user.email.trim()

    if (emailHasChanged) {
      setShowEmailChangeModal(true)
    } else {
      saveUserDetails()
    }
  }

  const handleEmailChangeClick = () => {
    const errors = validateAdmin(userDetails)
    const onSuccess = () => {
      toggle()
      window.location.href = "/logout"
    }
    const onError = () => {
      setShowEmailChangeModal(false)
      setErrors({ mutation: "Could not update User. Please try again." })
    }

    if (Object.keys(errors).length === 0) {
      saveUser(onSuccess, onError)
    }
    setShowEmailChangeModal(false)
    setErrors(errors)
  }

  const saveUserDetails = () => {
    const errors = validateAdmin(userDetails)
    const onSuccess = () => toggle()
    const onError = () =>
      setErrors({ mutation: "Could not update User. Please try again." })

    if (Object.keys(errors).length === 0) {
      saveUser(onSuccess, onError)
    }
    setErrors(errors)
  }

  const saveUser = (onSuccess, onError) => {
    const { id, firstName, lastName, positionTitle, email } = userDetails
    const variables = {
      self: {
        id,
        firstName,
        lastName,
        positionTitle,
        email,
      },
    }
    updateUser({ variables }).then(onSuccess).catch(onError)
  }

  const deleteProfile = () => {
    const variables = {
      id: user.id,
    }
    deleteSelf({ variables })
      .then(() => {
        window.location.href = "/logout"
      })
      .catch(() => {
        setErrors({ delete: "Could not delete profile" })
      })
  }

  return (
    <Modal className={modalClass} isOpen>
      <ModalHeader
        title={title}
        toggle={cancelToggle || toggle}
        className="u-p-fix p-4"
      />
      <ModalBody className="p-4">
        <Form autoComplete="off">
          <div className="container-fluid">
            <div className="row mt-3">
              <FormGroup className="col-6">
                <Label>First name</Label>
                <Input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  autoComplete="off"
                  value={firstName}
                  onChange={handleUserDetailsChange}
                />
                <InlineError text={errors.firstName} />
              </FormGroup>
              <FormGroup className="col-6">
                <Label>Last name</Label>
                <Input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  autoComplete="off"
                  value={lastName}
                  onChange={handleUserDetailsChange}
                />
                <InlineError text={errors.lastName} />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <Label>Position Title</Label>
                <Input
                  type="text"
                  name="positionTitle"
                  placeholder="Position Title"
                  autoComplete="off"
                  value={positionTitle || ""}
                  onChange={handleUserDetailsChange}
                />
              </FormGroup>
            </div>
            <div className="row">
              <FormGroup className="col-12">
                <Label>Email address</Label>
                <Input
                  disabled={!allowEditEmail}
                  type="text"
                  name="email"
                  placeholder="Email address"
                  autoComplete="off"
                  value={email}
                  onChange={handleUserDetailsChange}
                />
                <InlineError text={errors.email} />
              </FormGroup>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
              <div>
                {showDelete && (
                  <span
                    className="py-2 pr-3 mr-2 text-primary cursor-pointer"
                    onClick={() => setShowDeleteModal(true)}>
                    Delete Profile
                  </span>
                )}
              </div>
              <ButtonBar
                buttonText={"Save"}
                onButtonClick={handleSaveUserClick}
                onCancelClick={cancelToggle || toggle}
              />
            </div>
            <InlineError text={errors.mutation} />
          </div>
        </Form>
      </ModalBody>
      {showDeleteModal && (
        <DeleteModal
          isOpen
          toggle={() => setShowDeleteModal(false)}
          error={errors.delete}
          loading={deletingUser}
          heading="Delete My Profile?"
          text="You will no longer be able to access Class Solver as an Administrator if you continue.   This will apply to all Class Solver accounts linked to your profile.  If you later decide that you require access, you will need to ask another Administrator to re-add you."
          onButtonClick={deleteProfile}
        />
      )}
      {showEmailChangeModal && (
        <DeleteModal
          isOpen
          toggle={() => setShowEmailChangeModal(false)}
          error={errors.mutation}
          loading={updatingUser}
          heading="Change your email?"
          text="Are you sure you want to change your email? You will be logged out and a verification email will be sent to your new email address asking you to verify it. You will need to log back in with the new email address once it is verified."
          onButtonClick={handleEmailChangeClick}
          buttonText="Yes, change"
        />
      )}
    </Modal>
  )
}
