import React from "react"
import { Loader } from "components"
import { Preference } from "./preference"

export const Preferences = ({
  avoidSameTeacher,
  loading,
  includeStudentsWithNoValidFriends,
  onChange,
}) => (
  <div className="u-content-border-bottom pb-4">
    <h2 className="mb-5 mt-5 u-blue-primary">Preferences</h2>
    {loading ? (
      <div className="position-relative p-5">
        <Loader />
      </div>
    ) : (
      <div>
        <Preference
          id="avoidSameTeacher"
          name="avoidSameTeacher"
          title="Employ ‘Avoid Same Teacher’"
          description="‘Avoid Same Teacher' tries to avoid placing a student with the same teacher for two consecutive years."
          checked={avoidSameTeacher}
          onChangeHandler={e => onChange(e, avoidSameTeacher)}
        />
        <Preference
          id="includeStudentsWithNoValidFriends"
          name="includeStudentsWithNoValidFriends"
          title="Flag Students without friendship nominations"
          description="The green friendship warning flag will be displayed on the Solver page for students who have not nominated any friends or where the friendship nomination is not possible (e.g. the friend is in another grade)"
          checked={includeStudentsWithNoValidFriends}
          onChangeHandler={e => onChange(e, includeStudentsWithNoValidFriends)}
        />
      </div>
    )}
  </div>
)
