import React from "react"
import { useQuery, useMutation } from "@apollo/client"

import { Modal, Button } from "reactstrap"
import { useNavigate, Navigate } from "react-router-dom"

import { ModalHeader, Loader } from "components"
import {
  acceptSchoolInvitationMutation,
  declineSchoolInvitationMutation,
} from "domains/accountSettings/graphql"
import { userProfileQuery } from "domains/auth/graphql"

export const AcceptInvitationPage = () => {
  const { data: userProfileData, loading: loadingUser } = useQuery(
    userProfileQuery,
    { fetchPolicy: "network-only" }
  )

  const navigate = useNavigate()

  const [acceptInvitation] = useMutation(acceptSchoolInvitationMutation, {
    onCompleted: () => navigate("/"),
  })

  const [declineInvitation] = useMutation(declineSchoolInvitationMutation, {
    onCompleted: () => navigate("/"),
  })

  if (loadingUser) {
    return <Loader />
  }

  const schoolInvitation = userProfileData.myprofile.schoolInvitations[0]

  if (!schoolInvitation) {
    return <Navigate to="/" />
  }

  const handleAccept = () => {
    acceptInvitation({ variables: { inviteId: schoolInvitation.id } })
  }

  const handleDecline = () => {
    declineInvitation({ variables: { inviteId: schoolInvitation.id } })
  }

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <div className="p-4">
        <ModalHeader title="Accept Your Invitation" className="mb-4" />
        <div className="d-flex flex-column align-items-center">
          <div>
            <b>{schoolInvitation.inviterFullName}</b> has invited you to the
            following Class Solver account:
          </div>
          <div className="u-bold py-4">{schoolInvitation.school.name}</div>
          <div className="align-self-start pb-4">
            Would you like to continue?
          </div>
          <Button color="primary" className="w-50" onClick={handleAccept}>
            Yes, Accept
          </Button>
          <Button color="link" className="w-50" onClick={handleDecline}>
            Decline
          </Button>
        </div>
      </div>
    </Modal>
  )
}
