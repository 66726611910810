import React from "react"
import Select from "react-select"
import { defaultSelectStyles } from "components"

const transformForSelect = obj => {
  // obj.id can be null for Null Response criterion
  if (obj.name) {
    return {
      label: obj.name,
      value: obj.id,
    }
  }
}

export const EntryCriterion = ({
  availableCriteria,
  selectedCriterion,
  onChange,
  readOnly = false,
}) => {
  const onCriterionChange = selected => {
    if (selectedCriterion.id !== selected.value) {
      const newSelectedCriterion = availableCriteria.find(
        ({ id }) => id === selected.value
      )

      onChange({
        ...newSelectedCriterion,
        selectedOptions: newSelectedCriterion.options,
      })
    }
  }

  const onOptionsChange = selected => {
    const selectedOptions = selected
      ? selected.map(({ value }) =>
          selectedCriterion.options.find(({ id }) => id === value)
        )
      : []
    onChange({ ...selectedCriterion, selectedOptions })
  }

  const optionsStyle = readOnly
    ? {
        ...defaultSelectStyles,
        multiValue: (styles, { theme: { colors } }) => {
          return {
            ...styles,
            backgroundColor: colors.neutral10,
            border: `0.5px solid ${colors.neutral20}`,
            color: colors.neutral40,
          }
        },
        multiValueLabel: (styles, { theme: { colors } }) => {
          return {
            ...styles,
            color: colors.neutral40,
          }
        },
      }
    : defaultSelectStyles

  return (
    <>
      <div className="criterion col-4">
        <Select
          options={availableCriteria.map(transformForSelect)}
          onChange={onCriterionChange}
          value={transformForSelect(selectedCriterion)}
          isDisabled={readOnly}
        />
      </div>
      <div className="criterionValue col-7">
        <Select
          options={selectedCriterion.options.map(transformForSelect)}
          onChange={onOptionsChange}
          value={selectedCriterion.selectedOptions.map(transformForSelect)}
          isMulti
          styles={optionsStyle}
          isDisabled={readOnly}
        />
      </div>
    </>
  )
}
