export const ADVANCED_CLASS_SETTINGS_PLAYER_URL =
  "https://player.vimeo.com/video/601490518?h=4bd107fb0e"

export const SURVEY_INSTRUCTIONS_WITH_RESPONSES_URL =
  "https://player.vimeo.com/video/288935385?h=5f42cb74c3"

export const SURVEY_INSTRUCTIONS_WITHOUT_RESPONSES_URL =
  "https://player.vimeo.com/video/288935635?h=73cbddefd6"

export const INSTRUCTION_STEP_1_PLAYER_URL =
  "https://player.vimeo.com/video/683706883?h=55fd50b1c2"
export const INSTRUCTION_STEP_2_PLAYER_URL =
  "https://player.vimeo.com/video/683707022?h=c9cf9572c2"
export const INSTRUCTION_STEP_3_PLAYER_URL =
  "https://player.vimeo.com/video/683707181?h=bf16393561"

export const INSTRUCTION_ADD_STUDENTS_PLAYER_URL =
  "https://player.vimeo.com/video/853258560?h=121ddb3a2d"

export const INSTRUCTION_ADD_STUDENTS_PLAYER_URL_1 =
  "https://player.vimeo.com/video/881352047?h=ebbd13f070"

export const INSTRUCTION_ADD_STUDENTS_PLAYER_URL_2 =
  "https://player.vimeo.com/video/881352112?h=eb5be48b28"

export const INSTRUCTION_ADD_TEACHERS_PLAYER_URL =
  "https://player.vimeo.com/video/853258945?h=0381d55f76"

export const INSTRUCTION_CHARACTERISTICS_AND_RESPONSE_PLAYER_URL =
  "https://player.vimeo.com/video/853261432?h=b5dab326ca"

export const INSTRUCTION_SURVEY_INVITE_PLAYER_URL =
  "https://player.vimeo.com/video/853262578?h=76c04aa506"

export const INSTRUCTION_ADD_REQUEST_PLAYER_URL =
  "https://player.vimeo.com/video/853263793?h=9d7a2985c5"

export const INSTRUCTION_ADD_CLASS_PLAYER_URL =
  "https://player.vimeo.com/video/853264665?h=f0f4150e7e"

export const INSTRUCTION_RUN_SOLVER_PLAYER_URL =
  "https://player.vimeo.com/video/853218061?h=81bf92f2b7"

export const INSTRUCTION_SHARE_CLASSES_PLAYER_URL =
  "https://player.vimeo.com/video/853214187?h=d0c588cf85"

export const INSTRUCTION_ADD_AND_REMOVE_STUDENT_PLAYER_URL =
  "https://player.vimeo.com/video/881358416?h=fe61db46b0"
