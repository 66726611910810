import React, { useState } from "react"

import { mutationsIntrospectionQuery } from "domains/upload/graphql"

import {
  CloneSchool,
  DangerousMutation,
  DeleteSolutionsByGradesModal,
  RestoreBackupModal,
  SendMissingIdsEmailsModal,
  SettingItem,
  SwitchStudentNamesByCurrentGradeModal,
} from "domains/accountSettings/components"
import {
  backupSchoolMutation,
  convertToCabinsMutation,
  convertToActivityGroupsMutation,
  moveStudentsToTheirNewGradeMutation,
} from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"
import { useMutation, useQuery } from "@apollo/client"

import { characteristicsQuery } from "domains/characteristics/graphql"
import { getFormattedDescription } from "util/apiUtil"

const refetchCharacteristics = [
  { query: characteristicsQuery, variables: { schoolId: getSchoolId() } },
]

export const AdminActions = ({ isSuper, school }) => {
  const { data: introspectionData, loading } = useQuery(
    mutationsIntrospectionQuery
  )

  const [convertToCabins, { loading: convertingToCabins }] = useMutation(
    convertToCabinsMutation,
    {
      variables: { schoolId: getSchoolId() },
      refetchQueries: refetchCharacteristics,
    }
  )

  const [showConvertToCabinsModal, setShowConvertToCabinsModal] =
    useState(false)

  const [convertToActivityGroups, { loading: convertingToActivityGroups }] =
    useMutation(convertToActivityGroupsMutation, {
      variables: { schoolId: getSchoolId() },
      refetchQueries: refetchCharacteristics,
    })

  const [
    moveStudentsToTheirNewGrade,
    { loading: movingStudentsToTheirNewGrade },
  ] = useMutation(moveStudentsToTheirNewGradeMutation, {
    variables: { schoolId: getSchoolId() },
  })

  const [
    showConvertToActivityGroupsModal,
    setShowConvertToActivityGroupsModal,
  ] = useState(false)

  const [
    showMoveStudentsToTheirNewGradeModal,
    setShowMoveStudentsToTheirNewGradeModal,
  ] = useState(false)

  const [
    showRemoveSolutionsForGradesModal,
    setShowRemoveSolutionsForGradesModal,
  ] = useState(false)

  const [showSwitchStudentNamesModal, setShowSwitchStudentNamesModal] =
    useState(false)

  const [backupSchool, { loading: backingUpSchool }] = useMutation(
    backupSchoolMutation,
    { variables: { schoolId: getSchoolId() } }
  )

  const [showBackupSchoolModal, setShowBackupSchoolModal] = useState(false)

  const [showRestoreBackupModal, setShowRestoreBackupModal] = useState(false)

  const [showSendMissingIdsEmailsModal, setShowSendMissingIdsEmailsModal] =
    useState(false)

  if (loading) {
    return null
  }

  return (
    <>
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">Admin Actions</h2>
        <div className="mb-4">
          <CloneSchool school={school} />
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowBackupSchoolModal(true)}
            buttonText="Backup school">
            Take a backup of this school
          </SettingItem>
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowRestoreBackupModal(true)}
            buttonText="Restore backup">
            Restores a backup for this school
          </SettingItem>
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowConvertToCabinsModal(true)}
            buttonText="Convert to cabins"
            description={getFormattedDescription(
              introspectionData,
              "convertToCabins"
            )}>
            Convert this school to cabins
          </SettingItem>
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowConvertToActivityGroupsModal(true)}
            buttonText="Convert to Activity Groups"
            description={getFormattedDescription(
              introspectionData,
              "convertToActivityGroups"
            )}>
            Convert this school to activity groups
          </SettingItem>
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowMoveStudentsToTheirNewGradeModal(true)}
            buttonText="Roll up current students"
            description={getFormattedDescription(
              introspectionData,
              "moveStudentsToTheirNewGrade"
            )}>
            Roll up current students
          </SettingItem>
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowRemoveSolutionsForGradesModal(true)}
            buttonText="Remove Solutions by Grades"
            description={getFormattedDescription(
              introspectionData,
              "removeSolutionsForGrades"
            )}>
            Remove solutions for grades
          </SettingItem>
        </div>
        <div className="mb-4">
          <SettingItem
            onButtonClick={() => setShowSwitchStudentNamesModal(true)}
            buttonText="Switch student names">
            Switch student's first and last names by current grade
          </SettingItem>
        </div>
        {isSuper && (
          <div>
            <SettingItem
              onButtonClick={() => setShowSendMissingIdsEmailsModal(true)}
              buttonText="Send Missing Ids Emails">
              Sends emails to schools if there are students missing ids
            </SettingItem>
          </div>
        )}
      </div>
      {showConvertToCabinsModal && (
        <DangerousMutation
          dangerousMutation={convertToCabins}
          mutationLoading={convertingToCabins}
          cautionHeading="Converts school to cabins"
          cautionText="Are you sure you want to convert this school to cabins?"
          cautionButtonText="Yes, Proceed"
          successMessage="School has been converted"
          errorHandler={error => {
            return error.message
          }}
          toggleComponent={() => setShowConvertToCabinsModal(false)}
        />
      )}
      {showConvertToActivityGroupsModal && (
        <DangerousMutation
          dangerousMutation={convertToActivityGroups}
          mutationLoading={convertingToActivityGroups}
          cautionHeading="Convert school to activity groups"
          cautionText="Are you sure you want to convert this school to activity groups?"
          cautionButtonText="Yes, Proceed"
          successMessage="School has been converted"
          errorMessage="Could not convert school properly"
          toggleComponent={() => setShowConvertToActivityGroupsModal(false)}
        />
      )}
      {showMoveStudentsToTheirNewGradeModal && (
        <DangerousMutation
          dangerousMutation={moveStudentsToTheirNewGrade}
          mutationLoading={movingStudentsToTheirNewGrade}
          cautionHeading="move students to their new grade"
          cautionText="Are you sure you want to move students to their new grade?"
          cautionButtonText="Yes, Proceed"
          successMessage="Students have been moved to their new grade"
          errorMessage="Could not move students properly"
          toggleComponent={() => setShowMoveStudentsToTheirNewGradeModal(false)}
        />
      )}
      {showRemoveSolutionsForGradesModal && (
        <DeleteSolutionsByGradesModal
          schoolId={getSchoolId()}
          toggle={() => setShowRemoveSolutionsForGradesModal(false)}
        />
      )}
      {showSwitchStudentNamesModal && (
        <SwitchStudentNamesByCurrentGradeModal
          schoolId={getSchoolId()}
          toggle={() => setShowSwitchStudentNamesModal(false)}
        />
      )}
      {showBackupSchoolModal && (
        <DangerousMutation
          dangerousMutation={backupSchool}
          mutationLoading={backingUpSchool}
          cautionHeading="Backup this school"
          cautionText="Are you sure you want to take a backup of this school?"
          cautionButtonText="Yes, Proceed"
          successMessage="School successfully backed up"
          errorMessage="Failed to take a backup"
          toggleComponent={() => setShowBackupSchoolModal(false)}
        />
      )}
      {showRestoreBackupModal && (
        <RestoreBackupModal toggle={() => setShowRestoreBackupModal(false)} />
      )}

      {showSendMissingIdsEmailsModal && (
        <SendMissingIdsEmailsModal
          toggle={() => setShowSendMissingIdsEmailsModal(false)}
        />
      )}
    </>
  )
}
