import React from "react"
import { Modal, Button } from "reactstrap"
import { ModalHeader } from "components"

export const SolverPrompt = ({ isOpen, toggle, solveActiveGrade }) => {
  return (
    <>
      <Modal isOpen={isOpen} className="modal-dialog-centered u-modal__w-30rem">
        <div className="p-4">
          <ModalHeader title="Run Solver?" toggle={toggle} />
          <div className="d-flex flex-column align-items-center mt-3">
            <div className="mt-3 d-flex justify-content-between">
              <i className="fa fa-info-circle u-caution-icon mr-4" />
              <div>
                <div className="text-primary mb-3">IMPORTANT NOTICE</div>
                <div className="mb-3">
                  Clicking Run will completely replace your current lists with
                  new lists for the selected grade. If Students are locked (
                  <i className="fa fa-lock text-primary font-size-16" />
                  ), these students will not be moved.
                </div>
                <div>
                  You can still revert back to the original list by clicking on
                  'Undo' (
                  <i className="fa fa-undo text-primary font-size-15" />
                  ) or the Timeline (
                  <i className="fa fa-clock-o text-primary font-size-16" />
                  ).
                </div>
              </div>
            </div>
            <Button
              color="primary"
              className="mt-5 w-50"
              onClick={() => {
                toggle()
                solveActiveGrade()
              }}>
              Yes, Run
            </Button>
            <span
              className="text-primary p-3 pl-5 pr-5 mt-2 cursor-pointer"
              onClick={toggle}>
              Cancel
            </span>
          </div>
        </div>
      </Modal>
    </>
  )
}
