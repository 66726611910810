import React from "react"

import { CharacteristicHeaders } from "domains/reports/components"

export const ClassTableHeader = ({ schoolCharacteristics }) => (
  <thead className="c-reports-table__header bg-table-header">
    <tr>
      <th
        colSpan="6"
        className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
        STUDENT DETAILS
      </th>
      <th
        colSpan={schoolCharacteristics.length}
        className="c-reports-table__header__title color-total-text border-right py-3 pl-3">
        CHARACTERISTICS
      </th>
      <th
        colSpan="2"
        className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
        REQUESTS
      </th>
      <th
        colSpan="2"
        className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
        FRIENDSHIPS
      </th>
      <th
        colSpan="1"
        className="c-reports-table__header__title border-right color-total-text py-3 pl-3">
        NOTES
      </th>
    </tr>
    <tr>
      <th className="c-reports-table__header__label" />
      <th className="c-reports-table__header__label" />
      <th className="c-reports-table__header__label" />
      <th className="c-reports-table__header__label" />
      <th className="c-reports-table__header__label">Current Class</th>
      <th className="c-reports-table__header__label border-right">Gender</th>
      <CharacteristicHeaders schoolCharacteristics={schoolCharacteristics} />
      <th className="c-reports-table__header__label border-left" />
      <th className="c-reports-table__header__label" />
      <th className="c-reports-table__header__label border-left" />
      <th className="c-reports-table__header__label" />
      <th className="c-reports-table__header__label border-left border-right" />
    </tr>
  </thead>
)
