import { downloadAuthenticatedFile } from "util/downloadUtil"
import { getApiBaseUri } from "util/apiUtil"

const exportFile = async (url, context) => {
  const token = await context.getTokenSilently()

  if (!token) {
    return "No valid token found"
  }

  const response = await downloadAuthenticatedFile(token, url)

  if (!response.ok) {
    const errorMessage = await response.text()
    return [response, errorMessage]
  }

  return [response]
}

export const internalCsvExport = (
  dataType,
  schoolId,
  context,
  filterSensitive,
  gradeIds
) => {
  const gradeIdsParams = gradeIds && gradeIds.join(",")

  const params = gradeIdsParams
    ? {
        filter_sensitive: filterSensitive,
        grade_ids: gradeIdsParams,
      }
    : {
        filter_sensitive: filterSensitive,
      }

  const paramString = new URLSearchParams(params).toString()

  const url = `${getApiBaseUri()}/export/internal/${dataType}/${schoolId}?${paramString}`
  return exportFile(url, context)
}

export const adminReportCsvExport = (reportType, context) => {
  const url = `${getApiBaseUri()}/admin_reports/${reportType}`
  return exportFile(url, context)
}
