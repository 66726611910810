import React from "react"
import { Table } from "reactstrap"
import { SolverClassHeader } from "./solverClassHeader"
import { genderColor } from "util/studentUtil"
import { classesHaveMax } from "./solver"
import { fullName } from "util/nameUtil"

const chunkArray = (array, size) => {
  // Shamelessly stolen from https://medium.com/@Dragonza/four-ways-to-chunk-an-array-e19c889eac4
  const chunkedArray = []
  let index = 0
  while (index < array.length) {
    chunkedArray.push(array.slice(index, size + index))
    index += size
  }
  return chunkedArray
}

const PrintableTable = ({
  class: klass,
  activeGrade,
  showTeachers,
  hasMax,
  hasGenderX,
}) => {
  const sortedStudents = klass.students.slice().sort((a, b) => {
    if (a.newGrade.id === b.newGrade.id) return 0

    // activeGrade comes first
    if (a.newGrade.id === activeGrade.id) return -1
    if (b.newGrade.id === activeGrade.id) return 1

    // then sort by order
    return a.newGrade.order - b.newGrade.order
  })

  return (
    <Table className="printable-solver-table">
      <thead>
        <tr>
          <th className="table-header">
            <SolverClassHeader
              students={sortedStudents}
              classData={klass}
              isPrint={true}
              activeGradeId={activeGrade.id}
              hasMax={hasMax}
              hasGenderX={hasGenderX}
            />

            {showTeachers && (
              <>
                <div className="table-header-cell">
                  {klass.teachers[0] ? fullName(klass.teachers[0]) : ""}
                </div>
                <div className="table-header-cell">
                  {klass.teachers[1] ? fullName(klass.teachers[1]) : ""}
                </div>
              </>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedStudents.map((s, i) => {
          const studentColor = genderColor(s.gender)
          return (
            <tr key={i}>
              <td className={`table-cell ${studentColor}`}>
                <span className="float-left">{fullName(s)}</span>
                <span className="float-right">{s.newGrade.code}</span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export const PrintableSolver = ({
  solution: { classes, activeGrade },
  showTeachers,
  hasGenderX,
}) => {
  const chunkedClasses = chunkArray(classes, 6)
  const hasMax = classesHaveMax(classes)
  return (
    <>
      <div className="float-none">
        {chunkedClasses.map((classList, idx) => (
          <div key={idx} className="printable-solver-page">
            {classList.map((c, i) => (
              <PrintableTable
                key={i}
                class={c}
                activeGrade={activeGrade}
                showTeachers={showTeachers}
                hasMax={hasMax}
                hasGenderX={hasGenderX}
              />
            ))}
          </div>
        ))}
      </div>

      {classes.map((klass, idx) => {
        return (
          <div key={idx} className="printable-solver-page">
            <PrintableTable
              class={klass}
              activeGrade={activeGrade}
              showTeachers={showTeachers}
              hasMax={hasMax}
              hasGenderX={hasGenderX}
            />
          </div>
        )
      })}
    </>
  )
}
