import React from "react"
import { Input } from "reactstrap"
import { validateNonEmptyString } from "util/validators"

export const SearchInput = ({ placeholder, onChange, value }) => (
  <div className="c-search-input d-print-none">
    <Input
      className="c-search-input__input"
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    <span className="c-search-input__icon">
      {validateNonEmptyString(value) ? (
        <i
          className="fa fa-times color-grey-dark cursor-pointer"
          onClick={() => onChange(undefined)}
        />
      ) : (
        <i className="fa fa-search color-grey-mid" />
      )}
    </span>
  </div>
)
