import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import {
  inviteTeachersToSurveysMutation,
  teachersQuery,
} from "domains/teachers/graphql"
import { Modal, ModalBody, FormGroup, Label, Input } from "reactstrap"

import { INSTRUCTION_SURVEY_INVITE_PLAYER_URL } from "constants/videos"

import {
  Loader,
  InlineError,
  ButtonBar,
  ModalHeader,
  SuccessModal,
  VideoModal,
} from "components"
import {
  InviteTeachersTable,
  RestrictedSurveyTooltip,
} from "domains/teachers/components"

import { handleSessionExpired } from "util/app"

const initData = classes => {
  return classes.map(c => ({ ...c, teachers: [] }))
}

const transformForSelect = teachers => {
  return teachers.map(teacher => ({
    ...teacher,
    label: teacher.firstName + " " + teacher.lastName,
    value: teacher.id,
  }))
}

class InviteTeachersModalComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      successModal: false,
      showVideo: false,
      message: this.props.inviteMessage,
      classes: initData(this.props.classes),
      restricted: this.props.feederSchool ? true : false,
      errors: {},
    }
  }

  toggleSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
    })
  }

  openVideo = () => {
    this.setState({
      showVideo: true,
    })
  }

  closeVideo = () => {
    this.setState({
      showVideo: false,
    })
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onDataChange = (selectedTeachers, cId) => {
    const { classes } = this.state

    const idx = classes.findIndex(c => c.id === cId)

    classes[idx] = { ...classes[idx], teachers: selectedTeachers || [] }

    this.setState({
      classes: classes,
    })
  }

  handleRestrictedCheckboxChange = e => {
    this.setState({ restricted: e.target.checked })
  }

  sendInvites = () => {
    const { inviteTeachersToSurveysMutation, toggle, ccList, schoolId } =
      this.props
    const { message, classes, restricted } = this.state

    this.setState({ loading: true })

    const assignedTeachers = classes.flatMap(({ teachers }) => teachers)

    if (assignedTeachers.some(teacher => !teacher.email)) {
      this.setState({
        loading: false,
        errors: {
          dataError: "Please make sure all teachers have email addresses",
        },
      })
      return
    }

    // Build a map of classes with already assigned teachers
    // {t.id: [c.id, ...]}
    const alreadyAssignedTeacherClassesMap = this.props.classes.reduce(
      (acc, { id, teachers }) =>
        teachers.reduce((acc1, teacher) => {
          return { ...acc1, [teacher.id]: [...(acc1[teacher.id] || []), id] }
        }, acc),
      {}
    )

    // {t.id: [c.id, ...]}
    const teacherClassesMap = classes.reduce(
      (acc, { id: cId, teachers }) =>
        teachers.reduce((acc1, teacher) => {
          return { ...acc1, [teacher.id]: [...(acc1[teacher.id] || []), cId] }
        }, acc),
      {}
    )

    const classAssignments = Object.entries(teacherClassesMap).reduce(
      (acc, [teacherId, classIds]) => [
        ...acc,
        {
          teacherId: teacherId,
          // Add any already assigned classes to the teacher
          currentClassIds: classIds.concat(
            alreadyAssignedTeacherClassesMap[teacherId] || []
          ),
        },
      ],
      []
    )

    if (classAssignments.length > 0) {
      inviteTeachersToSurveysMutation({
        variables: { message, classAssignments, ccList, schoolId, restricted },
        refetchQueries: [{ query: teachersQuery, variables: { schoolId } }],
      })
        .then(() => {
          // Open success modal and close invite modal
          this.setState({ loading: false })
          this.toggleSuccessModal()
          toggle()
        })
        .catch(error => {
          handleSessionExpired(error)

          this.setState({
            loading: false,
            errors: { mutation: "There was an Error, please try again" },
          })
        })
    } else {
      this.setState({
        loading: false,
        errors: {
          noAssignments: "Please assign at least one teacher to one class",
        },
      })
    }
  }

  render() {
    const { isOpen, toggle, teachers } = this.props
    const { loading, showVideo, classes, errors, restricted } = this.state

    return (
      <div>
        <Modal isOpen={isOpen} className="modal-dialog-centered">
          {/* <div className="row"> */}
          <ModalHeader
            title="Invite Teachers to Survey"
            toggle={toggle}
            toggle2={this.openVideo}
            className="px-5 py-4"
          />
          {/* </div> */}
          {loading ? (
            <div className="position-relative p-5">
              <Loader />
            </div>
          ) : (
            <ModalBody className="px-5 py-4">
              <FormGroup>
                <div className="d-flex justify-content-between">
                  <Label className="c-teachers-invite-modal__text pb-2">
                    MESSAGE
                  </Label>
                  {this.props.feederSchool && (
                    <div>
                      <Label>
                        <Input
                          type="checkbox"
                          id="restricted"
                          name="restricted"
                          checked={restricted}
                          onChange={this.handleRestrictedCheckboxChange}
                        />
                        Feeder School Survey
                      </Label>
                      <RestrictedSurveyTooltip id="inviteTeachersRestrictedSurveyTooltip" />
                    </div>
                  )}
                </div>
                <Input
                  type="textarea"
                  className="c-teachers-invite-modal__message"
                  name="message"
                  value={this.state.message}
                  rows={5}
                  onChange={this.onChange}
                />
              </FormGroup>
              <h3 className="c-teachers-invite-modal__text pt-4">
                ALLOCATE TEACHERS
              </h3>
              <InviteTeachersTable
                classes={classes}
                teachers={transformForSelect(teachers)}
                onDataChange={this.onDataChange}
              />
              <div className="d-flex flex-column align-items-end mt-3">
                <ButtonBar
                  onCancelClick={toggle}
                  onButtonClick={this.sendInvites}
                  buttonText="Send Survey"
                />
                <InlineError text={errors.noAssignments} />
                <InlineError text={errors.mutation} />
                <InlineError text={errors.dataError} />
              </div>
            </ModalBody>
          )}
        </Modal>
        <SuccessModal
          isOpen={this.state.successModal}
          toggle={this.toggleSuccessModal}
          heading="Survey invite sent to Teachers"
          text="A copy of the invite is also sent to Administrators."
        />
        {showVideo && (
          <VideoModal
            toggle={this.closeVideo}
            videoUrl={INSTRUCTION_SURVEY_INVITE_PLAYER_URL}
            title="Instruction Step 1"
          />
        )}
      </div>
    )
  }
}

export const InviteTeachersModal = graphql(inviteTeachersToSurveysMutation, {
  name: "inviteTeachersToSurveysMutation",
})(InviteTeachersModalComponent)
