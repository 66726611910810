import React, { useState, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Button, Label } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { Loader } from "components"
import { getSchoolId } from "util/app"

import ExcelIcon from "images/excel-icon.svg"
import { useCreateBulkUploadJob } from "domains/upload/hooks/useCreateBulkUploadJob"

import friendshipPreferencesTemplate from "templateFiles/friendship_preferences_template.csv"

import { UploadErrorModal } from "domains/upload"

import { warnings } from "domains/students/upload/warnings"

import { schoolUploadsQuery } from "domains/upload/graphql"
import { pendingImport } from "domains/students/pendingImport"

import { allNamesMatched } from "./FriendshipPreferencesMapStudents"

import { IMPORT_BULK_UPLOAD_JOB } from "domains/upload/hooks/useBulkUploadJob"

export const FriendshipPreferencesUpload = () => {
  const schoolId = getSchoolId()

  // Check if there are current jobs
  const { data: schoolUploads, loading: loadingUploads } = useQuery(
    schoolUploadsQuery,
    {
      variables: { schoolId },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  )

  if (loadingUploads) {
    return <Loader />
  }

  const { job: currentUploadJob } = pendingImport(
    schoolUploads,
    "FRIENDSHIP_PREFERENCE"
  )

  return (
    <FriendshipPreferencesUploadComponent currentUploadJob={currentUploadJob} />
  )
}

const FriendshipPreferencesUploadComponent = ({ currentUploadJob }) => {
  // Utility for manipulating the router
  const navigate = useNavigate()

  // Redirect to the correct page if we already have an existing job
  useEffect(() => {
    if (currentUploadJob) {
      navigate("/Students/FriendshipPreferences/MapStudents")
    }
  })

  const schoolId = getSchoolId()

  // Keep a piece of state which equals the file which the user selected
  const [selectedFile, setSelectedFile] = useState(undefined)

  // Bring in a hook which allows us to create a new upload job
  const [createUploadJob, { loading }] = useCreateBulkUploadJob()

  // Handle when a user selects a new file by updating state accordingly
  const handleFileSelect = event => {
    setSelectedFile(event.target.files[0])
  }

  // State to hold onto create job errors
  const [createJobError, setCreateJobError] = useState()

  // Set up a mutation to import the upload job
  const [importUploadedJobMutation, { loading: loadingImport }] = useMutation(
    IMPORT_BULK_UPLOAD_JOB
  )

  const [warningCode, warningDetails, errorJobId] =
    createJobError && createJobError.graphQLErrors.length > 0
      ? [
          createJobError.graphQLErrors[0].message,
          createJobError.graphQLErrors[0].details,
          createJobError.graphQLErrors[0].job_id,
        ]
      : [undefined, undefined, undefined]

  // Handle when the user clicks the 'Confirm and Upload' button
  const handleConfirm = useCallback(() => {
    // Attempt to create the new upload job. On success, redirect to the next
    // screen or show the user an error

    return createUploadJob({
      variables: {
        schoolId,
        file: selectedFile,
        type: "FRIENDSHIP_PREFERENCE",
      },
    })
      .then(result => {
        if (result.data && result.data.createBulkUploadJob) {
          const uploadJob = result.data.createBulkUploadJob

          if (allNamesMatched(uploadJob)) {
            // Just import the job directly if everything matches
            importUploadedJobMutation({ variables: { jobId: uploadJob.id } })
              .then(() => {
                navigate("/Students")
              })
              .catch(error => {
                setCreateJobError(error)
              })
          } else {
            navigate("/Students/FriendshipPreferences/MapStudents")
          }
        }
      })
      .catch(error => {
        setCreateJobError(error)
      })
  }, [
    createUploadJob,
    selectedFile,
    navigate,
    schoolId,
    importUploadedJobMutation,
  ])

  // Go back to Students page
  const handleBackToStudentsPage = () => navigate("/Students")

  // Render the file picker interface if no file is currently selected
  if (selectedFile === undefined) {
    return (
      <>
        <div className="row m-4">
          <h1 className="mx-4">Import Friendship Preferences</h1>
          <a
            className="color-blue-mid mx-4 mt-1 font-size-14"
            href="https://classsolver.com/kb-fp/"
            target="_blank"
            rel="noopener noreferrer">
            <i className="fa fa-info-circle mr-2" />
            Learn more
          </a>
        </div>

        <div className="c-solver u-row-fix u-layout-component-grow ">
          <div className="c-solver__classes-container container-fluid ">
            <div className="container-fluid d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
                <img height="150px" src={ExcelIcon} alt="Excel Icon" />

                <h2 className="my-2 text-center">
                  1. Download and complete the friendship preferences template
                </h2>

                <p className="py-2 text-center">
                  <a
                    href={friendshipPreferencesTemplate}
                    download={`friendship_preferences_template.csv`}>
                    <Button className="btn-200-width" color="primary">
                      Download Template
                    </Button>
                  </a>
                </p>

                <h2 className="my-2 text-center">
                  2. Then upload the completed file
                </h2>

                <input
                  type="file"
                  id="uploadedFile"
                  name="uploadedFile"
                  onChange={handleFileSelect}
                  hidden
                />

                <p className="py-2 mb-0 text-center">
                  <a href="#">
                    <Label
                      htmlFor="uploadedFile"
                      className="btn-200-width btn-secondary">
                      Upload Completed File
                    </Label>
                  </a>
                </p>

                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleBackToStudentsPage}>
                  {"< Back to Students page"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  // A file has been selected, so let's render the confirmation interface
  else {
    return (
      <>
        <div className="row m-4">
          <h1 className="mx-4">Import Friendship Preferences</h1>
          <a
            className="color-blue-mid mx-4 mt-1 font-size-14"
            href="https://classsolver.com/kb-fp/"
            target="_blank"
            rel="noopener noreferrer">
            <i className="fa fa-info-circle mr-2" />
            Learn more
          </a>
        </div>

        <div className="c-solver u-row-fix u-layout-component-grow">
          <div className="c-solver__classes-container container-fluid ">
            <div className="container-fluid d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column justify-content-center align-items-center align-self-center u-layout-component-grow">
                <img height="150px" src={ExcelIcon} alt="Excel Icon" />

                <h2 className="m-2">
                  3. You have selected the following file to upload:
                </h2>

                <div className="m-2 row flex-column w-75 align-items-center">
                  <div className="m-2 u-text-20 u-bold">
                    {selectedFile.name}
                  </div>
                  {loadingImport && (
                    <div>
                      <Loader className="loader-container-relative" />
                    </div>
                  )}
                  <button
                    onClick={handleConfirm}
                    disabled={loading || loadingImport}
                    className="btn btn-primary m-2">
                    Confirm and Upload
                  </button>
                  <input
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    onChange={handleFileSelect}
                    hidden
                  />
                  <label htmlFor="fileUpload" className="btn btn-secondary m-2">
                    Choose Another File
                  </label>

                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={handleBackToStudentsPage}>
                    {"< Back to Students page"}
                  </button>

                  <div className="c-note d-flex flex-column mx-auto my-3">
                    <div className="my-2">
                      <i className="fa fa-info-circle mr-2"></i>
                      <b>Important</b>
                    </div>

                    <p>
                      <span className="font-weight-bold">Same names: </span>
                      This function may not work properly if you have two or
                      more students with <u>exactly</u> the same first name and
                      surname at your school. If this is the case, please let us
                      know at
                      <a
                        href="mailto:support@classsolver.com"
                        className="ml-1 color-blue-mid">
                        support@classsolver.com
                      </a>{" "}
                      and we will assist you with the import.
                    </p>

                    <p>
                      <span className="font-weight-bold">
                        Column headings:{" "}
                      </span>
                      Please ensure the imported file contains the column
                      headings as shown in the template.
                    </p>

                    <p>
                      <span className="font-weight-bold">
                        Replacing friends:{" "}
                      </span>
                      If friendship preferences have already been entered into
                      Class Solver, this function will over-write existing
                      friendship preferences for the imported student.
                    </p>

                    <p>
                      <span className="font-weight-bold">File format: </span>
                      If you are using a PC, please make sure the file is saved
                      as Comma delimited (.csv). If you are using a Mac, please
                      save the file as UTF-8 (.csv).
                    </p>
                  </div>
                  {createJobError && (
                    <UploadErrorModal
                      isOpen
                      title={warnings[warningCode].title}
                      type="Friendship Preferences"
                      jobId={errorJobId}
                      toggle={() => setCreateJobError(undefined)}
                      actions={warnings[warningCode].actions()}>
                      {warnings[warningCode].body(
                        "Friendship Preferences",
                        warningDetails
                      )}
                    </UploadErrorModal>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
