import { isDefinedNotNull, getPropertyIfDefined } from "util/objUtil"
import { UNAUTHORIZED, NO_ACCESS } from "constants/errorFields"
import { PAYMENT_STATUS } from "constants/payments"
import { SCHOOL_ID } from "constants/storageTokens"

export const handleSessionExpired = error => {
  const errorMessage = getPropertyIfDefined(error, "message")
  if (
    isDefinedNotNull(errorMessage) &&
    (errorMessage.includes(UNAUTHORIZED.key) ||
      errorMessage.includes(NO_ACCESS.key))
  ) {
    window.location.href = "Logout?expired=true"
  }
}

const eq = right => left => right === left

export const canAccessSolver = eq(PAYMENT_STATUS.PAID)

export const getSchoolId = () => sessionStorage.getItem(SCHOOL_ID)

export const removeSchoolId = () => sessionStorage.removeItem(SCHOOL_ID)

export const queriesAreLoading = queries => {
  const calledQueries = Object.values(queries).filter(
    query => query?.called === true
  )
  return calledQueries.some(query => query.loading)
}
