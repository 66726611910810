import React, { useState, useEffect } from "react"

import { CautionModal } from "components"

const MoreInfoModal = ({ toggle }) => {
  const text = (
    <div>
      <p>Your account has been rolled over for the new year.</p>

      <div>
        <b>What’s next?</b>
      </div>
      <p>
        Please upload a new list of your current students. Need help? Watch the
        Step 1 video on the Dashboard.
      </p>

      <div>
        <b>Why do I need to re-upload my student list?</b>
      </div>

      <p>
        Re-importing your student list ensures that you don’t have to manually
        track who has left or joined the school since last year.
      </p>

      <div>
        <b>Will last year’s data be retained?</b>
      </div>

      <p>
        Yes, absolutely! All your Requests, Friendship Preferences,
        Characteristic responses, and Notes will automatically merge with the
        new student list. Just make sure to use the same Student ID format as
        last year.
      </p>

      <div>
        <b>How do I check the Student ID format I used?</b>
      </div>

      <p>
        You can download last year’s student data file from <b>Settings</b>{" "}
        under Download Student History.
      </p>
    </div>
  )
  return (
    <CautionModal
      isOpen
      onButtonClick={toggle}
      toggle={toggle}
      heading="Where are my students?"
      text={text}
      buttonText="OK, I've got it"
      showCancel={false}
      showNotice={false}
      showCautionIcon={false}
    />
  )
}

export const RolledOverNote = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem("ROLLOVER_INFO_ACK") !== "true") {
      setTimeout(() => {
        setShowMoreInfo(true)
      }, 3000)
    }
  }, [])

  const toggle = () => {
    sessionStorage.setItem("ROLLOVER_INFO_ACK", true)
    setShowMoreInfo(prevState => !prevState)
  }

  return (
    <>
      <div className="c-modal-note text-center">
        Your account has been rolled over. Please re-import a new list of your
        current students using the template below.{" "}
        <span className="cursor-pointer text-primary" onClick={toggle}>
          Learn more
        </span>
      </div>
      {showMoreInfo && <MoreInfoModal toggle={toggle} />}
    </>
  )
}
