import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import {
  inviteTeachersToSurveysMutation,
  teachersQuery,
} from "domains/teachers/graphql"
import {
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap"
import Select from "react-select"

import { INSTRUCTION_SURVEY_INVITE_PLAYER_URL } from "constants/videos"

import {
  Loader,
  InlineError,
  ButtonBar,
  ModalHeader,
  SuccessModal,
  defaultSelectStyles,
  VideoModal,
} from "components"

import { isDefinedNotNull } from "util/objUtil"
import { isNonEmptyArray } from "util/array"

import { handleSessionExpired } from "util/app"

import { RestrictedSurveyTooltip } from "domains/teachers/components"

class InviteSingleTeacherModalComponent extends React.Component {
  constructor(props) {
    super(props)

    const { teacher, inviteMessage } = this.props

    const allocatedClasses = isNonEmptyArray(teacher.allocatedClasses)
      ? teacher.allocatedClasses
      : teacher.currentClasses

    this.state = {
      successModal: false,
      showVideo: false,
      allocatedClasses: allocatedClasses.map(this.transformClass),
      inviteMessage: inviteMessage,
      errors: {},
      restricted: teacher.restricted,
    }
  }

  toggleSuccessModal = () => {
    this.setState({
      successModal: !this.state.successModal,
    })
  }

  openVideo = () => {
    this.setState({
      showVideo: true,
    })
  }

  closeVideo = () => {
    this.setState({
      showVideo: false,
    })
  }

  transformClass = c => ({
    id: c.id,
    label: c.label,
    value: c.id,
  })

  handleClassChange = classes => this.setState({ allocatedClasses: classes })

  handleInviteMessageChange = (e, changeInviteMessage) => {
    const message = e.target.value
    this.setState({ inviteMessage: message })
    changeInviteMessage(message)
  }

  handleRestrictedCheckboxChange = e => {
    this.setState({ restricted: e.target.checked })
  }

  sendInvites = () => {
    const {
      inviteTeachersToSurveysMutation,
      teacher,
      ccList,
      schoolId,
      toggle,
      sendNotification,
    } = this.props
    const { inviteMessage, allocatedClasses, restricted } = this.state

    this.setState({ loading: true })

    const classAssignments = {
      teacherId: teacher.id,
      currentClassIds: allocatedClasses.map(c => c.id),
    }

    const hasAllocatedClasses = isNonEmptyArray(allocatedClasses)

    inviteTeachersToSurveysMutation({
      variables: {
        message: inviteMessage,
        classAssignments,
        ccList,
        schoolId,
        restricted,
        sendNotification,
      },
      refetchQueries: [{ query: teachersQuery, variables: { schoolId } }],
    })
      .then(() => {
        this.setState({ loading: false })
        hasAllocatedClasses ? this.toggleSuccessModal() : toggle()
      })
      .catch(error => {
        handleSessionExpired(error)

        this.setState({
          loading: false,
          errors: { mutation: "There was an error, please try again" },
        })
      })
  }

  render() {
    const {
      toggle,
      classes,
      changeInviteMessage,
      teacher,
      feederSchool,
      sendNotification,
    } = this.props
    const {
      loading,
      showVideo,
      errors,
      allocatedClasses,
      inviteMessage,
      restricted,
    } = this.state
    const availableClasses = classes.map(this.transformClass)
    const teacherName = `${teacher.firstName} ${teacher.lastName}`
    const emailExists = isDefinedNotNull(teacher.email)
    const hasAllocatedClasses = isNonEmptyArray(allocatedClasses)

    const showFeederSchoolCheckbox = teacher.restricted || feederSchool

    return (
      <div>
        <Modal isOpen className="modal-dialog-centered">
          <ModalHeader
            title={
              sendNotification
                ? `Invite ${teacherName} to complete survey`
                : `Update survey for ${teacherName}`
            }
            toggle={toggle}
            toggle2={this.openVideo}
            className="px-5 py-4"
          />
          {loading ? (
            <div className="position-relative p-5">
              <Loader />
            </div>
          ) : (
            <ModalBody className="px-5 py-4">
              <FormGroup>
                <div className="d-flex justify-content-between">
                  <Label className="c-teachers-invite-modal__text pb-2">
                    MESSAGE
                  </Label>
                  {showFeederSchoolCheckbox && (
                    <div>
                      <Label>
                        <Input
                          type="checkbox"
                          id="restricted"
                          name="restricted"
                          checked={restricted}
                          onChange={this.handleRestrictedCheckboxChange}
                        />
                        Feeder School Survey
                      </Label>
                      <RestrictedSurveyTooltip id="inviteSingleTeacherRestrictedSurveyTooltip" />
                    </div>
                  )}
                </div>
                <Input
                  type="textarea"
                  className="c-teachers-invite-modal__message"
                  name="message"
                  value={inviteMessage}
                  rows={5}
                  onChange={e =>
                    this.handleInviteMessageChange(e, changeInviteMessage)
                  }
                />
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <Label className="c-teachers-invite-modal__text pb-2">
                    ASSIGNED CLASSES
                  </Label>
                  <Select
                    className="c-input__multiselect"
                    placeholder="Select class(es)"
                    options={availableClasses}
                    value={allocatedClasses}
                    onChange={this.handleClassChange}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={defaultSelectStyles}
                  />
                </InputGroup>
              </FormGroup>
              <div className="d-flex flex-column align-items-end mt-3">
                <ButtonBar
                  onCancelClick={toggle}
                  onButtonClick={this.sendInvites}
                  buttonText={`${sendNotification ? "Send" : "Update"} Survey`}
                  disabled={!emailExists || !hasAllocatedClasses}
                />
                <InlineError text={errors.mutation} />
                {emailExists || (
                  <InlineError text="Teacher is missing an email" />
                )}
              </div>
            </ModalBody>
          )}
        </Modal>
        {this.state.successModal &&
          (sendNotification ? (
            <SuccessModal
              isOpen
              toggle={toggle}
              heading="Survey invite has been sent to the teacher"
              text="A copy of the invite has also been sent to Administrators."
            />
          ) : (
            <SuccessModal
              isOpen
              toggle={toggle}
              heading="Survey Updated"
              text={
                <div>
                  The survey has been updated. The teacher can use the original
                  email link.
                  <br />
                  <br />
                  Please ask the teacher to refresh their screen to see the
                  changes. No new email has been sent.
                </div>
              }
            />
          ))}
        {showVideo && (
          <VideoModal
            toggle={this.closeVideo}
            videoUrl={INSTRUCTION_SURVEY_INVITE_PLAYER_URL}
            title="Instruction Step 1"
          />
        )}
      </div>
    )
  }
}

export const InviteSingleTeacherModal = graphql(
  inviteTeachersToSurveysMutation,
  {
    name: "inviteTeachersToSurveysMutation",
  }
)(InviteSingleTeacherModalComponent)
