import React, { useState } from "react"
import { Button, Modal, Input, Label } from "reactstrap"
import { InlineError, ModalHeader, Loader } from "components"

export const SchoolDeleteModal = ({
  toggle,
  heading,
  text,
  error,
  loading,
  onButtonClick,
}) => {
  const [confirmationText, setConfirmationText] = useState("")
  const disabled = confirmationText.toLowerCase() !== "yes"
  const handleTextChange = e => setConfirmationText(e.target.value)

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <div className="p-4">
        <ModalHeader title={heading} toggle={toggle} />
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="d-flex flex-column align-items-center mt-2">
              <div className="mt-3 d-flex justify-content-between">
                <i className="fa fa-info-circle u-caution-icon mr-4" />
                <div className="u-white-space-pre-wrap">
                  <span className="text-primary mb-3">CAUTION: </span>
                  This action cannot be undone. {text}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <Label>Please type 'yes' to confirm</Label>
              <Input
                type="text"
                value={confirmationText}
                onChange={handleTextChange}
              />
              <Button
                color="primary"
                className="w-100 mt-4"
                onClick={onButtonClick}
                disabled={disabled}>
                Yes, Delete
              </Button>
              <div
                onClick={toggle}
                className="w-100 mt-3 text-center text-primary cursor-pointer py-2">
                Cancel
              </div>
              <InlineError text={error} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
