import React, { Component, useState } from "react"
import { useMutation } from "@apollo/client"
import { Form, FormGroup, Button, Label } from "reactstrap"
import { InlineError, Loader } from "components"
import { bulkUploadMiscMutation } from "domains/accountSettings/graphql"

const ImportFile = ({
  mutation,
  schoolId,
  fileSelect,
  uploadFile,
  isLoading,
  loadingFile,
}) => {
  // inputKey allows us to reset the state of the file input
  const [inputKey, setInputKey] = useState(Date.now())
  const [doMutation, { data, error }] = useMutation(mutation, {
    onError: () => {
      loadingFile(false)
    },
    onCompleted: () => {
      loadingFile(false)
      setInputKey(Date.now())
    },
  })

  const onSubmit = e => {
    e.preventDefault()
    loadingFile(true)
    if (uploadFile) {
      doMutation({
        variables: {
          schoolId,
          file: uploadFile,
        },
      })
    }
  }

  return (
    <>
      <div>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <input
              key={inputKey}
              type="file"
              name="fileUpload"
              id="fileUpload"
              hidden
              onChange={fileSelect}
            />
            <Label for="fileUpload" className="c-button--secondary">
              {uploadFile ? "Choose Another File" : "Upload File"}
            </Label>
          </FormGroup>
          {uploadFile ? (
            <>
              <div className="pb-4 text-center c-misc-upload__success">
                File to upload: <b>{uploadFile.name}</b>
              </div>
              <div className="pb-4">
                <Button className="c-button--secondary">
                  Confirm & Upload File
                </Button>
              </div>
            </>
          ) : null}
        </Form>
      </div>
      {isLoading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : null}
      {error && (
        <InlineError
          className="text-center c-misc-upload__success"
          text={"Upload unsuccessful. Please try again or contact support"}
        />
      )}
      {data && (
        <div className="text-success text-center c-misc-upload__success">
          <p className="font-weight-bold">File successfully uploaded!</p>
          <p>We will be in touch within 24 hours regarding next steps</p>
        </div>
      )}
    </>
  )
}

export class MiscFileUpload extends Component {
  state = {
    uploadFile: null,
    isLoading: false,
    showDownloadStudentHistories: false,
  }
  fileSelect = e => {
    this.setState({
      uploadFile: e.target.files[0],
    })
  }

  loadingFile = status => {
    this.setState({
      isLoading: status,
    })
  }

  toggleDownloadModal = () => {
    this.setState({
      showDownloadStudentHistories: true,
    })
  }

  render() {
    const { school } = this.props
    return (
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">Safe File Drop</h2>
        <div className="d-flex justify-content-between">
          <div className="c-misc-upload__description">
            Safely transfer a file to the ClassSolver Support Team.
          </div>
          <div>
            <ImportFile
              schoolId={school.id}
              mutation={bulkUploadMiscMutation}
              fileSelect={this.fileSelect}
              uploadFile={this.state.uploadFile}
              isLoading={this.state.isLoading}
              loadingFile={this.loadingFile}
            />
          </div>
        </div>
      </div>
    )
  }
}
