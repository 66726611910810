import React from "react"
import { useQuery } from "@apollo/client"
import { SharedSolutionPage } from "../../solver/pages/sharedSolution"
import { PERMISSIONS } from "constants/storageTokens"
import { ReportSociogramPage } from "domains/reports/pages"
import { loginWithSolutionTokenMutation } from "../graphql"
import {
  TOKEN_EXPIRED,
  TOKEN_NOT_FOUND,
  INVALID_TOKEN,
} from "domains/auth/errorFields"
import { useParams, useLocation } from "react-router-dom"
import { SHARED_SOLUTION } from "domains/auth/constants"

import { useTokenLogin } from "../hooks/useTokenLogin"
import { AccountTypeProvider } from "config/accountTypeContext"
import { schoolQuery } from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"
import { ErrorModal } from "config/routes"

export const SharedSolutionLoginPage = () => {
  const params = useParams()
  const location = useLocation()

  const errorMessages = {
    [TOKEN_EXPIRED]: {
      message:
        "To access shared class lists, please click below to get a new link sent to your email.",
      info: {
        title: "Why do I need a new link to access shared lists?",
        body: "For your security, the links can only be opened a certain number of times within a set period of time.  However, you can easily send yourself a new link by clicking “Send email with new link”. ",
      },
    },
    [TOKEN_NOT_FOUND]: {
      message:
        "This link is no longer available.  Please contact your school administrator if you would like it re-opened.",
    },
    [INVALID_TOKEN]: {
      message:
        "This link is invalid or incomplete.  Please double check that you have copied the entire link and try again.",
    },
  }

  const [authorized, AuthComponent, componentProps, response] = useTokenLogin({
    tokenType: SHARED_SOLUTION,
    loginMutation: loginWithSolutionTokenMutation,
    mutationName: "loginWithSolutionToken",
    errorMessages,
  })

  const {
    data: schoolData,
    loading: loadingSchool,
    error: loadSchoolError,
  } = useQuery(schoolQuery, {
    variables: { schoolId: getSchoolId() },
    fetchPolicy: "network-only",
    skip: !authorized,
  })

  if (loadingSchool) {
    return null
  }

  if (loadSchoolError) {
    return <ErrorModal error={loadSchoolError} />
  }

  if (authorized) {
    if (response && response.permissions) {
      const permissions = response.permissions
      // Set it into the session for use later on
      sessionStorage.setItem(PERMISSIONS, JSON.stringify(permissions))
    }

    // TODO: Explore the security of this
    const permissions = JSON.parse(sessionStorage.getItem(PERMISSIONS))

    // Access to the sociogram
    if (params.subPath === "sociogram" && permissions.showFriendships) {
      const pathname = location.pathname

      const solverPath =
        pathname.substring(0, pathname.lastIndexOf("/")) + location.search

      return (
        <AccountTypeProvider school={schoolData.school}>
          <ReportSociogramPage solverPath={solverPath} />
        </AccountTypeProvider>
      )
    }

    return (
      <AccountTypeProvider school={schoolData.school}>
        <SharedSolutionPage />
      </AccountTypeProvider>
    )
  } else {
    return <AuthComponent {...componentProps} />
  }
}
