import React, { useState } from "react"
import { Button } from "reactstrap"

import { HelpModal } from "domains/upload/helpModal"
import { ButtonBar } from "components/buttonBar"

export const IdChangeWarning = ({
  currentUploadJob,
  handleCancel,
  handleContinue,
}) => {
  const [showHelpModal, setShowHelpModal] = useState(false)

  const toggleHelpModal = () => setShowHelpModal(prevState => !prevState)

  return (
    <div className="container mt-5 pt-5 w-lg-75">
      <div className="mt-5 d-flex flex-row align-items-center">
        <h1 className="mb-0">Possible Student ID format change</h1>

        <Button
          className="u-font-weight-medium"
          color="link"
          onClick={toggleHelpModal}>
          Need Help Importing?
        </Button>

        <HelpModal
          isOpen={showHelpModal}
          toggle={toggleHelpModal}
          jobId={currentUploadJob.id}
          onSentNotification={() => {
            toggleHelpModal()
            handleCancel()
          }}
          type="No student code matches"
        />
      </div>

      <div className="pt-3 pb-4">
        Looks like you may have used a different Student ID format to last time
        or haven’t included a Student ID in your file. As a result, we did not
        find any matching student history (e.g. requests, friendships,
        characteristics). If you are unsure how to proceed, please click on
        “Need Help Importing” and we will contact you.
        <br />
        <br />
        Would you still like to continue importing students with a different
        Student ID format (or no Student ID)?
      </div>

      <div className="u-content-border-bottom">&nbsp;</div>

      <div className="mt-3 d-flex justify-content-end align-items-center">
        <ButtonBar
          buttonText="Yes"
          cancelText="No"
          className="p-3"
          onButtonClick={handleContinue}
          onCancelClick={handleCancel}
        />
      </div>
    </div>
  )
}
