import React, { Component } from "react"
import { StudentHistoriesModal } from "domains/students/components"
import { AccountTypeContext } from "config/accountTypeContext"

export class DownloadStudentHistory extends Component {
  static contextType = AccountTypeContext

  state = {
    showDownloadStudentHistories: false,
  }

  toggleDownloadModal = () => {
    this.setState({
      showDownloadStudentHistories: true,
    })
  }

  render() {
    const gettextObj = this.context.gettextObj

    return (
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">Download Student History</h2>

        <div className="d-flex justify-content-between mb-4 align-items-center">
          <div className="c-misc-upload__description">
            Every year an extract of your account is archived into an Excel file
            (e.g. Students, Friendship Preferences, Requests, Characteristics,
            Notes, and {gettextObj.gettext("Classes")}). Click on Download to
            view and access the available files.
          </div>
          <div
            className="c-button--secondary"
            onClick={this.toggleDownloadModal}>
            Download
          </div>
        </div>

        {this.state.showDownloadStudentHistories && (
          <StudentHistoriesModal
            toggle={() => {
              this.setState({
                showDownloadStudentHistories: false,
              })
            }}
          />
        )}
      </div>
    )
  }
}
