import React from "react"
import { Link } from "react-router-dom"
import { AccountTypeContext } from "config/accountTypeContext"

export const UnallocatedStudentsNote = ({ notification }) => {
  const unallocatedStudentsString = notification
    ? notification.title.toLowerCase()
    : ""
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <>
      <div className="c-modal-expired-note text-center pop">
        You have {unallocatedStudentsString}. Please click{" "}
        <Link
          style={{ textDecoration: "underline" }}
          to={{
            pathname: "/Students",
            search: new URLSearchParams({
              filterId: "unallocated-students",
            }).toString(),
          }}>
          here
        </Link>
        , open the student's card, select 'Edit', and assign a 'New{" "}
        {gettextObj.gettext("Class")}'.
      </div>
    </>
  )
}
