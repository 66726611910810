import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

import { Loader, InlineError, ModalHeader, ButtonBar } from "components"
import { StudentInputs } from "domains/students/components"

import { INSTRUCTION_ADD_AND_REMOVE_STUDENT_PLAYER_URL } from "constants/videos"
import { VideoModal } from "components"

export const AddModal = ({
  loading,
  isOpen,
  toggle,
  student,
  onChange,
  onCurrentClassChange,
  errors,
  onGenderChange,
  addStudent,
  newSchoolGrades,
  currentSchoolGrades,
  currentClasses,
  newClasses,
  onBulkUploadClick,
}) => {
  const [showVideo, setShowVideo] = useState(false)

  const toggleVideo = () => setShowVideo(prevState => !prevState)

  return (
    <>
      <Modal isOpen={isOpen} className="modal-dialog-centered">
        <ModalHeader
          title="Add Student"
          toggle={toggle}
          toggle2={toggleVideo}
          className="px-4 py-3"
        />

        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <ModalBody className="px-4">
            {/* Input Fields */}
            <StudentInputs
              student={student}
              onChange={onChange}
              onCurrentClassChange={onCurrentClassChange}
              onGenderChange={onGenderChange}
              errors={errors}
              currentSchoolGrades={currentSchoolGrades}
              newSchoolGrades={newSchoolGrades}
              currentClasses={currentClasses}
              newClasses={newClasses}
              addStudent
            />

            {/* Button Bar */}
            <div className="d-flex justify-content-between align-items-center">
              <span
                className="text-primary cursor-pointer"
                onClick={onBulkUploadClick}>
                Bulk Import Students
              </span>
              <ButtonBar
                onCancelClick={toggle}
                onButtonClick={addStudent}
                buttonText="Add Student"
                className="my-3"
              />
            </div>
            <InlineError text={errors.mutation} />
          </ModalBody>
        )}
      </Modal>
      {showVideo && (
        <VideoModal
          toggle={toggleVideo}
          videoUrl={INSTRUCTION_ADD_AND_REMOVE_STUDENT_PLAYER_URL}
          title="Add & Remove Students"
        />
      )}
    </>
  )
}
