export const detectBrowser = () => {
  // Stolen from https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browsers

  // Opera 8.0+
  var isOpera =
    (!!window.opr && !!window.opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined"

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    )

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia

  // Chrome 1 - 79
  var isChrome = !!window.chrome && !isOpera

  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS

  return {
    isOpera,
    isFirefox,
    isSafari,
    isIE,
    isEdge,
    isChrome,
    isEdgeChromium,
    isBlink,
  }
}
