import React from "react"

import { StickyTable, Row, Cell } from "react-sticky-table"

export class Table extends React.Component {
  renderHeaderSection(className) {
    const { sections, headers, tableAction } = this.props

    const modifiedHeaders = headers.slice()
    modifiedHeaders.unshift({
      name: "",
      id: "rowCount",
      section: headers[0].section,
    })

    const firstHeadersOfSections = modifiedHeaders.filter(
      (currentHeader, index, self) => {
        const selfIndex = self.findIndex(
          header => header.section === currentHeader.section
        )
        return selfIndex === index
      }
    )

    return (
      <Row id="row-id" className={`${className}-section`}>
        {modifiedHeaders.map((header, index) => {
          const isFirstInSection = firstHeadersOfSections.some(
            v => v.id === header.id
          )

          let sectionTitleContent
          if (isFirstInSection) {
            sectionTitleContent = sections[header.section].name
          } else if (index === headers.length && tableAction) {
            sectionTitleContent = tableAction
          } else if (header.sectionTitleContent) {
            sectionTitleContent = header.sectionTitleContent
          } else {
            sectionTitleContent = <span>&nbsp;</span>
          }

          return (
            <Cell
              key={index}
              className={`cell ${header.section} ${
                isFirstInSection && "first"
              }`}>
              <div className={`sectionTitle`}>{sectionTitleContent}</div>
              <div className={`headerName ${header.id}`}>{header.name}</div>
            </Cell>
          )
        })}
      </Row>
    )
  }

  renderData(className) {
    const { headers, data, rowCount, rowClick } = this.props

    return data.map((dataRow, i) => {
      const onClick = rowClick ? () => rowClick(dataRow) : undefined
      const rowClassName = onClick
        ? className + "row u-data-row"
        : className + "row"

      return (
        <Row
          key={i}
          onClick={onClick}
          className={`${rowClassName} ${dataRow.rowClassName}`}>
          {rowCount && (
            <Cell
              className={
                className +
                "cell " +
                className +
                "count u-table-edit-icon text-center"
              }>
              {i + 1}
            </Cell>
          )}
          {headers.map((header, j) => {
            const highlighted =
              dataRow.highlighted && dataRow.highlighted[header.id]
            return (
              <Cell
                key={j}
                className={
                  className +
                  "cell " +
                  header.dataClass +
                  (highlighted ? " highlighted" : "")
                }>
                {dataRow[header.id]}
              </Cell>
            )
          })}
        </Row>
      )
    })
  }

  render() {
    const { tableName, tableStyle, ...others } = this.props

    return (
      <StickyTable style={tableStyle} {...others}>
        {this.renderHeaderSection(tableName)}
        {this.renderData(tableName + "-data-")}
      </StickyTable>
    )
  }
}
