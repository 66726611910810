import React, { Component } from "react"

import { swapElement, isLast } from "util/array"
import {
  ColourPicker,
  PrioritySelector,
} from "domains/characteristics/components"
import { AddButton } from "components"
import { CHARACTERISTIC_COLOUR_PALETTE } from "domains/characteristics/constants"

export class CharacteristicResponses extends Component {
  increasePriority = index => {
    if (index === 0) {
      return
    }

    const { characteristicResponses, onCharacteristicResponsesChange } =
      this.props

    onCharacteristicResponsesChange(
      swapElement(characteristicResponses, index, index - 1)
    )
  }

  decreasePriority = index => {
    const { characteristicResponses, onCharacteristicResponsesChange } =
      this.props

    if (isLast(characteristicResponses, index)) {
      return
    }

    onCharacteristicResponsesChange(
      swapElement(characteristicResponses, index, index + 1)
    )
  }

  onColourChange = (index, colour) => {
    const { characteristicResponses, onCharacteristicResponsesChange } =
      this.props

    const newCharacteristicResponses = Array.from(characteristicResponses)

    newCharacteristicResponses[index] = {
      ...characteristicResponses[index],
      colour,
    }

    onCharacteristicResponsesChange(newCharacteristicResponses)
  }

  addResponse = () => {
    const { characteristicResponses, onCharacteristicResponsesChange } =
      this.props

    const usedColours = characteristicResponses.map(({ colour }) => colour)

    onCharacteristicResponsesChange([
      ...characteristicResponses,
      {
        description: "",
        label: "",
        colour: this.uniqueColour(usedColours, CHARACTERISTIC_COLOUR_PALETTE),
      },
    ])
  }

  removeResponse = index => {
    const { characteristicResponses, onCharacteristicResponsesChange } =
      this.props

    const newCharacteristicResponses = Array.from(characteristicResponses)
    newCharacteristicResponses.splice(index, 1)

    onCharacteristicResponsesChange(newCharacteristicResponses)
  }

  onResponseChange = (e, index) => {
    const { characteristicResponses, onCharacteristicResponsesChange } =
      this.props
    const newCharacteristicResponses = Array.from(characteristicResponses)

    // remove old and replace with new response
    newCharacteristicResponses.splice(index, 1, {
      ...newCharacteristicResponses[index],
      [e.target.name]: e.target.value,
    })

    onCharacteristicResponsesChange(newCharacteristicResponses)
  }

  uniqueColour = (usedColours, palette) => {
    return palette.find(colour => {
      return !usedColours.includes(colour)
    })
  }

  render() {
    const { characteristicResponses, displayColourPicker, toggleColourPicker } =
      this.props

    return (
      <div>
        {characteristicResponses.map((response, index, responses) => (
          <div className="d-flex" key={index}>
            <div className="c-chars__body-item">
              <div className="d-flex align-items-center m-3">
                <PrioritySelector
                  last={isLast(responses, index)}
                  index={index}
                  onDecreasePriorityClick={() => this.decreasePriority(index)}
                  onIncreasePriorityClick={() => this.increasePriority(index)}
                />
              </div>
            </div>
            <ColourPicker
              className="align-self-center"
              colour={response.colour}
              displayColourPicker={displayColourPicker === index}
              onChange={colour => this.onColourChange(index, colour)}
              onToggleColourPicker={() => toggleColourPicker(index)}
            />
            <input
              className="c-chars__input mr-2"
              value={response.label}
              type="text"
              name="label"
              onChange={e => {
                this.onResponseChange(e, index)
              }}
            />
            <input
              className="c-chars__input"
              placeholder="Add description (optional)"
              type="text"
              name="description"
              value={response.description === null ? "" : response.description}
              onChange={e => {
                this.onResponseChange(e, index)
              }}
            />
            <i
              className="fa fa-times student-upload-list__item__icon student-upload-list__item__icon--times m-2"
              onClick={() => this.removeResponse(index)}
            />
          </div>
        ))}

        <AddButton text="Add response" onClick={this.addResponse} />
      </div>
    )
  }
}
