import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import Select from "react-select"

import { activeSchoolGradesQuery } from "domains/classes/graphql"

import { Modal, ModalBody, Label } from "reactstrap"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  ModalNote,
  Loader,
  defaultSelectStyles,
} from "components"

export const ExportByGradesModal = props => {
  const { data, loading: loadingGrades } = useQuery(activeSchoolGradesQuery, {
    variables: { schoolId: props.schoolId },
  })

  if (loadingGrades) {
    return (
      <div className="position-relative p-5">
        <Loader />
      </div>
    )
  }

  return <ExportByGrades grades={data.activeSchoolGrades} {...props} />
}

const ExportByGrades = ({
  toggle,
  title,
  description,
  primaryButtonText,
  errorMessage,
  exportFunction,
  grades,
}) => {
  const availableGrades = grades.map(grade => {
    return {
      label: grade.label,
      value: grade.id,
    }
  })
  const [selectedGrades, setSelectedGrades] = useState(availableGrades)

  const handleButtonClick = () => {
    const gradeIds = selectedGrades.map(({ value }) => value)
    exportFunction(gradeIds)
  }

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-45rem">
      <ModalHeader className="p-4" title={title} toggle={toggle} />
      <ModalNote text={description} />
      <ModalBody className="pb-4">
        <div className="px-4 pt-4">
          <Label>Select Current Grade/s</Label>
          <Select
            name="current_grades"
            className="c-input__multiselect"
            placeholder="Select Current Grades"
            options={availableGrades}
            value={selectedGrades}
            onChange={grades => {
              setSelectedGrades(grades)
            }}
            closeMenuOnSelect={false}
            isMulti
            styles={defaultSelectStyles}
          />
        </div>
        <ButtonBar
          className="mt-5 px-4"
          buttonText={primaryButtonText}
          onButtonClick={handleButtonClick}
          onCancelClick={toggle}
          disabled={selectedGrades.length === 0}
        />
        {errorMessage && (
          <InlineError className="mt-5 px-4" text={errorMessage} />
        )}
      </ModalBody>
    </Modal>
  )
}
