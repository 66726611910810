import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { SCHOOL_ID } from "constants/storageTokens"
import { characteristicsQuery } from "domains/characteristics/graphql"

export const useSchoolCharacteristics = () => {
  // Retrieve the admin's School ID from sessionStorage
  const schoolId = sessionStorage.getItem(SCHOOL_ID)

  // Set up a lazy query to retrieve the characteristics. This needs to be lazy
  // as hooks can't be called conditionally, and we don't want to invoke the
  // query until we've checked the existence of `schoolId`
  const [fetchCharacteristics, query] = useLazyQuery(characteristicsQuery, {
    variables: {
      schoolId: schoolId,
    },
  })

  // As an effect, invoke the characteristics query if a school id exists and
  // if it has not been called already
  useEffect(() => {
    if (schoolId && !query.called) {
      fetchCharacteristics()
    }
  }, [fetchCharacteristics, schoolId, query.called])

  // Return the result of the query as an unwrapped tuple
  return [query.data && query.data.schoolCharacteristics, query]
}
