import React, { useState } from "react"
import { useQuery } from "@apollo/client"

import { Button, Modal, ModalBody } from "reactstrap"
import { ModalHeader, InlineError, CautionModal } from "components"
import Select from "react-select"
import { studentHistoriesQuery } from "domains/students/graphql"
import { getSchoolId } from "util/app"
import { downloadFile } from "util/downloadUtil"
import { dateTimeToString } from "util/dateTimeUtil"

const transformForSelect = studentHistories => {
  return studentHistories.map(({ file }) => {
    const label = dateTimeToString(file.timestamp)
    return {
      label: label,
      value: file.link,
    }
  })
}

export const StudentHistoriesModal = ({ toggle }) => {
  const schoolId = getSchoolId()
  const { data, loading, error } = useQuery(studentHistoriesQuery, {
    variables: { schoolId },
    fetchPolicy: "network-only",
  })

  const [selectedOption, setSelectedOption] = useState()

  if (loading) {
    return null
  }

  if (error) {
    return (
      <CautionModal
        isOpen
        onButtonClick={toggle}
        toggle={toggle}
        buttonText="Dismiss"
        showCancel={false}
        heading="Download Student History"
        text="There was an error fetching the Student History. Please try again later."
      />
    )
  }

  const options = transformForSelect(data.studentHistories)
  const noOptions = options.length === 0

  const download = () => {
    downloadFile(selectedOption.value)
  }

  return (
    <Modal isOpen className="modal-dialog-centered">
      <ModalHeader
        title="Download Student History"
        toggle={toggle}
        className="px-4 py-3"
      />

      <ModalBody className="px-4">
        <p>Please select from the drop down list below and click Download.</p>

        <div className="d-flex mb-3">
          <Select
            className="c-input__multiselect"
            placeholder="Select history"
            options={options}
            value={selectedOption}
            onChange={option => {
              setSelectedOption(option)
            }}
            closeMenuOnSelect={true}
            isDisabled={noOptions}
          />
          <Button
            className="ml-3"
            color="primary"
            onClick={download}
            disabled={!selectedOption}
            type="button">
            Download
          </Button>
        </div>
        {noOptions && (
          <InlineError text="There are no Student History files available" />
        )}
      </ModalBody>
    </Modal>
  )
}
