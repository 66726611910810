const pendingUploadJobCausingError = (error, jobIndex) => {
  if (!error) return false

  const graphQLErrors = error.graphQLErrors
  for (let graphQLError of graphQLErrors) {
    const path = graphQLError.path
    if (path[0] === "school" && path[1] === "uploadJobs") {
      const indexOfUploadJob = path[2]

      if (indexOfUploadJob === jobIndex) {
        return true
      }
    }
  }
  return false
}

const isServerError = error => {
  return error?.networkError?.statusCode >= 500
}

const isPendingJob = jobType => {
  return ({ status, type }) => status === "PENDING" && type === jobType
}

export const pendingImport = (schoolUploads, jobType) => {
  if (!schoolUploads) return { job: null, error: null }

  const error = schoolUploads.error
  // Server error, just return nothing because there won't be results
  if (isServerError(error)) return { job: null, error: error }

  const job =
    schoolUploads.school &&
    schoolUploads.school.uploadJobs.find(isPendingJob(jobType))

  const jobIndex =
    schoolUploads.school &&
    schoolUploads.school.uploadJobs.findIndex(isPendingJob(jobType))

  if (pendingUploadJobCausingError(error, jobIndex)) {
    return { job, error }
  }

  return { job, error: null }
}

export const graphQLErrorForIndex = (errors, index) => {
  // example error path: ["school", "uploadJobs", 2, "fileSummary"]
  return errors.find(error => error.path[2] === index)
}
