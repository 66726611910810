import React, { Component } from "react"
import { Modal, ModalBody, Form, FormGroup, Label, Input } from "reactstrap"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { createOrUpdateUserMutation } from "domains/accountSettings/graphql"
import { deleteUserMutation } from "domains/auth/graphql"
import {
  Loader,
  ButtonBar,
  InlineError,
  ModalHeader,
  DeleteModal,
} from "components"

import * as validators from "util/validators"

class SuperAdminModalComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      deleteLoading: false,
      deleteModal: false,
      cautionModal: false,
      adminData: this.props.adminData,
      errors: {},
    }
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    })
  }

  toggleCautionModal = () => {
    this.setState({
      cautionModal: !this.state.cautionModal,
    })
  }

  onChange = e => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value

    this.setState({
      adminData: {
        ...this.state.adminData,
        [e.target.name]: value,
      },
    })
  }

  validateAdmin(data) {
    const { firstName, lastName, email } = data
    let errors = {}
    if (!validators.validateNonEmptyString(firstName)) {
      errors.firstName = "Required"
    }
    if (!validators.validateNonEmptyString(lastName)) {
      errors.lastName = "Required"
    }
    if (!validators.validateEmail(email)) errors.email = "Invalid Email"

    return errors
  }

  addUniqueItem = (array, item) => [...new Set(array.concat([item]))]

  saveAdmin = () => {
    const { adminData } = this.state

    const errors = this.validateAdmin(adminData)
    this.setState({ errors })

    if (Object.keys(errors).length === 0) {
      this.addOrEditAdmin()
    }
  }

  addOrEditAdmin = () => {
    const { toggle, createOrUpdateUserMutation, refetchQueries, roleType } =
      this.props
    const {
      adminData: { id, firstName, lastName, positionTitle, email, mfa },
    } = this.state
    const variables = {
      user: {
        id,
        role: roleType,
        firstName,
        lastName,
        positionTitle,
        email,
        mfa,
      },
    }

    createOrUpdateUserMutation({ variables, refetchQueries })
      .then(() => {
        this.setState({
          loading: false,
        })
        toggle()
      })
      .catch(() => {
        this.setState({
          loading: false,
          errors: {
            mutation:
              "Could not update User. Please ensure email is unique and try again.",
          },
        })
      })
  }

  deleteSuperAdmin = () => {
    const {
      deleteUserMutation,
      refetchQueries,
      toggle,
      adminData: { id, role },
    } = this.props

    this.setState({ deleteLoading: true })

    const variables = {
      id,
      role,
    }

    deleteUserMutation({ variables, refetchQueries })
      .then(() => {
        this.setState({
          deleteLoading: false,
          deleteModal: false,
        })
        toggle()
      })
      .catch(() => {
        this.setState({
          deleteLoading: false,
          errors: { delete: "Could not delete admin" },
        })
      })
  }

  render() {
    const { toggle, addAdmin, canDelete } = this.props
    const {
      loading,
      deleteModal,
      deleteLoading,
      errors,
      adminData: { firstName, lastName, email, positionTitle, mfa },
    } = this.state

    return (
      <Modal isOpen>
        <ModalHeader
          title={addAdmin ? "Add Admin" : "Edit Admin"}
          toggle={toggle}
          className="u-p-fix p-4"
        />
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <ModalBody className="p-4">
            <Form autoComplete="off">
              <div className="container-fluid">
                <div className="row mt-3">
                  <FormGroup className="col-6">
                    <Label>First name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      autoComplete="off"
                      value={firstName}
                      onChange={this.onChange}
                    />
                    <InlineError text={errors.firstName} />
                  </FormGroup>
                  <FormGroup className="col-6">
                    <Label>Last name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      autoComplete="off"
                      value={lastName}
                      onChange={this.onChange}
                    />
                    <InlineError text={errors.lastName} />
                  </FormGroup>
                </div>
                <div className="row">
                  <FormGroup className="col-12">
                    <Label>Position Title</Label>
                    <Input
                      type="text"
                      name="positionTitle"
                      placeholder="Position Title"
                      autoComplete="off"
                      value={positionTitle || ""}
                      onChange={this.onChange}
                    />
                  </FormGroup>
                </div>
                <div className="row">
                  <FormGroup className="col-12">
                    <Label>Email address</Label>
                    <Input
                      disabled={!addAdmin}
                      type="text"
                      name="email"
                      placeholder="Email address"
                      autoComplete="off"
                      value={email}
                      onChange={this.onChange}
                    />
                    <InlineError text={errors.email} />
                  </FormGroup>
                </div>
                <div className="row">
                  <FormGroup className="col-12 mb-0">
                    <Label className="ml-4">
                      <Input
                        type="checkbox"
                        name="mfa"
                        id="mfa"
                        checked={mfa}
                        onChange={this.onChange}
                      />
                      Require Multi-Factor Authentication (MFA)
                    </Label>
                  </FormGroup>
                </div>
                {addAdmin ? (
                  <ButtonBar
                    className="mt-5 mb-3"
                    buttonText={"Add Admin"}
                    onButtonClick={this.saveAdmin}
                    onCancelClick={toggle}
                  />
                ) : (
                  <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
                    {canDelete ? (
                      <div>
                        <span
                          className="py-2 pr-3 mr-2 text-primary cursor-pointer"
                          onClick={this.toggleDeleteModal}>
                          Delete
                        </span>
                      </div>
                    ) : (
                      <span />
                    )}
                    <ButtonBar
                      buttonText={"Save"}
                      onButtonClick={this.saveAdmin}
                      onCancelClick={toggle}
                    />
                  </div>
                )}

                <InlineError text={errors.mutation} />
              </div>
            </Form>
          </ModalBody>
        )}
        {deleteModal && (
          <DeleteModal
            isOpen
            toggle={this.toggleDeleteModal}
            error={errors.delete}
            loading={deleteLoading}
            heading="Delete Admin?"
            text="Are you sure you want to delete this admin?"
            onButtonClick={this.deleteSuperAdmin}
          />
        )}
      </Modal>
    )
  }
}

export const SuperAdminModal = compose(
  graphql(createOrUpdateUserMutation, { name: "createOrUpdateUserMutation" }),
  graphql(deleteUserMutation, { name: "deleteUserMutation" })
)(SuperAdminModalComponent)
