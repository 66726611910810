import React from "react"
import { Modal, Button } from "reactstrap"
import { ModalHeader } from "components"

export const RunningSolverPrompt = ({ toggle }) => (
  <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
    <div className="p-4">
      <ModalHeader title="Running Solver" toggle={toggle} />
      <div className="d-flex flex-column align-items-center mt-3">
        <div className="mt-3 d-flex justify-content-between">
          <i className="fa fa-info-circle u-caution-icon mr-4" />
          <div>
            <div className="text-primary mb-3">IMPORTANT NOTICE</div>
            <div className="mb-3">
              We are running the Solver for this grade and will <b>email</b> you
              when it is ready (normally less than 2 hours).
            </div>
            <div>
              You can continue to use the program. Please avoid making any
              changes to <b>this grade</b> while this process is underway.
            </div>
          </div>
        </div>
        <Button color="primary" className="mt-5 w-50" onClick={toggle}>
          Ok, got it!
        </Button>
      </div>
    </div>
  </Modal>
)
