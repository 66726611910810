import React from "react"
import { Row, Cell } from "react-sticky-table"
import { ClassBadge } from "components"

import { getStudentResponse } from "util/characteristics"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { isSystemGenerated } from "util/studentUtil"

function renderFriendCells(studentId, friends, maxFriends) {
  const friendCells = []
  // Must remove themselves from friend constraints
  const filteredFriends = friends.filter(f => f.studentTo.id !== studentId)

  for (let i = 0; i < maxFriends; i++) {
    if (filteredFriends[i]) {
      friendCells.push(
        <Cell className="cell friends" key={i}>
          <div className="d-flex justify-content-between align-items-center">
            <span className="friends-name">
              {filteredFriends[i].studentTo.firstName +
                " " +
                filteredFriends[i].studentTo.lastName}
            </span>
            <ClassBadge
              label={getPropertyIfDefined(
                filteredFriends[i],
                "studentTo.currentClass.label"
              )}
            />
          </div>
        </Cell>
      )
    } else {
      friendCells.push(<Cell className="cell friends" key={i} />)
    }
  }
  return friendCells
}

function renderCharacteristicCells(
  schoolCharacteristics,
  characteristicResponses
) {
  if (
    isDefinedNotNull(schoolCharacteristics) &&
    schoolCharacteristics.length > 0
  ) {
    return schoolCharacteristics.map((c, index) => {
      // get students response for characteristic
      const response = getStudentResponse(c, characteristicResponses)

      return (
        <Cell key={index} className="cell characteristics">
          {response ? response.label : ""}
        </Cell>
      )
    })
  }
}

export const StudentsTableRow = ({
  data,
  index,
  className,
  maxFriends,
  teacherRequestsEditable,
  studentRequestsEditable,
  onAddRequestClick,
  schoolCharacteristics,
  teacherMode,
  onRowClick,
}) => {
  const {
    id,
    firstName,
    lastName,
    studentCode,
    comments,
    gender,
    currentGrade,
    currentClass,
    newGrade,
    newClass,
    constraintsCount,
    characteristicResponses,
    friends,
  } = data

  const isSystemGeneratedId = studentCode && isSystemGenerated(studentCode)

  return (
    <Row
      className="c-students-table__data u-data-row"
      key={index}
      onClick={() => onRowClick(data.id)}>
      <Cell
        className={`cell u-table-edit-icon count ${className} fixed-column-1`}>
        {index + 1}
      </Cell>
      <Cell className={`cell first-name ${className} fixed-column-2`}>
        {firstName}
      </Cell>
      <Cell className={`cell last-name ${className} fixed-column-3`}>
        {lastName}
      </Cell>
      <Cell
        className={`cell id ${className} ${
          isSystemGeneratedId && "generated"
        }`}>
        {studentCode}
      </Cell>
      <Cell className={`cell gender ${className}`}>{gender}</Cell>
      <Cell className={`cell current-grade ${className}`}>
        {getPropertyIfDefined(currentGrade, "label")}
      </Cell>
      <Cell className={`cell current-class ${className}`}>
        <ClassBadge label={getPropertyIfDefined(currentClass, "label")} />
      </Cell>
      <Cell className={`cell new-grade ${className}`}>
        {getPropertyIfDefined(newGrade, "label")}
      </Cell>
      {!teacherMode && (
        <Cell className={`cell new-class ${className}`}>
          <ClassBadge label={getPropertyIfDefined(newClass, "label")} />
        </Cell>
      )}
      <Cell className={`cell notes`}>{comments}</Cell>
      {(teacherRequestsEditable || studentRequestsEditable) && (
        <Cell className="cell requests-total">
          <span
            className="add-button"
            onClick={() => {
              onAddRequestClick(id)
            }}>
            {constraintsCount}
            <i className="ml-2" />
          </span>
        </Cell>
      )}
      {renderCharacteristicCells(
        schoolCharacteristics,
        characteristicResponses
      )}
      {renderFriendCells(id, friends, maxFriends)}
    </Row>
  )
}
