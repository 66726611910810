import React from "react"
import { useQuery } from "@apollo/client"

import { useNavigate } from "react-router-dom"

import { Loader } from "components"
import { userProfileQuery } from "domains/auth/graphql"
import { EditUserDetailsModal } from "domains/auth/components"

export const UpdateUserDetailsPage = () => {
  const { data: userProfileData, loading: loadingUser } = useQuery(
    userProfileQuery,
    { fetchPolicy: "network-only" }
  )

  const refetchQueries = [{ query: userProfileQuery }]

  const navigate = useNavigate()

  if (loadingUser) {
    return <Loader />
  }

  const toggle = () => {
    navigate("/")
  }

  const cancelToggle = () => {
    navigate("/logout")
  }

  return (
    <EditUserDetailsModal
      toggle={toggle}
      refetchQueries={refetchQueries}
      user={userProfileData.myprofile}
      modalClass="modal-dialog-centered"
      title="Update Your Profile"
      showDelete={false}
      allowEditEmail={false}
      cancelToggle={cancelToggle}
    />
  )
}
