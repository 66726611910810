import { SUPER, SUPPORT } from "domains/app/constants/roleTypes"

export const isPrivilegedRole = role => {
  return role === SUPER || role === SUPPORT
}

export const isSuper = role => {
  return role === SUPER
}

export const hasAcceptedTerms = profile => {
  return profile.acceptedCustomerTermsAt && profile.acceptedPrivacyPolicyAt
}
