import { gql } from "@apollo/client"

export const schoolQuery = gql`
  query ($schoolId: ID!) {
    school(schoolId: $schoolId) {
      id
      address
      name
      contactNumber
      countryCode
      numberOfStudents
      suburb
      countryCode
      stateCode
      postcode
      contactNumber
      administrationSystem
      paymentStatus {
        status
      }
      accountType
      accountName
      crmStatus
    }
  }
`

export const schoolWithSisQuery = gql`
  query ($schoolId: ID!) {
    school(schoolId: $schoolId) {
      id
      address
      name
      contactNumber
      countryCode
      numberOfStudents
      suburb
      countryCode
      stateCode
      postcode
      contactNumber
      administrationSystem
      billingEmailAddresses
      adminCanInviteUser
      paymentStatus {
        status
      }
      powerschoolSisConfig {
        id
        clientSecret
        clientId
        powerschoolSchoolId
        apiEndpoint
        currentClassOption
      }
      sftpSisConfig {
        id
        endpoint
        external
        user
        password
        publicKey
        autoSync
      }
      accountType
      accountName
      crmStatus
    }
  }
`

export const schoolWithSisSuperQuery = gql`
  query ($schoolId: ID!) {
    school(schoolId: $schoolId) {
      id
      address
      name
      contactNumber
      countryCode
      numberOfStudents
      suburb
      countryCode
      stateCode
      postcode
      contactNumber
      crmAccountName
      crmLink
      administrationSystem
      billingEmailAddresses
      paymentStatus {
        status
      }
      powerschoolSisConfig {
        id
        clientSecret
        clientId
        powerschoolSchoolId
        apiEndpoint
      }
      sftpSisConfig {
        id
        endpoint
        external
        user
        password
        publicKey
        autoSync
      }
      schoolStatus
      managers {
        id
        firstName
        lastName
      }
      accountType
      accountName
      crmStatus
    }
  }
`

export const schoolListQuery = gql`
  query {
    schools {
      id
      name
      stateCode
      suburb
      crm_account_name
    }
  }
`

export const preferencesQuery = gql`
  query ($schoolId: ID!) {
    schoolSettings(schoolId: $schoolId) {
      id
      maxFriends
      adminOnlyRequests
      avoidSameTeacher
      teachersToAddTeacherRequests
      teachersToAddStudentRequests
      includeStudentsWithNoValidFriends
      overrideSolverLock
      minFriendPrefs
      currentClassCharacteristicMinRc
      currentClassCharacteristicPriority
      currentClassAsCharacteristic
      autoRollover
      showTeachersInSolver
      allowWritableSharedSolutions
      newAccountLock
      minStudentCodeLength
      feederSchool
      requireOtp
      requireMfa
      autoServicing
      depersonaliseStudents
    }
  }
`

export const administratorsQuery = gql`
  query ($schoolId: ID, $filterRole: RoleTypes) {
    users(schoolId: $schoolId, filterRole: $filterRole) {
      id
      firstName
      lastName
      positionTitle
      email
      role
      schools {
        id
      }
      mfa
      lastLogin
      insertedAt
      updatedAt
      emailVerified
    }
  }
`

export const updateSchoolSettingMutation = gql`
  mutation ($schoolSettingParams: SchoolSettingParams) {
    updateSchoolSetting(schoolSetting: $schoolSettingParams) {
      id
      adminOnlyRequests
      avoidSameTeacher
      teachersToAddTeacherRequests
      teachersToAddStudentRequests
      maxFriends
      overrideSolverLock
      minFriendPrefs
      currentClassCharacteristicMinRc
      currentClassCharacteristicPriority
      currentClassAsCharacteristic
      autoRollover
      showTeachersInSolver
      allowWritableSharedSolutions
      newAccountLock
      minStudentCodeLength
      feederSchool
      requireOtp
      requireMfa
      autoServicing
      depersonaliseStudents
    }
  }
`

export const userByEmailQuery = gql`
  query ($email: String!) {
    userByEmail(email: $email) {
      id
      firstName
      lastName
      email
      role
      positionTitle
      schools {
        id
        name
        stateCode
        suburb
        crm_account_name
      }
    }
  }
`

export const createOrUpdateUserMutation = gql`
  mutation ($user: UpdateUserParams!) {
    createOrUpdateUser(user: $user) {
      id
    }
  }
`

export const updateSelfMutation = gql`
  mutation ($self: UpdateSelfParams!) {
    updateSelf(self: $self) {
      id
    }
  }
`

export const deleteSelfMutation = gql`
  mutation ($id: ID!) {
    deleteSelf(id: $id) {
      id
    }
  }
`

export const removeUserFromSchoolsMutation = gql`
  mutation ($userId: ID!, $schoolIds: [ID!]!) {
    removeUserFromSchools(userId: $userId, schoolIds: $schoolIds) {
      id
    }
  }
`

export const createOrUpdateSchoolMutation = gql`
  mutation ($school: SchoolParams) {
    createOrUpdateSchool(school: $school) {
      id
      billingEmailAddresses
    }
  }
`

export const deleteSchoolMutation = gql`
  mutation ($schoolId: ID!) {
    deleteSchool(schoolId: $schoolId) {
      id
    }
  }
`

export const cleanUploadedFilesMutation = gql`
  mutation ($schoolIds: [ID!]) {
    cleanUploadedFiles(schoolIds: $schoolIds)
  }
`

export const cloneSchoolMutation = gql`
  mutation cloneSchool(
    $schoolId: ID!
    $cloneSchoolParams: CloneSchoolParams!
    $dataToClone: [ClonedData!]
    $accountType: AccountType!
    $currentGradeIds: [ID!]!
  ) {
    cloneSchool(
      schoolId: $schoolId
      cloneSchoolParams: $cloneSchoolParams
      dataToClone: $dataToClone
      accountType: $accountType
      currentGradeIds: $currentGradeIds
    ) {
      id
      name
      address
    }
  }
`

export const deleteSchoolDataMutation = gql`
  mutation ($schoolId: ID!) {
    deleteSchoolData(schoolId: $schoolId) {
      id
    }
  }
`

export const settingsClientQuery = gql`
  query {
    settings @client {
      adminOnlyRequests
    }
  }
`

export const updateSettings = gql`
  mutation updateSettings($type: String!, $value: Value!) {
    updateSettings(type: $type, value: $value) @client
  }
`

export const rollOverData = gql`
  mutation rollOverData($schoolId: ID!) {
    rollOverData(schoolId: $schoolId)
  }
`

export const convertToCabinsMutation = gql`
  mutation convertToCabins($schoolId: ID!) {
    convertToCabins(schoolId: $schoolId)
  }
`

export const convertToActivityGroupsMutation = gql`
  mutation convertToActivityGroups($schoolId: ID!) {
    convertToActivityGroups(schoolId: $schoolId)
  }
`

export const moveStudentsToTheirNewGradeMutation = gql`
  mutation moveStudentsToTheirNewGrade($schoolId: ID!) {
    moveStudentsToTheirNewGrade(schoolId: $schoolId)
  }
`

export const removeSolutionsForGradesMutation = gql`
  mutation removeSolutionsForGrades($schoolId: ID!, $gradeIds: [ID!]!) {
    removeSolutionsForGrades(schoolId: $schoolId, gradeIds: $gradeIds)
  }
`

export const backupSchoolMutation = gql`
  mutation backupSchool($schoolId: ID!) {
    backupSchool(schoolId: $schoolId)
  }
`

export const restoreSchoolBackupMutation = gql`
  mutation restoreSchoolBackup($schoolId: ID!, $fileName: String!) {
    restoreSchoolBackup(schoolId: $schoolId, fileName: $fileName)
  }
`

export const sendMissingIdsEmailsMutation = gql`
  mutation sendMissingIdsEmails {
    sendMissingIdsEmails
  }
`

export const availableSchoolBackupsQuery = gql`
  query availableSchoolBackups($schoolId: ID!) {
    availableSchoolBackups(schoolId: $schoolId) {
      id
      file {
        id
        fileName
        timestamp
      }
    }
  }
`

export const bulkUploadMiscMutation = gql`
  mutation ($schoolId: ID!, $file: Upload!) {
    bulkUploadMisc(schoolId: $schoolId, file: $file)
  }
`

export const createOrUpdateSisConfigPowerschoolMutation = gql`
  mutation ($schoolId: ID!, $config: PowerschoolSisConfigInput!) {
    createOrUpdateSisConfigPowerschool(schoolId: $schoolId, config: $config) {
      id
      clientId
      clientSecret
      powerschoolSchoolId
      apiEndpoint
      currentClassOption
    }
  }
`

export const createOrUpdateSisConfigSftpMutation = gql`
  mutation ($schoolId: ID!, $config: SftpSisConfigInput!) {
    createOrUpdateSisConfigSftp(schoolId: $schoolId, config: $config) {
      id
      endpoint
      external
      user
      password
      publicKey
      autoSync
    }
  }
`

export const invitationsForSchoolQuery = gql`
  query invitationsForSchool($schoolId: ID!) {
    invitationsForSchool(schoolId: $schoolId) {
      id
      email
      updatedAt
    }
  }
`
export const inviteUsersToSchoolMutation = gql`
  mutation inviteUsersToSchool($emails: [String!]!, $schoolId: ID!) {
    inviteUsersToSchool(emails: $emails, schoolId: $schoolId) {
      id
      email
      updatedAt
    }
  }
`
export const revokeSchoolInvitationMutation = gql`
  mutation revokeSchoolInvitation($inviteId: ID!) {
    revokeSchoolInvitation(inviteId: $inviteId)
  }
`
export const acceptSchoolInvitationMutation = gql`
  mutation acceptSchoolInvitation($inviteId: ID!) {
    acceptSchoolInvitation(inviteId: $inviteId)
  }
`
export const declineSchoolInvitationMutation = gql`
  mutation declineSchoolInvitation($inviteId: ID!) {
    declineSchoolInvitation(inviteId: $inviteId)
  }
`
