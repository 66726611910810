import React from "react"

export const InfoNote = ({ heading, children, className = "" }) => (
  <div className={`c-note d-flex flex-column ${className}`}>
    <div className="my-2">
      <i className="fa fa-info-circle mr-2" />
      <b>{heading}</b>
    </div>
    <div>{children}</div>
  </div>
)
