import React, { PureComponent } from "react"
import { getOpacity } from "util/colours"

export class CharacteristicCircle extends PureComponent {
  render() {
    const { colour } = this.props

    const opacity = getOpacity(colour)
    const styles = {
      backgroundColor: colour,
    }

    if (opacity > 0) {
      return <div style={styles} className="u-characteristic-circle mr-1" />
    } else {
      return ""
    }
  }
}
