import React, { useState } from "react"
import { useMutation } from "@apollo/client"

import { AddOrEditTextModal } from "components"

import { createOrUpdateSchoolMutation } from "domains/accountSettings/graphql"

import { validateEmail } from "util/validators"

import { getNotificationsQuery } from "domains/notifications/graphql"
import { normalizeEmails } from "util/nameUtil"

const EMPTY_ADDRESS = ""
const SEPARATOR = ";"

export const EditBillingDetailsModal = ({ toggle, school }) => {
  const [emailAddresses, setEmailAddresses] = useState(
    school.billingEmailAddresses
      ? normalizeEmails(school.billingEmailAddresses.split(SEPARATOR))
      : [EMPTY_ADDRESS]
  )

  const [createOrUpdateSchool, { error, loading }] = useMutation(
    createOrUpdateSchoolMutation,
    {
      onError: () => {},
      refetchQueries: [
        {
          query: getNotificationsQuery,
          variables: { schoolId: school.id },
        },
      ],
    }
  )

  const emails = normalizeEmails(emailAddresses)
  const hasInvalidEmail = !emails.every(email => validateEmail(email))

  const save = () => {
    const variables = {
      school: {
        id: school.id,
        billingEmailAddresses: emails.join(`${SEPARATOR} `),
      },
    }

    createOrUpdateSchool({ variables }).then(() => {
      toggle()
    })
  }

  return (
    <AddOrEditTextModal
      toggle={toggle}
      loading={loading}
      inputs={emailAddresses}
      setInput={setEmailAddresses}
      onSave={save}
      saveDisabled={hasInvalidEmail}
      saveErrors={error}
      title="Edit Billing Email Addresses"
      description="Add the email address or addresses to send billing related correspondence."
      addButtonText="Add another email"
    />
  )
}
