import { isDefinedNotNull } from "util/objUtil"
import {
  DUPLICATE_FRIENDSHIP,
  DUPLICATE_STUDENT_REQUEST,
  DUPLICATE_TEACHER_REQUEST,
  OUTSIDE_GRADE_GROUP,
  TEACHER_NOT_ASSIGNED,
} from "domains/requests/errorFields"

export const requestError = errorMessage => {
  if (isDefinedNotNull(errorMessage)) {
    if (errorMessage.includes(DUPLICATE_FRIENDSHIP.key)) {
      return DUPLICATE_FRIENDSHIP.message
    }
    if (errorMessage.includes(DUPLICATE_STUDENT_REQUEST.key)) {
      return DUPLICATE_STUDENT_REQUEST.message
    }
    if (errorMessage.includes(DUPLICATE_TEACHER_REQUEST.key)) {
      return DUPLICATE_TEACHER_REQUEST.message
    }
  }
  return "Network Error, Could not save Request"
}

export const requestStatus = status => {
  if (isDefinedNotNull(status)) {
    if (status.reason.includes(OUTSIDE_GRADE_GROUP.key)) {
      return OUTSIDE_GRADE_GROUP.message
    }
    if (status.reason.includes(TEACHER_NOT_ASSIGNED.key)) {
      return TEACHER_NOT_ASSIGNED.message
    }
  }
}
export const valueHasChanged = (before, after) => {
  // This assumes truthy values are strings
  const value1 = before ? before.trim() : ""
  const value2 = after ? after.trim() : ""

  return value1 !== value2
}
