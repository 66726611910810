import React from "react"
import { CautionModal } from "components"
import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
  const navigate = useNavigate()

  const logout = () => navigate("/Logout")

  const text = (
    <div>
      Sorry, your email address is not set up to access Class Solver.
      <br />
      <br />
      Please contact
      <a href="mailto:support@classsolver.com" className="ml-1 color-blue-mid">
        support@classsolver.com
      </a>
      {" if you need any assistance."}
    </div>
  )

  return (
    <CautionModal
      isOpen
      toggle={logout}
      onButtonClick={logout}
      buttonText="Dismiss"
      showCancel={false}
      heading="Something went wrong ..."
      text={text}
    />
  )
}

export const UnauthorizedPage = Unauthorized
