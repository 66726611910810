import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { Button } from "reactstrap"
import { CautionModal } from "components"

import {
  getImportableStudentsQuery,
  importStudentsMutation,
} from "domains/sis/graphql"
import { Loader } from "components"

export const PowerschoolSync = ({ school, toggleSync, refetchQueries }) => {
  const [showSyncSummary, setShowSyncSummary] = useState(false)

  if (showSyncSummary) {
    return (
      <SyncSummary
        school={school}
        toggleSync={toggleSync}
        refetchQueries={refetchQueries}
        sisType="POWERSCHOOL"
      />
    )
  }

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
      <h1 className="m-2">Sync Students from PowerSchool</h1>
      <Button color="primary m-2" onClick={() => setShowSyncSummary(true)}>
        Sync
      </Button>
      <Button color="link" onClick={toggleSync}>
        Back to Students page
      </Button>
    </div>
  )
}

export const SftpSync = ({ school, toggleSync, refetchQueries }) => {
  const [showSyncSummary, setShowSyncSummary] = useState(false)

  if (showSyncSummary) {
    return (
      <SyncSummary
        school={school}
        toggleSync={toggleSync}
        refetchQueries={refetchQueries}
        sisType="SFTP"
      />
    )
  }

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
      <h1 className="m-2">Sync Students from SFTP Server</h1>
      <Button color="primary m-2" onClick={() => setShowSyncSummary(true)}>
        Sync
      </Button>
      <Button color="link" onClick={toggleSync}>
        Back to Students page
      </Button>
    </div>
  )
}

// sisType = "POWERSCHOOL" || "SFTP"

const sisName = {
  POWERSCHOOL: "PowerSchool",
  SFTP: "SFTP Server",
}

// TODO: This logic should be in the backend
const convertForImport = student => {
  // We only pick the first one for now
  const currentClass = student.currentClasses && student.currentClasses[0]
  return {
    firstName: student.firstName,
    lastName: student.lastName,
    gender: student.gender,
    studentCode: student.studentCode,
    currentClass: currentClass ? currentClass.name : null,
    currentGrade: student.currentGrade.name ? student.currentGrade.name : null,
  }
}

const ImportError = ({ error, toggle, sisType }) => {
  if (error) {
    if (error.graphQLErrors[0] && error.graphQLErrors[0].message) {
      const message = error.graphQLErrors[0].message
      let errorHeading
      let errorText

      if (message === "grade-does-not-exist") {
        errorHeading = "Error with Grade Codes"
        errorText = `
        Some grade codes were not found in your "School Grade Settings".
        Please go to "School Grade Settings" and make sure the Grade
        Codes matches the Grade Names in ${sisName[sisType]}.
        `
      } else if (message === "school-id-not-found") {
        errorHeading = "Error finding school in PowerSchool"
        errorText =
          "Unable to find the school with the configured DCID, please check you have entered the correct school DCID in the PowerSchool Integration settings."
      } else {
        errorHeading = "Error Importing data"
        errorText =
          "An error has occurred importing the data. Please double check the credentials and try again or contact Class Solver support."
      }
      return (
        <CautionModal
          isOpen
          toggle={toggle}
          onButtonClick={toggle}
          buttonText="Cancel Import"
          showCancel={false}
          heading={errorHeading}
          text={
            <div>
              <p>{errorText}</p>
            </div>
          }
        />
      )
    }
  } else {
    return null
  }
}

const SyncSummary = ({ school, toggleSync, refetchQueries, sisType }) => {
  const [error, setError] = useState()
  const {
    data,
    loading,
    error: getImportableStudentsError,
  } = useQuery(getImportableStudentsQuery, {
    variables: { schoolId: school.id, sis: sisType },
    fetchPolicy: "network-only",
  })

  const [importStudents] = useMutation(importStudentsMutation)

  if (loading) return <ConnectingMessage sisType={sisType} />
  if (getImportableStudentsError)
    return (
      <ImportError
        error={getImportableStudentsError}
        toggle={toggleSync}
        sisType={sisType}
      />
    )

  const students = data.getImportableStudents.students
  const numImportableStudents = students.length
  const studentsToImport = students.map(student => convertForImport(student))

  const onImportClick = () => {
    importStudents({
      variables: {
        schoolId: school.id,
        students: studentsToImport,
      },
      refetchQueries,
    })
      .then(() => {
        toggleSync()
      })
      .catch(e => {
        setError(e)
      })
  }

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
      <h1 className="m-2">
        We have found {numImportableStudents} students from {sisName[sisType]}{" "}
        ready for importing
      </h1>
      <Button
        color="primary m-2"
        onClick={onImportClick}
        disabled={numImportableStudents === 0}>
        Import into Class Solver
      </Button>
      <Button color="link" onClick={toggleSync}>
        Back to Students page
      </Button>
      <ImportError error={error} toggle={toggleSync} sisType={sisType} />
    </div>
  )
}

const ConnectingMessage = ({ sisType }) => {
  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center u-layout-component-grow">
      <h1>Connecting to {sisName[sisType]}, this may take a few minutes...</h1>
      <div className="mt-3">
        <Loader className="loader-container-relative" />
      </div>
    </div>
  )
}
