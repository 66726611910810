import React from "react"
import { AddButton } from "components"
import { EntryCriterion } from "./"
import { AccountTypeContext } from "config/accountTypeContext"

const DEFAULT_CRITERION = { id: null, options: [], selectedOptions: [] }

const deleteCriterion = (currentCriteria, criterion) => {
  const existingIndex = currentCriteria.findIndex(
    ({ id }) => id === criterion.id
  )
  const newCurrentCriteria = [...currentCriteria]
  newCurrentCriteria.splice(existingIndex, 1)
  return newCurrentCriteria
}

const addCriterion = (currentCriteria, criterion) => {
  if (criterion && !currentCriteria.some(({ id }) => id === criterion.id)) {
    return [...currentCriteria, criterion]
  } else {
    return currentCriteria
  }
}

const modifyCriterion = (currentCriteria, oldCriterion, newCriterion) => {
  const index = currentCriteria.findIndex(({ id }) => id === oldCriterion.id)

  const newCurrentCriteria = [...currentCriteria]
  newCurrentCriteria.splice(index, 1, newCriterion)
  return newCurrentCriteria
}

export const EntryCriteria = ({
  currentCriteria = [],
  availableCriteria,
  onChange,
  readOnly,
}) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj
  const noCriteria = currentCriteria.length === 0
  const remainingAvailableCriteria = availableCriteria.filter(
    c => !currentCriteria.some(({ id }) => c.id === id)
  )

  const criteria = Object.entries(currentCriteria)
    .sort()
    .map(([id, criterion]) => {
      return (
        <div key={id} className="c-classes__entry-criterion row">
          {readOnly || (
            <div className="col-1">
              <i
                className="fa fa-trash c-classes__delete-icon"
                onClick={() =>
                  onChange(deleteCriterion(currentCriteria, criterion))
                }
              />
            </div>
          )}
          <EntryCriterion
            selectedCriterion={criterion}
            availableCriteria={remainingAvailableCriteria}
            onChange={newCriterion => {
              onChange(
                modifyCriterion(currentCriteria, criterion, newCriterion)
              )
            }}
            readOnly={readOnly}
          />
        </div>
      )
    })

  const description = `Click on \`+ Add ${gettextObj.gettext(
    "Class"
  )} Entry Criteria\` if you would like only certain students to be placed in this ${gettextObj.gettext(
    "class"
  )}.`

  const heading = readOnly ? (
    <div className="c-modal-note--sub-note mb-1">Class Entry Criteria</div>
  ) : (
    <>
      <div className="c-modal-note--sub-note mb-1">{description}</div>
      <AddButton
        text={`Add ${gettextObj.gettext("Class")} Entry Criteria`}
        onClick={() => {
          onChange(addCriterion(currentCriteria, DEFAULT_CRITERION))
        }}
      />
    </>
  )

  return (
    <div className="c-classes__entry-criteria">
      {heading}
      {noCriteria ? (
        <div className="m-3">
          {readOnly &&
            "There are no Class Entry Criteria rules applied to this class."}
        </div>
      ) : (
        <div className="m-3">
          <div className="row">
            {readOnly || <div className="heading col-1"></div>}
            <div className="heading col-4">Characteristic</div>
            <div className="heading col-7">Response Options</div>
          </div>
          {criteria}
        </div>
      )}
    </div>
  )
}
