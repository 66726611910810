import React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { SuccessModal } from "components"

export const ImportSuccessPage = () => {
  // Grab the react router history and state
  const navigate = useNavigate()
  const { state: routeState } = useLocation()

  const redirect = () => navigate(routeState ? routeState.redirectTo : "/")

  const text = (
    <>
      <p>
        If you used Class Solver last year, information about your students,
        like Requests, Friendship Preferences, characteristic Responses and
        Notes, have been automatically added into the program for the students
        you have imported.
      </p>
      <p>
        You can bulk delete this information by clicking on <b>Remove</b> on the{" "}
        <b>Students</b> tab.
      </p>
    </>
  )

  return (
    <SuccessModal
      isOpen
      toggle={redirect}
      heading="All Done!"
      buttonText="OK"
      text={text}
    />
  )
}
