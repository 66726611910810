import React from "react"
import { useQuery } from "@apollo/client"

import { Loader } from "components"
import { UserDetails, LinkedAccounts } from "domains/auth/components"
import { userProfileQuery } from "domains/auth/graphql"
import { ProfilePreferences } from "domains/billingSettings/components/profilePreferences"

export const UserProfilePage = () => {
  const { data: profileData, loading: loadingProfile } = useQuery(
    userProfileQuery,
    { fetchPolicy: "network-only" }
  )

  const refetchQueries = [{ query: userProfileQuery }]

  if (loadingProfile) {
    return <Loader />
  }

  return (
    <div className="container u-mw-750 o-component">
      <UserDetails
        userProfile={profileData.myprofile}
        refetchQueries={refetchQueries}
      />
      <div className="pt-5">
        <LinkedAccounts
          userProfile={profileData.myprofile}
          refetchQueries={refetchQueries}
        />
      </div>
      <div className="pt-5 u-content-border-bottom">
        <ProfilePreferences
          userProfile={profileData.myprofile}
          refetchQueries={refetchQueries}
        />
      </div>
    </div>
  )
}
