import React from "react"

import { Loader, ClassModal, ActionBar, Table } from "components"
import { SECTIONS, HEADERS } from "domains/classes/table"
import { getSchoolId } from "util/app"
import { isNonEmptyArray } from "util/array"
import { AccountTypeContext } from "config/accountTypeContext"

import { ClassesColdStart } from "./"
import {
  DangerousMutation,
  SwapClassLabelsModal,
} from "domains/accountSettings/components"
import { Tooltip } from "react-tooltip"

import {
  getAvailableCriteria,
  getCurrentCriteria,
} from "domains/classes/components/entryCriteriaHelpers"

const newClassInitial = {
  teachers: [],
  label: "",
  schoolGrades: [],
}

function adjustAdvancedSettingsHeaders(
  HEADERS,
  hasClassEntryCriteria,
  hasMaxClassSize,
  gettextObj
) {
  let filtered_headers = maybeFilterHeaders(
    HEADERS,
    hasClassEntryCriteria,
    "classEntryCriteria"
  )
  filtered_headers = maybeFilterHeaders(
    filtered_headers,
    hasMaxClassSize,
    "classSizeSummary"
  )

  const filteredHeadersWithAdjustedName = filtered_headers.map(header => {
    const headerWithClassReplaced = {
      ...header,
      name: header.name.replace("Class", gettextObj.gettext("Class")),
    }

    return {
      ...headerWithClassReplaced,
      name: headerWithClassReplaced.name.replace(
        "New Grade/s",
        gettextObj.gettext("New Grade/s")
      ),
    }
  })

  const addAdjustingStylesToClassEntryCriteria =
    filteredHeadersWithAdjustedName.map(header => {
      if (header.id === "classEntryCriteria" && !hasMaxClassSize) {
        const dataClass = header["dataClass"]

        return {
          ...header,
          dataClass: `${dataClass} c-classes__table-data-border-left-2`,
        }
      } else {
        return header
      }
    })

  return addAdjustingStylesToClassEntryCriteria
}

function adjustSectionsAccordingToAccountType(SECTIONS, gettextObj) {
  const sectionsWithAdjustedName = {}
  for (const key in SECTIONS) {
    if (SECTIONS.hasOwnProperty(key)) {
      sectionsWithAdjustedName[key] = {
        name: SECTIONS[key].name.replace(
          "CLASSES",
          gettextObj.gettext("CLASSES")
        ),
      }
    }
  }

  return sectionsWithAdjustedName
}

function maybeFilterHeaders(headers, condition, id) {
  return condition ? headers : headers.filter(header => header.id !== id)
}

const getCriteriaAndResponse = (
  newClass,
  schoolCharacteristics,
  currentClasses
) => {
  const sortedCurrentClasses = currentClasses
  const availableCriteria = getAvailableCriteria(
    schoolCharacteristics,
    sortedCurrentClasses
  )
  const entryCriteriaArray = getCurrentCriteria(
    newClass.entryCriteriaCharacteristicResponse,
    newClass.entryCriteriaStudentAttribute,
    newClass.entryCriteriaNullResponse,
    availableCriteria
  )

  return entryCriteriaArray
}

const formatCriteriaResponseString = entryCriteriaArray => {
  const arrayOfEntryCriteriaStrings = []
  for (const entryCriteria of entryCriteriaArray) {
    const name = entryCriteria.name
    const selectedOptions = entryCriteria.selectedOptions
    const entryCriteriaAndResponses = []
    for (const option of selectedOptions) {
      const label = option.name
      entryCriteriaAndResponses.push(label)
    }
    const entryCriteriaString = `${name} (${entryCriteriaAndResponses.join(
      ", "
    )})`
    arrayOfEntryCriteriaStrings.push(entryCriteriaString)
  }
  return arrayOfEntryCriteriaStrings.join(", ")
}

export class Classes extends React.Component {
  static contextType = AccountTypeContext

  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      edit: false,
      showAddAnotherButton: true,
      canDelete: false,
      newClass: newClassInitial,
      showDeleteAllNewClasses: false,
      showSwapClassLabels: false,
    }
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
      showAddAnotherButton: true,
      edit: false,
      canDelete: false,
      newClass: newClassInitial,
    })
  }

  rowClick = newClass => {
    const {
      id,
      label,
      teachers,
      schoolGrades,
      solution,
      maxClassSize,
      maxSizePerGrade,
      entryCriteriaStudentAttribute,
      entryCriteriaCharacteristicResponse,
      entryCriteriaNullResponse,
    } = newClass

    this.setState({
      edit: true,
      canDelete: true,
      showAddAnotherButton: false,
      modal: true,
      newClass: {
        label,
        teachers,
        schoolGrades,
        id,
        solution,
        maxClassSize,
        maxSizePerGrade,
        entryCriteriaStudentAttribute,
        entryCriteriaCharacteristicResponse,
        entryCriteriaNullResponse,
      },
    })
  }

  render() {
    const {
      loading,
      newClasses,
      refetchQueries,
      settings,
      schoolCharacteristics,
      currentClasses,
      deleteAllNewClasses,
    } = this.props

    const {
      modal,
      newClass,
      edit,
      showDeleteAllNewClasses,
      showSwapClassLabels,
      canDelete,
      showAddAnotherButton,
    } = this.state

    if (loading) {
      return <Loader />
    }

    const classes = newClasses.map(newClass => {
      let newClassWithClassSize
      // Display the overall class maximum and allocation
      if (
        newClass.studentCount &&
        newClass.studentCount.total &&
        Number.isInteger(newClass.maxClassSize)
      ) {
        const overAllocated =
          newClass.studentCount.total > newClass.maxClassSize

        const classSizeSummary = (
          <>
            <span
              className={overAllocated ? "text-danger" : undefined}
              data-tooltip-id={newClass.id}>
              {`${newClass.maxClassSize} (${newClass.studentCount.total} allocated)`}
            </span>
            {overAllocated && (
              <Tooltip
                className="c-class-summary-tooltip"
                border="1px solid #bfbfbf"
                id={newClass.id}
                variant="light"
                opacity={1}
                float
                positionStrategy="fixed">
                <div>
                  <div className="c-class-summary-tooltip__body">
                    <b>WARNING:</b> There are more students allocated than the
                    maximum entered for this class
                  </div>
                </div>
              </Tooltip>
            )}
          </>
        )

        newClassWithClassSize = { ...newClass, classSizeSummary }

        // Display the per grade maximum and allocation
      } else if (isNonEmptyArray(newClass.maxSizePerGrade)) {
        const classSizeSummary = (
          <span>
            {newClass.maxSizePerGrade.map(({ grade, max }) => {
              const gradeCount =
                newClass.studentCount &&
                newClass.studentCount.studentCountPerGrade.find(
                  ({ grade: { id } }) => grade.id === id
                )

              const count = gradeCount ? gradeCount.count : null

              const overAllocated = count > max

              return (
                <div key={grade.id}>
                  <div
                    className={overAllocated ? "text-danger" : undefined}
                    data-tooltip-id={grade.id}>
                    {grade.label}: {max}
                    {Number.isInteger(count) && `  (${count} allocated)`}
                  </div>
                  {overAllocated && (
                    <Tooltip
                      className="c-class-summary-tooltip"
                      border="1px solid #bfbfbf"
                      id={grade.id}
                      variant="light"
                      opacity={1}
                      float
                      positionStrategy="fixed">
                      <div>
                        <div className="c-class-summary-tooltip__body">
                          <b>WARNING:</b> There are more students allocated than
                          the maximum entered for this grade
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              )
            })}
          </span>
        )

        newClassWithClassSize = {
          ...newClass,
          classSizeSummary: classSizeSummary,
        }
      } else {
        newClassWithClassSize = {
          ...newClass,
          classSizeSummary: newClass.maxClassSize,
        }
      }

      const criteriaAndResponse = getCriteriaAndResponse(
        newClass,
        schoolCharacteristics,
        currentClasses
      )

      const newClassWithCriteria = {
        ...newClassWithClassSize,
        classEntryCriteria: formatCriteriaResponseString(criteriaAndResponse),
      }

      return newClassWithCriteria
    })

    const hasClassEntryCriteria = classes.some(
      item => item["classEntryCriteria"] !== ""
    )

    const hasMaxClassSize = classes.some(
      item =>
        item["maxClassSize"] !== null ||
        isNonEmptyArray(item["maxSizePerGrade"])
    )

    const adminMode = settings.adminOnlyRequests

    const gettextObj = this.context.gettextObj

    const additionalActions = [
      {
        onClick: () => this.setState({ showDeleteAllNewClasses: true }),
        text: `Remove all ${gettextObj.gettext("Classes")}`,
        icon: "fa-trash",
      },
      {
        onClick: () => this.setState({ showSwapClassLabels: true }),
        text: `Swap ${gettextObj.gettext("Class")} Labels`,
        icon: "fa-trash",
      },
    ]

    return (
      <>
        {isNonEmptyArray(newClasses) ? (
          <div className="c-classes">
            <div>
              <ActionBar
                onAddClick={this.toggleModal}
                addText={`Add ${gettextObj.gettext("Class")}`}
                adminMode={adminMode}
                additionalActions={additionalActions}
              />
              <div className="c-classes__table-container">
                <Table
                  tableName="c-classes__table"
                  adjustAdvancedSettingsSection
                  sections={adjustSectionsAccordingToAccountType(
                    SECTIONS,
                    gettextObj
                  )}
                  headers={adjustAdvancedSettingsHeaders(
                    HEADERS,
                    hasClassEntryCriteria,
                    hasMaxClassSize,
                    gettextObj
                  )}
                  data={classes}
                  rowCount
                  stickyHeaderCount={1}
                  leftStickyColumnCount={0}
                  // Shitty workaround to built-in styles in react-sticky-table
                  borderWidth="inherit"
                  rowClick={this.props.newClasses.length > 0 && this.rowClick}
                />
              </div>
              <div className="c-classes__total">
                Total - {this.props.newClasses.length}{" "}
                {gettextObj.gettext("Classes")}
              </div>
            </div>
          </div>
        ) : (
          <ClassesColdStart onClick={this.toggleModal} />
        )}
        {modal && (
          <ClassModal
            {...newClass}
            currentClasses={currentClasses}
            newClasses={this.props.newClasses}
            refetchQueries={refetchQueries}
            isOpen={modal}
            canDelete={canDelete}
            showAddAnotherButton={showAddAnotherButton}
            toggle={this.toggleModal}
            heading={
              edit
                ? `Edit ${gettextObj.gettext("Class")}`
                : `Add ${gettextObj.gettext("Class")}`
            }
            buttonText={edit ? "Save Class" : "Add Class"}
            edit={edit}
            characteristics={schoolCharacteristics}
          />
        )}
        {showDeleteAllNewClasses && (
          <DangerousMutation
            dangerousMutation={deleteAllNewClasses}
            cautionHeading={`Remove all ${gettextObj.gettext("Classes")}`}
            cautionText={`You can not 'undo' this operation. Are you sure you would like to remove all the ${gettextObj.gettext(
              "classes"
            )}?`}
            cautionButtonText="Yes, Proceed"
            successMessage="All classes have been removed."
            errorMessage="Could not remove data"
            toggleComponent={() =>
              this.setState({
                showDeleteAllNewClasses: false,
              })
            }
          />
        )}

        {showSwapClassLabels && (
          <SwapClassLabelsModal
            schoolId={getSchoolId()}
            toggle={() => {
              this.setState({
                showSwapClassLabels: false,
              })
            }}
            classes={classes}
          />
        )}
      </>
    )
  }
}
