// The standard style for React-Select components
// https://react-select.com/styles
export const defaultSelectStyles = {
  multiValue: (styles, { theme: { colors } }) => ({
    ...styles,
    backgroundColor: colors.primary25,
    border: `0.5px solid ${colors.primary50}`,
  }),
  multiValueLabel: (styles, { theme: { colors } }) => ({
    ...styles,
    color: colors.primary,
  }),
  placeholder: styles => ({
    ...styles,
    color: "#bfbfbf",
    fontWeight: "$font-weight-light",
    fontSize: "0.9rem",
  }),
  menu: (styles, _) => ({
    ...styles,
    zIndex: 10,
  }),
}
