import React, { Component } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import { AccountTypeContext } from "config/accountTypeContext"

class ReportHeaderPre extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dropdownReportsOpen: false,
      dropdownExportsOpen: false,
    }
  }

  toggleDropdownReports = () => {
    this.setState(prevState => ({
      dropdownReportsOpen: !prevState.dropdownReportsOpen,
    }))
  }

  toggleDropdownExports = () => {
    this.setState(prevState => ({
      dropdownExportsOpen: !prevState.dropdownExportsOpen,
    }))
  }

  print = () => {
    const { setAllClassesExpand } = this.props
    if (setAllClassesExpand) {
      setAllClassesExpand(true, () => window.print())
    } else {
      window.print()
    }
  }

  render() {
    const {
      isClassReport,
      gradeId,
      solverGrades,
      onActiveGradeChange,
      csvExport,
      gettextObj,
    } = this.props

    const { dropdownExportsOpen, dropdownReportsOpen } = this.state
    return (
      <div className="d-print-none">
        <Row>
          <div className="col-12">
            <div className="py-3 pl-2 pr-3">
              <Link
                className="solve-page-link color-blue-mid font-weight-bold"
                to="/Solver">
                <i className="fa fa-angle-left pr-2 color-blue-mid font-weight-bold" />
                Back to Solver
              </Link>
            </div>
          </div>
        </Row>
        <Row className="pb-4 col-12">
          {isClassReport && (
            <div className="col-sm-4 col-lg-2">
              <Input
                type="select"
                value={gradeId}
                onChange={e => {
                  onActiveGradeChange(e.target.value)
                }}>
                {solverGrades.map((g, index) => (
                  <option key={index} value={g.id}>
                    {g.label}
                  </option>
                ))}
              </Input>
            </div>
          )}
          <Dropdown
            className="reports mr-2"
            isOpen={dropdownReportsOpen}
            toggle={this.toggleDropdownReports}>
            <DropdownToggle caret>{this.props.reportTitle}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag="div">
                <Link to="/SummaryReport/School">School Summary Report</Link>
              </DropdownItem>
              <DropdownItem tag="div">
                <Link to="/SummaryReport/Class">{`${gettextObj.gettext(
                  "Class"
                )} Summary Report`}</Link>
              </DropdownItem>
              <DropdownItem tag="div">
                <Link to="/SummaryReport/Sociogram">Sociogram View</Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            className="reports"
            isOpen={dropdownExportsOpen}
            toggle={this.toggleDropdownExports}>
            <DropdownToggle caret>More ...</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={this.print}>Print</DropdownItem>
              {isClassReport && (
                <DropdownItem onClick={csvExport}>Export</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </Row>
      </div>
    )
  }
}

export const ReportHeader = props => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return <ReportHeaderPre gettextObj={gettextObj} {...props} />
}
