import React from "react"
import { SurveyStudentsPage } from "../../surveyStudents/pages"
import {
  TOKEN_EXPIRED,
  TOKEN_NOT_FOUND,
  INVALID_TOKEN,
} from "domains/auth/errorFields"
import { loginWithSurveyTokenMutation } from "../graphql"
import { useTokenLogin } from "../hooks/useTokenLogin"
import { SURVEY } from "domains/auth/constants"
import { TEACHER_ID } from "constants/storageTokens"

export const SurveyLoginPage = () => {
  const errorMessages = {
    [TOKEN_EXPIRED]: {
      message:
        "To access your Survey, please click below to get a new link sent to your email.",
      info: {
        title: "Why do I need a new link to access the Survey?",
        body: "For your security, the links can only be opened a certain number of times within a set period of time. However, you can easily send yourself a new link by clicking “Send email with new link”. You will not lose any information.",
      },
    },
    [TOKEN_NOT_FOUND]: {
      message:
        "Thank you! This survey has been closed. Please contact your school administrator if you would like it re-opened.",
      promoBanner: {
        title:
          "Help make a difference at another school and you both save $100",
        message:
          "Do you know someone at another school who could benefit from Class Solver? Or would you like to learn more about how Class Solver helps schools create their best lists in a fraction of the time?",
        button: {
          link: "https://classsolver.com/watch-demo/",
          title: "Watch Now",
        },
      },
    },
    [INVALID_TOKEN]: {
      message:
        "This link is invalid or incomplete. Please double check that you have copied the entire link and try again.",
    },
  }

  const [authorized, AuthComponent, componentProps, response] = useTokenLogin({
    tokenType: SURVEY,
    loginMutation: loginWithSurveyTokenMutation,
    mutationName: "loginWithSurveyToken",
    errorMessages,
  })

  if (authorized) {
    if (response && response.user) {
      sessionStorage.setItem(TEACHER_ID, response.user.id)
    }
    return <SurveyStudentsPage teacherId={sessionStorage.getItem(TEACHER_ID)} />
  } else {
    return <AuthComponent {...componentProps} />
  }
}
