import React from "react"
import { Modal, ModalBody, Button } from "reactstrap"

import { Loader, InlineError, ModalHeader } from "components"
import { TeacherInputs } from "domains/teachers/components"

export const TeacherModal = ({
  isOpen,
  toggle,
  loading,
  edit,
  onChange,
  onMultiClassSelectChange,
  teacher,
  classOptions,
  onAddEditClick,
  onDeleteTeacherClick,
  errors,
}) => (
  <Modal isOpen={isOpen} className="modal-dialog-centered">
    <ModalHeader
      title={`${edit ? "Edit" : "Add"} Teacher`}
      toggle={toggle}
      className="py-4 px-5"
    />

    {loading ? (
      <div className="position-relative p-5">
        <Loader />
      </div>
    ) : (
      <ModalBody className="px-5">
        <TeacherInputs
          {...teacher}
          onChange={onChange}
          onMultiClassSelectChange={onMultiClassSelectChange}
          classOptions={classOptions}
          errors={errors}
          editMode={edit}
        />

        {/* Button Bar */}
        <div className="row my-4 justify-content-end align-items-center">
          {edit ? (
            <span
              className="text-primary col-3 cursor-pointer"
              onClick={onDeleteTeacherClick}>
              Delete Teacher
            </span>
          ) : null}
          <div className="col-9 d-flex justify-content-end align-items-center">
            <span className="text-primary px-4 cursor-pointer" onClick={toggle}>
              Cancel
            </span>
            <Button
              className="ml-2"
              onClick={() => onAddEditClick(true)}
              color="primary">
              Save Teacher
            </Button>
            {!edit ? (
              <Button
                className="ml-2"
                onClick={() => onAddEditClick(false)}
                color="primary">
                Save & Add Another Teacher
              </Button>
            ) : null}
          </div>
        </div>
        {errors.mutation && <InlineError text={errors.mutation} />}
      </ModalBody>
    )}
  </Modal>
)
