import React from "react"

// Styles
import "bootstrap/dist/css/bootstrap.css"
import "./styles/app.scss"

// API
import { ApolloProvider } from "@apollo/client"
import { createApolloClient } from "config/api"

import { Router } from "config/routes"

import { useAuth0 } from "domains/auth/auth0Wrapper"
import { Loader } from "components"
import { RecommendedBrowserAlert } from "domains/app/components"

export const App = () => {
  const context = useAuth0()

  if (context.loading) {
    return <Loader />
  }

  const apolloClient = createApolloClient(context)
  const onSignupPage = window.location.pathname.toLowerCase() === "/signup"
  return (
    <ApolloProvider client={apolloClient}>
      <div className="container-fluid">
        {!onSignupPage && <RecommendedBrowserAlert />}
        <Router />
      </div>
    </ApolloProvider>
  )
}
