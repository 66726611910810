import React, { useState } from "react"
import {
  Modal,
  ModalBody,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import {
  Loader,
  FilteredInput,
  Toggle,
  ModalHeader,
  ModalSuccessNote,
  InlineError,
  VideoModal,
} from "components"
import { RequestAdminNote } from "domains/requests/components"

import { INSTRUCTION_ADD_REQUEST_PLAYER_URL } from "constants/videos"

let styles = { textarea: { height: 122, maxHeight: 122, minHeight: 122 } }

export const RequestModal = ({
  edit,
  loading,
  addSuccessful,
  modal,
  errors,
  toggleDeleteModal,
  adminMode,
  saveRequest,
  students,
  studentsToExclude,
  teachers,
  studentFrom,
  studentFromName,
  personToName,
  inputGeneric,
  inputChangeStudentFrom,
  inputChangePersonTo,
  mandatory,
  pair,
  description,
  adminOnly,
  closeRequestModal,
}) => {
  const [showVideo, setShowVideo] = useState(false)
  const toggleVideo = () => {
    setShowVideo(!showVideo)
  }

  return (
    <>
      <Modal isOpen={modal} className="modal-dialog-centered">
        <Form>
          <ModalHeader
            title={`${edit ? "Edit" : "Add"} Request`}
            toggle={closeRequestModal}
            toggle2={toggleVideo}
            className="px-5 py-4"
          />
          {adminMode ? <RequestAdminNote className="px-5" /> : null}
          {addSuccessful ? (
            <ModalSuccessNote
              className="px-5"
              text="Request saved successfully!"
            />
          ) : null}
          {loading ? (
            <div className="position-relative p-5">
              <Loader />
            </div>
          ) : (
            <ModalBody className="pt-0 pl-5 pr-5">
              {adminMode && (
                <div className="row">
                  <div className="col-12 mt-4">
                    <Input
                      type="checkbox"
                      className="ml-0"
                      name="adminOnly"
                      id="adminOnly"
                      checked={adminOnly}
                      onChange={inputGeneric}
                    />
                    <Label htmlFor="adminOnly" className="ml-4">
                      Admin only Request
                    </Label>
                  </div>
                </div>
              )}
              <div className="row text-right justify-content-end">
                <div className="col-6 pr-4 pt-4">
                  <FormGroup className="text-left">
                    <Label htmlFor="StudentId">Student</Label>
                    <FilteredInput
                      nameVal="studentFromName"
                      changeHandler={inputGeneric}
                      selectedValue={studentFromName}
                      selectFunction={inputChangeStudentFrom}
                      placeholder="Type to select a student..."
                      students={students}
                      studentsToExclude={studentsToExclude}
                    />
                    <InlineError text={errors.studentFrom} />
                  </FormGroup>
                  <FormGroup className="text-left">
                    <Label htmlFor="pairToggle">Pair/Separate</Label>
                    <Toggle
                      fullWidth
                      nameForId="pair"
                      trueValue="Pair"
                      defaultState={pair}
                      falseValue="Separate"
                      changeFunction={inputGeneric}
                    />
                  </FormGroup>
                  <FormGroup className="text-left">
                    <Label htmlFor="studentTeacher">Student/Teacher</Label>
                    <FilteredInput
                      nameVal="personToName"
                      changeHandler={inputGeneric}
                      selectedValue={personToName}
                      selectFunction={inputChangePersonTo}
                      placeholder="Type to select a student/teacher..."
                      students={students}
                      teachers={teachers}
                      studentsToExclude={[studentFrom]}
                    />
                    <InlineError text={errors.personTo} />
                  </FormGroup>
                </div>
                <div className="col-6 pt-4">
                  <FormGroup className="text-left">
                    <Label htmlFor="mandatory">Importance</Label>
                    <Toggle
                      fullWidth
                      nameForId="mandatory"
                      defaultState={mandatory}
                      trueValue="Mandatory"
                      falseValue="Important"
                      changeFunction={inputGeneric}
                    />
                  </FormGroup>
                  <FormGroup className="text-left">
                    <Label htmlFor="description">
                      Comment (optional - max 255 characters)
                    </Label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Add comment..."
                      value={description || ""}
                      onChange={inputGeneric}
                      style={styles.textarea}
                      maxLength={255}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row my-4 justify-content-end align-items-center">
                {edit ? (
                  <span
                    className="text-primary col-3 cursor-pointer"
                    onClick={toggleDeleteModal}>
                    Delete Request
                  </span>
                ) : null}
                <div className="col-9 d-flex justify-content-end align-items-center">
                  <span
                    className="text-primary px-4 cursor-pointer"
                    onClick={closeRequestModal}>
                    Cancel
                  </span>
                  <Button
                    className="ml-2"
                    onClick={() => saveRequest(false)}
                    color="primary">
                    Save Request
                  </Button>
                  {!edit ? (
                    <Button
                      className="ml-2"
                      onClick={() => saveRequest(true)}
                      color="primary">
                      Save & Add Another Request
                    </Button>
                  ) : null}
                </div>
              </div>
              <InlineError text={errors.mutation} />
            </ModalBody>
          )}
        </Form>
      </Modal>
      {showVideo && (
        <VideoModal
          toggle={toggleVideo}
          videoUrl={INSTRUCTION_ADD_REQUEST_PLAYER_URL}
          title="Instruction Step 1"
        />
      )}
    </>
  )
}
