export const UNAUTHORIZED = { key: "unauthorized" }
export const NO_ACCESS = { key: "You don't have access to this schools data" }
export const GRADES_HAVE_STUDENTS = {
  key: "some-grades-have-students",
  message: "Could not save grades as some grades have students",
}
export const UNSUPPORTED_FORMAT = {
  key: "unsupported-format",
  message: "Format not supported, please make sure file is in .csv format",
}
