import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { FriendshipsRadio } from "./friendshipsRadio"
import { InlineError } from "components"
import { updateSchoolSettingMutation } from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"
import {
  maxFriendshipQuery,
  pruneMaxFriendsMutation,
  constraintsFriendQuery,
} from "domains/friendships/graphql"
import { DangerousMutation } from "domains/accountSettings/components"
import { Loader } from "components"
import { countBy } from "lodash"

export const FriendSettings = () => {
  const schoolId = getSchoolId()

  // State to keep track of selected max friends
  const [maxFriends, setMaxFriends] = useState()

  // State to determine whether we have a dangerous mutation
  const [warnDangerousAction, setWarnDangerousAction] = useState(false)

  // Query to retrieve the max friends
  const { data, loading } = useQuery(maxFriendshipQuery, {
    variables: { schoolId },
  })

  // Query to retrieve all friendship constraints
  const { data: constraintsData, loading: loadingConstraints } = useQuery(
    constraintsFriendQuery,
    {
      variables: { schoolId, adminOnly: true },
    }
  )

  // Mutation to prune max friends per student
  const [pruneMaxFriends] = useMutation(pruneMaxFriendsMutation, {
    onError: () => {},
  })

  // Mutation to update friend setting
  const [updateMaxFriends, { error: errorUpdating }] = useMutation(
    updateSchoolSettingMutation,
    {
      onCompleted: () => pruneMaxFriends({ variables: { schoolId } }),
      onError: () => {},
    }
  )

  if (loading || loadingConstraints) {
    return (
      <div className="position-relative p-5">
        <Loader />
      </div>
    )
  }

  const {
    schoolSettings: { maxFriends: currentMaxFriends },
  } = data

  const changeMaxFriends = maxFriends => {
    const variables = {
      schoolSettingParams: { maxFriends, schoolId },
    }

    return updateMaxFriends({ variables })
  }

  const handleFriendshipsRadioChange = e => {
    const newMaxFriends = parseInt(e.currentTarget.value, 10)

    // Check if there are friends allocated over the new maximum allowed
    const isDangerousAction = Object.values(
      countBy(constraintsData.constraints.constraintsFriend, "studentFromId")
    ).some(count => count > newMaxFriends)

    if (isDangerousAction) {
      setMaxFriends(newMaxFriends)
      setWarnDangerousAction(true)
    } else {
      changeMaxFriends(newMaxFriends)
    }
  }

  return (
    <div className="d-flex justify-content-between">
      <div className="w-25">
        <div className="mb-2">Friendship Preferences</div>
        <p>Maximum number of friends each student can nominate.</p>
      </div>
      <div className="d-flex flex-column">
        <div className="mt-4 mb-1">
          <FriendshipsRadio
            onChange={handleFriendshipsRadioChange}
            value={currentMaxFriends}
          />
        </div>

        {errorUpdating && (
          <InlineError text="Error changing friendship preference, please refresh and try again" />
        )}
      </div>
      {warnDangerousAction && (
        <DangerousMutation
          dangerousMutation={() => changeMaxFriends(maxFriends)}
          cautionHeading="Reducing maximum friends"
          cautionText={
            <>
              <div>
                Are you sure you would like to decrease the number of friends?
              </div>
              <br />
              <div>
                If teachers have already added student friendship information,
                you will be deleting any additional friends entered over this
                number.
              </div>
            </>
          }
          cautionButtonText="Yes, Proceed"
          errorMessage="Error changing friendship preference, please refresh and try again"
          toggleComponent={() => setWarnDangerousAction(false)}
          showSuccess={false}
        />
      )}
    </div>
  )
}
