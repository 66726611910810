import { gql } from "@apollo/client"

export const newClassesQuery = gql`
  query ($schoolId: ID!) {
    newClasses(schoolId: $schoolId) {
      id
      label
      maxClassSize
      maxSizePerGrade {
        grade {
          id
          label
          code
        }
        max
      }
      schoolGrades {
        id
        code
        order
        label
      }
      teachers {
        id
        firstName
        lastName
      }
      solution {
        id
      }
      studentCount {
        total
        studentCountPerGrade {
          grade {
            id
          }
          count
        }
      }
      entryCriteriaStudentAttribute {
        attribute
        value
      }
      entryCriteriaCharacteristicResponse {
        characteristicResponse {
          id
          label
          characteristic {
            id
            name
          }
          value
        }
      }
      entryCriteriaNullResponse {
        characteristic {
          id
          name
        }
      }
    }
  }
`

export const currentClassesQuery = gql`
  query ($schoolId: ID!) {
    currentClasses(schoolId: $schoolId) {
      id
      label
      grades {
        id
        code
      }
    }
  }
`

export const activeCurrentClassesQuery = gql`
  query ($schoolId: ID!) {
    activeCurrentClasses(schoolId: $schoolId) {
      id
      label
      studentCount
      grades {
        id
        code
        order
      }
    }
  }
`

export const schoolGradesQuery = gql`
  query ($schoolId: ID!) {
    schoolGrades(schoolId: $schoolId) {
      id
      code
      label
      order
    }
  }
`

export const newSchoolGradesQuery = gql`
  query ($schoolId: ID!, $includeNew: Boolean, $includeLeaving: Boolean) {
    newSchoolGrades(
      schoolId: $schoolId
      includeNew: $includeNew
      includeLeaving: $includeLeaving
    ) {
      id
      code
      label
      order
    }
  }
`

export const currentSchoolGradesQuery = gql`
  query ($schoolId: ID!, $includeNew: Boolean, $includeLeaving: Boolean) {
    currentSchoolGrades(
      schoolId: $schoolId
      includeNew: $includeNew
      includeLeaving: $includeLeaving
    ) {
      id
      code
      order
      label
      defaultNewGrade {
        __typename
        ... on SchoolGrade {
          id
          label
          code
          order
        }
        ... on SchoolGradesByGender {
          maleGrade {
            id
            label
            code
            order
          }
          femaleGrade {
            id
            label
            code
            order
          }
          nonBinaryGrade {
            id
            label
            code
            order
          }
        }
      }
    }
  }
`

export const activeSchoolGradesQuery = gql`
  query ($schoolId: ID!) {
    activeSchoolGrades(schoolId: $schoolId) {
      id
      code
      order
      label
    }
  }
`

export const activeNewSchoolGradesQuery = gql`
  query ($schoolId: ID!) {
    activeNewSchoolGrades(schoolId: $schoolId) {
      id
      code
      order
      label
    }
  }
`

export const schoolGradesWithSolutionsQuery = gql`
  query ($schoolId: ID!) {
    schoolGradesWithSolutions(schoolId: $schoolId) {
      id
      code
      order
    }
  }
`

export const schoolGradesMutation = gql`
  mutation ($schoolGradeParams: SchoolGradeParams!) {
    createOrUpdateSchoolGrades(schoolGradeParams: $schoolGradeParams) {
      id
      code
      label
    }
  }
`

export const createOrUpdateNewClass = gql`
  mutation createOrUpdateNewClass($newClassParams: NewClassParams!) {
    createOrUpdateNewClass(newClassParams: $newClassParams) {
      id
    }
  }
`

export const createOrUpdateCurrentClass = gql`
  mutation createOrUpdateCurrentClass(
    $currentClassParams: CurrentClassParams!
  ) {
    createOrUpdateCurrentClass(currentClassParams: $currentClassParams) {
      id
    }
  }
`

export const swapNewClassLabels = gql`
  mutation swapNewClassLabels($swapClassesParams: SwapClassesParams!) {
    swapNewClassLabels(swapClassesParams: $swapClassesParams) {
      id
    }
  }
`

export const deleteNewClassMutation = gql`
  mutation ($id: ID!) {
    deleteNewClass(id: $id) {
      id
    }
  }
`

export const deleteAllNewClasses = gql`
  mutation deleteAllNewClasses($schoolId: ID!) {
    deleteAllNewClasses(schoolId: $schoolId)
  }
`
