import React from "react"
import { Tooltip } from "react-tooltip"
import { isDefinedNotNull } from "util/objUtil"

export const CharacteristicTooltip = ({
  name,
  characteristicResponses,
  ...others
}) => (
  <Tooltip className="c-characteristic-tooltip p-0" opacity={1} {...others}>
    <div>
      <div className="c-characteristic-tooltip__header text-primary">
        {name}
      </div>
      <div className="c-characteristic-tooltip__body">
        {isDefinedNotNull(characteristicResponses) &&
          characteristicResponses.map((r, index) => (
            <div
              className="mt-3 c-characteristic-tooltip__description"
              key={index}>
              <span className="u-bold">{r.label}</span>
              {r.description !== null && <span>{` - ${r.description}`}</span>}
            </div>
          ))}
      </div>
    </div>
  </Tooltip>
)
