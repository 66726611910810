import React, { useState } from "react"
import { Button, Modal, Input, Label } from "reactstrap"
import { InlineError, ModalHeader, Loader } from "components"

import { sendMissingIdsEmailsMutation } from "domains/accountSettings/graphql"

import { useMutation } from "@apollo/client"
import { getSchoolId } from "util/app"

export const SendMissingIdsEmailsModal = ({ toggle, heading }) => {
  const [errorMessage, setErrorMessage] = useState()
  const [sendMissingIdsEmails, { loading }] = useMutation(
    sendMissingIdsEmailsMutation,
    {
      variables: { schoolId: getSchoolId() },
      onError: error => {
        setErrorMessage(`Error - Unable to send emails - ${error}`)
      },
      onCompleted: () => {
        toggle()
      },
    }
  )

  const [confirmationText, setConfirmationText] = useState("")
  const disabled = confirmationText.toLowerCase() !== "yes"
  const handleTextChange = e => setConfirmationText(e.target.value)

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <div className="p-4">
        <ModalHeader title={heading} toggle={toggle} />
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="mt-3">
              <Label>Please type 'yes' to confirm</Label>
              <Input
                type="text"
                value={confirmationText}
                onChange={handleTextChange}
              />
              <Button
                color="primary"
                className="w-100 mt-4"
                onClick={sendMissingIdsEmails}
                disabled={disabled}>
                Yes, Send Emails
              </Button>
              <div
                onClick={toggle}
                className="w-100 mt-3 text-center text-primary cursor-pointer py-2">
                Cancel
              </div>
              <InlineError text={errorMessage} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
