import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  getStudentImportMatchers,
  schoolUploadsQuery,
  updateBulkUploadJob,
  updateStudentImportMatchers,
  importUploadedFileFromJob,
} from "domains/upload/graphql"
import { GradeLabels } from "domains/students/components"
import { currentSchoolGradesQuery } from "domains/classes/graphql"
import { getSchoolId, queriesAreLoading } from "util/app"
import { warnings } from "domains/students/upload/warnings"
import { useNavigate, Navigate } from "react-router-dom"
import { pendingImport } from "../pendingImport"
import { Loader } from "components"

const GradeLabelsPageComponent = props => {
  const { schoolGrades, studentImportMatchers, ...others } = props
  const navigate = useNavigate()

  if (queriesAreLoading(props)) return <Loader />

  const { job: currentUploadJob } = pendingImport(
    props.schoolUploads,
    "STUDENT"
  )

  if (!currentUploadJob) {
    return <Navigate to="/Students" replace />
  }

  let matchingGrades
  if (
    studentImportMatchers &&
    studentImportMatchers.studentsImport.grades.length
  ) {
    matchingGrades = studentImportMatchers.studentsImport.grades
  } else {
    matchingGrades = currentUploadJob.fileSummary.grades
  }

  return (
    <GradeLabels
      matchingGrades={matchingGrades}
      schoolGrades={schoolGrades.currentSchoolGrades}
      currentUploadJob={currentUploadJob}
      knownWarnings={warnings}
      navigate={navigate}
      {...others}
    />
  )
}

export const GradeLabelsPage = compose(
  graphql(getStudentImportMatchers, { name: "studentImportMatchers" }),
  graphql(schoolUploadsQuery, {
    name: "schoolUploads",
    options: ({ studentImportMatchers }) => {
      // The Apollo cache is read-only so we need to clone the array before using it
      const columns = [...studentImportMatchers.studentsImport.columns]

      return {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: {
          schoolId: getSchoolId(),
          columnMap: columns,
        },
      }
    },
  }),
  graphql(currentSchoolGradesQuery, {
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
        includeLeaving: true,
      },
      fetchPolicy: "network-only",
    }),
    name: "schoolGrades",
  }),
  graphql(updateBulkUploadJob, { name: "updateBulkUploadJob" }),
  graphql(updateStudentImportMatchers, { name: "updateStudentImportMatchers" }),
  graphql(importUploadedFileFromJob, { name: "importUploadedFileFromJob" })
)(GradeLabelsPageComponent)
