import { useMutation } from "@apollo/client"
import { gql } from "@apollo/client"

export const CREATE_BULK_UPLOAD_JOB = gql`
  mutation createBulkUploadJob(
    $file: Upload!
    $schoolId: ID!
    $type: UploadJobType!
  ) {
    createBulkUploadJob(file: $file, schoolId: $schoolId, type: $type) {
      id
      meta
      type
      status
      failureCount
      updatedAt
      createdAt
      uploadedFile {
        csvData {
          canonicalHeaders
          headers
          rows
        }
      }
    }
  }
`

export const useCreateBulkUploadJob = () => {
  // Prepare and return a mutation. This hook is just a thin wrapper
  return useMutation(CREATE_BULK_UPLOAD_JOB)
}
