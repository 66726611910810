import React from "react"
import { Modal } from "reactstrap"
import { ButtonBar, InlineError, ModalHeader, Loader } from "components"

export const DeleteModal = ({
  isOpen,
  toggle,
  heading,
  text,
  error,
  loading,
  onButtonClick,
  buttonText = "Yes, delete",
}) => (
  <Modal isOpen={isOpen} className="modal-dialog-centered u-modal__w-30rem">
    <div className="p-4">
      <ModalHeader title={heading} toggle={toggle} />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column align-items-center mt-2">
            <div className="mt-3 d-flex justify-content-between">
              <i className="fa fa-info-circle u-caution-icon mr-4" />
              <div>
                <span className="text-primary mb-3">CAUTION: </span>
                {text}
              </div>
            </div>
          </div>
          <ButtonBar
            className="mt-5"
            buttonText={buttonText}
            onButtonClick={onButtonClick}
            onCancelClick={toggle}
          />
          <InlineError text={error} />
        </div>
      )}
    </div>
  </Modal>
)
