import React from "react"
import { StickyTable, Row, Cell } from "react-sticky-table"

import { CharacteristicTooltip } from "components"
import { StudentsTableRow } from "domains/students/components"

import { isDefinedNotNull } from "util/objUtil"

const getStudentsData = students => {
  if (students.length < 1) {
    // If search returns no result, show empty fields
    return [
      {
        firstName: "",
        lastName: "",
        gender: "",
        constraintsCount: 0,
        characteristicResponses: [],
        friends: [],
      },
    ]
  }
  return students
}

export const StudentsTable = ({
  students,
  teacherMode,
  maxFriends,
  schoolCharacteristics,
  teacherRequestsEditable,
  studentRequestsEditable,
  onAddRequestClick,
  onRowClick,
  navToCharacteristics,
}) => {
  // Build Header Labels
  const friendsHeaders = Array(maxFriends)
    .fill()
    .map((_, i) => ({ label: "Friend " + (i + 1) }))

  const isSchoolCharacteristics =
    isDefinedNotNull(schoolCharacteristics) && schoolCharacteristics.length > 0

  return (
    <div className="c-students-table">
      <StickyTable
        id="student-table-id"
        stickyHeaderCount={1}
        leftStickyColumnCount={1}
        // Shitty workaround to built-in styles in react-sticky-table
        borderWidth="inherit">
        {/* Header Sections */}
        <Row className="c-students-table__sections">
          <Cell
            style={{ zIndex: 4 }}
            className={`section student-details fixed-column-1`}>
            <span>STUDENT DETAILS</span>
            <div
              className={`c-students-table__headers header student-details count`}>
              &nbsp;
            </div>
          </Cell>
          <Cell
            style={{ zIndex: 3 }}
            className={`section student-details fixed-column-2`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details first-name`}>
              First Name
            </div>
          </Cell>
          <Cell
            style={{ zIndex: 3 }}
            className={`section student-details-last-name fixed-column-3`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details last-name`}>
              Last Name
            </div>
          </Cell>
          <Cell className={`section student-details`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details id`}>
              ID
            </div>
          </Cell>
          <Cell className={`section student-details`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details gender`}>
              Gender
            </div>
          </Cell>
          <Cell className={`section student-details`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details current-grade`}>
              Current Grade
            </div>
          </Cell>
          <Cell className={`section student-details`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details current-class`}>
              Current Class
            </div>
          </Cell>
          <Cell className={`section student-details`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details new-grade`}>
              New Grade
            </div>
          </Cell>
          {!teacherMode && (
            <Cell className={`section student-details`}>
              <span>&nbsp;</span>
              <div
                className={`c-students-table__headers header student-details new-class`}>
                New Class
              </div>
            </Cell>
          )}
          <Cell className={`section student-details`}>
            <span>&nbsp;</span>
            <div
              className={`c-students-table__headers header student-details notes`}>
              Notes
            </div>
          </Cell>
          {(teacherRequestsEditable || studentRequestsEditable) && (
            <Cell className="section requests">
              <span>REQUESTS</span>
              <div className="c-students-table__headers header requests">
                Total
              </div>
            </Cell>
          )}
          {isSchoolCharacteristics &&
            schoolCharacteristics.map((c, index) => {
              const lastElement = index === schoolCharacteristics.length - 1

              let section
              if (index === 0) {
                section = <span>CHARACTERISTICS</span>
              } else if (lastElement) {
                section = (
                  <span className="characteristics-icon">
                    <i
                      className="fa fa-gear pr-4 cursor-pointer"
                      onClick={navToCharacteristics}
                    />
                  </span>
                )
              } else {
                section = <span>&nbsp;</span>
              }

              const header = (
                <div className="d-flex justify-content-between align-items-center w-100 pr-1">
                  <span>{c.name}</span>
                  <i
                    className="fa fa-info-circle font-size-14 u-opacity-50"
                    data-tooltip-id={`characteristic-${index}`}
                  />
                </div>
              )

              return (
                <Cell className={`section characteristics`} key={index}>
                  {section}
                  <div
                    className="c-students-table__headers header characteristics"
                    key={index}>
                    {header}
                  </div>
                </Cell>
              )
            })}
          {friendsHeaders.length > 0 &&
            friendsHeaders.map((fh, index) => {
              const sectionTitle =
                index === 0 ? <span>FRIENDSHIPS</span> : <span>&nbsp;</span>

              return (
                <Cell className="section friendships" key={index}>
                  {sectionTitle}
                  <div
                    className="c-students-table__headers header friendships"
                    key={index}>
                    {fh.label}
                  </div>
                </Cell>
              )
            })}
        </Row>
        {/* Data */}
        {getStudentsData(students).map((student, index) => {
          return (
            <StudentsTableRow
              key={index}
              data={student}
              index={index}
              className={teacherMode && "c-students-table__student-survey"}
              maxFriends={maxFriends}
              teacherMode={teacherMode}
              teacherRequestsEditable={teacherRequestsEditable}
              studentRequestsEditable={studentRequestsEditable}
              onAddRequestClick={onAddRequestClick}
              schoolCharacteristics={schoolCharacteristics}
              onRowClick={onRowClick}
            />
          )
        })}
      </StickyTable>
      {/* Must Create Tooltips outside of Sticky Table to render properly */}
      {isSchoolCharacteristics &&
        schoolCharacteristics.map((c, index) => {
          return (
            <CharacteristicTooltip
              // Need to adjust the z-index to overcome the react sticky table header
              style={{ zIndex: "10" }}
              name={c.name}
              key={index}
              id={`characteristic-${index}`}
              place="right"
              variant="light"
              characteristicResponses={c.characteristicResponses}
            />
          )
        })}
    </div>
  )
}
