import React, { useState } from "react"

import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"

import { ModalHeader } from "components"

const MoreInfoModal = ({ toggle }) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        title="Subscription Expired"
        toggle={toggle}
        className="p-3"
      />

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between mr-2">
          <i className="fa fa-info-circle u-caution-icon mr-3" />
          <div>
            <h2>How do I renew my subscription?</h2>
            <p>
              Please email{" "}
              <a
                className="u-underline u-font-weight-medium u-blue-mid"
                href="mailto: support@classsolver.com">
                support@classsolver.com
              </a>{" "}
              with the number of students that you would like to use in the
              program.
            </p>
            <h2>Can I still use Class Solver in the meantime?</h2>
            <p>
              Yes, you are welcome to complete Steps 1 and 2, however you will
              not be able to place students into classes (Step 3) until renewal
              has been confirmed.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const SubscriptionExpiredNote = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const toggle = () => setShowMoreInfo(prevState => !prevState)

  return (
    <>
      <div className="c-modal-expired-note text-center">
        Your subscription has expired.  Please email{" "}
        <a
          className="u-underline u-font-weight-medium u-blue-mid"
          href="mailto: support@classsolver.com">
          support@classsolver.com
        </a>{" "}
        to renew. You can still complete Steps 1 and 2 prior to renewal.{" "}
        <span className="cursor-pointer text-primary" onClick={toggle}>
          Learn more
        </span>
      </div>
      {showMoreInfo && <MoreInfoModal toggle={toggle} />}
    </>
  )
}
