import React from "react"

export const VersionPage = () => {
  return (
    <pre>
      {JSON.stringify({
        version: process.env.REACT_APP_VERSION || "0.1.0-development",
      })}
    </pre>
  )
}
