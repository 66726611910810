import React, { useState } from "react"
import { useMutation } from "@apollo/client"

import { AddOrEditTextModal, SuccessModal } from "components"

import { inviteUsersToSchoolMutation } from "domains/accountSettings/graphql"

import { validateEmail } from "util/validators"

import { normalizeEmails } from "util/nameUtil"

const EMPTY_ADDRESS = ""

export const InviteUsersModal = ({ toggle, school, refetchQueries }) => {
  const [inviteSuccessful, setInviteSuccessful] = useState(false)

  const [emailAddresses, setEmailAddresses] = useState([EMPTY_ADDRESS])

  const [inviteUsersToSchool, { error, loading }] = useMutation(
    inviteUsersToSchoolMutation,
    {
      onError: () => {},
      refetchQueries,
    }
  )

  const openSuccessModal = () => {
    setInviteSuccessful(true)
  }

  const closeSuccessModal = () => {
    setInviteSuccessful(false)
    toggle()
  }

  const emails = normalizeEmails(emailAddresses)

  const hasInvalidEmail =
    emails.length === 0 || !emails.every(email => validateEmail(email))

  const save = () => {
    const variables = {
      emails,
      schoolId: school.id,
    }

    inviteUsersToSchool({ variables }).then(() => {
      openSuccessModal()
    })
  }

  return (
    <>
      <AddOrEditTextModal
        toggle={toggle}
        loading={loading}
        inputs={emailAddresses}
        setInput={setEmailAddresses}
        onSave={save}
        saveDisabled={hasInvalidEmail}
        saveButtonText="Add"
        saveErrors={error}
        title="Add Administrator"
        description=" Enter the email address or email addresses of the user or users you would like to invite."
        addButtonText="Add another email"
        note="Note: All administrators will be able to see the details for administrator view only requests (i.e. highly confidential requests that have been added to the system)."
      />
      {inviteSuccessful && (
        <SuccessModal
          isOpen
          toggle={closeSuccessModal}
          heading="New Admin created"
          text="An invitation has been sent to the new administrator's email address."
        />
      )}
    </>
  )
}
