import React from "react"
import { Link } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"

import { Loader, InlineError, SchoolDetailsInputs } from "components"
import Logo from "images/logo.png"

import { validateSchoolData } from "util/validators"
import { setApiRegion, getRegionFromCountryCode } from "util/apiUtil"
import {
  TERMS_OF_USE_LINK,
  PRIVACY_POLICY_LINK,
} from "domains/app/constants/documentLinks"

const styles = {
  logo: {
    maxWidth: 150,
  },
}

export class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        schoolName: "",
        addressLine1: "",
        suburb: "",
        countryCode: null,
        stateCode: "",
        postcode: "",
        contactFirstName: "",
        contactLastName: "",
        contactNumber: "",
        contactEmail: "",
        contactPositionTitle: "",
        numberOfStudents: "",
        administrationSystem: "",
      },
      errors: {},
      loading: false,
    }
  }

  onChange = e => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [e.target.name]: e.target.value,
      },
      errors: { ...prevState.errors, [e.target.name]: null },
    }))
  }

  onSubmit = e => {
    const { signupMutation } = this.props
    e.preventDefault()
    const errors = validateSchoolData(this.state.data)
    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })

      const variables = {
        ...this.state.data,
        numberOfStudents: parseInt(this.state.data.numberOfStudents, 10),
      }

      // Sign up to the correct API instance
      const region = getRegionFromCountryCode(this.state.data.countryCode)
      setApiRegion(region)

      signupMutation({ variables })
        .then(() => {
          this.props.updateEmail({
            variables: { email: variables.contactEmail },
          })
          this.props.navigate("/Signup/Success")
        })
        .catch(e => {
          let signUpError
          if (e.message === "invalid-email-domain") {
            signUpError = "Please use your school email address"
          } else {
            // TODO: This should not be the default error but it is at the moment
            signUpError =
              "The email address is already associated with a user account, please use a different email address"
          }
          this.setState({
            loading: false,
            errors: { signUp: signUpError },
          })
        })
    }
  }

  render() {
    const { errors, data, loading } = this.state

    if (loading) {
      return <Loader />
    }

    const signupLink =
      process.env.REACT_APP_ENV === "production" ? (
        <a href="https://classsolver.com/login/"> Sign In</a>
      ) : (
        <Link to="/Login"> Sign In</Link>
      )
    return (
      <div>
        <Row className="justify-content-around align-items-center mb-5">
          <img
            src={Logo}
            alt="Class Solver Logo"
            style={styles.logo}
            className="mt-3"
          />
          <div>
            <span>Already have an account?</span>
            {signupLink}
          </div>
        </Row>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <SchoolDetailsInputs
            data={data}
            onChange={this.onChange}
            errors={errors}
          />
          <div className="pl-5">
            Please make sure you are authorized to create an account for your
            school. Click to view{" "}
            <a
              href={TERMS_OF_USE_LINK}
              target="_blank"
              rel="noopener noreferrer">
              Customer Terms
            </a>
            &nbsp;and&nbsp;
            <a
              href={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
            <Button
              color="primary"
              size="md"
              className="mt-4 mb-4"
              block
              onClick={this.onSubmit}>
              Create account
            </Button>
            {errors.signUp && <InlineError text={errors.signUp} />}
          </div>
        </Col>
      </div>
    )
  }
}
