import React, { useState } from "react"
import { EditUserDetailsModal } from "domains/auth/components"
import { fullName } from "util/nameUtil"

export const UserDetails = ({ userProfile, refetchQueries }) => {
  const [editUserModal, setEditUserModal] = useState(false)

  const toggleEditUserModal = () => setEditUserModal(prevState => !prevState)

  return (
    <div className="c-account-details pb-5 u-content-border-bottom">
      <div className="mb-4 d-flex justify-between">
        <h2 className="u-blue-primary">My Profile</h2>
        <a className="text-primary" onClick={toggleEditUserModal}>
          Edit
        </a>
      </div>
      <div>
        <div className=" my-1">
          <div className="mb-3 u-bold">{fullName(userProfile)}</div>
          <div className="mb-2">{userProfile.positionTitle}</div>
          <div className="mb-2">{userProfile.email}</div>
          {editUserModal && (
            <EditUserDetailsModal
              toggle={toggleEditUserModal}
              refetchQueries={refetchQueries}
              user={userProfile}
            />
          )}
        </div>
      </div>
    </div>
  )
}
