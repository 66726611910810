import React from "react"
import SolverClockIcon from "images/solver-clock-icon.svg"
import { Button } from "reactstrap"

export const RunningSolveView = ({ solverRefetch }) => (
  <div className="container-fluid d-flex justify-content-center align-items-center">
    <div className="flex-column text-center">
      <img src={SolverClockIcon} alt="Solver Clock Icon" />
      <div>&nbsp;</div>
      <h1 className="mt-3">Currently solving classes for this grade</h1>
      <p className="pt-2 font-size-16">
        The administrator who began the process will receive an <b>email</b>{" "}
        when it is ready (normally within 2 hours)
      </p>
      <Button color="primary" onClick={solverRefetch}>
        Refresh page
      </Button>
    </div>
  </div>
)
