import React from "react"
import { ConstraintsBadge, ConditionalDisplay } from "components"

export const SolverTeachers = ({
  isStudentInClass,
  selectedStudentId,
  teachers,
  selectedTeacherId,
  teacherConstraints,
  maxTeacherPerClass,
  updateSelectedTeacherId,
  toggleModal,
  readMode,
  toggleTeacherPanel,
  teacherPanelIsOpen,
}) => {
  // renders the teachers
  let eitherTeacherSelected = false
  const teachersRender = teachers.map((teacher, index) => {
    // logic for selecting teachers
    const selected = teacher.id === selectedTeacherId
    let className =
      "c-solver-teachers__teacher d-flex d-print-inline-flex justify-content-between"
    if (selected) {
      eitherTeacherSelected = true
      className += selected ? " c-solver-teachers__teacher--selected" : ""
    }

    // Checks for if the teacher is related to constraint
    let currentTeacherConstraints = []
    teacherConstraints.forEach(teacherConstraint => {
      // check if constraint is to teacher
      if (teacherConstraint.teacherTo.id === teacher.id) {
        // build the constrain object
        const { mandatory, pair } = teacherConstraint
        currentTeacherConstraints.push({
          mandatory,
          pair,
          isMet: pair
            ? isStudentInClass(selectedStudentId)
            : !isStudentInClass(selectedStudentId),
        })
      }
    })
    return (
      <span
        key={index}
        className={className}
        onClick={() => updateSelectedTeacherId(teacher.id)}>
        {`${teacher.firstName} ${teacher.lastName}`}
        {currentTeacherConstraints.map((constraint, index) => (
          <span className="ml-1" key={index}>
            <ConstraintsBadge
              mandatory={constraint.mandatory}
              pair={constraint.pair}
              isMet={constraint.isMet}
            />
          </span>
        ))}
      </span>
    )
  })

  // This is to "pad out" the list of teachers so that expanding the teacher section for one class will expand the teacher sections for the other classes
  // to the same height. It was done this way so that this effect could be acheived without having to hardcode css inside this component.
  const numberOfEmptyItemsToAdd =
    teachers.length === 0 && maxTeacherPerClass > 0
      ? maxTeacherPerClass - teachers.length - 1
      : maxTeacherPerClass - teachers.length
  for (let i = 0; i < numberOfEmptyItemsToAdd; i++) {
    let className =
      "c-solver-teachers__teacher w-100 d-flex d-print-inline-flex justify-content-between"
    teachersRender.push(<span key={`empty-${i}`} className={className}></span>)
  }

  const iconClass = teacherPanelIsOpen ? "fa-angle-up" : "fa-angle-down"

  const panelClass =
    maxTeacherPerClass <= 1 || teacherPanelIsOpen
      ? "c-solver-teachers--open"
      : "c-solver-teachers--close"

  const teacherContainerClassName = eitherTeacherSelected
    ? "c-solver-teachers--selected"
    : ""

  return (
    <div className={`c-solver-teachers_outer ${teacherContainerClassName}`}>
      <div className={`c-solver-teachers ${panelClass}`}>
        <ConditionalDisplay hide={readMode}>
          <i
            className="fa fa-pencil ml-2 cursor-pointer d-print-none c-solver-teachers__pencil"
            onClick={toggleModal}
          />
        </ConditionalDisplay>
        <div className="w-100 d-flex flex-column align-items-start text-truncate">
          {teachers.length === 0 && (
            <span className="c-solver-teachers__teacher">
              No Teacher Assigned
            </span>
          )}
          <div className="w-80">{teachersRender}</div>
        </div>
      </div>
      {maxTeacherPerClass > 2 && (
        <div className="c-solver-teachers__arrow_space">
          {teachers.length > 2 && (
            <i
              onClick={toggleTeacherPanel}
              className={`fa ${iconClass} c-solver-teachers__arrow`}
            />
          )}
        </div>
      )}
    </div>
  )
}
