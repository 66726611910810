import React, { Component } from "react"
import { ClassBadge } from "components"
import { CharacteristicTotalCells } from "domains/reports/components"

import { fullName } from "util/nameUtil"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { genderColor } from "util/studentUtil"

import { AccountTypeContext } from "config/accountTypeContext"

export class ClassTableBody extends Component {
  toggleExpanded = () => {
    this.props.setExpanded(!this.props.expanded)
  }

  static contextType = AccountTypeContext

  render() {
    const {
      data,
      schoolCharacteristics,
      rowLength,
      expanded,
      unmetConstraints,
      hasGenderX,
    } = this.props
    const icon = expanded ? "fa-chevron-up" : "fa-chevron-down"
    const studentRowClassName = expanded ? "" : "d-none"

    const { classDetails, counts, students } = data

    const gettextObj = this.context.gettextObj

    const genderCount = hasGenderX
      ? `${counts.genders.totalMaleCount}M / ${counts.genders.totalFemaleCount}F / ${counts.genders.totalNonBinaryCount}X`
      : `${counts.genders.totalMaleCount}M / ${counts.genders.totalFemaleCount}F`

    const sortedStudents = students
      .slice()
      .sort((a, b) => a.lastName.localeCompare(b.lastName))

    // Initialise an array
    let characteristicTotals = schoolCharacteristics.map(characteristic => {
      return new Array(characteristic.characteristicResponses.length).fill(0)
    })

    const formatConstraintsString = (
      firstName,
      lastName,
      mandatory,
      pair,
      unmet
    ) =>
      `${firstName} ${lastName} (${mandatory ? "Mandatory" : "Important"} ${
        pair ? "Pair" : "Separate"
      } - ${unmet ? "Unmet" : "Met"})`

    const formatStudentConstraints = (student, unmetConstraints) => {
      let strings = []
      student.studentConstraints.forEach(constraint => {
        const unmet = unmetConstraints.some(
          uc =>
            uc.__typename === "ConstraintsStudent" &&
            uc.constraintId === constraint.constraintId
        )

        student.id === constraint.studentFrom.id
          ? strings.push(
              formatConstraintsString(
                constraint.studentTo.firstName,
                constraint.studentTo.lastName,
                constraint.mandatory,
                constraint.pair,
                unmet
              )
            )
          : strings.push(
              formatConstraintsString(
                constraint.studentFrom.firstName,
                constraint.studentFrom.lastName,
                constraint.mandatory,
                constraint.pair,
                unmet
              )
            )
      })
      return strings.join(", ")
    }

    const formatTeacherConstraints = (student, unmetConstraints) => {
      let strings = []
      student.teacherConstraints.forEach(constraint => {
        const unmet = unmetConstraints.some(
          uc =>
            uc.__typename === "ConstraintsTeacher" &&
            uc.constraintId === constraint.constraintId
        )
        strings.push(
          formatConstraintsString(
            constraint.teacherTo.firstName,
            constraint.teacherTo.lastName,
            constraint.mandatory,
            constraint.pair,
            unmet
          )
        )
      })
      return strings.join(", ")
    }

    return (
      <React.Fragment>
        {/* This is needed as `breakAfter` only works between block elements */}
        <tbody style={{ display: "block" }}>
          <tr>
            <td />
          </tr>
        </tbody>
        <tbody className="c-reports-table-body">
          <tr className="c-reports-table-body__row--divider bg-table-header">
            <td
              colSpan={rowLength}
              className="c-reports-table-body__row__class-label">
              <i
                className={`fa ${icon} cursor-pointer p-2`}
                onClick={this.toggleExpanded}
              />

              {`${gettextObj.gettext("Class")} ${classDetails.label}`}
            </td>
          </tr>
          {sortedStudents.map((student, index) => {
            const { gender, characteristicResponses } = student

            return (
              <tr
                key={index}
                className={`c-reports-table-body__row ${studentRowClassName}`}>
                {/* STUDENT DETAILS */}
                <td
                  className="c-reports-table-body__row__data text-left pl-3"
                  colSpan="4">
                  {fullName(student)}
                </td>
                <td className="c-reports-table-body__row__data">
                  <ClassBadge
                    className="m-a"
                    label={getPropertyIfDefined(student, "currentClass.label")}
                  />
                </td>
                <td
                  className={`c-reports-table-body__row__data u-bold ${genderColor(
                    gender
                  )}`}>
                  {gender.toUpperCase()}
                </td>

                {/* CHARACTERISTICS */}
                {schoolCharacteristics.map((characteristic, index) => {
                  // get the students response for that characteristic
                  const studentResponse = characteristicResponses.find(
                    response => response.characteristic.id === characteristic.id
                  )
                  // start building the data label
                  let responseLabel = ""
                  for (
                    let i = 0;
                    i < characteristic.characteristicResponses.length;
                    i++
                  ) {
                    const { id, label } =
                      characteristic.characteristicResponses[i]
                    if (
                      isDefinedNotNull(studentResponse) &&
                      id === studentResponse.id &&
                      studentResponse.value > 0
                    ) {
                      responseLabel = label
                      // increment the total
                      characteristicTotals[index][i]++
                    }
                  }

                  return (
                    <td key={index} className="c-reports-table-body__row__data">
                      {responseLabel}
                    </td>
                  )
                })}

                {/* PERFORMANCE */}
                <td
                  colSpan="2"
                  className="c-reports-table-body__row__data c-reports-table-body__row__notes">
                  <p>{formatStudentConstraints(student, unmetConstraints)}</p>
                  <p>{formatTeacherConstraints(student, unmetConstraints)}</p>
                </td>
                <td
                  colSpan="2"
                  className="c-reports-table-body__row__data c-reports-table-body__row__notes">
                  {student.friends.map(friend => {
                    const met = sortedStudents.some(
                      student => student.id === friend.studentTo.id
                    )
                    return `${friend.studentTo.firstName} ${
                      friend.studentTo.lastName
                    } - (${met ? "Met" : "Unmet"})\n`
                  })}
                </td>

                <td className="c-reports-table-body__row__data c-reports-table-body__row__notes">
                  {student.comments}
                </td>
              </tr>
            )
          })}
          <tr className="c-reports-table-body__row--total bg-grey-requests">
            <td colSpan="4" className="text-right pr-2 border-right">
              Total
            </td>

            {/* STUDENT DETAILS */}
            <td className="c-reports-table-body__row__data">{counts.total}</td>
            <td className="c-reports-table-body__row__data">{genderCount}</td>

            {/* CHARACTERISTICS */}
            <CharacteristicTotalCells data={characteristicTotals} />

            {/* PERFORMANCE */}
            <td colSpan="2" className="c-reports-table-body__row__data"></td>
            <td colSpan="2" className="c-reports-table-body__row__data"></td>
            {/* NOTES */}
            <td className="c-reports-table-body__row__data"></td>
          </tr>
          <tr className="c-reports-table-body__row--empty">
            <td colSpan={rowLength}>Empty Row</td>
          </tr>
        </tbody>
        {/* This is needed as `breakAfter` only works between block elements */}
        <tbody style={{ display: "block", breakAfter: "page" }}>
          <tr>
            <td />
          </tr>
        </tbody>
      </React.Fragment>
    )
  }
}
