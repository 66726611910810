import React from "react"
import { AddButton } from "components"

import { ShareSolutionAssignmentRow } from "./shareSolutionAssignmentRow"
import { defaultSelectStyles } from "components"
import { EMPTY_ASSIGNMENT, GRADES } from "../constants"
import { isSystemGenerated } from "util/teacherUtil"
import { AccountTypeContext } from "config/accountTypeContext"

const selectTeacherStyles = {
  ...defaultSelectStyles,
  multiValue: (styles, { data, theme: { colors } }) => {
    if (isSystemGenerated(data.email)) {
      return {
        ...styles,
        backgroundColor: colors.dangerLight,
        border: `0.5px solid ${colors.danger}`,
      }
    } else {
      return {
        ...styles,
        backgroundColor: colors.primary25,
        border: `0.5px solid ${colors.primary50}`,
      }
    }
  },
}

export const ShareSolutionByGradesOrClasses = ({
  assignments = [],
  assignmentsToSelect,
  teacherWithSharedSolutionToEdit,
  availableGradesOrClasses,
  availableTeachers,
  onAssignmentsChange,
  shareType,
}) => {
  const onAddRowClick = () =>
    onAssignmentsChange([...assignments, EMPTY_ASSIGNMENT])

  const onDeleteRowClick = index => {
    return () => {
      const newAssignments = [...assignmentsToSelect]

      newAssignments.splice(index, 1)
      onAssignmentsChange(newAssignments)
    }
  }

  const onGradeOrClassChange = index => {
    return gradesOrClasses => {
      const newAssignments = [...assignmentsToSelect]

      const newAssignment = {
        ...newAssignments[index],
        gradesOrClasses: gradesOrClasses || [],
      }
      newAssignments.splice(index, 1, newAssignment)

      onAssignmentsChange(newAssignments)
    }
  }

  const onTeacherChange = index => {
    return teachers => {
      const newAssignments = [...assignmentsToSelect]

      const newAssignment = {
        ...newAssignments[index],
        teachers: teachers || [],
      }
      newAssignments.splice(index, 1, newAssignment)
      onAssignmentsChange(newAssignments)
    }
  }

  const gradeOrClassOptions = availableGradesOrClasses.map(
    ({ id, label, defaultTeachers }) => ({
      value: id,
      label: label,
      defaultTeachers: defaultTeachers,
    })
  )

  const teacherOptions = availableTeachers.map(teacher => ({
    value: teacher.id,
    label: `${teacher.firstName} ${teacher.lastName}`,
    email: teacher.email,
  }))

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <>
      <div className="row">
        <div className="shared-solutions-menu__heading col-1" />
        <div className="shared-solutions-menu__heading col-5">
          {shareType === GRADES
            ? gettextObj.gettext("Grade/s")
            : gettextObj.gettext("Class/es")}
        </div>
        <div className="shared-solutions-menu__heading col-6">Teacher/s</div>
      </div>

      {assignmentsToSelect.map((assignment, index) => {
        const firstColumnProps = {
          options: gradeOrClassOptions,
          onChange: onGradeOrClassChange(index),
          value: assignment.gradesOrClasses || [],
          styles: defaultSelectStyles,
        }

        const secondColumnProps = {
          options: teacherOptions,
          onChange: onTeacherChange(index),
          value: teacherWithSharedSolutionToEdit
            ? assignmentsToSelect[0].teachers
            : assignment.teachers || [],
          styles: selectTeacherStyles,
        }
        return (
          <ShareSolutionAssignmentRow
            key={index}
            onDeleteRowClick={onDeleteRowClick(index)}
            firstColumnProps={firstColumnProps}
            secondColumnProps={secondColumnProps}
            isDisabled={!!teacherWithSharedSolutionToEdit}
          />
        )
      })}
      <AddButton
        text={`Add ${
          shareType === GRADES
            ? gettextObj.gettext("Grade")
            : gettextObj.gettext("Class")
        }`}
        onClick={onAddRowClick}
      />
    </>
  )
}
