import { createContext } from "react"
import Gettext from "node-gettext"
import { classTranslations } from "translations/en-AU/class"
import { activityGroupTranslations } from "translations/en-AU/activityGroup"
import { campTranslations } from "translations/en-AU/camp"

export const AccountTypeContext = createContext()

export const AccountTypeProvider = ({ children, school }) => {
  const gt = new Gettext()
  gt.addTranslations("en-AU", "CLASS", classTranslations)
  gt.addTranslations("en-AU", "CAMP", campTranslations)
  gt.addTranslations("en-AU", "ACTIVITY_GROUP", activityGroupTranslations)

  if (school) {
    gt.setTextDomain(school.accountType)
  }
  gt.setLocale("en-AU")

  const contextValue = { gettextObj: gt }

  return (
    <AccountTypeContext.Provider value={contextValue}>
      {children}
    </AccountTypeContext.Provider>
  )
}
