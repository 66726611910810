import { gql } from "@apollo/client"

export const requestQuery = gql`
  query ($adminOnly: Boolean, $schoolId: ID!) {
    constraints(adminOnly: $adminOnly, schoolId: $schoolId) {
      constraintsStudent {
        id
        schoolId
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
          currentGrade {
            id
            order
          }
        }
        studentFrom {
          firstName
          lastName
          id
          currentGrade {
            id
            order
          }
          currentClass {
            id
            label
          }
        }
        pair
        description
        inputByName
        insertedAt
        mandatory
        constraintId
        adminOnly
        status {
          reason
          status
        }
      }
      constraintsTeacher {
        id
        pair
        description
        inputByName
        insertedAt
        studentFrom {
          firstName
          lastName
          id
          currentGrade {
            id
            order
          }
          currentClass {
            id
            label
          }
        }
        schoolId
        teacherTo {
          id
          firstName
          lastName
        }
        mandatory
        constraintId
        adminOnly
        status {
          reason
          status
        }
      }
      unmetConstraints {
        __typename
        ... on ConstraintsStudent {
          id
          constraintId
          met
        }
        ... on ConstraintsTeacher {
          id
          constraintId
          met
        }
      }
    }
  }
`

export const studentRequestMutation = gql`
  mutation createOrUpdateConstraintsStudent(
    $studentFromId: ID!
    $studentToId: ID!
    $description: String
    $inputByName: String!
    $mandatory: Boolean!
    $pair: Boolean!
    $schoolId: ID!
    $constraintId: ID
    $adminOnly: Boolean
  ) {
    createOrUpdateConstraintsStudent(
      constraintsStudent: {
        studentFromId: $studentFromId
        studentToId: $studentToId
        description: $description
        inputByName: $inputByName
        mandatory: $mandatory
        pair: $pair
        schoolId: $schoolId
        constraintId: $constraintId
        adminOnly: $adminOnly
      }
    ) {
      id
      studentToId
      studentFromId
    }
  }
`

export const teacherRequestMutation = gql`
  mutation createOrUpdateConstraintsTeacher(
    $studentFromId: ID!
    $teacherToId: ID!
    $description: String
    $inputByName: String!
    $mandatory: Boolean!
    $pair: Boolean!
    $schoolId: ID!
    $constraintId: ID
    $adminOnly: Boolean
  ) {
    createOrUpdateConstraintsTeacher(
      constraintsTeacher: {
        studentFromId: $studentFromId
        teacherToId: $teacherToId
        description: $description
        inputByName: $inputByName
        mandatory: $mandatory
        pair: $pair
        schoolId: $schoolId
        constraintId: $constraintId
        adminOnly: $adminOnly
      }
    ) {
      id
      teacherToId
      studentFromId
    }
  }
`

export const deleteConstraintsStudent = gql`
  mutation deleteConstraintsStudent($constraintId: ID!) {
    deleteConstraintsStudent(constraintId: $constraintId) {
      id
      constraintId
    }
  }
`

export const deleteConstraintsTeacher = gql`
  mutation deleteConstraintsTeacher($constraintId: ID!) {
    deleteConstraintsTeacher(constraintId: $constraintId) {
      id
      constraintId
    }
  }
`

export const deleteAllRequestConstraints = gql`
  mutation deleteAllRequestConstraints($schoolId: ID!) {
    deleteAllRequestConstraints(schoolId: $schoolId)
  }
`

export const deleteRequestConstraintsByCurrentGrade = gql`
  mutation deleteRequestConstraintsByCurrentGrade(
    $schoolId: ID!
    $currentGradeIds: [ID!]!
    $deletePairRequests: Boolean
    $deleteSeparateRequests: Boolean
  ) {
    deleteRequestConstraintsByCurrentGrade(
      schoolId: $schoolId
      currentGradeIds: $currentGradeIds
      deletePairRequests: $deletePairRequests
      deleteSeparateRequests: $deleteSeparateRequests
    )
  }
`

export const uiRequestsPageQuery = gql`
  {
    requestsPage @client {
      currentGrade
    }
  }
`
