import * as React from "react"
import { Tooltip } from "react-tooltip"
import { Input } from "reactstrap"
import { ColumnSelect } from "components"
import { EMPTY } from "domains/characteristicsImport/constants"

export const MapRow = props => {
  const {
    column,
    columnData,
    options,
    mappedColumns,
    handleColumnMapped,
    handleColumnClear,
    optional,
  } = props

  // Determine the label and tooltip (if any) for this column
  const rowLabel =
    typeof columnData === "object" ? columnData.label : columnData

  const tooltipText = typeof columnData === "object" ? columnData.tooltip : null

  const rowLabelIsSelectable =
    typeof columnData === "object" && columnData.selectableRowItems
  const selectableRowItems = rowLabelIsSelectable
    ? columnData.selectableRowItems
    : null
  const selectedId = rowLabelIsSelectable ? columnData.selectedId : null
  const handleChange = rowLabelIsSelectable ? columnData.handleChange : null
  const placeholderText = rowLabelIsSelectable ? columnData.placeholderText : ""
  const isSelectableRowItemsLoading = rowLabelIsSelectable
    ? columnData.isSelectableRowItemsLoading
    : null

  const optionalString = optional ? " (optional)" : ""

  return (
    <div className="row py-2" key={column}>
      <div className="col-lg">
        {selectableRowItems ? (
          <Input type="select" value={selectedId} onChange={handleChange}>
            {selectableRowItems.map(item => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              )
            })}
            <option key={EMPTY} value={EMPTY} hidden>
              {placeholderText}
            </option>
          </Input>
        ) : (
          <div>
            <span>{`${rowLabel}${optionalString}`}</span>
            {!optional && (
              <span className="pl-1 font-weight-bold text-danger">*</span>
            )}
            {tooltipText && (
              <>
                <i
                  style={{ color: "#00000080" }}
                  className="fa fa-info-circle pl-2"
                  data-tooltip-id={column}
                />
                <Tooltip
                  id={column}
                  opacity={1}
                  variant="light"
                  // border colour is equivalent to $color-grey-class-header
                  border="1px solid #dce1e8">
                  {tooltipText}
                </Tooltip>
              </>
            )}
          </div>
        )}
      </div>

      <div className="col-sm">
        <ColumnSelect
          mapTo={column}
          mappedColumns={mappedColumns}
          options={options}
          onChange={handleColumnMapped}
          onClear={handleColumnClear}
          optional={optional}
          isDisabled={isSelectableRowItemsLoading}
        />
      </div>
    </div>
  )
}
