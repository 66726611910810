import React from "react"
import {
  getOpacity,
  hexToRgba,
  rgbaToRgb,
  contrastedTextColor,
} from "util/colours"

const MAXIMUM_BADGES = 5

export const CharacteristicResponseCounts = ({
  characteristicResponseCounts,
}) => {
  // We don't want to show any responses which don't have students
  const responsesWithStudents = characteristicResponseCounts.filter(
    ({ studentCount }) => studentCount > 0
  )

  const numCounts = responsesWithStudents.length

  return (
    <div className="d-flex" style={{ gap: "0.25rem" }}>
      {numCounts === 0 && (
        <div>
          <div className={`c-badge__response-circle`}>&nbsp;</div>
        </div>
      )}
      {responsesWithStudents
        .slice(0, MAXIMUM_BADGES)
        .map(({ characteristicResponse, studentCount }, index) => {
          // Use white if the colour is completely transparent
          const color =
            getOpacity(characteristicResponse.colour) > 0
              ? characteristicResponse.colour
              : "#ffffffff"

          return (
            <CharacteristicResponseBadge key={index} color={color}>
              {studentCount}
            </CharacteristicResponseBadge>
          )
        })}
      {numCounts > MAXIMUM_BADGES && (
        <div>
          <div className="c-badge__response-circle">...</div>
        </div>
      )}
    </div>
  )
}

const CharacteristicResponseBadge = ({ children, color }) => {
  // Use white if there is no colour
  const colorRgb = rgbaToRgb(hexToRgba(color))
  const textColor = contrastedTextColor(colorRgb)
  const style = { backgroundColor: color }

  return (
    <div>
      <div className={`c-badge__response-circle`} style={style}>
        <div className={`${textColor}`}>{children}</div>
      </div>
    </div>
  )
}
