import React from "react"

import BoostIcon from "images/boost-icon.svg"
import BoostIconDisabled from "images/boost-icon-disabled.svg"
import { isDefinedNotNull } from "util/objUtil"
import { Tooltip } from "react-tooltip"

const differentTeacherToChevron = differentTeacher => {
  switch (differentTeacher) {
    case -1:
      return <i className="fa fa-chevron-down" />
    case 0:
      return "-"
    case 1:
      return <i className="fa fa-chevron-up" />
    default:
      break
  }
}

const tooltip = content => {
  let tipColor
  if (content) {
    if (content.id === "friendship-setting") {
      tipColor = "color-blue-mid"
    } else if (content.id === "unassignedteacher-setting") {
      tipColor = "color-red"
    }
  }

  return (
    content && (
      <>
        <i
          className={`fa fa-exclamation-circle ${tipColor} px-2 cursor-pointer`}
          data-tooltip-id={content.id}
        />
        <Tooltip
          className="c-suggestion-tooltip"
          id={content.id}
          positionStrategy="fixed"
          clickable
          variant="light"
          place="right"
          delayShow={200}
          opacity={1}>
          {content.tooltip}
        </Tooltip>
      </>
    )
  )
}

export const AttributeLabel = ({
  label,
  className = "",
  textClassName = "",
  icon,
  count,
  total,
  percentage,
  difference,
  iconStyle,
  onClick,
  differentTeacher,
  disabled,
  showMetric = true,
  suggestionContent,
  warningContent,
  onBadgeClick,
}) => {
  let labelText = ""
  if (showMetric) {
    if (isDefinedNotNull(total)) {
      labelText = percentage
        ? `${Math.floor((count / total) * 100)}%`
        : `${count}/${total}`
    } else {
      labelText = count === true ? "YES" : "NO"
    }
  }

  return (
    <div
      className={`c-attribute-label w-100 d-flex justify-content-between align-items-center ${className}`}>
      <span className={`c-attribute-label__text ${textClassName}`}>
        {label}
      </span>
      <div>
        {tooltip(suggestionContent)}
        {tooltip(warningContent)}
        <span
          onClick={onBadgeClick}
          className={`c-attribute-label__icon ${icon} ${textClassName}`}
          style={iconStyle}>
          {labelText}
        </span>
        {isDefinedNotNull(difference) && (
          <span className="c-attribute-label__improvement">
            {difference > -1 ? `+${difference}` : difference}
          </span>
        )}
        {isDefinedNotNull(differentTeacher) && (
          <span className="c-attribute-label__improvement">
            {differentTeacherToChevron(differentTeacher)}
          </span>
        )}
        {onClick ? (
          count === true || count === total || disabled ? (
            <img
              src={BoostIconDisabled}
              alt="boost"
              className="c-attribute-label__boost-icon d-print-none"
            />
          ) : (
            <img
              src={BoostIcon}
              alt="boost"
              className="c-attribute-label__boost-icon d-print-none cursor-pointer"
              onClick={onClick}
            />
          )
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
