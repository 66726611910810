import React, { useState } from "react"
import { useQuery } from "@apollo/client"

import { AdminList, SuperAdminModal } from "components"
import { administratorsQuery } from "domains/accountSettings/graphql"
import { SUPPORT, SUPER } from "domains/app/constants/roleTypes"
import { Loader } from "components"

export const SuperAdministrators = ({ isSuper, myprofile }) => {
  const [adminModal, setAdminModal] = useState(false)
  const [addAdmin, setAddAdmin] = useState(false)
  const [adminData, setAdminData] = useState({})

  const { data: supportUsers, loading: loadingSupportUsers } = useQuery(
    administratorsQuery,
    { variables: { filterRole: SUPPORT } }
  )

  const { data: superUsers, loading: loadingSuperUsers } = useQuery(
    administratorsQuery,
    { variables: { filterRole: SUPER } }
  )

  const onAddAdminClick = () => {
    setAdminModal(true)
    setAdminData({
      firstName: "",
      lastName: "",
      positionTitle: "",
      email: "",
      schools: [],
      accounts: [],
      mfa: false,
    })
    setAddAdmin(true)
  }

  const onEditAdminClick = admin => {
    setAdminModal(true)
    setAdminData({ ...admin })
    setAddAdmin(false)
  }

  const refetchQueries = [
    {
      query: administratorsQuery,
      variables: {
        filterRole: SUPPORT,
      },
    },
    {
      query: administratorsQuery,
      variables: {
        filterRole: SUPER,
      },
    },
  ]

  if (loadingSupportUsers || loadingSuperUsers) {
    return (
      <div className="position-relative p-5">
        <Loader />
      </div>
    )
  }

  const admins = supportUsers.users.concat(superUsers.users)

  return (
    <div>
      <h1 className="mb-5 mt-5 u-blue-primary">Support Administrators</h1>
      <div className="u-content-border-bottom pb-4">
        <AdminList
          users={admins}
          onAddAdminClick={onAddAdminClick}
          onEditAdminClick={onEditAdminClick}
          allowAdd={isSuper}
          allowEdit={isSuper}
        />
      </div>
      {adminModal && (
        <SuperAdminModal
          toggle={() => setAdminModal(false)}
          refetchQueries={refetchQueries}
          addAdmin={addAdmin}
          canDelete={adminData.id !== myprofile.id}
          adminData={adminData}
          roleType={SUPPORT}
        />
      )}
    </div>
  )
}
