import React, { useState } from "react"

export const SettingItem = ({
  children,
  buttonText,
  onButtonClick,
  description,
}) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <div className={`d-flex justify-content-between mb-2`}>
      <div>
        {children}

        {description && (
          <>
            <div
              className="cursor-pointer"
              onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? (
                <>
                  <i className="fa fa-minus text-primary mr-2" />
                  <span className="text-primary">Less details</span>
                </>
              ) : (
                <>
                  <i className="fa fa-plus text-primary mr-2" />
                  <span className="text-primary">More details</span>
                </>
              )}
            </div>
            {showDetails && (
              <div className="border px-3 pt-3">{description}</div>
            )}
          </>
        )}
      </div>

      <div className="c-button--secondary" onClick={onButtonClick}>
        {buttonText}
      </div>
    </div>
  )
}
