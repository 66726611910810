import React from "react"
import { Row } from "reactstrap"
import { requestQuery } from "domains/requests/graphql"
import { deleteRequestConstraintsByCurrentGrade } from "domains/requests/graphql"
import { studentQuery } from "domains/students/graphql"
import { dashboardQuery } from "domains/dashboard/graphql"

import {
  Table,
  ActionBar,
  DeleteModal,
  ConstraintsBadge,
  ClassBadge,
  CurrentGradeFilterInput,
} from "components"

import { RequestModal } from "domains/requests/components"
import { SECTIONS, HEADERS } from "domains/requests/table"

import { handleSessionExpired, getSchoolId } from "util/app"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { dateToString } from "util/dateTimeUtil"

import { validateNonEmptyString } from "util/validators"
import { sortRequests } from "util/sortUtil"
import { internalCsvExport } from "util/exportUtil"
import { requestError, requestStatus } from "util/requests"
import {
  ExportByGradesModal,
  RemoveRequestsByCurrentGradesModal,
} from "domains/accountSettings/components"

import { Auth0Context } from "domains/auth/auth0Wrapper"

import { valueHasChanged } from "util/requests"
import { fullName } from "util/nameUtil"

// TODO: Deduplicate the same function in friendships.js
const formatPersonName = person => {
  const fName = fullName(person)

  return person.currentClass ? `${fName} (${person.currentClass.label})` : fName
}

const initialRequestModalState = {
  constraintId: undefined,
  studentFrom: "",
  studentFromName: "",
  personTo: {},
  personToName: "",
  pair: true,
  mandatory: false,
  description: "",
  errors: {},
}

export class Requests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      search: "",
      requestModal: false,
      addSuccessful: false,
      adminOnly: this.props.settings.adminOnlyRequests,
      showDeleteAllRequestConstraints: false,
      showExportStudentRequests: false,
      showExportTeacherRequests: false,
      ...initialRequestModalState,
    }
  }

  static contextType = Auth0Context

  UNSAFE_componentWillReceiveProps = nextProps => {
    handleSessionExpired(nextProps.error)
  }

  closeRequestModal = () => {
    this.setState({
      requestModal: !this.state.requestModal,
      ...initialRequestModalState,
    })
  }

  toggleRequestModal = () => {
    this.setState({
      requestModal: !this.state.requestModal,
      addSuccessful: false,
      errors: {},
    })
  }

  toggleDeleteModal = () => {
    this.setState({ deleteModal: !this.state.deleteModal })
  }

  addClick = () => {
    this.setState({
      constraintId: undefined,
      studentFromName: "",
      personToName: "",
    })
    this.toggleRequestModal()
  }

  searchChange = e => {
    if (e === undefined) {
      this.setState({
        search: "",
      })
    } else {
      this.setState({
        search: e.target.value,
      })
    }
  }

  inputGeneric = e => {
    let returnedValue = e.target.value
    let returnedName = e.target.name

    if (
      returnedValue !== "true" &&
      returnedValue !== "false" &&
      returnedValue !== "on" &&
      returnedValue !== "off"
    ) {
      this.setState({
        [returnedName]: returnedValue,
      })
    } else {
      this.setState({
        [returnedName]: !this.state[returnedName],
      })
    }
  }

  inputChangeStudentFrom = value => {
    if (value) {
      this.setState({
        studentFrom: value.id,
        studentFromName: formatPersonName(value),
      })
    } else {
      this.setState({
        studentFrom: "",
        studentFromName: "",
      })
    }
  }

  inputChangePersonTo = value => {
    if (value) {
      this.setState({
        personToName: formatPersonName(value),
        personTo: {
          isTeacher: value.__typename === "Teacher",
          id: value.id,
        },
      })
    } else {
      this.setState({
        personToName: "",
        personTo: {
          isTeacher: false,
          id: "",
        },
      })
    }
  }

  rowClick = rowData => {
    if (isDefinedNotNull(rowData.constraintId)) {
      this.setState({
        mandatory: rowData.studentPair.props.mandatory,
        description: rowData.studentDescription,
        studentFrom: rowData.studentFromId,
        studentFromName: rowData.studentFromName,
        pair: rowData.studentPair.props.pair,
        personTo: rowData.personTo,
        personToName: rowData.personToName,
        adminOnly: rowData.adminOnly,
        constraintId: rowData.constraintId,
      })
    }
    this.toggleRequestModal()
  }

  validateRequest(data) {
    const { studentFrom, personTo } = data
    const errors = {}

    if (!validateNonEmptyString(studentFrom)) {
      errors.studentFrom = "Must select a student"
    }
    if (!validateNonEmptyString(getPropertyIfDefined(personTo, "id"))) {
      errors.personTo = "Must select a student or teacher"
    }

    return errors
  }

  onSuccessfulRequest = addAgain => {
    const { settings } = this.props
    this.setState({
      requestModal: addAgain,
      addSuccessful: addAgain,
      loading: false,
      studentFrom: "",
      studentFromName: "",
      personTo: "",
      personToName: "",
      pair: true,
      mandatory: false,
      adminOnly: settings.adminOnlyRequests,
      description: "",
      constraintId: undefined,
    })
  }

  onErrorRequest = error => {
    handleSessionExpired(error)
    this.setState({
      loading: false,
      errors: {
        mutation: requestError(error.message),
      },
    })
  }

  saveRequest = addAgain => {
    const {
      myprofile,
      settings,
      teacherRequestMutation,
      studentRequestMutation,
      requestsData,
    } = this.props
    const { studentFrom } = this.state
    const schoolId = getSchoolId()

    // queries to be refetched after mutation
    const refetchQueries = [
      {
        query: studentQuery,
        variables: { id: studentFrom, adminOnly: settings.adminOnlyRequests },
      },
      {
        query: requestQuery,
        variables: { adminOnly: settings.adminOnlyRequests, schoolId },
      },
      {
        query: dashboardQuery,
        variables: { adminOnly: settings.adminOnlyRequests, schoolId },
      },
    ]

    // Check that all fields are valid
    const errors = this.validateRequest(this.state)
    this.setState({ errors })

    // Do Mutation If fields are valid
    if (Object.keys(errors).length === 0) {
      const {
        studentFrom,
        personTo,
        description,
        mandatory,
        pair,
        constraintId,
        adminOnly,
      } = this.state

      this.setState({ loading: true })

      // build variables for mutation
      let variables = {
        schoolId,
        studentFromId: studentFrom,
        mandatory,
        pair,
        constraintId,
        adminOnly,
        inputByName: fullName(myprofile),
      }

      // Do not update some fields which haven't changed, this makes sure we don't overwrite any
      // truncated fields
      const requests = personTo.isTeacher
        ? requestsData.constraintsTeacher
        : requestsData.constraintsStudent

      const originalRequest = requests.find(
        request => request.id === constraintId
      )

      if (
        !originalRequest ||
        valueHasChanged(originalRequest.description, description)
      ) {
        variables = {
          ...variables,
          description,
        }
      }

      if (personTo.isTeacher) {
        variables = {
          ...variables,
          teacherToId: personTo.id,
        }

        teacherRequestMutation({
          variables,
          refetchQueries,
        })
          .then(() => {
            this.onSuccessfulRequest(addAgain)
          })
          .catch(error => {
            this.onErrorRequest(error)
          })
      } else {
        variables = {
          ...variables,
          studentToId: personTo.id,
        }

        refetchQueries.push({
          query: studentQuery,
          variables: { id: personTo.id, adminOnly: settings.adminOnlyRequests },
        })

        studentRequestMutation({
          variables,
          refetchQueries,
        })
          .then(() => {
            this.onSuccessfulRequest(addAgain)
          })
          .catch(error => {
            this.onErrorRequest(error)
          })
      }
    }
  }

  onFilterChange = e => {
    this.props.setCurrentGrade(e.target.value)
  }

  onSuccessfulDelete = () => {
    this.setState({
      loading: false,
      deleteModal: false,
      requestModal: false,
      studentFrom: "",
      studentFromName: "",
      personTo: "",
      personToName: "",
      pair: true,
      mandatory: false,
      description: "",
      constraintId: undefined,
    })
  }

  onErrorDelete = error => {
    handleSessionExpired(error)
    this.setState({
      loading: false,
      errors: { delete: "Can not delete Request" },
    })
  }

  deleteRequest = () => {
    const { deleteConstraintsStudent, deleteConstraintsTeacher, settings } =
      this.props
    const {
      constraintId,
      studentFrom,
      personTo: { id, isTeacher },
    } = this.state

    const schoolId = getSchoolId()

    this.setState({
      loading: true,
    })

    const refetchQueries = [
      {
        query: studentQuery,
        variables: { id: studentFrom, adminOnly: settings.adminOnlyRequests },
      },
      {
        query: requestQuery,
        variables: { adminOnly: settings.adminOnlyRequests, schoolId },
      },
    ]

    const variables = {
      constraintId,
    }

    if (isTeacher) {
      deleteConstraintsTeacher({
        variables,
        refetchQueries,
        awaitRefetchQueries: true,
      })
        .then(() => {
          this.onSuccessfulDelete()
        })
        .catch(error => {
          this.onErrorDelete(error)
        })
    } else {
      refetchQueries.push({
        query: studentQuery,
        variables: { id, adminOnly: settings.adminOnlyRequests },
      })
      deleteConstraintsStudent({
        variables,
        refetchQueries,
        awaitRefetchQueries: true,
      })
        .then(() => {
          this.onSuccessfulDelete()
        })
        .catch(error => {
          this.onErrorDelete(error)
        })
    }
  }

  render() {
    const {
      requestsData,
      notMetRequests,
      currentSchoolGrades,
      students,
      teachers,
      settings,
      currentGrade,
    } = this.props
    const {
      loading,
      requestModal,
      addSuccessful,
      search,
      deleteModal,
      errors,
      constraintId,
      studentFrom,
      studentFromName,
      personTo,
      personToName,
      description,
      mandatory,
      pair,
      adminOnly,
      showDeleteAllRequestConstraints,
      showExportStudentRequests,
      showExportTeacherRequests,
    } = this.state

    const adminMode = settings.adminOnlyRequests

    const studentsToExclude = personTo.isTeacher ? [] : [personTo.id]

    let constraintsStudent = requestsData.constraintsStudent
    let constraintsTeacher = requestsData.constraintsTeacher

    let totalCount = constraintsStudent.length + constraintsTeacher.length

    // refined requests to render
    let requestsRefined = []

    constraintsStudent.forEach(student => {
      requestsRefined.push({
        studentName: `${student.studentFrom.firstName} ${student.studentFrom.lastName}`,
        studentFromName: formatPersonName(student.studentFrom),
        studentFromId: student.studentFrom.id,

        studentClass: (
          <ClassBadge
            label={getPropertyIfDefined(
              student,
              "studentFrom.currentClass.label"
            )}
          />
        ),
        studentPair: (
          <ConstraintsBadge mandatory={student.mandatory} pair={student.pair} />
        ),
        studentTo: `${student.studentTo.firstName} ${student.studentTo.lastName}`,
        personToName: formatPersonName(student.studentTo),
        personTo: {
          id: student.studentTo.id,
          isTeacher: false,
        },

        studentToClass: (
          <ClassBadge
            label={getPropertyIfDefined(
              student,
              "studentTo.currentClass.label"
            )}
          />
        ),
        studentRequestImportance: student.mandatory ? "Mandatory" : "Important",
        studentDescription: student.description,
        studentRequestInputBy: student.inputByName || "",
        studentRequestInputOn: dateToString(student.insertedAt) || "",
        studentRequestStatus:
          student.status && student.status.status === "invalid" ? (
            requestStatus(student.status)
          ) : notMetRequests.includes(student.constraintId) ? (
            <span className="color-requests-mandatory u-font-weight-medium">
              Request Not Met
            </span>
          ) : (
            "-"
          ),
        constraintId: student.constraintId,
        rowClassName: student.adminOnly ? "bg-admin" : "",
        // not rendered used for sorting
        studentFromLastName: student.studentFrom.lastName,
        personToLastName: student.studentTo.lastName,
        // Not rendered, used for filtering
        studentToGrade: getPropertyIfDefined(
          student,
          "studentTo.currentGrade.id"
        ),
        studentFromGrade: getPropertyIfDefined(
          student,
          "studentFrom.currentGrade.id"
        ),
        adminOnly: student.adminOnly,
      })
      return null
    })
    constraintsTeacher.forEach(teacher => {
      requestsRefined.push({
        studentName: `${teacher.studentFrom.firstName} ${teacher.studentFrom.lastName}`,
        studentFromName: formatPersonName(teacher.studentFrom),
        studentFromId: teacher.studentFrom.id,

        studentClass: (
          <ClassBadge
            label={getPropertyIfDefined(
              teacher,
              "studentFrom.currentClass.label"
            )}
          />
        ),
        studentPair: (
          <ConstraintsBadge mandatory={teacher.mandatory} pair={teacher.pair} />
        ),
        studentTo: `${teacher.teacherTo.firstName} ${teacher.teacherTo.lastName}`,
        personToName: formatPersonName(teacher.teacherTo),
        personTo: {
          id: teacher.teacherTo.id,
          isTeacher: true,
        },
        studentToClass: <span>Teacher</span>,
        studentRequestImportance:
          teacher.mandatory === true ? "Mandatory" : "Important",
        studentDescription: teacher.description,
        studentRequestInputBy: teacher.inputByName || "",
        studentRequestInputOn: dateToString(teacher.insertedAt) || "",
        studentRequestStatus:
          teacher.status && teacher.status.status === "invalid" ? (
            requestStatus(teacher.status)
          ) : notMetRequests.includes(teacher.constraintId) ? (
            <span className="color-requests-mandatory u-font-weight-medium">
              Request Not Met
            </span>
          ) : (
            "-"
          ),
        constraintId: teacher.constraintId,
        rowClassName: teacher.adminOnly ? "bg-admin" : "",
        // Not rendered, used for sorting
        studentFromLastName: teacher.studentFrom.lastName,
        personToLastName: teacher.teacherTo.lastName,
        // Not rendered, used for filtering
        studentFromGrade: getPropertyIfDefined(
          teacher,
          "studentFrom.currentGrade.id"
        ),
        adminOnly: teacher.adminOnly,
      })
      return null
    })

    // build filter component
    const filterComponent = (
      <CurrentGradeFilterInput
        onChange={this.onFilterChange}
        value={currentGrade}
        currentSchoolGrades={currentSchoolGrades}
      />
    )

    // replaces the react element for studentClass with a string for the class label
    const dataWithClassLabel = item => {
      if (item) {
        if (item.props) {
          return item.props.label
        }
      }
      return item
    }

    // code for the filter in the search bar
    requestsRefined = requestsRefined.filter(tData => {
      if (currentGrade === "all-grades") {
        // If not filtering grades
        return (
          Object.values(tData)
            .map(item => dataWithClassLabel(item))
            .join()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1
        )
      }
      return (
        Object.values(tData)
          .map(item => dataWithClassLabel(item))
          .join()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1 &&
        (tData.studentFromGrade === currentGrade ||
          tData.studentToGrade === currentGrade)
      )
    })
    // Sort by grade then studentFrom lastName then personTo lastName
    sortRequests(requestsRefined)

    const filteredNumber = requestsRefined.length
    // reason for below code is sticky table cannot handle data being null so we pass in empty strings
    // for all the feilds to keep it happy
    if (!requestsRefined.length) {
      requestsRefined.push({
        studentName: "",
        studentClass: "",
        studentPair: "",
        studentTo: "",
        studentToClass: "",
        studentRequestImportance: "",
        studentDescription: "",
        studentRequestInputBy: "",
      })
    }

    const schoolId = getSchoolId()
    const context = this.context

    const csvExport = type => {
      let onSuccess, onError

      if (type === "student_requests") {
        onSuccess = () => this.setState({ showExportStudentRequests: false })
        onError = () =>
          this.setState({
            errors: {
              exportStudentRequests: "Error exporting student requests",
            },
          })
      } else if (type === "teacher_requests") {
        onSuccess = () => this.setState({ showExportTeacherRequests: false })
        onError = () =>
          this.setState({
            errors: {
              exportTeacherRequests: "Error exporting teacher requests",
            },
          })
      }

      return async gradeIds => {
        const [response, _errorMessage] = await internalCsvExport(
          type,
          schoolId,
          context,
          !this.props.settings.adminOnlyRequests,
          gradeIds
        )

        if (response.ok) {
          onSuccess()
        } else {
          onError()
        }
      }
    }

    const additionalActions = [
      {
        onClick: () => this.setState({ showExportStudentRequests: true }),
        text: "Export Student Requests",
        icon: "fa-download",
      },
      {
        onClick: () => this.setState({ showExportTeacherRequests: true }),
        text: "Export Teacher Requests",
        icon: "fa-download",
      },
      {
        onClick: () => this.setState({ showDeleteAllRequestConstraints: true }),
        text: "Remove Requests",
        icon: "fa-trash",
      },
    ]

    return (
      <div className="c-requests">
        <div>
          <Row>
            <ActionBar
              searchPlaceholder="Search Requests..."
              onAddClick={() => this.addClick()}
              onSearchChange={this.searchChange}
              searchValue={search}
              addText="Add Request"
              adminMode={adminMode}
              filterComponent={filterComponent}
              additionalActions={additionalActions}
            />
          </Row>
          <Row>
            <div className="requests-table">
              <Table
                tableName="requests"
                sections={SECTIONS}
                headers={HEADERS}
                data={requestsRefined}
                rowCount
                stickyHeaderCount={1}
                leftStickyColumnCount={0}
                // Shitty workaround to built-in styles in react-sticky-table
                borderWidth="inherit"
                rowClick={this.rowClick}
              />
            </div>
          </Row>
          {currentGrade !== "all-grades" || search !== "" ? (
            <div className="u-total-text my-2 ml-2">{`Total - ${filteredNumber}/${totalCount} Requests`}</div>
          ) : (
            <div className="u-total-text my-2 ml-2">{`Total - ${totalCount} Requests`}</div>
          )}
        </div>

        <RequestModal
          edit={isDefinedNotNull(constraintId)}
          addSuccessful={addSuccessful}
          loading={loading}
          errors={errors}
          modal={requestModal}
          closeRequestModal={this.closeRequestModal}
          toggleDeleteModal={this.toggleDeleteModal}
          adminMode={adminMode}
          saveRequest={this.saveRequest}
          students={students}
          studentsToExclude={studentsToExclude}
          teachers={teachers}
          studentFrom={studentFrom}
          studentFromName={studentFromName}
          personTo={personTo}
          personToName={personToName}
          inputGeneric={this.inputGeneric}
          inputChangeStudentFrom={this.inputChangeStudentFrom}
          inputChangePersonTo={this.inputChangePersonTo}
          mandatory={mandatory}
          pair={pair}
          description={description}
          adminOnly={adminOnly}
        />
        <DeleteModal
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          loading={loading}
          heading="Delete Request?"
          text="Are you sure you want to delete this request?"
          buttonText="Yes, delete"
          onButtonClick={this.deleteRequest}
          error={errors.delete}
        />

        {showDeleteAllRequestConstraints && (
          <RemoveRequestsByCurrentGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({
                showDeleteAllRequestConstraints: false,
              })
            }
            title="Remove Requests"
            description="This function removes the Requests for students in the Current Grade/s you have selected.  Students in Current Grade/s not selected will not be affected."
            cautionHeading="Remove Requests"
            cautionText="You can not 'undo' this operation. Are you sure you would like to permanently remove all Requests by grade?"
            removeButtonText="Remove Requests"
            cautionButtonText="Yes, Proceed"
            successMessage="Requests by grade have been removed."
            errorMessage="Could not remove data"
            refetchQueries={[
              {
                query: requestQuery,
                variables: {
                  adminOnly: settings.adminOnlyRequests,
                  schoolId: getSchoolId(),
                },
              },
            ]}
            removeByGradesMutation={deleteRequestConstraintsByCurrentGrade}
          />
        )}
        {showExportStudentRequests && (
          <ExportByGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({ showExportStudentRequests: false, errors: {} })
            }
            title="Export Student Requests by Current Grade"
            description="This function exports student requests based on the Current Grade/s that you select below."
            primaryButtonText="Export Student Requests"
            exportFunction={csvExport("student_requests")}
            errorMessage={this.state.errors.exportStudentRequests}
          />
        )}
        {showExportTeacherRequests && (
          <ExportByGradesModal
            schoolId={getSchoolId()}
            toggle={() =>
              this.setState({ showExportTeacherRequests: false, errors: {} })
            }
            title="Export Teacher Requests by Current Grade"
            description="This function exports teacher requests based on the Current Grade/s that you select below."
            primaryButtonText="Export Teacher Requests"
            exportFunction={csvExport("teacher_requests")}
            errorMessage={this.state.errors.exportTeacherRequests}
          />
        )}
      </div>
    )
  }
}
