import React from "react"
import { useTable, useSortBy } from "react-table"
import { CharacteristicTooltip, ClassBadge } from "components"
import { compareClasses } from "util/sortUtil"
import { isSystemGenerated } from "util/studentUtil"

import { AccountTypeContext } from "config/accountTypeContext"

export const SortableStudentsTable = props => {
  // Destructure props
  const {
    students,
    onRowClick,
    maxFriends,
    schoolCharacteristics,
    navToCharacteristics,
  } = props

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  // Define a sort function for the Friendship columns
  const sortFriendship = React.useCallback((rowA, rowB, columnId) => {
    const friend1 = rowA.values[columnId]
    const friend2 = rowB.values[columnId]

    // Sort by friend lastName's if they both exist
    if (friend1 && friend2) {
      if (friend1.studentTo && friend2.studentTo) {
        return friend1.studentTo.lastName.localeCompare(
          friend2.studentTo.lastName
        )
      }
    }

    return friend1 ? -1 : 1
  }, [])

  // Define a sort function for the Class columns
  const sortClass = React.useCallback((rowA, rowB, columnId) => {
    const classA = rowA.values[columnId]
    const classB = rowB.values[columnId]

    // Delegate the result to `compareClasses`
    if (classA && classB) {
      // Put the current grades on to the class objects so the `compareClasses`
      // util can do its job

      const classAClone = {
        grades: [rowA.values.currentGrade],
        ...classA,
      }

      const classBClone = {
        grades: [rowB.values.currentGrade],
        ...classB,
      }

      return compareClasses(rowA.values, classAClone, rowB.values, classBClone)
    } else {
      return 1
    }
  }, [])

  // Sort by the characteristic response label
  const sortCharacteristicResponses = React.useCallback(
    (rowA, rowB, columnId) => {
      const studentResponseA = rowA.values[columnId]
      const studentResponseB = rowB.values[columnId]

      const responseLabelA = studentResponseA ? studentResponseA.label : ""
      const responseLabelB = studentResponseB ? studentResponseB.label : ""

      return responseLabelA.localeCompare(responseLabelB)
    },
    []
  )

  // Helper function to define an scId in the data
  const scId = characteristic => `sc-${characteristic.id}`

  // Define and memoize the table's columns
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Student Details",
        headerClassName: "sticky-column-1",
        columns: [
          {
            Header: "First Name",
            accessor: "firstName",
            className: "first-name-cell sticky-column-1",
            headerClassName: "sticky-column-1",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            className: "last-name-cell sticky-column-2",
            headerClassName: "sticky-column-2",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: "ID",
            accessor: "studentCode",
            Cell: StudentCodeCell,
          },
          {
            Header: "Gender",
            accessor: "gender",
            className: "gender-cell",
            headerClassName: "text-center",
          },
          {
            Header: "Current Grade",
            Cell: row => row.value.label,
            accessor: "currentGrade",
            className: "current-grade-cell text-center",
            headerClassName: "text-center",
            disableSortBy: true,
          },
          {
            Header: "Current Class",
            Cell: ClassCell,
            accessor: "currentClass",
            className: "current-class-cell",
            headerClassName: "text-center",
            sortType: sortClass,
          },
          {
            accessor: "newGrade",
            Header: `${gettextObj.gettext("New Grade")}`,
            Cell: row => row.value.label,
            className: "new-grade-cell text-center",
            headerClassName: "text-center",
            sortType: sortClass,
          },
          {
            Header: `New ${gettextObj.gettext("Class")}`,
            Cell: ClassCell,
            accessor: "newClass",
            className: "new-class-cell",
            headerClassName: "text-center",
            sortType: sortClass,
          },
          {
            Header: "Notes",
            accessor: "comments",
            className: "notes-cell",
          },
        ],
      },
      {
        Header: "Requests",
        headerClassName: "total-column",
        columns: [
          {
            Header: "Total",
            Cell: ConstraintRequestsCell,
            accessor: "constraintsCount",
            headerClassName: "total-column",
            className: "text-center",
          },
        ],
      },
      {
        Header: () => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <span>Characteristics</span>
              <span>
                <i className="fa fa-cog" onClick={navToCharacteristics} />
              </span>
            </div>
          )
        },
        id: "characteristics-header",
        headerClassName: "characteristic-column",
        columns: schoolCharacteristics.map(c => ({
          Header: CharacteristicHeader,
          Cell: CharacteristicCell,
          id: scId(c),
          accessor: scId(c),
          className: "text-center",
          headerClassName: "characteristic-column",
          characteristic: c,
          sortType: sortCharacteristicResponses,
        })),
      },
      {
        Header: "Friendships",
        headerClassName: "friendship-column",
        columns: Array(maxFriends)
          .fill()
          .map((_value, index) => {
            return {
              Header: `Friend ${index + 1}`,
              Cell: FriendCell,
              accessor: `friends[${index}]`,
              headerClassName: "friendship-column",
              friendIndex: index,
              sortType: sortFriendship,
            }
          }),
      },
    ]
  }, [
    navToCharacteristics,
    maxFriends,
    sortClass,
    sortFriendship,
    schoolCharacteristics,
    sortCharacteristicResponses,
    gettextObj,
  ])

  // Memoize the student data passed in from props
  const studentData = React.useMemo(() => {
    // Add the characteristic responses per characteristic
    // This will make the table more efficient to deal with for sorting
    // later
    return students.map(student => {
      return schoolCharacteristics.reduce((acc, characteristic) => {
        const studentResponse = student.characteristicResponses.find(
          resp => resp.characteristicId === characteristic.id
        )
        return { ...acc, [scId(characteristic)]: studentResponse }
      }, student)
    })
  }, [students, schoolCharacteristics])

  // Set up the table stuff
  const tableProps = {
    ...props,
    data: studentData,
    columns: columns,
    autoResetSortBy: false,
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(tableProps, useSortBy)

  return (
    <div className="students-sortable-table">
      <table {...getTableProps()} cellSpacing="0">
        <thead className={students.length === 0 ? "no-data" : ""}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                // Apply sortable props and header class name if it exists
                const props = {
                  ...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    className: column.headerClassName,
                  }),
                }

                // Render the 'th' cell as a sortable column
                return (
                  <th {...props} title="">
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ↓"
                          : " ↑"
                        : ""}
                    </span>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        {students.length === 0 && (
          <tbody>
            <tr className="empty-row">
              <td className="empty-data-cell" colSpan="100">
                There are no students matching this filter. Try selecting a
                different filter from the ‘Filter’ dropdown above.
              </td>
            </tr>
          </tbody>
        )}

        {students.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)

              // Handle if this particular row is clicked
              // We can't use `row.values` because the student ID is not shown on the
              // table (hence not resolved)
              const handleClick = () => onRowClick(row.original.id)

              return (
                <tr onClick={handleClick} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    // Inject the cell's className in to its other props if
                    // it is set, react table doesn't handle this itself.
                    const cellProps = cell.getCellProps({
                      className: cell.column.className,
                    })

                    // Render the cell
                    return <td {...cellProps}>{cell.render("Cell")}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
    </div>
  )
}

const ClassCell = row => {
  return row.value ? (
    <ClassBadge className="mx-auto" label={row.value.label} />
  ) : null
}

const CharacteristicHeader = table => {
  const {
    column: { characteristic },
  } = table

  return (
    <div className="d-flex justify-content-between align-items-center w-100 pr-1">
      <span className="font-bold">{characteristic.name}</span>
      <i
        className="fa fa-info-circle font-size-14 u-opacity-50"
        data-tooltip-id={`characteristic-${characteristic.id}`}
      />
      <CharacteristicTooltip
        name={characteristic.name}
        id={`characteristic-${characteristic.id}`}
        place="right"
        variant="light"
        characteristicResponses={characteristic.characteristicResponses}
      />
    </div>
  )
}

const ConstraintRequestsCell = table => {
  // Destructure the props we ened
  const {
    value,
    teacherRequestsEditable,
    studentRequestsEditable,
    onAddRequestClick,
    row,
  } = table

  // Render the cell, with an add button if requests are editable
  if (teacherRequestsEditable || studentRequestsEditable) {
    // Handle if the button is clicked
    const handleClick = () => onAddRequestClick(row.values.studentCode)

    return (
      <span className="add-button" onClick={handleClick}>
        {value}
        <i className="ml-2 fa fa-plus" />
      </span>
    )
  } else {
    return value
  }
}

const CharacteristicCell = ({ value: studentResponse }) => {
  return studentResponse ? studentResponse.label : ""
}

const StudentCodeCell = ({ value: studentCode }) => {
  return (
    <div
      className={
        studentCode && isSystemGenerated(studentCode) ? "generated" : ""
      }>
      {studentCode}
    </div>
  )
}

const FriendCell = row => {
  const { value } = row

  // Render the friend if they exist, otherwise render nothing
  if (value) {
    return (
      <div className="friend-cell">
        <div className="d-flex justify-content-between align-items-center">
          <span className="friends-name">
            {value.studentTo.firstName} {value.studentTo.lastName}
          </span>

          {value.studentTo.currentClass && (
            <ClassBadge label={value.studentTo.currentClass.label} />
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}
