import { useRef, useEffect } from "react"
import * as d3 from "d3"

export const useD3 = (renderComponentFn, data) => {
  const ref = useRef()

  useEffect(() => {
    return renderComponentFn(d3.select(ref.current))
  }, [renderComponentFn, data])

  return ref
}
