export const isSystemGenerated = id => id.startsWith("new_")

export const genderColor = gender => {
  const genderCode = gender.toUpperCase()

  if (genderCode === "M") return "color-male"
  if (genderCode === "F") return "color-female"
  // default is gender X
  return "color-non-binary"
}

export const genderName = gender => {
  const genderCode = gender.toUpperCase()

  if (genderCode === "M") return "Male"
  if (genderCode === "F") return "Female"
  // default is gender X
  return "Non-Binary"
}
