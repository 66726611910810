import React from "react"

export const ConstraintsBadge = ({ mandatory, pair, isMet }) => {
  const label = pair ? "P" : "S"
  let className = "c-badge__circle "

  if (isMet) {
    className += mandatory
      ? "c-badge__is-met-mandatory"
      : "c-badge__is-met-important"
    return <div className={className}>{label}</div>
  }
  // If Not Met
  className += mandatory ? "c-badge__mandatory" : "c-badge__important"
  return <div className={className}>{label}</div>
}
