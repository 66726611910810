import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { Button, Modal, ModalBody } from "reactstrap"
import { ModalHeader, InlineError, CautionModal } from "components"
import Select from "react-select"
import {
  availableSchoolBackupsQuery,
  restoreSchoolBackupMutation,
} from "domains/accountSettings/graphql"
import { getSchoolId } from "util/app"
import { dateTimeToString } from "util/dateTimeUtil"
import { DangerousMutation } from "domains/accountSettings/components"

const transformForSelect = schoolBackups => {
  return schoolBackups.map(({ file }) => {
    const label = dateTimeToString(file.timestamp)
    return {
      label: label,
      value: file.fileName,
    }
  })
}

export const RestoreBackupModal = ({ toggle }) => {
  const schoolId = getSchoolId()
  const { data, loading, error } = useQuery(availableSchoolBackupsQuery, {
    variables: { schoolId },
    fetchPolicy: "network-only",
  })

  const [selectedOption, setSelectedOption] = useState()

  const [restoreSchoolBackup, { loading: restoringSchoolBackup }] = useMutation(
    restoreSchoolBackupMutation,
    {
      variables: { schoolId: getSchoolId(), fileName: selectedOption?.value },
    }
  )

  const [showRestoreWarning, setShowRestoreWarning] = useState(false)

  if (loading) {
    return null
  }

  if (error) {
    return (
      <CautionModal
        isOpen
        onButtonClick={toggle}
        toggle={toggle}
        buttonText="Dismiss"
        showCancel={false}
        heading="Restore Backup"
        text="There was an error fetching the available backups. Please try again later."
      />
    )
  }

  const options = transformForSelect(data.availableSchoolBackups)
  const noOptions = options.length === 0

  const restore = () => {
    setShowRestoreWarning(true)
  }

  return (
    <>
      <Modal isOpen className="modal-dialog-centered">
        <ModalHeader
          title="Restore backup"
          toggle={toggle}
          className="px-4 py-3"
        />

        <ModalBody className="px-4">
          <p>Please select from the drop down list below and click Restore</p>

          <div className="d-flex mb-3">
            <Select
              className="c-input__multiselect"
              placeholder="Select Backup"
              options={options}
              value={selectedOption}
              onChange={option => {
                setSelectedOption(option)
              }}
              closeMenuOnSelect={true}
              isDisabled={noOptions}
            />
            <Button
              className="ml-3"
              color="primary"
              onClick={restore}
              disabled={!selectedOption}
              type="button">
              Restore
            </Button>
          </div>
          {noOptions && (
            <InlineError text="There are no backup files available" />
          )}
        </ModalBody>
      </Modal>
      {showRestoreWarning && (
        <DangerousMutation
          dangerousMutation={restoreSchoolBackup}
          mutationLoading={restoringSchoolBackup}
          cautionHeading="Restore backup for this school"
          cautionText={`Are you sure you want to restore backup from ${selectedOption.label}? This will replace all current data with the backup for this school.`}
          cautionButtonText="Yes, Proceed"
          successMessage="Backup has been restored"
          errorMessage="Could not restore backup"
          toggleComponent={() => {
            setShowRestoreWarning(false)
            toggle()
          }}
        />
      )}
    </>
  )
}
