import React from "react"

export const AddButton = ({
  onClick,
  text,
  active = true,
  link,
  className = "",
}) => (
  <a
    className={`${active ? "c-add-button" : "c-add-button__inactive"} d-block ${className}`}
    href={link}
    onClick={active ? onClick : null}>
    <i className={`fa fa-plus ${active ? "u-blue-mid" : "u-grey-mid"} mr-3`} />
    <span
      className={`${active ? "u-blue-mid" : "u-grey-mid"} u-font-weight-medium`}>
      {text}
    </span>
  </a>
)
