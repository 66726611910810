import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Button, Input } from "reactstrap"

import Logo from "images/logo.png"
import { InlineError } from "components"

const logoStyles = {
  maxWidth: 150,
}

export const AuthWithOTP = ({
  loginQuery,
  passwordIncorrect,
  setPasswordIncorrect,
  requestOtpSuccessful,
  requestOtp,
  resendTimeout,
}) => {
  const [password, setPassword] = useState("")

  const login = () => {
    // Add the password into the loginQuery
    loginQuery({
      variables: { password },
      onError: () => {
        setPasswordIncorrect(true)
      },
    })
  }

  const justResent = resendTimeout !== 0

  let resendButtonText
  if (justResent) {
    resendButtonText = `Resend in ${resendTimeout} seconds`
  } else if (requestOtpSuccessful) {
    resendButtonText = "Request Password Again"
  } else {
    resendButtonText = "Request Password"
  }

  return (
    <div className="c-survey-login-error">
      <Link to="/">
        <img
          src={Logo}
          alt="Class Solver Logo"
          style={logoStyles}
          className="ml-5 mt-5"
        />
      </Link>
      <div className="c-survey-login-error__container">
        <div className="h4 pb-3 w-75 text-center">
          Please click below to request a one-time password (OTP) to access
          Class Solver.
        </div>
        <Button
          disabled={justResent}
          className="u-font-weight-medium"
          color="primary"
          onClick={requestOtp}>
          {resendButtonText}
        </Button>
        {requestOtpSuccessful && (
          <>
            <div className="u-font-weight-medium mt-2">
              Didn't receive an email?
            </div>
            <div className="u-font-weight-medium">
              Check your junk/spam folder.
            </div>
            <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
              <div className="h4">
                Please check your email and enter the password below
              </div>
              <Input
                className="w-auto mt-3 mb-4"
                type="text"
                value={password}
                name="password"
                onChange={e => {
                  setPassword(e.target.value)
                }}
                maxLength={6}
                size="6"
              />
            </div>
            <Button
              className="u-font-weight-medium"
              color="primary"
              onClick={login}>
              Submit
            </Button>
            {passwordIncorrect && (
              <InlineError text="Invalid password. Please try again." />
            )}
          </>
        )}
        {requestOtpSuccessful === false && (
          <InlineError
            text={
              <div className="mt-2 text-center">
                <div>Oops, something went wrong. Please try it again.</div>
                <div>
                  If the issue persists, please contact your school
                  Administrator
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  )
}
