import React from "react"
import compose from "lodash.flowright"

import { graphql } from "@apollo/client/react/hoc"
import { ColumnMapping } from "domains/students/components/upload/columnMapping"
import { Navigate } from "react-router-dom"
import { pendingImport } from "../pendingImport"

import {
  schoolUploadsQuery,
  updateBulkUploadJob,
  updateStudentImportMatchers,
} from "domains/upload/graphql"

import { getSchoolId } from "util/app"
import { Loader } from "components"
import { queriesAreLoading } from "util/app"

const ColumnMappingPageComponent = props => {
  if (queriesAreLoading(props)) return <Loader />

  const { job: currentUploadJob } = pendingImport(
    props.schoolUploads,
    "STUDENT"
  )

  if (!currentUploadJob) {
    return <Navigate to="/Students" replace />
  }

  return <ColumnMapping {...props} currentUploadJob={currentUploadJob} />
}

export const ColumnMappingPage = compose(
  graphql(updateBulkUploadJob, { name: "updateBulkUploadJob" }),
  graphql(schoolUploadsQuery, {
    name: "schoolUploads",
    options: () => {
      return {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        variables: { schoolId: getSchoolId() },
      }
    },
  }),
  graphql(updateStudentImportMatchers, { name: "updateStudentImportMatchers" })
)(ColumnMappingPageComponent)
