import React from "react"
import { FormGroup, Label, Input, Button } from "reactstrap"

// Queries to be refetched after mutation
import { studentQuery } from "domains/students/graphql"

import {
  Loader,
  ConstraintsBadge,
  ClassBadge,
  ButtonBar,
  FilteredInput,
  InlineError,
  DeleteModal,
} from "components"

import * as resolverTypes from "constants/resolverTypes"
import { getPropertyIfDefined } from "util/objUtil"
import { requestError } from "util/requests"
import { handleSessionExpired } from "util/app"
import { SCHOOL_ID } from "constants/storageTokens"
import { valueHasChanged } from "util/requests"
import { fullName } from "util/nameUtil"

const NewRequestButton = ({ onClick }) => (
  <div onClick={onClick} className="cursor-pointer">
    <i className="fa fa-plus mr-2 text-primary font-size-16" />
    <span className="text-primary u-font-weight-medium font-size-16">
      New Request
    </span>
  </div>
)
// TODO: Deduplicate the same function in friendships.js
const formatPersonName = person => {
  const fName = fullName(person)

  return person.currentClass ? `${fName} (${person.currentClass.label})` : fName
}

export class Requests extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      edit: false,
      add: false,
      deleteModal: false,
      pair: true,
      mandatory: false,
      personToName: "",
      personTo: { id: "" },
      description: "",
      constraintId: undefined,
      adminOnly: false,
      errors: {},
      saveDisabled: true,
      constraintToDelete: undefined,
    }
  }

  onChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]:
        e.target.name === "adminOnly" ? !this.state.adminOnly : e.target.value,
    })
  }

  onSelectPersonTo = value => {
    if (value) {
      this.props.setEdit(true)
      this.setState({
        personToName: formatPersonName(value),
        personTo: {
          isTeacher: value.__typename === "Teacher",
          id: value.id,
        },
        saveDisabled: false,
      })
    } else {
      this.props.setEdit(false)
      this.setState({
        personToName: "",
        personTo: {
          isTeacher: false,
          id: "",
        },
        saveDisabled: true,
      })
    }
  }

  onCheckboxChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: !this.state[e.target.name],
    })
  }

  onEditClick = request => {
    const { studentId } = this.props
    const { studentTo, studentFrom, teacherTo } = request

    let personTo = ""
    let personToName = ""
    if (teacherTo) {
      personTo = { id: teacherTo.id, isTeacher: true }
      personToName = formatPersonName(teacherTo)
    } else {
      // whether to show student From or student to
      if (studentTo.id === studentId) {
        personTo = { id: studentFrom.id, isTeacher: false }
        personToName = formatPersonName(studentFrom)
      } else {
        personTo = { id: studentTo.id, isTeacher: false }
        personToName = formatPersonName(studentTo)
      }
    }

    this.toggleEdit()
    this.setState({
      adminOnly: request.adminOnly,
      mandatory: request.mandatory,
      pair: request.pair,
      description: request.description || "",
      personToName,
      personTo,
      constraintId: request.constraintId,
      constraintToDelete: request,
    })
  }

  toggleDeleteModal = constraintToDelete => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      constraintToDelete,
    })
  }

  toggleEdit = () => {
    this.props.toggleEdit()
    this.setState({
      edit: !this.state.edit,
      errors: {},
      saveDisabled: false,
    })
  }

  cancelAdd = () => {
    this.props.toggleEdit()
    this.setState({
      add: false,
      pair: true,
      mandatory: false,
      personToName: "",
      personTo: { id: "" },
      description: "",
      constraintId: undefined,
      adminOnly: false,
      errors: {},
      saveDisabled: true,
    })
  }

  toggleAdd = () => {
    // toggle add and reset state
    this.setState({
      add: !this.state.add,
      pair: true,
      mandatory: false,
      personToName: "",
      personTo: { id: "" },
      description: "",
      constraintId: undefined,
      adminOnly: false,
      errors: {},
      saveDisabled: true,
    })
  }

  onSuccessfulSave = () => {
    const { updateStudentEditFlag } = this.props
    const { constraintId } = this.state

    constraintId ? this.toggleEdit() : this.cancelAdd()
    // flag that the student has been updated
    updateStudentEditFlag({
      variables: { type: resolverTypes.STUDENT_UPDATED, value: true },
    })
    this.setState({ loading: false })
  }

  onErrorSave = error => {
    handleSessionExpired(error)
    this.setState({
      loading: false,
      errors: { mutation: requestError(error.message) },
    })
  }

  onAddEditRequestClick = () => {
    const {
      studentId,
      inputByName,
      adminOnlySetting,
      refetchQueries,
      teacherRequestMutation,
      studentRequestMutation,
    } = this.props
    const { pair, mandatory, personTo, description, constraintId, adminOnly } =
      this.state
    this.setState({ loading: true })

    // build variables for mutation
    let variables = {
      schoolId: sessionStorage.getItem(SCHOOL_ID),
      studentFromId: studentId,
      inputByName,
      pair,
      mandatory,
      constraintId,
      adminOnly,
    }

    // Do not update some fields which haven't changed, this makes sure we don't overwrite any
    // truncated fields
    const requestType = personTo.isTeacher
      ? "ConstraintsTeacher"
      : "ConstraintsStudent"

    const originalRequest = this.props.requests.find(request => {
      return request.__typename === requestType && request.id === constraintId
    })

    if (
      !originalRequest ||
      valueHasChanged(originalRequest.description, description)
    ) {
      variables = {
        ...variables,
        description,
      }
    }

    refetchQueries.push({
      query: studentQuery,
      variables: {
        id: studentId,
        adminOnly: adminOnlySetting,
      },
    })

    // check whether teacher for appropriate mutation
    if (personTo.isTeacher) {
      variables = {
        ...variables,
        teacherToId: personTo.id,
      }
      teacherRequestMutation({ variables, refetchQueries })
        .then(() => {
          this.onSuccessfulSave()
        })
        .catch(error => {
          this.onErrorSave(error)
        })
    } else {
      variables = {
        ...variables,
        studentToId: personTo.id,
      }
      studentRequestMutation({ variables, refetchQueries })
        .then(() => {
          this.onSuccessfulSave()
        })
        .catch(error => {
          this.onErrorSave(error)
        })
    }
  }

  onSuccessfulDelete = () => {
    // flag that the student has been updated
    this.props.updateStudentEditFlag({
      variables: { type: resolverTypes.STUDENT_UPDATED, value: true },
    })

    if (this.state.edit) this.toggleEdit()

    this.setState({
      loading: false,
      deleteModal: false,
      constraintToDelete: undefined,
    })
  }

  onErrorDelete = error => {
    handleSessionExpired(error)
    this.setState({
      loading: false,
      errors: { mutation: "Could not delete constraint" },
      constraintToDelete: undefined,
    })
  }

  deleteRequest = () => {
    const {
      deleteConstraintsStudent,
      deleteConstraintsTeacher,
      adminOnlySetting,
      refetchQueries,
    } = this.props

    const { constraintToDelete } = this.state

    this.setState({
      loading: true,
    })

    const variables = {
      constraintId: constraintToDelete.id,
    }

    // Update the list of constraints for the student
    refetchQueries.push({
      query: studentQuery,
      variables: {
        id: constraintToDelete.studentFrom.id,
        adminOnly: adminOnlySetting,
      },
    })

    if (constraintToDelete.teacherTo) {
      deleteConstraintsTeacher({
        variables,
        refetchQueries,
        awaitRefetchQueries: true,
      })
        .then(() => {
          this.onSuccessfulDelete()
        })
        .catch(error => {
          this.onErrorDelete(error)
        })
    } else {
      // When deleting a student constraint, also update the list of constraints for the studentTo
      refetchQueries.push({
        query: studentQuery,
        variables: {
          id: constraintToDelete.studentTo.id,
          adminOnly: adminOnlySetting,
        },
      })
      deleteConstraintsStudent({
        variables,
        refetchQueries,
        awaitRefetchQueries: true,
      })
        .then(() => {
          this.onSuccessfulDelete()
        })
        .catch(error => {
          this.onErrorDelete(error)
        })
    }
  }

  renderRequestRow(studentId, request, index) {
    const {
      mandatory,
      pair,
      studentTo,
      studentFrom,
      teacherTo,
      description,
      adminOnly,
    } = request

    let constraintTo
    let constraintToBadge

    if (studentTo === undefined) {
      // This is a teacher constraint
      constraintTo = fullName(teacherTo)
      constraintToBadge = "Teacher"
    } else {
      // Constraints are 2-way so we need to find out whether the
      // other student is in the studentFrom or studentTo field
      const otherStudent = studentTo.id === studentId ? studentFrom : studentTo

      constraintTo = fullName(otherStudent)

      constraintToBadge = (
        <ClassBadge
          label={getPropertyIfDefined(otherStudent, "currentClass.label")}
        />
      )
    }

    const className = adminOnly ? "bg-admin" : ""

    const { teacherRequestsEditable, studentRequestsEditable } = this.props

    return (
      <div
        className={`row c-student-modal-requests__table-row ${className}`}
        key={index}>
        <div className="col-1">
          <ConstraintsBadge mandatory={mandatory} pair={pair} />
        </div>
        <div className="col-3">{constraintTo}</div>
        <div className="col-2 u-bold d-flex justify-content-center">
          {constraintToBadge}
        </div>
        <div className="col-4">{description}</div>
        {(teacherRequestsEditable || studentRequestsEditable) && (
          <>
            <div
              className="col-1 text-primary cursor-pointer font-size-14"
              onClick={() => {
                this.onEditClick(request)
              }}>
              Edit
            </div>
            <div
              className="col-1 text-primary cursor-pointer font-size-14"
              onClick={() => this.toggleDeleteModal(request)}>
              Delete
            </div>
          </>
        )}
      </div>
    )
  }

  renderInputs = () => {
    const {
      students,
      teachers,
      studentId,
      adminOnlySetting,
      teacherRequestsEditable,
      studentRequestsEditable,
    } = this.props
    const { pair, mandatory, personToName, description, adminOnly } = this.state

    const studentsToShow = studentRequestsEditable ? students : []
    const teachersToShow = teacherRequestsEditable ? teachers : []
    let studentTeacherSubHeading
    let studentTeacherPlaceHolder
    if (teacherRequestsEditable && studentRequestsEditable) {
      studentTeacherSubHeading = "Student/Teacher"
      studentTeacherPlaceHolder = "Type to select a student/teacher..."
    } else if (teacherRequestsEditable) {
      studentTeacherSubHeading = "Teacher"
      studentTeacherPlaceHolder = "Type to select a teacher..."
    } else if (studentRequestsEditable) {
      studentTeacherSubHeading = "Student"
      studentTeacherPlaceHolder = "Type to select a student..."
    }

    return (
      <div className="modal-content">
        {adminOnlySetting && (
          <div className="ml-3 mt-4">
            <Input
              type="checkbox"
              className="ml-0"
              name="adminOnly"
              id="adminOnly"
              checked={adminOnly}
              onChange={this.onChange}
            />
            <Label htmlFor="adminOnly" className="ml-4">
              Admin only Request
            </Label>
          </div>
        )}
        <div className="d-flex justify-content-between mt-4">
          <div className="d-flex flex-column justify-content-between col">
            <FormGroup>
              <Label>Pair/Separate</Label>
              <div className="c-toggle ml-0 w-100">
                <input
                  type="checkbox"
                  className="c-toggle__checkbox"
                  name="pair"
                  id="pair"
                  checked={pair}
                  onChange={this.onCheckboxChange}
                />
                <label className="c-toggle__label" htmlFor="pair">
                  <div className="c-toggle__on">Pair</div>
                  <div className="c-toggle__off">Separate</div>
                </label>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>{studentTeacherSubHeading}</Label>
              <FilteredInput
                containerClass="flex-grow-1"
                nameVal="personToName"
                selectedValue={personToName}
                changeHandler={this.onChange}
                selectFunction={this.onSelectPersonTo}
                placeholder={studentTeacherPlaceHolder}
                students={studentsToShow}
                studentsToExclude={[studentId]}
                teachers={teachersToShow}
              />
            </FormGroup>
            <FormGroup>
              <Label>Importance</Label>
              <div className="c-toggle ml-0 w-100">
                <input
                  type="checkbox"
                  className="c-toggle__checkbox"
                  name="mandatory"
                  id="mandatory"
                  checked={mandatory}
                  onChange={this.onCheckboxChange}
                />
                <label className="c-toggle__label" htmlFor="mandatory">
                  <div className="c-toggle__on">Mandatory</div>
                  <div className="c-toggle__off">Important</div>
                </label>
              </div>
            </FormGroup>
          </div>
          <FormGroup className="ml-3 col">
            <Label>Comment (optional - max 255 characters)</Label>
            <Input
              type="textarea"
              className="h-75"
              placeholder="Comment..."
              name="description"
              value={description || ""}
              onChange={this.onChange}
              maxLength={255}
            />
          </FormGroup>
        </div>
      </div>
    )
  }

  render() {
    const {
      requests,
      studentId,
      teacherRequestsEditable,
      studentRequestsEditable,
    } = this.props
    const {
      loading,
      add,
      edit,
      errors,
      deleteModal,
      saveDisabled,
      constraintToDelete,
    } = this.state

    // Must have different content for different selections
    // edit request, add request, requests, no requests
    let content
    if (edit) {
      content = (
        <div>
          {/* Header */}
          <div className="c-student-modal-requests__header">EDIT REQUEST</div>

          {/* Input Fields */ this.renderInputs()}

          {/* Button Bar */}

          <div className="d-flex justify-content-between align-items-center">
            <span
              className="text-primary pl-3 pr-3 cursor-pointer"
              onClick={() => this.toggleDeleteModal(constraintToDelete)}>
              Delete Request
            </span>
            <div>
              <span
                className="text-primary pl-5 pr-5 cursor-pointer"
                onClick={this.toggleEdit}>
                Cancel
              </span>
              <Button
                color="primary"
                onClick={this.onAddEditRequestClick}
                disabled={saveDisabled}>
                Save Request
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-end w-100">
            <InlineError text={errors.mutation} />
          </div>
        </div>
      )
    } else if (add) {
      content = (
        <div>
          {/* Header */}
          <div className="c-student-modal-requests__header">NEW REQUEST</div>

          {/* Input Fields */ this.renderInputs()}

          <ButtonBar
            onCancelClick={this.cancelAdd}
            buttonText="Save Request"
            onButtonClick={this.onAddEditRequestClick}
            disabled={saveDisabled}
          />
          <div className="d-flex justify-content-end w-100">
            <InlineError text={errors.mutation} />
          </div>
        </div>
      )
    } else if (requests && requests.length > 0) {
      content = (
        <div className="d-flex flex-column">
          <div className="c-student-modal-requests__header">REQUESTS</div>
          <div className="m-3">
            {/* Table Header */}
            <div className="row c-student-modal-requests__table-header">
              <div className="col-6 u-bold">Pair/Separate</div>
              <div className="col-6 u-bold">Comment</div>
            </div>
            {requests.map((r, index) => {
              return this.renderRequestRow(studentId, r, index)
            })}
          </div>
          {(teacherRequestsEditable || studentRequestsEditable) && (
            <NewRequestButton onClick={this.toggleAdd} />
          )}
        </div>
      )
    } else {
      content = (
        <div>
          <div className="c-student-modal-requests__header-container">
            <div className="c-student-modal-requests__header">REQUESTS</div>
            {(teacherRequestsEditable || studentRequestsEditable) && (
              <NewRequestButton onClick={this.toggleAdd} />
            )}
          </div>
          <div className="c-student-modal-requests__no-requests">
            No requests recorded
          </div>
        </div>
      )
    }

    const className =
      edit || add ? "c-student-modal-requests-edit" : "c-student-modal-requests"

    return (
      <div className={`${className} px-2rem`}>
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          content
        )}
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => this.toggleDeleteModal(constraintToDelete)}
          loading={loading}
          heading="Delete Request?"
          text="Deleting the request will remove any pairing/separating information for the student."
          buttonText="Yes, delete"
          onButtonClick={this.deleteRequest}
          error={errors.mutation}
        />
      </div>
    )
  }
}
