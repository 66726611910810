import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import {
  Input,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import { InlineError } from "components"
import { sendAdminNotificationMutation } from "domains/notifications/graphql"

export const HelpModal = ({
  isOpen,
  toggle,
  onSentNotification,
  jobId,
  type = "",
}) => {
  const [message, setMessage] = useState("")
  const [sendNotification, { error, loading: sendingNotification }] =
    useMutation(sendAdminNotificationMutation, {
      onError: () => {},
      onCompleted: onSentNotification,
    })
  const uploadMessage = `File upload type: ${type}\n${message}`

  const variables = {
    notificationType: "UPLOAD_ERROR",
    uploadJobId: jobId,
    message: uploadMessage,
  }

  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader tag="h2" toggle={toggle}>
        <span>Help Me Import</span>
      </ModalHeader>

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between">
          <i className="fa fa-info-circle u-caution-icon mr-4" />
          <div>
            <div>
              Would you like us to import the file you have uploaded? We will
              notify you by email when this is completed (normally within
              24hrs).
            </div>
            <br />
            <div>Message (Optional)</div>
            <Input
              type="textarea"
              name="message"
              value={message}
              rows={3}
              onChange={e => {
                setMessage(e.target.value)
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={sendingNotification}
          onClick={() => sendNotification({ variables })}>
          Help me import
        </Button>
      </ModalFooter>
      <InlineError text={error} />
    </Modal>
  )
}
