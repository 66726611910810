import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"

import {
  createOrUpdateCurrentClass,
  activeCurrentClassesQuery,
} from "domains/classes/graphql"

import { Modal, ModalBody, Label, Input } from "reactstrap"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  ModalNote,
  Loader,
} from "components"

import { defaultSelectStyles } from "components"

import Select from "react-select"

export const RenameCurrentClassModal = ({ toggle, schoolId }) => {
  const [label, setLabel] = useState("")
  const [selectedClassValue, setSelectedClassValue] = useState("")

  const [successfulUpdate, setSuccessfulUpdate] = useState(false)
  const [updateError, setUpdateError] = useState(false)
  const { data, loading: loadingClasses } = useQuery(
    activeCurrentClassesQuery,
    {
      variables: { schoolId: schoolId },
    }
  )

  const [createOrUpdateCurrentClassMutation, { loading: renamingClass }] =
    useMutation(createOrUpdateCurrentClass, {
      refetchQueries: [
        { query: activeCurrentClassesQuery, variables: { schoolId: schoolId } },
      ],
    })

  const onChangeLabel = e => {
    setLabel(e.target.value)
  }

  const onChangeSelectedClassValue = e => {
    setSelectedClassValue(e.value)
  }

  let classOptions = []

  if (data) {
    let sortedClasses = [...data.activeCurrentClasses].sort((a, b) =>
      a.label.localeCompare(b.label, undefined, {
        numeric: true,
      })
    )

    classOptions = sortedClasses.map(sortedClass => ({
      label: sortedClass.label,
      value: sortedClass.id,
    }))
  }

  // TODO Nick: Re examine the following.
  // The logic of this function is performed twice which is technically unnecessary. However it was implemented this way to avoid a type issue.
  // selectedClassValue was originally just selectedClass. In order for Select to display the placeholder text it needs to be an empty string? But when passed into the Select
  // as a value, it seems to need to be an object? This causes a slight awkardness with the type.
  // Not sure this is the best solution.

  const getClassObjectFromValue = value => {
    return classOptions.find(c => c.value === value)
  }

  const updateClassLabel = () => {
    const classToUpdate = getClassObjectFromValue(selectedClassValue)

    const currentClassParams = {
      schoolId: schoolId,
      id: classToUpdate.value,
      label: label,
    }

    createOrUpdateCurrentClassMutation({
      variables: { currentClassParams },
    })
      .then(() => {
        setLabel("")
        setSelectedClassValue("")
        setUpdateError(false)
        setSuccessfulUpdate(true)
      })
      .catch(() => {
        setSuccessfulUpdate(false)
        setUpdateError(true)
      })
  }

  const loading = loadingClasses || renamingClass

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-40rem">
      <ModalHeader
        className="p-4"
        title="Rename Current Class"
        toggle={toggle}
      />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <>
          <ModalNote text="This feature will replace an existing Current Class label with the new label.  To rename a Current Class Label, select the existing label from the drop-down on the left, then type the desired new label on the right.  Then click Rename.  Repeat if you would like to rename multiple labels." />

          <ModalBody className="py-4 px-5">
            <div className="d-flex py-2 justify-content-between">
              <div className="d-flex-col w-40 h-24">
                <Label>Existing Label</Label>
                <Select
                  id="current classes"
                  className="c-input__multiselect"
                  placeholder="Select Current Class..."
                  options={classOptions}
                  value={getClassObjectFromValue(selectedClassValue)}
                  onChange={onChangeSelectedClassValue}
                  closeMenuOnSelect={true}
                  isSingle
                  styles={defaultSelectStyles}
                />
              </div>
              <div className="px-3 pt-3 mt-4">change to</div>
              <div className="w-40">
                <Label>New Label</Label>
                <Input
                  type="text"
                  name="label"
                  onChange={onChangeLabel}
                  maxLength={10}
                  placeholder="Type new label"
                  value={label}
                />
              </div>
            </div>
            <ButtonBar
              className="mt-4"
              buttonText="Rename"
              onButtonClick={updateClassLabel}
              onCancelClick={toggle}
              disabled={selectedClassValue === "" || label === ""}
            />
            {updateError && (
              <InlineError
                className="pr-4"
                text="Error - could not update class label. Another class with that label
          may already exist"
              />
            )}
            {successfulUpdate && (
              <p className="text-success text-right pt-2">
                Success! The label has been updated.
              </p>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  )
}
