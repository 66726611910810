import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { Modal, ModalBody, Label } from "reactstrap"
import Select from "react-select"

import { swapNewClassLabels, newClassesQuery } from "domains/classes/graphql"
import { AccountTypeContext } from "config/accountTypeContext"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  Loader,
  defaultSelectStyles,
} from "components"

export const SwapClassLabelsModal = ({ toggle, schoolId, classes }) => {
  const [selectedClass1, setSelectedClass1] = useState()
  const [selectedClass2, setSelectedClass2] = useState()

  const [successfulUpdate, setSuccessfulUpdate] = useState(false)
  const [updateError, setUpdateError] = useState(false)

  const classOptions = classes.map(sortedClass => ({
    label: sortedClass.label,
    value: sortedClass.id,
  }))

  const [swapNewClassLabelsMutation, { loading: renamingClass }] = useMutation(
    swapNewClassLabels,
    {
      refetchQueries: [
        { query: newClassesQuery, variables: { schoolId: schoolId } },
      ],
    }
  )

  const onChangeSelectedClassValue1 = e => {
    setSelectedClass1(e)

    if (selectedClass2 && e.value === selectedClass2.value) {
      setSelectedClass2(null)
    }
  }

  const onChangeSelectedClassValue2 = e => {
    setSelectedClass2(e)

    if (selectedClass1 && e.value === selectedClass1.value) {
      setSelectedClass1(null)
    }
  }

  const updateClassLabel = () => {
    const swapClassesParams = {
      schoolId: schoolId,
      classOneId: selectedClass1 && selectedClass1.value,
      classTwoId: selectedClass2 && selectedClass2.value,
    }

    swapNewClassLabelsMutation({
      variables: { swapClassesParams },
    })
      .then(() => {
        setSelectedClass1(null)
        setSelectedClass2(null)
        setUpdateError(false)
        setSuccessfulUpdate(true)
      })
      .catch(() => {
        setSuccessfulUpdate(false)
        setUpdateError(true)
      })
  }

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-40rem">
      <ModalHeader
        className="px-5 py-4"
        title={`Swap ${gettextObj.gettext("Class")} Labels`}
        toggle={toggle}
      />
      {renamingClass ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <ModalBody className="p-0 u-modal__h-14rem">
          <div className="d-flex p-4 justify-content-between">
            <div className="d-flex p-3 w-100">
              <div className="d-flex-col w-40">
                <Label>{gettextObj.gettext("Class")} 1</Label>
                <Select
                  id="currentClasseOne"
                  className="c-input__multiselect"
                  placeholder={`Select ${gettextObj.gettext("Class")}...`}
                  options={classOptions}
                  value={selectedClass1}
                  onChange={onChangeSelectedClassValue1}
                  closeMenuOnSelect={true}
                  isSingle
                  styles={defaultSelectStyles}
                />
              </div>
              <div className="d-flex px-3 pt-3 mt-4">swap with</div>
              <div className="d-flex-col w-40">
                <Label>{gettextObj.gettext("Class")} 2</Label>
                <Select
                  id="currentClasseTwo"
                  className="c-input__multiselect"
                  placeholder={`Select ${gettextObj.gettext("Class")}...`}
                  options={classOptions}
                  value={selectedClass2}
                  onChange={onChangeSelectedClassValue2}
                  closeMenuOnSelect={true}
                  isSingle
                  styles={defaultSelectStyles}
                />
              </div>
            </div>
          </div>
          <div className="px-4 d-flex justify-content-end">
            <ButtonBar
              className="px-4"
              buttonText="Swap"
              onButtonClick={updateClassLabel}
              onCancelClick={toggle}
              disabled={selectedClass1 === null || selectedClass2 === null}
            />
          </div>
          {updateError && (
            <InlineError
              className="pr-4"
              text="Error - could not update class label. Another class with that label
                may already exist"
            />
          )}
          {successfulUpdate && (
            <div className="px-4">
              <p className="text-success text-right px-4 pt-2">
                Success! The label has been updated.
              </p>
            </div>
          )}
        </ModalBody>
      )}
    </Modal>
  )
}
