import { getApiRegion, setApiRegion, DEFAULT_REGION } from "util/apiUtil"
import { useAuth0 } from "../auth0Wrapper"
import queryString from "query-string"
import { useLocation } from "react-router-dom"

const Login = () => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const country = queryParams.country

  if (country) {
    setApiRegion(country)
    // reload entire page without queries to make
    // sure we are talking to the correct Auth0 instance
    window.location.href = window.location.pathname
    return null
  } else {
    if (getApiRegion() && !isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: window.location.origin,
        },
      })
    } else {
      window.location.href =
        process.env.REACT_APP_HOME_URL ||
        "http://localhost:3000/login?country=" + DEFAULT_REGION
    }
  }

  return null
}
export const LoginPage2 = Login
