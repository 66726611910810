import { saveAs } from "file-saver"

export const downloadAuthenticatedFile = async (token, url) => {
  const response = await fetch(url, {
    method: "GET",
    headers: { authorization: token ? `Bearer ${token}` : "" },
  })

  if (response.status === 200) {
    const blob = await response.blob()

    const fileName = response.headers
      .get("content-disposition")
      .match(/attachment;\s*filename="(.+)"/)[1]

    saveAs(blob, fileName)
  }
  return response
}

export const downloadFile = url => {
  saveAs(url)
}
