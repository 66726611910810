const isStudentAttribute = criterion =>
  criterion.id === "gender" || criterion.id === "current_class"

const isCharacteristicResponse = criterion => !isStudentAttribute(criterion)

const convertToEntryCriterionStudentAttributes = criterion => {
  return criterion.selectedOptions.map(({ id }) => ({
    attribute: criterion.id,
    value: id,
  }))
}

const convertToEntryCriterionCharacteristicResponses = criterion => {
  return criterion.selectedOptions.flatMap(({ id }) => {
    return id ? [{ characteristicResponseId: id }] : []
  })
}

const convertToEntryCriterionNullResponses = criterion => {
  if (criterion.selectedOptions.find(({ id }) => id === null)) {
    return { characteristicId: criterion.id }
  }
}

export const getAndConvertEntryCriteriaStudentAttribute = criteria =>
  criteria
    .filter(isStudentAttribute)
    .flatMap(convertToEntryCriterionStudentAttributes)

export const getAndConvertEntryCriteriaCharacteristicResponse = criteria =>
  criteria
    .filter(isCharacteristicResponse)
    .flatMap(convertToEntryCriterionCharacteristicResponses)

export const getAndConvertEntryCriteriaNullResponse = criteria =>
  criteria.filter(isCharacteristicResponse).flatMap(criterion => {
    const nullResponseCriterion =
      convertToEntryCriterionNullResponses(criterion)
    return nullResponseCriterion ? [nullResponseCriterion] : []
  })

export const getAvailableCriteria = (characteristics, currentClasses) => {
  const characteristicCriteria = characteristics.map(
    ({ id, name, characteristicResponses }) => {
      const options = characteristicResponses
        .map(response => ({
          id: response.id,
          name: response.label,
        }))
        .concat({ id: null, name: "Unanswered" })

      return { id, name, options }
    }
  )

  const currentClassCriterion = {
    id: "current_class",
    name: "Current Class",
    options: currentClasses.map(c => ({ id: c.label, name: c.label })),
  }

  const genderCriterion = {
    id: "gender",
    name: "Gender",
    options: [
      { id: "M", name: "Male" },
      { id: "F", name: "Female" },
      { id: "X", name: "Non-Binary" },
    ],
  }

  return [...characteristicCriteria, genderCriterion, currentClassCriterion]
}

export const getCurrentCriteria = (
  entryCriteriaCharacteristicResponse,
  entryCriteriaStudentAttribute,
  entryCriteriaNullResponse,
  availableCriteria
) => {
  if (
    !(
      entryCriteriaCharacteristicResponse &&
      entryCriteriaStudentAttribute &&
      entryCriteriaNullResponse
    )
  ) {
    return []
  }

  const responseCriteria = entryCriteriaCharacteristicResponse.map(
    ({ characteristicResponse }) => {
      const criterion = availableCriteria.find(
        ({ id }) => id === characteristicResponse.characteristic.id
      )

      const selectedOption =
        criterion &&
        criterion.options.find(({ id }) => id === characteristicResponse.id)

      return { criterion, selectedOption }
    }
  )

  const nullResponseCriteria = entryCriteriaNullResponse.map(
    ({ characteristic }) => {
      const criterion = availableCriteria.find(
        ({ id }) => id === characteristic.id
      )
      const selectedOption =
        criterion && criterion.options.find(({ id }) => id === null)

      return { criterion, selectedOption }
    }
  )

  const studentAttributeCriteria = entryCriteriaStudentAttribute.map(
    ({ attribute, value }) => {
      const criterion = availableCriteria.find(({ id }) => id === attribute)

      const selectedOption =
        criterion && criterion.options.find(({ id }) => id === value)
      return { criterion, selectedOption }
    }
  )

  return Object.values(
    responseCriteria
      .concat(studentAttributeCriteria, nullResponseCriteria)
      .reduce((acc, { criterion, selectedOption }) => {
        // Ignore invalid selectedOptions
        if (!criterion || !selectedOption) return acc

        const existingCriterion = acc[criterion.id]

        if (existingCriterion) {
          const selectedOptions = [
            ...existingCriterion.selectedOptions,
            selectedOption,
          ]
          acc[criterion.id] = { ...existingCriterion, selectedOptions }
          return acc
        } else {
          return {
            ...acc,
            [criterion.id]: { ...criterion, selectedOptions: [selectedOption] },
          }
        }
      }, {})
  )
}
