import React, { Component } from "react"

import { Button } from "reactstrap"

import { handleSessionExpired } from "util/app"

import { InlineError } from "components"

export class QueryError extends Component {
  componentDidMount() {
    handleSessionExpired(this.props.error)
  }

  render() {
    const {
      message = "Oops, something went wrong.",
      refetch,
      error,
    } = this.props
    return (
      <div className="c-query-error">
        <h2 className="mt-2">{message}</h2>
        <br />

        <div className="justify-content-center">
          Please try: <br />
          <br />
          1. Closing and re-opening your browser
          <br />
          2. Accessing our website from a different device
          <br />
          <br />
          If the issue is still not resolve, please contact your school's IT
          Department and make sure that your network is allowed to access
          api.classsolver.com. They may need to adjust their network whitelist
          to allow this.
          <br />
          <br />
          If you need any further assistance, please contact us at{" "}
          <a
            href="mailto:support@classsolver.com"
            className="ml-1 color-blue-mid">
            support@classsolver.com
          </a>
          <br />
          <br />
          <InlineError text={error.message} />
        </div>

        <Button
          color="primary"
          className="p-1 u-text-12"
          onClick={() => refetch()}>
          Retry
        </Button>
      </div>
    )
  }
}
