import React from "react"

import { Modal, ModalBody, Form, Input, FormGroup, Col, Row } from "reactstrap"

import {
  InlineError,
  ButtonBar,
  Loader,
  ModalHeader,
  ModalNote,
  AddButton,
} from "components"

const EMPTY_FIELD = ""

export const AddOrEditTextModal = ({
  toggle,
  loading,
  inputs,
  setInput,
  onSave,
  saveDisabled,
  saveErrors,
  title,
  description,
  addButtonText,
  note,
  saveButtonText = "Save",
}) => {
  const handleFieldChange = (e, index) => {
    setInput(inputs.toSpliced(index, 1, e.target.value))
  }

  const addNewField = () => setInput(inputs.concat([EMPTY_FIELD]))

  const removeField = index => setInput(inputs.toSpliced(index, 1))

  return (
    <Modal isOpen>
      <ModalHeader title={title} toggle={toggle} className="px-4 py-4" />
      {note && <ModalNote text={note} />}
      <ModalBody className="p-4">
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <div>
            <Row className="d-flex align-items-center justify-content-between">
              <Col>
                <Form
                  onSubmit={e => {
                    // Do not submit the form when the user hits "Enter"
                    e.preventDefault()
                  }}>
                  <FormGroup className="mb-4">
                    <p>{description}</p>
                    {inputs.map((input, index) => (
                      <div key={index} className="d-flex my-1">
                        <Input
                          type="text"
                          value={input}
                          onChange={e => handleFieldChange(e, index)}
                          name={`field${index}`}
                          id={`field${index}`}
                        />
                        {inputs.length > 1 && (
                          <i
                            className={`fa fa-times student-upload-list__item__icon student-upload-list__item__icon--times m-2`}
                            onClick={() => removeField(index)}
                          />
                        )}
                      </div>
                    ))}
                    <AddButton
                      text={addButtonText}
                      onClick={addNewField}
                      className="mt-3"
                    />
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <ButtonBar
              onCancelClick={toggle}
              buttonText={saveButtonText}
              onButtonClick={onSave}
              disabled={saveDisabled}
            />
            {saveErrors && <InlineError text={saveErrors} />}
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}
