import React from "react"
import { Solver } from "domains/solver/components"
import {
  restrictedSolverQuery,
  allGradesWithNewClassesQuery,
  updateSolverActiveGrade,
  solverClientQuery,
  restrictedMoveStudentMutation,
  solveActiveGradeMutation,
  boostCharacteristicMutation,
  boostSolutionMetricMutation,
  boostStudentMetricMutation,
  lockStudentMutation,
  updateSolverUndoRedo,
  updateLastAssignmentMutation,
  favouriteAnAssignmentMutation,
  unfavouriteAnAssignmentMutation,
  updateFavouritedAssignmentMutation,
  pickAnAssignmentMutation,
} from "domains/solver/graphql"
import { SHARED_READ_ONLY, SHARED_WRITABLE } from "domains/solver/constants"
import { studentFlagsClientQuery } from "domains/students/graphql"
import { useQuery, useMutation } from "@apollo/client"
import { preferencesQuery } from "domains/accountSettings/graphql"
import { Loader, QueryError } from "components"
import { getPropertyIfDefined } from "util/objUtil"
import { Query } from "@apollo/client/react/components"
import { SCHOOL_ID, PERMISSIONS } from "constants/storageTokens"
import { UncontrolledAlert } from "reactstrap"
import { Footer } from "domains/dashboard/components"

export const SharedSolutionPage = () => {
  const schoolId = sessionStorage.getItem(SCHOOL_ID)

  const permissions = JSON.parse(sessionStorage.getItem(PERMISSIONS))

  const { data: solutionState, loading: loadingSolutionState } =
    useQuery(solverClientQuery)

  const { data: allGradesData, loading: loadingAllGrades } = useQuery(
    allGradesWithNewClassesQuery,
    { variables: { schoolId } }
  )

  const { data: schoolSettingsData, loading: loadingSchoolSetting } = useQuery(
    preferencesQuery,
    { variables: { schoolId } }
  )

  const { data: studentFlagsData, loading: loadingStudentFlags } = useQuery(
    studentFlagsClientQuery
  )

  const [updateActiveGrade] = useMutation(updateSolverActiveGrade)
  const [restrictedMoveStudent] = useMutation(restrictedMoveStudentMutation)
  const [lockStudent] = useMutation(lockStudentMutation)
  const [solveActiveGrade] = useMutation(solveActiveGradeMutation)
  const [boostCharacteristic] = useMutation(boostCharacteristicMutation)
  const [boostSolutionMetric] = useMutation(boostSolutionMetricMutation)
  const [boostStudentMetric] = useMutation(boostStudentMetricMutation)
  const [updateUndoRedo] = useMutation(updateSolverUndoRedo)
  const [updateLastAssignment] = useMutation(updateLastAssignmentMutation)
  const [favouriteAnAssignment] = useMutation(favouriteAnAssignmentMutation)
  const [unfavouriteAnAssignment] = useMutation(unfavouriteAnAssignmentMutation)
  const [updateFavouritedAssignment] = useMutation(
    updateFavouritedAssignmentMutation
  )
  const [pickAnAssignment] = useMutation(pickAnAssignmentMutation)

  if (
    loadingSchoolSetting ||
    loadingSolutionState ||
    loadingAllGrades ||
    loadingStudentFlags
  ) {
    return <Loader />
  }

  const {
    solver: {
      selectedStudent,
      activeGrade,
      undoMoves,
      redoMoves,
      lastAssignmentCached,
    },
  } = solutionState

  const gradeId =
    activeGrade !== ""
      ? activeGrade.toString()
      : getPropertyIfDefined(allGradesData.allGradesWithNewClasses[0], "id")

  return (
    <Query
      // Need this key otherwise Apollo will reuse the old data
      // https://github.com/apollographql/react-apollo/issues/2202
      key={gradeId}
      query={restrictedSolverQuery}
      variables={{ solution: { schoolId, gradeId } }}
      fetchPolicy="network-only"
      errorPolicy="all">
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loader />

        if (error && error.message !== "no-solutions-found") {
          return <QueryError error={error} refetch={refetch} />
        }

        // We need to set it to undefined if not found due to weirdness later in the code
        const sharedSolution =
          getPropertyIfDefined(data, "sharedSolution") || undefined

        const solverMode = permissions.writable
          ? SHARED_WRITABLE
          : SHARED_READ_ONLY

        return (
          <>
            {solverMode === SHARED_WRITABLE && (
              <UncontrolledAlert color="primary">
                Your administrator has provided you with the ability to move
                students. Please do not move students at the same time as other
                teachers or administrators as it may result in you losing
                information.
              </UncontrolledAlert>
            )}
            <Solver
              data={sharedSolution}
              refetch={refetch}
              activeGradeId={gradeId}
              friendPreferences={false}
              studentFlags={studentFlagsData}
              schoolSettings={schoolSettingsData.schoolSettings}
              solverGrades={allGradesData.allGradesWithNewClasses}
              selectedStudent={selectedStudent}
              updateSolverUndoRedo={updateUndoRedo}
              updateLastAssignment={updateLastAssignment}
              updateSolverActiveGrade={updateActiveGrade}
              solverMode={solverMode}
              showTeachers={permissions.showTeachers}
              showStudentRequests={permissions.showStudentRequests}
              showTeacherRequests={permissions.showTeacherRequests}
              showMetrics={permissions.showMetrics}
              showCharacteristics={permissions.showCharacteristics}
              showFriendships={permissions.showFriendships}
              refetchQueries={[]}
              moveStudentMutation={restrictedMoveStudent}
              lockStudentMutation={lockStudent}
              solveActiveGradeMutation={solveActiveGrade}
              boostCharacteristicMutation={boostCharacteristic}
              boostSolutionMetricMutation={boostSolutionMetric}
              boostStudentMetricMutation={boostStudentMetric}
              favouriteAnAssignmentMutation={favouriteAnAssignment}
              unfavouriteAnAssignmentMutation={unfavouriteAnAssignment}
              updateFavouritedAssignmentMutation={updateFavouritedAssignment}
              pickAnAssignmentMutation={pickAnAssignment}
              undoMoves={undoMoves}
              redoMoves={redoMoves}
              lastAssignmentCached={lastAssignmentCached}
            />
            <Footer className="ml-0" />
          </>
        )
      }}
    </Query>
  )
}
