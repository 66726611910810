import * as React from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Loader } from "components"
import { MapColumnHeadings } from "components"
import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"
import { UploadErrorModal } from "domains/upload"

const defaultWarning = {
  body: error => {
    return (
      <div>
        <p>
          Sorry, there was an error with your uploaded file. Please try
          uploading again and if the error persists, please contact us by
          clicking "Need help importing?" with the following error details:
        </p>
        <pre>{error.message}</pre>
      </div>
    )
  },
}

export const MapColumns = () => {
  // Utility for manipulating the router
  const navigate = useNavigate()

  // Bring in the router location so we can grab the jobId from state
  const { state: routeState } = useLocation()

  // Load up the upload job
  const {
    fetch: [uploadJob, { error: uploadJobError }],
    update: [updateBulkUploadJob, { loading: isUpdatingJob }],
    cancel: [cancelUploadJob],
  } = useBulkUploadJob(routeState.jobId)

  // Handle when the user presses the Continue button
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Teachers")
    })
  }, [cancelUploadJob, navigate])

  if (uploadJobError) {
    return (
      <UploadErrorModal
        isOpen
        title="Error with uploaded file"
        type="Teachers"
        jobId={routeState.jobId}
        toggle={handleCancel}
        actions={[
          {
            color: "warning",
            onClick: handleCancel,
            text: "Ok",
          },
        ]}>
        {defaultWarning.body(uploadJobError)}
      </UploadErrorModal>
    )
  }

  if (!uploadJob) {
    return <Loader />
  }

  return (
    <MapColumnPresentation
      navigate={navigate}
      uploadJob={uploadJob}
      updateBulkUploadJob={updateBulkUploadJob}
      isUpdatingJob={isUpdatingJob}
      cancelUploadJob={cancelUploadJob}
    />
  )
}

const MapColumnPresentation = ({
  navigate,
  uploadJob,
  updateBulkUploadJob,
  isUpdatingJob,
  cancelUploadJob,
}) => {
  // Memoize a list of suggestions we can automatically apply
  const automaticSuggestions = React.useMemo(() => {
    const suggestions = {
      first_name: ["first name", "first_name"],
      last_name: ["last name", "last_name", "surname"],
      email: ["email"],
    }

    const numHeadersInFile = uploadJob?.fileSummary?.headers?.length || 0

    const estimatedNumClasses = Math.max(
      numHeadersInFile - Object.keys(suggestions).length,
      0
    )

    const currentClassSuggestions = Object.fromEntries(
      Array(estimatedNumClasses)
        .fill()
        .map((_, index) => {
          const num = index + 1
          const suggestion =
            num === 1
              ? [
                  "current class",
                  `current class ${num}`,
                  `current_class_${num}`,
                ]
              : [`current class ${num}`, `current_class_${num}`]

          return [`current_class_${num}`, suggestion]
        })
    )

    return { ...suggestions, ...currentClassSuggestions }
  }, [uploadJob])

  // Keep a piece of state which determines if the help modal should be displaed
  const [showHelpModal, setShowHelpModal] = React.useState(false)

  // Memoize the available column headers to map to. Default to an empty
  // array if the query has not finished resolving yet
  const availableColumns = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      return uploadJob.fileSummary.headers
    } else {
      return []
    }
  }, [uploadJob])

  const initialMapping = () => {
    return Object.entries(automaticSuggestions).reduce(
      (acc, [key, suggestions]) => {
        const suggestion = availableColumns.find(column => {
          const lowerCase = column.toLowerCase()
          const noSpaces = column.replace(/\s/g, "")

          return (
            suggestions.includes(column) ||
            suggestions.includes(lowerCase) ||
            suggestions.includes(noSpaces)
          )
        })

        if (suggestion) {
          return { ...acc, [key]: suggestion }
        } else {
          return acc
        }
      },
      {}
    )
  }

  // Keep track of which columns have been mapped
  const [mappedColumns, setMappedColumns] = React.useState(() => {
    return initialMapping()
  })

  const requiredColumns = React.useMemo(() => {
    return {
      first_name: "First Name ID",
      last_name: "Last Name ID",
      email: "Email",
    }
  }, [])

  const estimatedClassColumns = Math.max(
    availableColumns.length - Object.keys(requiredColumns).length,
    0
  )

  const optionalColumns = Object.fromEntries(
    Array(estimatedClassColumns)
      .fill()
      .map((_, index) => [
        `current_class_${index + 1}`,
        `Current Class ${index + 1}`,
      ])
  )

  // Memoize whether the Save & Continue button should be disabled because
  // the user is yet to map some fields
  const isMappingComplete = React.useMemo(() => {
    const mapped = Object.keys(mappedColumns)
    const required = Object.keys(requiredColumns)

    if (mapped.length >= required.length) {
      return required.every(column => mapped.includes(column))
    } else {
      return false
    }
  }, [mappedColumns, requiredColumns])

  // Handle when the user presses the Continue button
  const handleContinue = React.useCallback(() => {
    // Compute the meta we want to set on this job from this step
    const meta = JSON.stringify({ mapped_columns: mappedColumns })

    // Put params together in a nice way
    const params = { meta, overwriteMeta: false }

    // Update the job and either continue to the next step or show an error
    return updateBulkUploadJob(params)
      .then(result => {
        if (result.data && result.data.updateBulkUploadJob) {
          navigate("/Teachers/MapClassLabels", {
            state: {
              jobId: result.data.updateBulkUploadJob.id,
            },
          })
        } else {
          // TODO: Show an error
        }
      })
      .catch(() => {
        // TODO: Show an error
      })
  }, [updateBulkUploadJob, navigate, mappedColumns])

  // Handle when the user presses the Continue button
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Teachers")
    })
  }, [cancelUploadJob, navigate])

  // Helper to toggle the help modal
  const toggleHelpModal = React.useCallback(() => {
    setShowHelpModal(showModal => !showModal)
  }, [])

  // Handle when the user maps a particular column
  const handleColumnMapped = React.useCallback(({ selectedOption, mapTo }) => {
    // Update state to record this mapped column
    setMappedColumns(columns => ({ ...columns, [mapTo]: selectedOption }))
  }, [])

  // Handle when the user wishes to clear a column
  const handleColumnClear = React.useCallback(columnName => {
    // Update state such the column is unmapped
    setMappedColumns(columns => {
      delete columns[columnName]
      return { ...columns }
    })
  }, [])

  return (
    <MapColumnHeadings
      importType={"Teachers"}
      handleToggleHelpModal={toggleHelpModal}
      showHelpModal={showHelpModal}
      uploadJob={uploadJob}
      requiredColumns={requiredColumns}
      optionalColumns={optionalColumns}
      mappedColumns={mappedColumns}
      options={availableColumns}
      handleColumnMapped={handleColumnMapped}
      handleColumnClear={handleColumnClear}
      isMappingComplete={isMappingComplete}
      isUpdatingJob={isUpdatingJob}
      handleContinue={handleContinue}
      handleCancel={handleCancel}
    />
  )
}
