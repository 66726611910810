import React from "react"
import { Tooltip } from "react-tooltip"
import { Input } from "reactstrap"

import {
  InlineError,
  ButtonBar,
  Loader,
  CharacteristicTooltip,
} from "components"

import { handleSessionExpired } from "util/app"
import { getStudentResponse } from "util/characteristics"
import * as resolverTypes from "constants/resolverTypes"

const CharacteristicLabel = ({ style, label }) => (
  <div style={style} className="c-student-modal-characteristics__label">
    {label}
  </div>
)

export class Characteristics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      edit: false,
      errors: {},
      studentResponses: this.props.studentResponses,
    }
  }

  toggleEdit = () => {
    this.props.toggleEdit()
    this.setState({
      edit: !this.state.edit,
    })
  }

  onCancelClick = () => {
    const { studentResponses } = this.props
    this.toggleEdit()
    // reset responses back to original from props
    this.setState({
      studentResponses: studentResponses,
    })
  }

  onSaveClick = () => {
    const {
      updateStudentCharacteristicResponses,
      updateStudentEditFlag,
      studentId,
      refetchQueries,
    } = this.props
    const { studentResponses } = this.state

    this.setState({ loading: true })

    let characteristicResponseIds = []
    if (studentResponses.length > 0) {
      studentResponses.forEach(r => {
        characteristicResponseIds.push(parseInt(r.id, 10))
      })
    }
    const variables = {
      studentId,
      characteristicResponseIds,
    }

    updateStudentCharacteristicResponses({ variables, refetchQueries })
      .then(() => {
        this.toggleEdit()
        this.setState({ loading: false })
        // flag that the student has been updated
        updateStudentEditFlag({
          variables: { type: resolverTypes.STUDENT_UPDATED, value: true },
        })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
          errors: { mutation: "Could Not Update Responses" },
        })
      })
  }

  onChange = (e, characteristic) => {
    const { studentResponses } = this.state
    // clone state
    const newResponses = Array.from(studentResponses)

    // get the response from target value
    const newResponse = characteristic.characteristicResponses.find(
      r => r.value === parseInt(e.target.value, 10)
    )

    const responseIndex = newResponses.findIndex(
      r => r.characteristicId === characteristic.id
    )

    // remove old response
    if (responseIndex > -1) {
      newResponses.splice(responseIndex, 1)
    }

    // Add new response if it exists
    if (newResponse) {
      newResponses.push(newResponse)
    }

    this.setState({
      studentResponses: newResponses,
    })
  }

  render() {
    const { queryLoading, schoolCharacteristics, editable } = this.props
    const { loading, errors, studentResponses } = this.state

    const divLoader = (
      <div className="position-relative p-5">
        <Loader />
      </div>
    )

    if (queryLoading) {
      return divLoader
    }

    let content
    if (this.state.edit) {
      content = (
        <div className="c-student-modal-characteristics__edit px-2rem">
          <div className="c-student-modal-characteristics__header">
            CHARACTERISTICS
          </div>
          {loading ? (
            divLoader
          ) : (
            <div className="mt-3">
              {schoolCharacteristics.map((c, index) => {
                // Get the student response for this characteristic
                const studentResponse = getStudentResponse(c, studentResponses)

                // To match an unselected response to placeholder
                const value = studentResponse ? studentResponse.value : ""

                return (
                  <div key={index} className="row mb-3">
                    <div className="col-6">
                      {/* Label and Tooltip */}
                      <i
                        className="fa fa-info-circle ml-3 icon"
                        data-tooltip-id={`description${index}`}
                      />
                      <CharacteristicLabel label={c.name} />
                      <CharacteristicTooltip
                        id={`description${index}`}
                        name={c.name}
                        place="right"
                        variant="light"
                        characteristicResponses={c.characteristicResponses}
                      />
                    </div>

                    {/* Input */}
                    <div className="col-6">
                      <Input
                        type="select"
                        value={value}
                        onChange={e => {
                          this.onChange(e, c)
                        }}>
                        {value === "" && (
                          /* Only Show Option when value="" */
                          <option value="" />
                        )}
                        {c.characteristicResponses.map(r => (
                          <option value={r.value} key={r.id}>
                            {r.label}
                          </option>
                        ))}
                        <option value={null} key={null} />
                      </Input>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <ButtonBar
            onCancelClick={this.onCancelClick}
            buttonText="Save Characteristics"
            onButtonClick={this.onSaveClick}
          />
          <InlineError text={errors.mutation} />
        </div>
      )
    } else {
      content = (
        <div className="c-student-modal-characteristics px-2rem">
          <div className="c-student-modal-characteristics__header-container">
            <span className="c-student-modal-characteristics__header">
              CHARACTERISTICS
            </span>
            {editable && (
              <span
                className="text-primary cursor-pointer"
                onClick={this.toggleEdit}>
                Edit
              </span>
            )}
          </div>
          <div className="mt-3">
            {schoolCharacteristics.map((c, index) => {
              // Create style from colour
              const response = getStudentResponse(c, studentResponses)
              const style = response
                ? { borderLeft: `4px solid ${response.colour}` }
                : {}

              // Get student response for characteristic
              return (
                <div key={index} className="row p-0">
                  <div className="col-6">
                    <CharacteristicLabel label={c.name} style={style} />
                  </div>
                  <div className="col-6 c-student-modal-characteristics__response">
                    {response && (
                      <div>
                        <span>{response.label}</span>
                        {/* Show tooltip if Description */}
                        {response.description && (
                          <span>
                            <i
                              className="fa fa-info-circle ml-3"
                              data-tooltip-id={`description${index}`}
                            />
                            <Tooltip
                              id={`description${index}`}
                              place="right"
                              // border colour is equivalent to $color-grey-class-header
                              border="1px solid #dce1e8"
                              opacity={1}
                              variant="light">
                              {response.description}
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    return content
  }
}
