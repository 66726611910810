import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Select from "react-select"

import { activeSchoolGradesQuery } from "domains/classes/graphql"

import { Modal, ModalBody, Label, ButtonGroup, Button } from "reactstrap"

import {
  ButtonBar,
  InlineError,
  ModalHeader,
  ModalNote,
  Loader,
  defaultSelectStyles,
} from "components"

import { DangerousMutation } from "domains/accountSettings/components"

export const RemoveRequestsByCurrentGradesModal = ({
  toggle,
  schoolId,
  title,
  description,
  cautionHeading,
  removeButtonText,
  cautionText,
  cautionButtonText,
  successMessage,
  errorMessage,
  refetchQueries,
  removeByGradesMutation,
}) => {
  const [selectGradesModalOpen, setSelectGradesModalOpen] = useState(true)

  const [removePairingRequests, setRemovePairingRequests] = useState(true)

  const [removeSeparatingRequests, setRemoveSeparatingRequests] = useState(true)

  const { data, loading: loadingGrades } = useQuery(activeSchoolGradesQuery, {
    variables: { schoolId },
  })

  const [
    removeByCurrentGrade,
    { error: errorRemoving, loading: removingGrades },
  ] = useMutation(removeByGradesMutation, { onError: () => {} })

  const [selectedGrades, setSelectedGrades] = useState([])

  const loading = loadingGrades || removingGrades

  const availableGrades = loading
    ? []
    : data.activeSchoolGrades.map(grade => {
        return {
          label: grade.label,
          value: grade.id,
        }
      })

  if (selectGradesModalOpen) {
    return (
      <Modal isOpen className="modal-dialog-centered u-modal__w-45rem">
        <ModalHeader className="p-4" title={title} toggle={toggle} />
        {loading ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <>
            <ModalNote text={description} />

            <ModalBody className="pb-4">
              <div className="px-4 pt-4">
                <Label>Select Current Grade/s to apply to:</Label>
                <Select
                  name="current_grades"
                  className="c-input__multiselect"
                  placeholder="Select Current Grades"
                  options={availableGrades}
                  value={selectedGrades}
                  onChange={grades => {
                    setSelectedGrades(grades)
                  }}
                  closeMenuOnSelect={false}
                  isMulti
                  styles={defaultSelectStyles}
                />
              </div>

              <div className="px-4 pt-4">
                <Label className="pt-2">
                  Select the Request Type/s to apply to:
                </Label>
                <ButtonGroup className="pt-2">
                  <Button
                    className="btn-mid-150-width"
                    active={removePairingRequests && !removeSeparatingRequests}
                    onClick={() => {
                      setRemovePairingRequests(true)
                      setRemoveSeparatingRequests(false)
                    }}>
                    Pairings Only
                  </Button>
                  <Button
                    className="btn-mid-150-width"
                    active={!removePairingRequests && removeSeparatingRequests}
                    onClick={() => {
                      setRemovePairingRequests(false)
                      setRemoveSeparatingRequests(true)
                    }}>
                    Separations Only
                  </Button>
                  <Button
                    className="btn-mid-150-width"
                    active={removePairingRequests && removeSeparatingRequests}
                    onClick={() => {
                      setRemovePairingRequests(true)
                      setRemoveSeparatingRequests(true)
                    }}>
                    Both
                  </Button>
                </ButtonGroup>
              </div>

              <ButtonBar
                className="mt-5 px-4"
                buttonText={removeButtonText}
                onButtonClick={() => setSelectGradesModalOpen(false)}
                onCancelClick={toggle}
                disabled={selectedGrades.length === 0}
              />
              {errorRemoving && (
                <InlineError
                  className="mt-5 px-4"
                  text={errorRemoving.message}
                />
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    )
  } else {
    return (
      <DangerousMutation
        dangerousMutation={() => {
          return removeByCurrentGrade({
            variables: {
              schoolId: schoolId,
              currentGradeIds: selectedGrades.map(option => option.value),
              deletePairRequests: removePairingRequests,
              deleteSeparateRequests: removeSeparatingRequests,
            },
            refetchQueries: refetchQueries,
            onCompleted: toggle,
          })
        }}
        cautionHeading={cautionHeading}
        cautionText={cautionText}
        cautionButtonText={cautionButtonText}
        successMessage={successMessage}
        errorMessage={errorMessage}
        toggleComponent={toggle}
      />
    )
  }
}
