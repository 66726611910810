import React from "react"

export const PrioritySelector = ({
  index,
  last,
  onDecreasePriorityClick,
  onIncreasePriorityClick,
}) => {
  return (
    <React.Fragment>
      <i
        className={`c-chars__arrow-icon fa fa-arrow-down font-size-16 cursor-pointer ${
          last && "invisible"
        }`}
        onClick={onDecreasePriorityClick}
      />
      <i
        className={`c-chars__arrow-icon fa fa-arrow-up font-size-16 cursor-pointer ${
          index === 0 && "invisible"
        }`}
        onClick={onIncreasePriorityClick}
      />
    </React.Fragment>
  )
}
