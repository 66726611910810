import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import { FriendSettings } from "domains/friendships/components"
import { Preference } from "./preference"
import { AccountTypeContext } from "config/accountTypeContext"

export const SurveySettings = ({
  teachersToAddTeacherRequests,
  teachersToAddStudentRequests,
  feederSchool,
  onChange,
}) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj
  const { pathname: currentPath } = useLocation()

  return (
    <div className="c-survey-settings">
      <h2 className="mb-5 mt-5 u-blue-primary">Survey Settings</h2>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div className="u-w-55">
          <div className="mb-2">Characteristics</div>
          <p>
            Set up the characteristics for balancing{" "}
            {gettextObj.gettext("classes")} at your school.
          </p>
        </div>
        <NavLink
          className="c-button--secondary c-survey-settings__right"
          to={{
            pathname: "/Characteristics",
            state: { navFrom: currentPath },
          }}>
          Characteristic Settings
        </NavLink>
      </div>
      <div className="mb-4">
        <FriendSettings />
      </div>
      <Preference
        id="teachersToAddStudentRequests"
        name="teachersToAddStudentRequests"
        title="Allow Teachers to add Student Requests"
        description="Allow teachers to be able to add and view requests to pair and separate students from other students."
        checked={teachersToAddStudentRequests}
        onChangeHandler={e => onChange(e, teachersToAddStudentRequests)}
      />
      <Preference
        id="teachersToAddTeacherRequests"
        name="teachersToAddTeacherRequests"
        title="Allow Teachers to add Teacher Requests"
        description="Allow teachers to be able to add and view requests to pair and separate students from teachers."
        checked={teachersToAddTeacherRequests}
        onChangeHandler={e => onChange(e, teachersToAddTeacherRequests)}
      />
      <Preference
        id="feederSchool"
        name="feederSchool"
        title="Enable Feeder School Survey Option"
        description="If you plan to send surveys external to your school (e.g. feeder schools) enable this feature."
        checked={feederSchool}
        onChangeHandler={e => onChange(e, feederSchool)}
      />
    </div>
  )
}
