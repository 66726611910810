import React, { Component } from "react"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { characteristicsQuery } from "domains/characteristics/graphql"
import {
  schoolReportQuery,
  unmetConstraintsQuery,
} from "domains/reports/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"

import { Loader } from "components"
import {
  ClassTableHeader,
  ClassTableBody,
} from "domains/reports/components/class"
import {
  SchoolTableHeader,
  SchoolTableBody,
} from "domains/reports/components/school"

import { isDefinedNotNull } from "util/objUtil"
import { handleSessionExpired, getSchoolId } from "util/app"
import { naturalSortByClassLabels } from "util/sortUtil"
import { preferencesQuery } from "domains/accountSettings/graphql"

class ReportTableComponent extends Component {
  state = {
    loadingExtra: false,
    studentMetrics: this.props.studentMetrics || [],
    gradesFetched: 0,
    schoolData: [],
    unmetConstraints: [],
  }

  componentDidMount() {
    const { isClassReport } = this.props
    if (isClassReport) {
      this.fetchClassReportExtras()
    } else {
      this.fetchSchoolReportExtras()
    }
  }

  fetchClassReportExtras = () => {
    const { client, data, settings, setExpanded } = this.props

    const adminOnly = settings.adminOnlyRequests

    const schoolId = getSchoolId()

    // Keep track of all the classes `expanded` state
    naturalSortByClassLabels(data).forEach((c, index) => setExpanded(index))

    client
      .query({
        query: unmetConstraintsQuery,
        variables: { adminOnly, schoolId },
        fetchPolicy: "no-cache",
      })
      .then(data => {
        this.setState({
          unmetConstraints: data.data.constraints.unmetConstraints,
        })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loadingExtra: false,
        })
      })
  }

  fetchSchoolReportExtras = () => {
    const { client, allGradesWithNewClasses, settings } = this.props
    this.setState({
      loadingExtra: true,
    })
    const adminOnly = settings.adminOnlyRequests
    const schoolId = getSchoolId()

    client
      .query({
        query: unmetConstraintsQuery,
        variables: { adminOnly, schoolId },
        fetchPolicy: "no-cache",
      })
      .then(data => {
        this.setState({
          unmetConstraints: data.data.constraints.unmetConstraints
            ? data.data.constraints.unmetConstraints.map(r => r.constraintId)
            : [],
        })
        allGradesWithNewClasses.forEach(grade => {
          const variables = {
            solution: { gradeId: grade.id, schoolId },
            adminOnly,
          }
          client
            .query({
              query: schoolReportQuery,
              variables,
              fetchPolicy: "no-cache",
            })
            .then(data => {
              this.setState(prevState => {
                return {
                  loadingExtra:
                    prevState.gradesFetched + 1 !==
                    allGradesWithNewClasses.length,
                  gradesFetched: prevState.gradesFetched + 1,
                  schoolData: data.data.solution.classes
                    ? [...prevState.schoolData, data.data.solution]
                    : prevState.schoolData,
                  studentMetrics: data.data.solution.studentMetrics
                    ? [
                        ...prevState.studentMetrics,
                        ...data.data.solution.studentMetrics,
                      ]
                    : prevState.studentMetrics,
                }
              })
            })
            .catch(error => {
              handleSessionExpired(error)
              this.setState(prevState => {
                return {
                  loadingExtra:
                    prevState.gradesFetched + 1 !==
                    allGradesWithNewClasses.length,
                  gradesFetched: prevState.gradesFetched + 1,
                }
              })
            })
        })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loadingExtra: false,
        })
      })
  }

  render() {
    const {
      data,
      loadingCharacteristics,
      loadingSchoolSettings,
      schoolCharacteristics,
      allGradesWithNewClasses,
      isClassReport,
      expanded,
      setExpanded,
      schoolSettings,
    } = this.props
    const {
      loadingExtra,
      gradesFetched,
      studentMetrics,
      unmetConstraints,
      schoolData,
    } = this.state

    if (loadingCharacteristics || loadingExtra || loadingSchoolSettings) {
      return isClassReport ? (
        <Loader />
      ) : (
        <div className="position-relative d-flex align-items-center flex-column">
          <Loader />
          <div className="mt-5 pt-6">
            Fetching Grades {gradesFetched}/{allGradesWithNewClasses.length}
          </div>
        </div>
      )
    }

    if (schoolData.length > 0) {
      schoolData.sort((a, b) => a.activeGrade.order - b.activeGrade.order)
    }

    let sortedData
    if (data) {
      sortedData = naturalSortByClassLabels(data)
    }

    const schoolSummaryHasGenderX =
      schoolData &&
      schoolData
        .flatMap(({ classes }) => {
          return classes.flatMap(({ students }) => students)
        })
        .some(({ gender }) => gender === "X")

    const classSummaryHasGenderX =
      data &&
      data
        .flatMap(({ students }) => students)
        .some(({ gender }) => gender === "X")

    return (
      <table className="c-reports-table">
        {isClassReport ? (
          <React.Fragment>
            <ClassTableHeader schoolCharacteristics={schoolCharacteristics} />
            {isDefinedNotNull(sortedData) &&
              sortedData.map((bodyData, index) => {
                return (
                  <ClassTableBody
                    key={index}
                    rowLength={11 + schoolCharacteristics.length}
                    data={bodyData}
                    schoolCharacteristics={schoolCharacteristics}
                    expanded={expanded[index]}
                    setExpanded={value => setExpanded(index, value)}
                    unmetConstraints={unmetConstraints}
                    hasGenderX={classSummaryHasGenderX}
                  />
                )
              })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SchoolTableHeader
              schoolCharacteristics={schoolCharacteristics}
              hasGenderX={schoolSummaryHasGenderX}
              schoolSettings={schoolSettings}
            />
            {isDefinedNotNull(schoolData) &&
              schoolData.map((bodyData, index) => (
                <SchoolTableBody
                  key={index}
                  rowLength={11 + schoolCharacteristics.length}
                  data={bodyData}
                  schoolCharacteristics={schoolCharacteristics}
                  unmetConstraints={unmetConstraints}
                  studentMetrics={studentMetrics}
                  hasGenderX={schoolSummaryHasGenderX}
                />
              ))}
          </React.Fragment>
        )}
      </table>
    )
  }
}

export const ReportTable = compose(
  graphql(preferencesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolSettings } }) => ({
      loadingSchoolSettings: loading,
      schoolSettings,
    }),
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolCharacteristics } }) => ({
      loadingCharacteristics: loading,
      schoolCharacteristics,
    }),
  })
)(ReportTableComponent)
