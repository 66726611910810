import React from "react"

export const Referral = props => {
  return (
    <div className="c-account-details__referal">
      <div className="c-account-details__referal__left">
        <h3 className="u-bold u-text-18 text-uppercase">{props.heading}</h3>
        <p className="mb-0">{props.message}</p>
        {props.button && (
          <a
            href={props.button.link}
            target="blank"
            className="mt-2 btn btn-primary">
            {props.button.title}
          </a>
        )}
      </div>
    </div>
  )
}
