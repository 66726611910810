import React, { useEffect, useState } from "react"
import { AUTH_TOKEN, SCHOOL_ID } from "constants/storageTokens"
import { Loader } from "components"
import queryString from "query-string"
import { setApiRegion } from "util/apiUtil"
import { useMutation } from "@apollo/client"
import { LoginError } from "../components/loginError"
import { RequestNewToken } from "../../auth/components/requestNewToken"
import { requestOtpMutation, verifyBearerTokenMutation } from "../graphql"
import { AuthWithOTP } from "../components/authWithOTP"
import { TOKEN_EXPIRED, UNAUTHORIZED } from "domains/auth/errorFields"
import { useParams, useLocation } from "react-router-dom"

const setSession = response => {
  // Set some session information on successful login for
  // other components to access
  sessionStorage.setItem(AUTH_TOKEN, response.token)
  // TODO: schoolId will be deprecated
  if (response.user.schoolId) {
    sessionStorage.setItem(SCHOOL_ID, response.user.schoolId)
  } else {
    sessionStorage.removeItem(SCHOOL_ID)
  }
}

const clearSession = () => {
  sessionStorage.clear()
}

export const useTokenLogin = ({
  tokenType,
  loginMutation,
  mutationName,
  errorMessages,
}) => {
  const params = useParams()
  const location = useLocation()

  // Set up the Apollo client
  const queryParams = queryString.parse(location.search)
  setApiRegion(queryParams.country)
  const token = params.token
  const [verifyBearerToken] = useMutation(verifyBearerTokenMutation)

  // State which we return to the caller of this hook
  const [authorized, setAuthorized] = useState(false)
  const [response, setResponse] = useState(null)

  // Internal state
  const [passwordIncorrect, setPasswordIncorrect] = useState(false)
  const [resendTimeout, setResendTimeout] = useState(0)
  const [requestOtpSuccessful, setRequestOtpSuccessful] = useState()

  // Throttle the number of times the requestOtp mutation can be called
  // within a time period
  const throttleRequestOtp = timeSec => {
    setResendTimeout(timeSec)
    if (timeSec > 0) {
      setTimeout(() => {
        throttleRequestOtp(timeSec - 1)
      }, 1000)
    }
  }

  const [requestOtp] = useMutation(requestOtpMutation, {
    variables: { currentToken: token, tokenType },
    onCompleted: () => {
      setRequestOtpSuccessful(true)
      throttleRequestOtp(10)
    },
    onError: () => {
      setRequestOtpSuccessful(false)
    },
  })

  const [login, { error }] = useMutation(loginMutation, {
    variables: { token },
    onCompleted: data => {
      const response = data[mutationName]
      setSession(response)
      setAuthorized(true)
      setResponse(response)
    },
    onError: () => {},
  })

  // Login only once since we'll be letting the `AuthWithOTP` component login afterwards
  useEffect(() => {
    const bearerToken = sessionStorage.getItem(AUTH_TOKEN)
    if (bearerToken) {
      // Need to check the bearer token is valid so they can't use it to access other data
      verifyBearerToken({
        variables: { bearerToken, temporaryToken: token },
      })
        .then(({ data: { verifyBearerToken } }) => {
          if (verifyBearerToken) {
            setAuthorized(verifyBearerToken)
          } else {
            clearSession()
            login()
          }
        })
        .catch(() => {
          clearSession()
          login()
        })
    } else {
      clearSession()
      login()
    }
  }, [login, verifyBearerToken, token])

  if (authorized) {
    return [true, null, null, response]
  }

  if (error) {
    const errorCode = error.graphQLErrors[0].message
    const errorContent = errorMessages[errorCode]

    let Component
    let componentProps
    // We handle the `unauthorized` explicitly
    if (errorCode === UNAUTHORIZED) {
      Component = AuthWithOTP
      componentProps = {
        loginQuery: login,
        requestOtp,
        passwordIncorrect,
        setPasswordIncorrect,
        requestOtpSuccessful,
        resendTimeout,
      }
    } else {
      Component = LoginError
      componentProps = {
        message: errorContent.message,
        // Allow a user to request a new token if the token is expired
        additional: errorCode === TOKEN_EXPIRED && (
          <RequestNewToken currentToken={token} tokenType={tokenType} />
        ),
        info: errorContent.info,
        promoBanner: errorContent.promoBanner,
      }
    }

    return [false, Component, componentProps, null]
  }

  // By default just return the loader
  return [false, Loader, {}, null]
}
