import React from "react"
import { Tooltip } from "react-tooltip"

export const BadgeWithTooltip = ({
  id,
  onClick,
  labelText,
  tooltipHeader,
  tooltipBody,
  badgeType,
}) => {
  const activeClassName = badgeType ? `c-toggle-badge--${badgeType}` : ""
  return (
    <div className={`c-toggle-badge ${activeClassName}`}>
      <a onClick={onClick} data-tooltip-id={id}>
        {labelText}
      </a>
      <Tooltip
        className="c-badge-tooltip p-0"
        id={id}
        variant="light"
        float
        opacity={1}>
        <div>
          <div className="c-badge-tooltip__header">{tooltipHeader}</div>
          <div className="c-badge-tooltip__body">{tooltipBody}</div>
        </div>
      </Tooltip>
    </div>
  )
}
