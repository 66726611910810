import { gql } from "@apollo/client"

export const dashboardQuery = gql`
  query ($adminOnly: Boolean, $schoolId: ID!) {
    dashboard(adminOnly: $adminOnly, schoolId: $schoolId) {
      newClassCount
      requestCount
      studentCount
      inactiveStudentCount
      teacherCount
      surveySentCount
      surveyCompletedCount
      studentsPlacedCount
      newAccountLock
      overrideSolverLock
    }
  }
`
