export const classTranslations = {
  translations: {
    "": {
      Class: { msgstr: ["Class"], msgid_plural: ["Classes"] },
      class: { msgstr: ["class"], msgid_plural: ["classes"] },
      CLASS: { msgstr: ["CLASS"], msgid_plural: ["CLASSES"] },
      Classes: { msgstr: ["Classes"] },
      classes: { msgstr: ["classes"] },
      CLASSES: { msgstr: ["CLASSES"] },
      "Class/es": { msgstr: ["Class/es"] },
      Grade: { msgstr: ["Grade"], msgid_plural: ["Grades"] },
      grade: { msgstr: ["grade"], msgid_plural: ["grades"] },
      grades: { msgstr: ["grades"] },
      Grades: { msgstr: ["Grades"] },
      GRADES: { msgstr: ["GRADES"] },
      "grade(s)": { msgstr: ["grade(s)"] },
      "Grade/s": { msgstr: ["Grade/s"] },
      "grade (or mixed/composite grade)": {
        msgstr: ["grade (or mixed/composite grade)"],
      },
      "New Grade": { msgstr: ["New Grade"] },
      "New Grade/s": { msgstr: ["New Grade/s"] },
    },
  },
}
