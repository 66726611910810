import React from "react"
import { ReportCharacteristicColours } from "domains/reports/components"

export const CharacteristicHeaders = ({ schoolCharacteristics }) =>
  schoolCharacteristics.map((characteristic, index) => (
    <th key={index} className="c-reports-table__header__label text-center">
      {characteristic.name}
      <ReportCharacteristicColours
        characteristicName={characteristic.name}
        characteristicResponses={characteristic.characteristicResponses}
      />
    </th>
  ))
