import React from "react"
import { CautionModal } from "components"
import queryString from "query-string"
import { useLocation } from "react-router-dom"

export const AuthenticationErrorPage = ({
  authenticationError: { message: errorMessage },
}) => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  const logOut = () => (window.location.href = "/Logout")

  const supportEmail = (
    <a href="mailto:support@classsolver.com" className="ml-1 color-blue-mid">
      support@classsolver.com
    </a>
  )

  const timeCheckerURL = (
    <a
      href="https://time.is/"
      target="_blank"
      rel="noopener noreferrer"
      className="ml-1 color-blue-mid">
      https://time.is/
    </a>
  )

  let text = ""

  switch (errorMessage) {
    case "auth0_error":
      const accessDenied = queryParams.error === "access_denied"

      if (accessDenied) {
        text = (
          <div>
            <div>
              {queryParams.error_description}
              <br />
              <br />
              If the issue persists, please contact our support team at
              {supportEmail} for assistance.
            </div>
          </div>
        )
      } else {
        text = (
          <div>
            <div>
              Sorry, there was an error with your login. Please try closing your
              browser and logging in again.
              <br />
              <br />
              If the issue persists, please contact our support team at
              {supportEmail} for assistance.
            </div>
            <br />
            <p>
              Error details:
              <br />
              <code>
                {queryParams.error} - {queryParams.error_description}
              </code>
            </p>
          </div>
        )
      }
      break
    case "id_token was issued in the future (invalid iat)":
      text = (
        <div>
          <div>
            It seems like the time on your computer/network is out of sync with
            the time on the internet.
          </div>
          <br />
          <div>
            To help ensure your data remains protected with industry-leading
            security we require these times to match (within 1 minute). You can
            check the current internet time at {timeCheckerURL}
          </div>
          <br />
          <div>
            Please contact your IT department to fix the time for your
            computer/network. If you need more details or assistance, please
            contact us at {supportEmail}
          </div>
        </div>
      )

      break

    default:
      text = (
        <div>
          Sorry, there was an error with your login. Please try closing your
          browser and logging in again.
          <br />
          <br />
          If the issue persists, please contact our support team at
          {supportEmail} for assistance.
        </div>
      )
  }

  return (
    <CautionModal
      isOpen
      toggle={logOut}
      onButtonClick={logOut}
      buttonText="Dismiss"
      showCancel={false}
      heading="Login Error"
      text={text}
    />
  )
}
