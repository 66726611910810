import React from "react"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { newClassesQuery } from "domains/classes/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"
import { allGradesWithNewClassesQuery } from "domains/solver/graphql"
import { characteristicsQuery } from "domains/characteristics/graphql"
import { dashboardQuery } from "domains/dashboard/graphql"

import { Loader, ClassBadge } from "components"
import { Classes } from "domains/classes/components"

import { handleSessionExpired, getSchoolId } from "util/app"
import { sortGrades, sortNewClasses } from "util/sortUtil"
import {
  activeCurrentClassesQuery,
  deleteAllNewClasses,
} from "domains/classes/graphql"

const getClassGradesLabel = grades =>
  grades.map(grade => grade.code).join(" & ")

const entryCriteriaCharacteristicResponseCompare = (a, b) => {
  const valueA = a.characteristicResponse.value
  const valueB = b.characteristicResponse.value

  return valueB - valueA
}

class ClassesPageComponent extends React.Component {
  UNSAFE_componentWillReceiveProps = nextProps => {
    handleSessionExpired(nextProps.error)
  }

  render() {
    const {
      loadingClasses,
      loadingTwo,
      loadingThree,
      newClasses,
      settings,
      ...others
    } = this.props

    if (loadingClasses || loadingTwo || loadingThree) {
      return <Loader />
    }

    const schoolId = getSchoolId()

    const refetchQueries = [
      { query: newClassesQuery, variables: { schoolId } },
      { query: allGradesWithNewClassesQuery, variables: { schoolId } },
      {
        query: dashboardQuery,
        variables: {
          schoolId,
          adminOnly: settings.adminOnlyRequests,
        },
      },
    ]

    const formattedClasses = sortNewClasses(
      newClasses.map(c => {
        const teacherNames = c.teachers
          .map(t => t.firstName.concat(" ", t.lastName))
          .join(", ")

        const clonedGrades = Array.from(c.schoolGrades)
        sortGrades(clonedGrades)

        const entryCriteriaCharacteristicResponse =
          c.entryCriteriaCharacteristicResponse
        const sortedEntryCriteriaCharacteristicResponse = [
          ...entryCriteriaCharacteristicResponse,
        ].sort(entryCriteriaCharacteristicResponseCompare)

        return {
          ...c,
          entryCriteriaCharacteristicResponse:
            sortedEntryCriteriaCharacteristicResponse,
          schoolGrades: clonedGrades,
          labelElement: <ClassBadge label={c.label} />,
          grades: getClassGradesLabel(clonedGrades),
          teacherNames,
        }
      })
    )

    return (
      <Classes
        refetchQueries={refetchQueries}
        newClasses={formattedClasses}
        settings={settings}
        {...others}
      />
    )
  }
}

export const ClassesPage = compose(
  graphql(newClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { loading, error, newClasses } }) => ({
      loadingClasses: loading,
      error,
      newClasses,
    }),
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolCharacteristics } }) => ({
      loadingTwo: loading,
      schoolCharacteristics,
    }),
  }),
  graphql(activeCurrentClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { loading, activeCurrentClasses } }) => ({
      loadingThree: loading,
      currentClasses: activeCurrentClasses,
    }),
  }),
  graphql(deleteAllNewClasses, {
    name: "deleteAllNewClasses",
    options: () => ({
      variables: { schoolId: getSchoolId() },
      refetchQueries: [
        {
          query: newClassesQuery,
          variables: { schoolId: getSchoolId() },
        },
      ],
    }),
  })
)(ClassesPageComponent)
