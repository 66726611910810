import React from "react"

const ModalToggleButton = ({ toggle2 }) => {
  if (toggle2) {
    return (
      <a className="color-blue-mid mx-4 mt-1 font-size-14" onClick={toggle2}>
        <i className="fa fa-play mr-2" />
        {"Watch Video"}
      </a>
    )
  }

  return null
}

export const ModalHeader = ({ title, toggle, toggle2, className }) => (
  <div className={`container-fluid u-modal__header`}>
    <div className={`row  ${className}`}>
      <div
        style={{ display: "flex", flexWrap: "flex-wrap" }}
        className="mr-a mb-0">
        <h6
          style={{ maxWidth: "500px", wordWrap: "break-word" }}
          className="max-w-xs">
          {title}
        </h6>
        <ModalToggleButton toggle2={toggle2} />
      </div>
      {toggle && (
        <i className="fa fa-times u-modal__close-icon" onClick={toggle} />
      )}
    </div>
  </div>
)
