import React from "react"

import { Link } from "react-router-dom"

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"

import { InlineError } from "components"
import {
  ActionBar,
  AttributeLabel,
  MoreDropdown,
  ReportsDropdown,
  SolverPrompt,
  EditBottomView,
} from "domains/solver/components"

import { UnassignedTeacherContent } from "./unassignedTeacherContent"

import * as resolverTypes from "constants/resolverTypes"
import {
  AT_LEAST_ONE_FRIEND,
  MANDATORY_REQUESTS,
  IMPORTANT_REQUESTS,
  DIFFERENT_TEACHER,
  GENDER_BALANCE,
  NORMAL,
  SHARED_READ_ONLY,
  SHARED_WRITABLE,
  CLASS_ENTRY_CRITERIA,
} from "domains/solver/constants"
import Logo from "images/logo.png"
import ReactMarkdown from "react-markdown"
import { PERMISSIONS } from "constants/storageTokens"
import { pluralize } from "util/nameUtil"
import { MinusCircleIcon } from "images/minusCircleIcon"

export class SideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      studentModal: false,
      promptModal: false,
      dropdownReportsOpen: false,
      dropdownExportsOpen: false,
      solutionsDropDownOpen: false,
    }
  }

  togglePanel = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  toggleDropdownReports = () => {
    this.setState(prevState => ({
      dropdownReportsOpen: !prevState.dropdownReportsOpen,
    }))
  }

  toggleDropdownExports = () => {
    this.setState(prevState => ({
      dropdownExportsOpen: !prevState.dropdownExportsOpen,
    }))
  }

  print = () => window.print()

  togglePrompt = () => {
    this.setState({
      promptModal: !this.state.promptModal,
    })
  }

  studentModalToggle = () => {
    const {
      studentFlags: { studentUpdated },
      updateStudentEditFlag,
    } = this.props

    const isOpen = !this.state.studentModal

    this.setState({ studentModal: isOpen })
    // if closing the modal and the student has been updated
    if (!isOpen && studentUpdated) {
      this.props.solverRefetch()
      // clear the flag
      updateStudentEditFlag({
        variables: { type: resolverTypes.STUDENT_UPDATED, value: false },
      })
    }
  }

  render() {
    const {
      isOpen,
      togglePanel,
      undoMoves,
      redoMoves,
      schoolSettings,
      selectedStudent,
      solveActiveGrade,
      solverGrades,
      activeGradeId,
      onActiveGradeChange,
      metrics = {},
      studentMetrics = [],
      activeGradeCount,
      activeCharacteristic,
      onActiveCharacteristicChange,
      onUndoRedoClick,
      asyncSolveInProgress,
      onBoostSolutionMetricClick,
      onBoostCharacteristicClick,
      onBoostStudentMetricClick,
      csvExport,
      sharedSolutionCsvExport,
      shareSolution,
      metricDifferences,
      errors,
      solverMode,
      refetchQueries,
      hasSolution,
      showMetrics,
      showCharacteristics,
      showTeacherRequests,
      showStudentRequests,
      showFriendships,
      currentClasses,
      hasAsyncSolveInProgress,
      solutionSuggestions,
      unassignedTeacherPrompt,
      toggleCharacteristicResponseSort,
      showClassEntryCriteria,
      location,
      assignments,
      favourites,
      favouriteAnAssignmentMutation,
      unfavouriteAnAssignmentMutation,
      updateFavouritedAssignmentMutation,
      pickAnAssignment,
      assignmentWithClasses,
    } = this.props
    const {
      studentModal,
      dropdownExportsOpen,
      dropdownReportsOpen,
      solutionsDropDownOpen,
      promptModal,
    } = this.state

    const {
      atLeastOneFriend = {},
      characteristicScores = {},
      mandatoryRequests = {},
      importantRequests = {},
      genderBalance = {},
      differentTeacher = {},
      classEntryCriteria = {},
    } = metrics || {}

    const writable = solverMode === NORMAL || solverMode === SHARED_WRITABLE

    const panelClass = isOpen ? "open" : "close"
    const iconClass = isOpen ? "fa-angle-left" : "fa-angle-right"

    const friendPreferences = schoolSettings.maxFriends > 0
    const differentTeacherSetting = schoolSettings.avoidSameTeacher
    const minFriendPrefs = schoolSettings.minFriendPrefs

    const friendshipSuggestion =
      solutionSuggestions &&
      solutionSuggestions.find(({ type }) => type === "FRIENDSHIP_SETTING")

    const friendshipSuggestionContent = friendshipSuggestion
      ? {
          id: "friendship-setting",
          tooltip: (
            <div className="p-1">
              <div className="d-flex align-items-center">
                <h2 className="mb-0 mr-1">Tip: Green Warning Flags</h2>
                <div className="c-badge__no-friend-pref">
                  <MinusCircleIcon />
                </div>
              </div>

              <ReactMarkdown>{friendshipSuggestion.text}</ReactMarkdown>
            </div>
          ),
        }
      : null

    const unassignedTeacherWarning = unassignedTeacherPrompt
      ? {
          id: "unassignedteacher-setting",
          tooltip: UnassignedTeacherContent(),
        }
      : null

    let metricsToRender = [
      {
        label: `At Least ${pluralize(minFriendPrefs, "Friend", "Friends")}`,
        className: "mt-4",
        icon: "_badge bg-friendships mr-1",
        count: friendPreferences ? atLeastOneFriend.count : "-",
        total: atLeastOneFriend.total,
        onClick: e => {
          onBoostSolutionMetricClick(e, AT_LEAST_ONE_FRIEND)
        },
        difference: metricDifferences.atLeastOneFriend,
        disabled: !friendPreferences,
        suggestionContent: friendshipSuggestionContent,
      },
      {
        label: "Mandatory Requests",
        icon: "_badge bg-requests-mandatory",
        count: mandatoryRequests.count,
        total: mandatoryRequests.total,
        onClick: e => onBoostSolutionMetricClick(e, MANDATORY_REQUESTS),
        difference: metricDifferences.mandatoryRequests,
        warningContent: unassignedTeacherWarning,
      },
      {
        label: "Important Requests",
        className: "mt-4",
        icon: "_badge bg-requests-important mr-1",
        count: importantRequests.count,
        total: importantRequests.total,
        onClick: e => {
          onBoostSolutionMetricClick(e, IMPORTANT_REQUESTS)
        },
        difference: metricDifferences.importantRequests,
        warningContent: unassignedTeacherWarning,
      },
    ]

    if (showClassEntryCriteria) {
      metricsToRender = metricsToRender.concat([
        {
          label: "Class Entry Criteria",
          className: "mt-4",
          icon: "_badge bg-class-entry-criteria mr-1",
          count: classEntryCriteria.count,
          total: classEntryCriteria.total,
          onClick: e => {
            onBoostSolutionMetricClick(e, CLASS_ENTRY_CRITERIA)
          },
          difference: metricDifferences.classEntryCriteria,
        },
      ])
    }

    // Only show these metrics if in edit mode
    if (solverMode === NORMAL || solverMode === SHARED_WRITABLE) {
      metricsToRender = metricsToRender.concat([
        {
          label: "Different Teacher",
          className: "mt-4",
          icon: "_badge bg-requests-teacher mr-1",
          count: differentTeacherSetting ? differentTeacher.count : "-",
          total: differentTeacher.total,
          onClick: e => {
            onBoostSolutionMetricClick(e, DIFFERENT_TEACHER)
          },
          difference: metricDifferences.differentTeacher,
          disabled: !differentTeacherSetting,
        },
        {
          label: "Gender Balance",
          className: "mt-4",
          icon: "_badge _badge-white mr-1",
          count: genderBalance.count,
          total: genderBalance.total,
          percentage: true,
          onClick: e => {
            onBoostSolutionMetricClick(e, GENDER_BALANCE)
          },
          difference: metricDifferences.genderBalance,
        },
      ])
    }

    const permissions = JSON.parse(sessionStorage.getItem(PERMISSIONS))

    return (
      <div className="c-side-bar d-print-none">
        <span
          className={`c-side-bar__hide-button c-side-bar__hide-button--${panelClass} d-flex align-items-center justify-content-center cursor-pointer d-print-none`}
          onClick={togglePanel}>
          <i className={"fa " + iconClass} />
        </span>
        <div>
          <div
            className={`c-side-bar__panel p-4 c-side-bar__panel--${panelClass}`}>
            {solverMode === NORMAL && (
              <>
                <React.Fragment>
                  <div className="container c-side-bar__panel__minor-actions-container pb-4 d-print-none">
                    <div className="row">
                      <div className="col-8 pl-0 pr-1">
                        <ReportsDropdown
                          dropdownReportsOpen={dropdownReportsOpen}
                          toggle={this.toggleDropdownReports}
                        />
                      </div>
                      <div className="col p-0">
                        <MoreDropdown
                          dropdownExportsOpen={dropdownExportsOpen}
                          toggle={this.toggleDropdownExports}
                          solverMode={solverMode}
                          print={this.print}
                          csvExport={csvExport}
                          shareSolution={shareSolution}
                        />
                      </div>
                    </div>
                  </div>
                  {errors.export && <InlineError text={errors.export} />}
                  <div className="c-side-bar__panel__divider-line d-print-none" />
                </React.Fragment>
              </>
            )}
            {(solverMode === SHARED_READ_ONLY ||
              solverMode === SHARED_WRITABLE) && (
              <>
                <img className="w-50 my-4" src={Logo} alt="Class Solver Logo" />
                <div className="d-flex justify-content-between my-3">
                  <Button
                    className="mr-1 flex-grow-1"
                    disabled={!hasSolution}
                    color="secondary"
                    onClick={this.print}>
                    Print
                  </Button>
                  {permissions.showFriendships ? (
                    <Dropdown
                      className="ml-1 "
                      isOpen={dropdownExportsOpen}
                      toggle={this.toggleDropdownExports}>
                      <DropdownToggle caret disabled={!hasSolution}>
                        More ...
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            sharedSolutionCsvExport(activeGradeId)
                          }>
                          Export
                        </DropdownItem>
                        <DropdownItem>
                          <Link
                            // We can't use `useLocation` or `useSearchParams` here because
                            // this is not functional component. Instead we need to pass it
                            // down from the parent component
                            to={`sociogram${location.search}`}>
                            Sociogram View
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <Button
                      className="ml-1 flex-grow-1"
                      disabled={!hasSolution}
                      color="secondary"
                      onClick={() => sharedSolutionCsvExport(activeGradeId)}>
                      Export
                    </Button>
                  )}
                </div>
              </>
            )}
            <div>
              <ActionBar
                hasSolution={hasSolution}
                canUndo={undoMoves.length > 0}
                canRedo={redoMoves.length > 0}
                onUndoRedoClick={onUndoRedoClick}
                asyncSolveInProgress={asyncSolveInProgress}
                activeGradeId={activeGradeId}
                onActiveGradeChange={onActiveGradeChange}
                solverGrades={solverGrades}
                toggle={hasSolution ? this.togglePrompt : solveActiveGrade}
                errors={errors}
                solverMode={solverMode}
                hasAsyncSolveInProgress={hasAsyncSolveInProgress}
                assignments={assignments}
                favourites={favourites}
                solutionsDropDownOpen={solutionsDropDownOpen}
                favouriteAnAssignmentMutation={favouriteAnAssignmentMutation}
                unfavouriteAnAssignmentMutation={
                  unfavouriteAnAssignmentMutation
                }
                updateFavouritedAssignmentMutation={
                  updateFavouritedAssignmentMutation
                }
                pickAnAssignment={pickAnAssignment}
                assignmentWithClasses={assignmentWithClasses}
              />
            </div>
            <span className="c-side-bar__count">
              Total - {activeGradeCount} Students
            </span>

            <div>
              {showMetrics &&
                metricsToRender.map(
                  ({
                    label,
                    icon,
                    count,
                    total,
                    onClick,
                    difference,
                    percentage,
                    suggestionContent,
                    warningContent,
                  }) => (
                    <AttributeLabel
                      key={label}
                      label={label}
                      className="mt-4"
                      icon={`${icon} mr-1`}
                      count={count}
                      total={total}
                      percentage={percentage}
                      onClick={writable && onClick}
                      difference={difference}
                      showMetric={
                        solverMode === NORMAL || solverMode === SHARED_WRITABLE
                      }
                      suggestionContent={suggestionContent}
                      warningContent={warningContent}
                    />
                  )
                )}
            </div>
            <EditBottomView
              activeCharacteristic={activeCharacteristic}
              onActiveCharacteristicChange={onActiveCharacteristicChange}
              characteristicScores={characteristicScores}
              onBoostCharacteristicClick={onBoostCharacteristicClick}
              metricDifferences={metricDifferences}
              selectedStudent={selectedStudent}
              studentMetrics={studentMetrics}
              onBoostStudentMetricClick={onBoostStudentMetricClick}
              friendPreferences={friendPreferences}
              differentTeacherSetting={differentTeacherSetting}
              studentModal={studentModal}
              studentModalToggle={this.studentModalToggle}
              refetchQueries={refetchQueries}
              solverMode={solverMode}
              showCharacteristics={showCharacteristics}
              showTeacherRequests={showTeacherRequests}
              showStudentRequests={showStudentRequests}
              showFriendships={showFriendships}
              currentClasses={currentClasses}
              toggleCharacteristicResponseSort={
                toggleCharacteristicResponseSort
              }
              showClassEntryCriteria={showClassEntryCriteria}
            />
            <SolverPrompt
              isOpen={promptModal}
              toggle={this.togglePrompt}
              solveActiveGrade={solveActiveGrade}
            />
          </div>
        </div>
      </div>
    )
  }
}
