import React, { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"

import {
  updateNewGradeForStudents,
  studentsQuery,
} from "domains/students/graphql"
import {
  newSchoolGradesQuery,
  currentSchoolGradesQuery,
} from "domains/classes/graphql"

import { Modal, ModalBody, Label } from "reactstrap"
import {
  ButtonBar,
  InlineError,
  ModalHeader,
  ModalNote,
  Loader,
} from "components"

import { defaultSelectStyles } from "components"

import Select from "react-select"

import { AccountTypeContext } from "config/accountTypeContext"

export const UpdateNewGradeModal = ({ toggle, schoolId, adminOnly }) => {
  const [selectedGradeValue1, setSelectedGradeValue1] = useState("")
  const [selectedGradeValue2, setSelectedGradeValue2] = useState("")

  const [successfulUpdate, setSuccessfulUpdate] = useState(false)
  const [updateError, setUpdateError] = useState(false)

  const { data: currentSchoolGradesData, loading: loadingCurrentSchoolGrades } =
    useQuery(currentSchoolGradesQuery, {
      variables: { schoolId: schoolId },
    })

  const { data: newSchoolGradesData, loading: loadingNewSchoolGrades } =
    useQuery(newSchoolGradesQuery, {
      variables: {
        schoolId: schoolId,
        includeLeaving: true,
      },
    })

  const [updateNewGradeForStudentsMutation, { loading: renamingGrade }] =
    useMutation(updateNewGradeForStudents, {
      refetchQueries: [
        {
          query: studentsQuery,
          variables: { adminOnly: adminOnly, schoolId: schoolId },
          fetchPolicy: "network-only",
        },
      ],
    })

  const onChangeSelectedGradeValue1 = e => {
    setSelectedGradeValue1(e.value)
  }

  const onChangeSelectedGradeValue2 = e => {
    setSelectedGradeValue2(e.value)
  }

  const convertToGradeOption = grade => {
    return {
      label: grade.label,
      value: grade.id,
    }
  }

  const currentGradeOptions = currentSchoolGradesData
    ? currentSchoolGradesData.currentSchoolGrades.map(convertToGradeOption)
    : []

  const newGradeOptions = newSchoolGradesData
    ? newSchoolGradesData.newSchoolGrades.map(convertToGradeOption)
    : []

  // TODO Nick: Re examine the following.
  // The logic of this function is performed twice which is technically unnecessary. However it was implemented this way to avoid a type issue.
  // selectedGradeValue1 was originally just selectedGrade. In order for Select to display the placeholder text it needs to be an empty string? But when passed into the Select
  // as a value, it seems to need to be an object? This causes a slight awkardness with the type.
  // Not sure this is the best solution.

  const getGradeObjectFromValue = (gradeOptions, value) => {
    return gradeOptions.find(c => c.value === value)
  }

  const updateGrade = () => {
    const gradeToUpdate1 = getGradeObjectFromValue(
      currentGradeOptions,
      selectedGradeValue1
    )
    const gradeToUpdate2 = getGradeObjectFromValue(
      newGradeOptions,
      selectedGradeValue2
    )

    const updateNewGradeParams = {
      schoolId: schoolId,
      currentSchoolGradeId: gradeToUpdate1.value,
      newSchoolGradeId: gradeToUpdate2.value,
    }

    updateNewGradeForStudentsMutation({
      variables: { updateNewGradeParams },
    })
      .then(() => {
        setSelectedGradeValue1("")
        setSelectedGradeValue2("")
        setUpdateError(false)
        setSuccessfulUpdate(true)
      })
      .catch(() => {
        setSuccessfulUpdate(false)
        setUpdateError(true)
      })
  }

  const loading =
    loadingCurrentSchoolGrades || loadingNewSchoolGrades || renamingGrade

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-40rem">
      <ModalHeader
        className="p-4"
        title={`Update ${gettextObj.gettext("New Grade")}`}
        toggle={toggle}
      />
      {loading ? (
        <div className="position-relative p-5">
          <Loader />
        </div>
      ) : (
        <>
          <ModalNote
            text={`This feature enables you to change the ${gettextObj.gettext(
              "New Grade"
            )} for students in a Current Grade. To update the ${gettextObj.gettext(
              "New Grade"
            )}, select the Current Grade from the drop-down on the left, then select the ${gettextObj.gettext(
              "New Grade"
            )} on the right.  Then click Update.  Repeat if you would like to make multiple updates.`}
          />

          <ModalBody className="py-4 px-5">
            <div className="d-flex py-2 justify-content-between">
              <div className="d-flex-col w-40 h-24">
                <Label>Current Grade</Label>
                <Select
                  id="current grades"
                  className="c-input__multiselect"
                  placeholder="Select Current Grade..."
                  options={currentGradeOptions}
                  value={getGradeObjectFromValue(
                    currentGradeOptions,
                    selectedGradeValue1
                  )}
                  onChange={onChangeSelectedGradeValue1}
                  closeMenuOnSelect={true}
                  isSingle
                  styles={defaultSelectStyles}
                />
              </div>
              <div className="px-3 pt-3 mt-4">goes to</div>
              <div className="w-40">
                <Label>{gettextObj.gettext("New Grade")}</Label>
                <Select
                  id="new grades"
                  className="c-input__multiselect"
                  placeholder={`Select ${gettextObj.gettext("New Grade")}...`}
                  options={newGradeOptions}
                  value={getGradeObjectFromValue(
                    newGradeOptions,
                    selectedGradeValue2
                  )}
                  onChange={onChangeSelectedGradeValue2}
                  closeMenuOnSelect={true}
                  isSingle
                  styles={defaultSelectStyles}
                />
              </div>
            </div>
            <ButtonBar
              className="mt-4"
              buttonText="Update"
              onButtonClick={updateGrade}
              onCancelClick={toggle}
              disabled={
                selectedGradeValue1 === "" || selectedGradeValue2 === ""
              }
            />
            {updateError && (
              <InlineError
                className="pr-4"
                text="Error - could not update grade."
              />
            )}
            {successfulUpdate && (
              <p className="text-success text-right pt-2">
                Success! The label has been updated.
              </p>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  )
}
